import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { endooSpotManagementAuthModule } from '../Login/endooSpotManagementAuth.module'
import { apListComponent } from './APList/apList.component';
import { apGlobalComponent } from './APGlobal/apGlobal.component';
import { apGlobalBasicSettingsComponent } from './APGlobal/APGlobalBasicSettings/apGlobalBasicSettings.component';
import { apGlobalTimeSettingsComponent } from './APGlobal/APGlobalTimeSettings/apGlobalTimeSettings.component';
import { ApDeviceService } from './APService/apDevice.service';
import { WifiSettingsService } from './APService/WifiSettings.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { NewApDialogComponent } from './NewAp/newAp.dialog.component';
import { APDetailDialogComponent } from './APDetail/APDetailDialog.component';
import { AddApDialogComponent } from './AddAP/AddAp.dialog.component';
import { ModelService } from './APService/model.service';
import { WifiCard1Component } from './APDetail/wifiCard1-component/wifiCard1.component';
import { WifiCard2Component } from './APDetail/wifiCard2-component/wifiCard2.component';
import { SsidsComponent } from './APDetail/ssids-component/ssids.component';
import { endooSpotPlotlyGraphModule } from 'app/Dashboard/Plotly/endooSpotPlotlyGraph.module';
import { ConnectedStationsComponent } from './APDetail/connected-stations/connected-stations.component';
import { PortConfigurationComponent } from './APDetail/port-configuration/port-configuration.component';
import { SortNumbersPipe } from './APDetail/port-configuration/sort-numbers.pipe';
import { endooSpotComponentsModule } from 'app/Components/endooSpotComponents.module';
import { RouterModule } from '@angular/router';
import { SwitchSettingsComponent } from './APDetail/switch-settings/switch-settings.component';
import { GlobalSwitchSettingsComponent } from './GlobalSwitchSettings/globalSwitchSettings.component';
import { GlobalSwitchSettingsService } from './APService/globalSwitchSettings.service';
import { MatMenuModule } from '@angular/material/menu';
import { RemoveDeviceDialogComponent } from './APDetail/remove-device-dialog/remove-device-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultipleApSaveConfirmDialogComponent } from './APDetail/MultipleApSaveConfirmDialog/multipleApSaveConfirm.dialog.component';
import { ApDetailService } from './APDetail/apDetail.service';
import { DevicesListPipe } from './APDetail/devices-list.pipe';
import { NotSavedDialogComponent } from './APDetail/NotSavedDialog/not-saved-dialog.component';
import { SwitchService } from './APService/switch-device.service';
import { QRCodeModule } from 'angularx-qrcode';
import { RouterGlobalComponent } from './RouterGlobal/routerGlobal.component';
import { RouterGlobalService } from './APService/routerGlobal.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatGridListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatMenuModule,
        MatRadioModule,
        MatTooltipModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        endooSpotManagementAuthModule,
        endooSpotPlotlyGraphModule,
        endooSpotComponentsModule,
        QRCodeModule,
    ],
    declarations: [
        apListComponent,
        apGlobalComponent,
        apGlobalBasicSettingsComponent,
        apGlobalTimeSettingsComponent,
        GlobalSwitchSettingsComponent,
        NewApDialogComponent,
        AddApDialogComponent,
        APDetailDialogComponent,
        WifiCard1Component,
        WifiCard2Component,
        SsidsComponent,
        ConnectedStationsComponent,
        PortConfigurationComponent,
        SortNumbersPipe,
        SwitchSettingsComponent,
        RemoveDeviceDialogComponent,
        MultipleApSaveConfirmDialogComponent,
        DevicesListPipe,
        NotSavedDialogComponent,
        RouterGlobalComponent,
    ],
    providers: [
        ApDeviceService,
        WifiSettingsService,
        ModelService,
        GlobalSwitchSettingsService,
        RouterGlobalService,
        ApDetailService,
        SwitchService,
    ],
    exports: [
        apListComponent,
        apGlobalComponent,
    ],
})
export class endooSpotAPManagementModule { }
