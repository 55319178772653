import { Injectable } from '@angular/core';
import { DBCustomerFunctionProperty, DBProperty } from '../../data/endooSpotDB_objects';
import { forkJoin, Observable, throwError } from 'rxjs';
import { mergeAll, mergeMap, map } from 'rxjs/operators';
import { CustomerFunctionPropertyService } from '../../endooSpotApplication/Services/CustomerFunctionPropertyService';
import { WifiNetwork } from '../../data/WifiNetwork';

@Injectable()
export class WifiNetworkService {
    private wifiNetworkFunctionIDs: string[] = ['WifiNetwork1', 'WifiNetwork2', 'WifiNetwork3', 'WifiNetwork4', 'WifiNetwork5', 'WifiNetwork6', 'WifiNetwork7', 'WifiNetwork8'];
    private maxNetworkID: number = 0;
    private numberOfNetworks: number = 0;

    constructor(private cfpService: CustomerFunctionPropertyService) { }

    get WifiNetworkFunctionIDs() {
        return this.wifiNetworkFunctionIDs;
    }

    /**
     * returns all Wifi Networks configured for the specific school
     */
    getWifiNetworks(): Observable<WifiNetwork[]> {
        return this.cfpService.getListCustomerFunctionProperties(this.wifiNetworkFunctionIDs).pipe(map(
            (result: DBCustomerFunctionProperty[][]) => { // get all properties belonging to the different wifi networks
                const wifiNetworks: WifiNetwork[] = [];
                this.maxNetworkID = 0;
                this.numberOfNetworks = 0;
                for (let t = 0; t < result.length; t++) {
                    const response: DBCustomerFunctionProperty[] = result[t];
                    let wifiNetwork: WifiNetwork;
                    if (response.length > 0) {
                        wifiNetwork = new WifiNetwork( // use wrapper object to work with customer function properties
                            Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_id_property)), // wifi network id
                            Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_logical_networkID)), // network_id of logical network
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_client2client) === 'true', // clienttoclient
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_ssid), // ssid
                            (this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_enabled) || 'true') === 'true', // activated
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_hidessid) === 'true', // hide_ssid
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_encryption) === 'true', // encryption
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_encryption_mode), // encryption_mode
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_encryption_key), // encryption_key
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_radius_ip), // radius_ip
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_radius_secret), // radius_secret
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_radius_port), // radius_port
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifitime_active) === 'true', // wifitime active
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_mon_on), // wifi_time_mon_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_tue_on), // wifi_time_tue_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_wed_on), // wifi_time_wed_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_thu_on), // wifi_time_thu_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_fri_on), // wifi_time_fri_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_sat_on), // wifi_time_sat_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_sun_on), // wifi_time_sun_on
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_mon_off), // wifi_time_mon_off
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_tue_off), // wifi_time_tue_off
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_wed_off), // wifi_time_wed_off
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_thu_off), // wifi_time_thu_off
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_fri_off), // wifi_time_fri_off
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_sat_off), // wifi_time_sat_off
                            this.cfpService.getCustomerFunctionPropertyValue(response, this.wifiNetworkFunctionIDs[t], DBProperty.wifi_network_wifi_time_sun_off), // wifi_time_sun_off
                        );
                        if (wifiNetwork.wifi_network_id > this.maxNetworkID) { // update max network id if there is an network with higher network_id
                            this.maxNetworkID = wifiNetwork.wifi_network_id;
                        }
                        this.numberOfNetworks = t + 1; // if network exists update number of networks to actual index t+1 (because index starts at 0)
                    }
                    if (wifiNetwork !== undefined) {
                        wifiNetworks[t] = wifiNetwork;
                    }
                }
                return wifiNetworks;
            }
        ));
    }

    /**
     * Function to create a new network in the system.
     * @param new_network Object which contains information about the new network that should be added
     */
    createWifiNetwork(new_network: WifiNetwork): Observable<void> {
        if (this.numberOfNetworks >= this.wifiNetworkFunctionIDs.length) {
            return throwError('Maximale Anzahl an Netzwerken erreicht');
        }

        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_id_property, new_network.wifi_network_id.toString()); // wifi_network_id
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_logical_networkID, new_network.logical_network_id.toString()); // network_id of logical network
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_client2client, String(new_network.client2client)); // client2client
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_ssid, new_network.ssid); // ssid
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_encryption, String(new_network.encryption)); // encryption
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_encryption_mode, new_network.encryption_mode); // encryption_mode
        setRequests[6] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_encryption_key, new_network.encryption_key); // encryption_key
        setRequests[7] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_radius_ip, new_network.encryption_radius_ip); // radius_ip
        setRequests[8] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_radius_secret, new_network.encryption_radius_secret); // radius_secret
        setRequests[9] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_radius_port, new_network.encryption_radius_port.toString()); // radius_port
        setRequests[10] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifitime_active, String(new_network.wifitime_active)); // wifitime active
        setRequests[11] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_mon_on, new_network.wifitime_mon_on); // wifi_time_mon_on
        setRequests[12] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_tue_on, new_network.wifitime_tue_on); // wifi_time_tue_on
        setRequests[13] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_wed_on, new_network.wifitime_wed_on); // wifi_time_wed_on
        setRequests[14] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_thu_on, new_network.wifitime_thu_on); // wifi_time_thu_on
        setRequests[15] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_fri_on, new_network.wifitime_fri_on); // wifi_time_fri_on
        setRequests[16] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_sat_on, new_network.wifitime_sat_on); // wifi_time_sat_on
        setRequests[17] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_sun_on, new_network.wifitime_sun_on); /// wifi_time_sun_on
        setRequests[18] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_mon_off, new_network.wifitime_mon_off); /// wifi_time_mon_off
        setRequests[19] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_tue_off, new_network.wifitime_tue_off); // wifi_time_tue_off
        setRequests[20] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_wed_off, new_network.wifitime_wed_off); // wifi_time_wed_off
        setRequests[21] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_thu_off, new_network.wifitime_thu_off); // wifi_time_thu_off
        setRequests[22] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_fri_off, new_network.wifitime_fri_off); // wifi_time_fri_off
        setRequests[23] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_sat_off, new_network.wifitime_sat_off); // wifi_time_sat_off
        setRequests[24] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_wifi_time_sun_off, new_network.wifitime_sun_off); // wifi_time_sun_off
        setRequests[25] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_hidessid, String(new_network.hide_ssid)); // hide_ssid
        setRequests[26] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[this.numberOfNetworks], DBProperty.wifi_network_enabled, String(new_network.activated)); // activated

        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Function to change a logical network in the system.
     * @param index index of the network (2 for second logical network)
     * @param new_network Object which contains information about the new network that should be added
     */
    saveWifiNetwork(index: number, new_network: WifiNetwork): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_id_property, new_network.wifi_network_id.toString()); // network_id of logical network
        setRequests[24] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_logical_networkID, new_network.logical_network_id.toString()); // network_id of logical network
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_client2client, String(new_network.client2client)); // client2client
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_ssid, new_network.ssid); // ssid
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_encryption, String(new_network.encryption)); // encryption
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_encryption_mode, new_network.encryption_mode); // encryption_mode
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_encryption_key, new_network.encryption_key); // encryption_key
        setRequests[6] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_radius_ip, new_network.encryption_radius_ip); //radius_ip
        setRequests[7] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_radius_secret, new_network.encryption_radius_secret); // radius_secret
        setRequests[8] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_radius_port, new_network.encryption_radius_port.toString()); // radius_port
        setRequests[9] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifitime_active, String(new_network.wifitime_active)); // wifitime active
        setRequests[10] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_mon_on, new_network.wifitime_mon_on); // wifi_time_mon_on
        setRequests[11] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_tue_on, new_network.wifitime_tue_on); // wifi_time_tue_on
        setRequests[12] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_wed_on, new_network.wifitime_wed_on); // wifi_time_wed_on
        setRequests[13] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_thu_on, new_network.wifitime_thu_on); // wifi_time_thu_on
        setRequests[14] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_fri_on, new_network.wifitime_fri_on); // wifi_time_fri_on
        setRequests[15] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_sat_on, new_network.wifitime_sat_on); // wifi_time_sat_on
        setRequests[16] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_sun_on, new_network.wifitime_sun_on); // /wifi_time_sun_on
        setRequests[17] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_mon_off, new_network.wifitime_mon_off); // /wifi_time_mon_off
        setRequests[18] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_tue_off, new_network.wifitime_tue_off); // wifi_time_tue_off
        setRequests[19] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_wed_off, new_network.wifitime_wed_off); // wifi_time_wed_off
        setRequests[20] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_thu_off, new_network.wifitime_thu_off); // wifi_time_thu_off
        setRequests[21] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_fri_off, new_network.wifitime_fri_off); // wifi_time_fri_off
        setRequests[22] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_sat_off, new_network.wifitime_sat_off); // wifi_time_sat_off
        setRequests[23] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_wifi_time_sun_off, new_network.wifitime_sun_off); // wifi_time_sun_off
        setRequests[25] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_hidessid, String(new_network.hide_ssid)); // hide ssid
        setRequests[26] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[index], DBProperty.wifi_network_enabled, String(new_network.activated)); // activated

        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Function to delete a logical network from the the system.
     * @param network Object which contains information about the logical network that should be deleted
     */
    deleteWifiNetwork(network: WifiNetwork): Observable<void> {
        return this.getWifiNetworks().pipe(mergeMap(// go through all logical network and after deleted network has been found all following networks will be stored one network lower
            (result: WifiNetwork[]) => {
                let deleted: boolean = false;
                const setRequests: Observable<void>[] = [];
                let i = 0;
                for (let t = 0; t < result.length; t++) {
                    const response: WifiNetwork = result[t];
                    if (deleted === true) {
                        setRequests[i] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_id_property, response.wifi_network_id.toString()); // network_id of logical network
                        setRequests[i + 24] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_logical_networkID, response.logical_network_id.toString()); // network_id of logical network
                        setRequests[i + 1] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_client2client, String(response.client2client)); // client2client
                        setRequests[i + 2] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_ssid, response.ssid); // ssid
                        setRequests[i + 3] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_encryption, String(response.encryption)); // encryption
                        setRequests[i + 4] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_encryption_mode, response.encryption_mode); // encryption_mode
                        setRequests[i + 5] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_encryption_key, response.encryption_key); // encryption_key
                        setRequests[i + 6] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_radius_ip, response.encryption_radius_ip); // radius_ip
                        setRequests[i + 7] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_radius_secret, response.encryption_radius_secret); // radius_secret
                        setRequests[i + 8] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_radius_port, response.encryption_radius_port); // radius_port
                        setRequests[i + 9] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifitime_active, String(response.wifitime_active)); // wifitime active
                        setRequests[i + 10] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_mon_on, response.wifitime_mon_on); // wifi_time_mon_on
                        setRequests[i + 11] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_tue_on, response.wifitime_tue_on); // wifi_time_tue_on
                        setRequests[i + 12] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_wed_on, response.wifitime_wed_on); // wifi_time_wed_on
                        setRequests[i + 13] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_thu_on, response.wifitime_thu_on); // wifi_time_thu_on
                        setRequests[i + 14] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_fri_on, response.wifitime_fri_on); // wifi_time_fri_on
                        setRequests[i + 15] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_sat_on, response.wifitime_sat_on); // wifi_time_sat_on
                        setRequests[i + 16] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_sun_on, response.wifitime_sun_on); // /wifi_time_sun_on
                        setRequests[i + 17] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_mon_off, response.wifitime_mon_off); // /wifi_time_mon_off
                        setRequests[i + 18] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_tue_off, response.wifitime_tue_off); // wifi_time_tue_off
                        setRequests[i + 19] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_wed_off, response.wifitime_wed_off); // wifi_time_wed_off
                        setRequests[i + 20] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_thu_off, response.wifitime_thu_off); // wifi_time_thu_off
                        setRequests[i + 21] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_fri_off, response.wifitime_fri_off); // wifi_time_fri_off
                        setRequests[i + 22] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_sat_off, response.wifitime_sat_off); // wifi_time_sat_off
                        setRequests[i + 23] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_wifi_time_sun_off, response.wifitime_sun_off); // wifi_time_sun_off
                        setRequests[i + 25] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_hidessid, String(response.hide_ssid)); // hide ssid
                        setRequests[i + 26] = this.cfpService.setCustomerFunctionProperty(this.wifiNetworkFunctionIDs[t - 1], DBProperty.wifi_network_enabled, String(response.activated)); // hide ssid
                        i = i + 27;
                    }
                    if (response.wifi_network_id === network.wifi_network_id) {
                        deleted = true; // set deleted to true so that after that all networks will be stored one time lower
                    }
                }
                setRequests[i] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_id_property); // wifi_network id
                setRequests[i + 24] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_logical_networkID); // network_id of logical network
                setRequests[i + 1] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_client2client); // client2client
                setRequests[i + 2] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_ssid); // ssid
                setRequests[i + 3] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_encryption); // encryption
                setRequests[i + 4] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_encryption_mode); // encryption_mode
                setRequests[i + 5] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_encryption_key); // encryption_key
                setRequests[i + 6] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_radius_ip); // radius_ip
                setRequests[i + 7] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_radius_secret); // radius_secret
                setRequests[i + 8] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_radius_port); // radius_port
                setRequests[i + 9] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifitime_active); // wifitime active
                setRequests[i + 10] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_mon_on); // wifi_time_mon_on
                setRequests[i + 11] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_tue_on); // wifi_time_tue_on
                setRequests[i + 12] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_wed_on); // wifi_time_wed_on
                setRequests[i + 13] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_thu_on); // wifi_time_thu_on
                setRequests[i + 14] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_fri_on); // wifi_time_fri_on
                setRequests[i + 15] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_sat_on); // wifi_time_sat_on
                setRequests[i + 16] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_sun_on); // /wifi_time_sun_on
                setRequests[i + 17] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_mon_off); // /wifi_time_mon_off
                setRequests[i + 18] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_tue_off); // wifi_time_tue_off
                setRequests[i + 19] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_wed_off); // wifi_time_wed_off
                setRequests[i + 20] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_thu_off); // wifi_time_thu_off
                setRequests[i + 21] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_fri_off); // wifi_time_fri_off
                setRequests[i + 22] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_sat_off); // wifi_time_sat_off
                setRequests[i + 23] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_wifi_time_sun_off); // wifi_time_sun_off
                setRequests[i + 25] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_hidessid); // hide_ssid
                setRequests[i + 26] = this.cfpService.removeCustomerFunctionProperty(this.wifiNetworkFunctionIDs[result.length - 1], DBProperty.wifi_network_enabled); // hide ssid

                return forkJoin(setRequests).pipe(mergeAll());
            }));
    }

    public getMaxNetworkID(): number {
        //await this.getWifiNetworks().toPromise(); // first update highest max networkID by getting actual networks
        return this.maxNetworkID;
    }

    async networkNameExists(wifiNetworkName: string): Promise<boolean> {
        return (await this.getWifiNetworks().toPromise()).some(wifiNetwork => wifiNetwork.ssid === wifiNetworkName);
    }
}
