<div [class.box]="!showRegisterLink" [class.box-min-height]="showRegisterLink">
    <div *ngIf="showRegisterLink" class="homepageLink" id="header">
        <a href="https://endoospot.de">
            <mat-icon style="vertical-align: middle;">exit_to_app</mat-icon>
            Zur endoo Website
        </a>
    </div>
    <div id="main">
        <div id="content">
            <div id="div-top">
                <img width="128" height="40" src="/assets/img/endoo_logo.png" draggable="false">
                <h2 class="user-select-none" id="title">
                    {{appTitle}}
                </h2>
            </div>
            <div id="div-bottom">
                <form (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
                    <div class="fullwidth_form">
                        <mat-form-field appearance="outline">
                            <mat-label>Benutzername</mat-label>
                            <input matInput type="text" name="username" [(ngModel)]="username" required
                                #usernameField />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Passwort</mat-label>
                            <input matInput type="password" name="password" [(ngModel)]="password" required
                                #passwordField />
                        </mat-form-field>
                        <button style="margin-top: 10px;" mat-raised-button color="primary" [disabled]="loading">
                            Anmelden
                        </button>
                    </div>
                    <div id="div-register" *ngIf="showRegisterLink">
                        <a class="link" routerLink="/register" routerLinkActive="active">Schule registrieren</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div *ngIf="showRegisterLink" class="homepageLink" id="footer">
        <a href="https://endoospot.de">
            <mat-icon style="vertical-align: middle;">exit_to_app</mat-icon>
            Zur endoo Website
        </a>
    </div>
</div>
