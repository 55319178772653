import { Component, Input, ViewChild } from '@angular/core';
import { PlotlyGraph } from '../plotly-graph';
import { AppService } from '../../../Services/app.service';

declare const Plotly;

@Component({
    selector: 'endoo-plotly-graph',
    templateUrl: './plotly-graph.component.html',
    styleUrls: ['plotly-graph.component.scss'],
})
export class PlotlyGraphComponent {
    loading: boolean = true;
    devicesGraph: PlotlyGraph;
    @ViewChild('ref') ref: any;

    constructor(private appService: AppService) { }

    @Input() set DevicesGraph(devicesGraph: PlotlyGraph) {
        if (devicesGraph === undefined) {
            return;
        }

        this.devicesGraph = devicesGraph;
        this.loading = false;
    };

    relayout(): void {
        Plotly.relayout(this.ref.plotlyInstance, this.devicesGraph.layout);
    }

    openSupportWidgetContactFormular(event: PointerEvent): void {
        this.appService.openSupportWidgetContactFormular(event);
    }
}
