<mat-toolbar>
	<span class="user-select-none">Schulnetzwerke verwalten</span>
	<span class="example-spacer"></span>

	<ng-container *ngIf="appService.isAppScreen()">
		<button mat-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
				(click)="newNetworkBtClick()">Neues Schulnetzwerk</a>
		</mat-menu>
	</ng-container>
	<ng-container *ngIf="!appService.isAppScreen()">
		<button mat-raised-button *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
			(click)="newNetworkBtClick()">Neues Schulnetzwerk</button>
	</ng-container>
</mat-toolbar>
<mat-tab-group>
	<mat-tab *ngFor="let logicalNetwork of networks; index as i">
		<ng-template mat-tab-label>
			Netzwerk:
			<div class="network-name-circle-symbol" [style.backgroundColor]="logicalNetwork.color">
			</div>
			{{logicalNetwork?.name}}
		</ng-template>
		<div class="content-container">
			<mat-accordion>
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon class="mat-icon-left">settings
							</mat-icon>
							<span>Allgemeine Einstellungen</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<LogicalNetworkBasicSettings (changed)="networkChanged()" [index]="i" [Network]="logicalNetwork"
						[notAvailableColors]="usedColors"></LogicalNetworkBasicSettings>
				</mat-expansion-panel>
				<mat-expansion-panel *ngIf="authenticationService.hasPermission('ENDOO-PREVIEW')">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon class="mat-icon-left">settings</mat-icon>
							<span>endooConnect Einstellungen</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<RouterNetworkSettings [logicalNetwork]="logicalNetwork"></RouterNetworkSettings>
				</mat-expansion-panel>
			</mat-accordion>
		</div>
	</mat-tab>
</mat-tab-group>
