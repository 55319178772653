export class User {
    username: string = '';
    givenname: string = '';
    surname: string = '';
    wlan: boolean = false;
    login: boolean = true;
    leavingyear: string = '';
    birthyear: string = '';
    password: string = '';
    group: string = '';
    classes: any[] = [];
    grade: string = '';
    shorten: string = '';
}
