import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiNetwork } from '../../data/WifiNetwork';
import { WifiNetworkService } from '../NetworkService/WifiNetwork.service';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { DeleteWifiNetworkDialogComponent } from './WifiNetworkManagementDialogs.component';
import { AuthenticationService } from '../../Login/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'WifiNetworkSettings',
    templateUrl: './WifiNetworkSettings.component.html',
})
export class WifiNetworkSettingsComponent {
    showKey: boolean = false;
    background_working: boolean = false;
    logicalNetworks: LogicalNetwork[];
    form: FormGroup;
    @Input('network') network: WifiNetwork;
    @Input('index') index: number;
    @Output() changed: EventEmitter<void> = new EventEmitter();

    constructor(public authenticationService: AuthenticationService, private logicalNetworkService: LogicalNetworkService, private wifiNetworkService: WifiNetworkService, private snackBar: MatSnackBar, private dialog: MatDialog, private fb: FormBuilder) {
        this.getLogicalNetworks();
        this.form = this.fb.group({
            ssid_name: ['', [Validators.required, Validators.maxLength(32)]],
            logical_network_id: ['', [Validators.required]],
        });
    }

    async getLogicalNetworks(): Promise<void> {
        try {
            this.logicalNetworks = await this.logicalNetworkService.getLogicalNetworks().toPromise()
        } catch (err) {
            this.snackBar.open('Die logischen Netzwerke konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    async saveWifiNetworkBtClick(): Promise<void> {
        if (this.form.invalid) {
            return;
        }
        this.background_working = true;
        if (this.network.encryption && !this.network.encryption_mode) {
            this.snackBar.open('Bei eingeschalteter Verschlüsselung muss eine Verschlüsselungsart gewählt werden.', 'OK');
        } else {
            try {
                await this.wifiNetworkService.saveWifiNetwork(this.index, this.network).toPromise();
                this.changed.emit();
                this.snackBar.open('WLAN-Netzwerk wurde erfolgreich gespeichert', 'OK');
            } catch (err) {
                this.snackBar.open('Das WLAN-Netzwerk konnte nicht gespeichert werden: ' + err, 'OK');
            }
        }
        this.background_working = false;
    }

    async deleteWifiNetworkBtClick(): Promise<void> {
        const dialogRef = this.dialog.open(DeleteWifiNetworkDialogComponent, {
            data: { network: this.network }
        });

        dialogRef.afterClosed().subscribe(deleted => {
            if (deleted) {
                this.changed.emit();
            }
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
