import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LogicalNetwork } from 'app/data/LogicalNetwork';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';

@Component({
    templateUrl: './chooseNewUntaggedNetwork.component.html',
    styleUrls: ['./chooseNewUntaggedNetwork.component.css'],
})
export class ChooseNewUntaggedNetworkComponent implements OnInit {
    networks: LogicalNetwork[] = [];
    excludeNetworkFromSelection: number;

    constructor(
        public logicalNetworkService: LogicalNetworkService,
        private dialogRef: MatDialogRef<ChooseNewUntaggedNetworkComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data !== null) {
            this.excludeNetworkFromSelection = data.excludeNetworkFromSelection;
        }
    }

    ngOnInit() {
        this.getNetworks();
    }

    async getNetworks(): Promise<void> {
        this.networks = await this.logicalNetworkService.getLogicalNetworks().toPromise();
        if (this.excludeNetworkFromSelection !== undefined) {
            this.networks = this.networks.filter((n, index) => index !== this.excludeNetworkFromSelection);
        }
    }

    closeDialog(result: boolean): void {
        this.dialogRef.close(result);
    }

    async onChooseNetwork(index: number): Promise<void> {
        this.networks[index].activated = true;
        this.networks[index].untagged = true;
        await this.logicalNetworkService.saveLogicalNetwork(index, this.networks[index]).toPromise();
        this.closeDialog(true);
    }
}
