import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DBDevice } from 'app/data/endooSpotDB_objects';
import { of } from 'rxjs';
import { MultipleApSaveConfirmDialogComponent } from './MultipleApSaveConfirmDialog/multipleApSaveConfirm.dialog.component';

@Injectable()
export class ApDetailService {

    constructor(
        private dialog: MatDialog
    ) { }

    /**
     * @param devices List of affected devices.
     */
    confirmChange(devices: DBDevice[]): Promise<boolean> {
        if (devices.length > 1) {
            const dialogRef = this.dialog.open(MultipleApSaveConfirmDialogComponent, { data: devices.map(device => device.inventoryNumber) });
            return dialogRef.afterClosed().toPromise();
        } else {
            return of(true).toPromise();
        }
    }
}
