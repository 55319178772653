import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WifiQrCodeGeneratorComponent } from './wifi-qr-code-generator-component/wifi-qr-code-generator.component';
import { NavigationMenuComponent } from './navigation-menu-component/navigation-menu.component';
import { ColorPickerComponent } from './color-picker-component/color-picker.component';
import { NavigationMenuService } from 'app/Services/navigation-menu.service';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { AppService } from 'app/Services/app.service';
import { LoadingDialogComponent } from './loading-dialog-component/loading-dialog.component';
import { MessageDialogComponent } from './message-dialog-component/message-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { QrCodeReaderComponent } from './qr-code-reader-component/qr-code-reader.component';
import { DatetimePipe } from './pipes/datetime.pipe';
import { TimesControlComponent } from 'app/Components/times-control/times-control.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { endooSpotWidgetsModule } from 'app/widgets/endooSpotWidgets.module';
import { QRCodeModule } from 'angularx-qrcode';
import { ConfirmDialogComponent } from './confirm-dialog-component/confirm-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatCardModule,
        MatSlideToggleModule,
        FormsModule,
        MatButtonModule,
        endooSpotWidgetsModule,
        QRCodeModule,
    ],
    declarations: [
        WifiQrCodeGeneratorComponent,
        NavigationMenuComponent,
        ColorPickerComponent,
        LoadingDialogComponent,
        MessageDialogComponent,
        QrCodeReaderComponent,
        DatetimePipe,
        TimesControlComponent,
        ConfirmDialogComponent,
    ],
    exports: [
        WifiQrCodeGeneratorComponent,
        NavigationMenuComponent,
        ColorPickerComponent,
        LoadingDialogComponent,
        MessageDialogComponent,
        QrCodeReaderComponent,
        DatetimePipe,
        TimesControlComponent,
        ConfirmDialogComponent,
    ],
    providers: [
        NavigationMenuService,
        AppService,
    ],
})
export class endooSpotComponentsModule { }
