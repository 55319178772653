<ng-container *ngIf="routerNetworkID">
    <form [formGroup]="editRouterNetworkForm">
        <div style="display: flex; justify-content: space-between; flex-wrap: wrap;">
            <mat-list>
                <div mat-subheader>Allgemeine Netzwerkeinstellungen</div>
                <!-- Select: Netzwerktyp -->
                <mat-list-item>
                    <mat-form-field appearance="outline">
                        <mat-label>Netzwerktyp</mat-label>
                        <mat-select formControlName="network_type">
                            <mat-option value="wan">Uplink-Netzwerk</mat-option>
                            <mat-option value="management">Management-Netzwerk</mat-option>
                            <mat-option value="byod">BYOD-Netzwerk</mat-option>
                            <mat-option value="media">Medien-Netzwerk</mat-option>
                            <mat-option value="paed">Pädagogisches-Netzwerk</mat-option>
                            <mat-option value="custom">Benutzerdefiniertes-Netzwerk</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editRouterNetworkForm.get('network_type').hasError('required')">
                            Dieses Feld darf nicht leer sein
                        </mat-error>
                    </mat-form-field>
                </mat-list-item>
                <!-- Select: IP-Konfiguration -->
                <mat-list-item *ngIf="editRouterNetworkForm.get('network_type').value">
                    <mat-form-field appearance="outline">
                        <mat-label>IP-Konfiguration</mat-label>
                        <mat-select formControlName="network_ip_type">
                            <mat-option value="dhcp">DHCP-Client</mat-option>
                            <mat-option value="static">Statisch</mat-option>
                        </mat-select>
                        <mat-error *ngIf="editRouterNetworkForm.get('network_ip_type').hasError('required')">
                            Dieses Feld darf nicht leer sein
                        </mat-error>
                    </mat-form-field>
                </mat-list-item>
                <!-- Textfeld: Router-IP-Adresse -->
                <mat-list-item *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static'">
                    <mat-form-field appearance="outline">
                        <mat-label>Router-IP-Adresse</mat-label>
                        <input matInput formControlName="interface_ip" />
                        <mat-error *ngIf="editRouterNetworkForm.get('interface_ip').hasError('required')">
                            Dieses Feld darf nicht leer sein
                        </mat-error>
                        <mat-error
                            *ngIf="editRouterNetworkForm.get('interface_ip').hasError('invalid_ip') && !editRouterNetworkForm.get('interface_ip').hasError('required')">
                            Ungültige IP-Adresse
                        </mat-error>
                        <mat-error
                            *ngIf="!editRouterNetworkForm.get('interface_ip').hasError('invalid_ip') && !editRouterNetworkForm.get('interface_ip').hasError('required')">
                            <mat-error *ngIf="editRouterNetworkForm.get('interface_ip').hasError('notInSameSubnet')">
                                Router IP-Adresse und Gateway IP-Adresse sind nicht im selben Subnetz
                            </mat-error>
                            <mat-error *ngIf="editRouterNetworkForm.get('interface_ip').hasError('ipIsNetworkAddress')">
                                Router IP-Adresse entspricht der Netzwerkadresse
                            </mat-error>
                            <mat-error
                                *ngIf="editRouterNetworkForm.get('interface_ip').hasError('ipIsBroadcastAddress')">
                                Router IP-Adresse entspricht der Broadcastadresse
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </mat-list-item>
                <!-- Textfeld: Router-IP-Subnetz -->
                <mat-list-item *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static'">
                    <mat-form-field appearance="outline">
                        <mat-label>Router-IP-Subnetz</mat-label>
                        <input matInput formControlName="network_subnet" />
                        <mat-hint>Zum Beispiel: 255.255.255.0</mat-hint>
                        <mat-error
                            *ngIf="editRouterNetworkForm.get('network_subnet').hasError('invalid_subnet') && !editRouterNetworkForm.get('network_subnet').hasError('required')">
                            Ungültige Subnetzmaske
                        </mat-error>
                    </mat-form-field>
                </mat-list-item>
                <ng-container *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static'">
                    <!-- Toggle: endooConnect ist das Gateway im Netzwerk -->
                    <mat-list-item *ngIf="editRouterNetworkForm.get('network_type').value !== 'wan'">
                        <mat-slide-toggle formControlName="gateway">
                            endooConnect ist das Gateway im Netzwerk
                        </mat-slide-toggle>
                    </mat-list-item>
                    <!-- Textfeld: Gateway-IP-Adresse -->
                    <mat-list-item *ngIf="!editRouterNetworkForm.get('gateway').value">
                        <mat-form-field appearance="outline">
                            <mat-label>Gateway-IP-Adresse</mat-label>
                            <input matInput formControlName="gateway_ip" />
                            <mat-error *ngIf="editRouterNetworkForm.get('gateway_ip').hasError('required')">
                                Dieses Feld darf nicht leer sein
                            </mat-error>
                            <mat-error
                                *ngIf="editRouterNetworkForm.get('gateway_ip').hasError('invalid_ip') && !editRouterNetworkForm.get('gateway_ip').hasError('required')">
                                Ungültige IP-Adresse
                            </mat-error>
                            <mat-error
                                *ngIf="!editRouterNetworkForm.get('gateway_ip').hasError('invalid_ip') && !editRouterNetworkForm.get('gateway_ip').hasError('required')">
                                <mat-error *ngIf="editRouterNetworkForm.get('gateway_ip').hasError('notInSameSubnet')">
                                    Gateway IP-Adresse und Router IP-Adresse sind nicht im selben Subnetz
                                </mat-error>
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('gateway_ip').hasError('ipIsNetworkAddress')">
                                    Gateway IP-Adresse entspricht der Netzwerkadresse
                                </mat-error>
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('gateway_ip').hasError('ipIsBroadcastAddress')">
                                    Gateway IP-Adresse entspricht der Broadcastadresse
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </mat-list-item>
                </ng-container>
                <!-- Toggle: Bevorzugtes WAN-Netzwerk / Uplink Netzwerk -->
                <mat-list-item *ngIf="editRouterNetworkForm.get('network_type').value === 'wan'">
                    <mat-slide-toggle formControlName="preferred_uplink_network">
                        Bevorzugtes WAN-Netzwerk / Uplink Netzwerk
                    </mat-slide-toggle>
                </mat-list-item>
                <mat-divider class="form1_divider"></mat-divider>
            </mat-list>
            <mat-list>
                <div mat-subheader>Weitergehende Netzwerkeinstellungen</div>
                <mat-list-item
                    *ngIf="(editRouterNetworkForm.get('network_ip_type').value === 'dhcp' && !editRouterNetworkForm.get('gateway').value) || editRouterNetworkForm.get('network_type').value === 'wan'">
                    Keine weiteren Einstellungen möglich
                </mat-list-item>
                <ng-container
                    *ngIf="(editRouterNetworkForm.get('network_ip_type').value === 'static' ||
                editRouterNetworkForm.get('gateway').value) && editRouterNetworkForm.get('network_type').value !== 'wan'">
                    <ng-container *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static'">
                        <!-- Toggle: DHCP-Server im Netzwerk aktivieren -->
                        <mat-list-item *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static'">
                            <mat-slide-toggle formControlName="dhcp_server_enabled">
                                DHCP-Server im Netzwerk aktivieren</mat-slide-toggle>
                        </mat-list-item>
                        <!-- Textfeld: DHCP-Server Start-Adresse -->
                        <mat-list-item
                            *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static' && editRouterNetworkForm.get('dhcp_server_enabled').value">
                            <mat-form-field appearance="outline">
                                <mat-label>DHCP-Server Start-Adresse</mat-label>
                                <input matInput formControlName="dhcp_server_start_address" />
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('dhcp_server_start_address').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('dhcp_server_start_address').hasError('invalid_ip') && !editRouterNetworkForm.get('dhcp_server_start_address').hasError('required')">
                                    Ungültige IP-Adresse
                                </mat-error>
                                <mat-error
                                    *ngIf="!editRouterNetworkForm.get('dhcp_server_start_address').hasError('invalid_ip') && !editRouterNetworkForm.get('dhcp_server_start_address').hasError('required')">
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('dhcp_server_start_address').hasError('ipIsNetworkAddress')">
                                        DHCP-Server Start-Adresse entspricht der Netzwerkadresse
                                    </mat-error>
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('dhcp_server_start_address').hasError('ipIsBroadcastAddress')">
                                        DHCP-Server Start-Adresse entspricht der Broadcastadresse
                                    </mat-error>
                                </mat-error>
                                <mat-error
                                    *ngIf="!editRouterNetworkForm.get('dhcp_server_start_address').hasError('invalid_ip') && !editRouterNetworkForm.get('dhcp_server_start_address').hasError('required') && !editRouterNetworkForm.get('dhcp_server_start_address').hasError('ipIsNetworkAddress') && !editRouterNetworkForm.get('dhcp_server_start_address').hasError('ipIsBroadcastAddress')">
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('dhcp_server_start_address').hasError('dhcpRangeInvalid')">
                                        DHCP-Server Start-Adresse befindet sich nicht im Subnetz
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <!-- Textfeld: DHCP-Server End-Adresse -->
                        <mat-list-item
                            *ngIf="editRouterNetworkForm.get('network_ip_type').value === 'static' && editRouterNetworkForm.get('dhcp_server_enabled').value">
                            <mat-form-field appearance="outline">
                                <mat-label>DHCP-Server End-Adresse</mat-label>
                                <input matInput formControlName="dhcp_server_end_address" />
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('dhcp_server_end_address').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('dhcp_server_end_address').hasError('invalid_ip') && !editRouterNetworkForm.get('dhcp_server_end_address').hasError('required')">
                                    Ungültige IP-Adresse
                                </mat-error>
                                <mat-error
                                    *ngIf="!editRouterNetworkForm.get('dhcp_server_end_address').hasError('invalid_ip') && !editRouterNetworkForm.get('dhcp_server_end_address').hasError('required')">
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('dhcp_server_end_address').hasError('ipIsNetworkAddress')">
                                        DHCP-Server End-Adresse entspricht der Netzwerkadresse
                                    </mat-error>
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('dhcp_server_end_address').hasError('ipIsBroadcastAddress')">
                                        DHCP-Server End-Adresse entspricht der Broadcastadresse
                                    </mat-error>
                                </mat-error>
                                <mat-error
                                    *ngIf="!editRouterNetworkForm.get('dhcp_server_end_address').hasError('invalid_ip') && !editRouterNetworkForm.get('dhcp_server_end_address').hasError('required') && !editRouterNetworkForm.get('dhcp_server_end_address').hasError('ipIsNetworkAddress') && !editRouterNetworkForm.get('dhcp_server_end_address').hasError('ipIsBroadcastAddress')">
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('dhcp_server_end_address').hasError('dhcpRangeInvalid')">
                                        DHCP-Server End-Adresse befindet sich nicht im Subnetz
                                    </mat-error>
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                    </ng-container>
                </ng-container>
                <ng-container
                    *ngIf="editRouterNetworkForm.get('gateway').value && editRouterNetworkForm.get('network_type').value !== 'management'">
                    <mat-divider></mat-divider>
                    <div mat-subheader>Captive Portal</div>
                    <!-- Toggle: Captive-Portal aktiviert -->
                    <mat-list-item>
                        <mat-slide-toggle formControlName="captive_portal_enabled">
                            Captive-Portal aktiviert
                        </mat-slide-toggle>
                    </mat-list-item>
                    <ng-container *ngIf="editRouterNetworkForm.get('captive_portal_enabled').value">
                        <!-- Toggle: Upload-Rate (MBit/s) -->
                        <mat-list-item>
                            <mat-form-field appearance="outline">
                                <mat-label>Upload-Rate (MBit/s)</mat-label>
                                <input type="number" min="0" matInput
                                    formControlName="captive_portal_max_client_up_speed" />
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('captive_portal_max_client_up_speed').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <!-- Toggle: Download-Rate (MBit/s) -->
                        <mat-list-item>
                            <mat-form-field appearance="outline">
                                <mat-label>Download-Rate (MBit/s)</mat-label>
                                <input type="number" min="0" matInput
                                    formControlName="captive_portal_max_client_down_speed" />
                                <mat-error
                                    *ngIf="editRouterNetworkForm.get('captive_portal_max_client_down_speed').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <div mat-subheader>Login-Verfahren</div>
                        <mat-list-item>
                            <!-- Button Group: endoo Kennungen verwenden -->
                            <mat-button-toggle-group formControlName="captive_portal_endoo_auth">
                                <mat-button-toggle [value]="'endoo-users'">
                                    Endoo Kennungen
                                </mat-button-toggle>
                                <mat-button-toggle [value]="'custom'">
                                    Eigene Kennungen
                                </mat-button-toggle>
                                <mat-button-toggle [value]="'endoo-guest'">
                                    Ohne Kennungen (nur AGB)
                                </mat-button-toggle>
                            </mat-button-toggle-group>
                        </mat-list-item>
                        <mat-list-item
                            *ngIf="editRouterNetworkForm.get('captive_portal_endoo_auth').value === 'endoo-users' || editRouterNetworkForm.get('captive_portal_endoo_auth').value === 'custom'">
                            <a href="https://login.endoo.eu" target="_blank" class="link">Beispiel für Anmeldemaske
                                anzeigen</a>
                        </mat-list-item>
                        <mat-list-item
                            *ngIf="editRouterNetworkForm.get('captive_portal_endoo_auth').value === 'endoo-guest'">
                            <a href="https://login.endoo.eu/guest" target="_blank" class="link">Beispiel für
                                Anmeldemaske
                                anzeigen</a>
                        </mat-list-item>
                        <ng-container *ngIf="editRouterNetworkForm.get('captive_portal_endoo_auth').value === 'custom'">
                            <!-- Textfeld: Radius-IP -->
                            <mat-list-item>
                                <mat-form-field appearance="outline">
                                    <mat-label>Radius-IP</mat-label>
                                    <input matInput formControlName="captive_portal_radius_ip" />
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('captive_portal_radius_ip').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('captive_portal_radius_ip').hasError('invalid_ip') && !editRouterNetworkForm.get('captive_portal_radius_ip').hasError('required')">
                                        Ungültige IP-Adresse
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <!-- Textfeld: Radius-Port -->
                            <mat-list-item>
                                <mat-form-field appearance="outline">
                                    <mat-label>Radius-Port</mat-label>
                                    <input matInput type="number" min="1" max="65535"
                                        formControlName="captive_portal_radius_port" />
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('captive_portal_radius_port').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <!-- Textfeld: Radius-Secret -->
                            <mat-list-item>
                                <mat-form-field appearance="outline">
                                    <mat-label>Radius-Secret</mat-label>
                                    <input matInput formControlName="captive_portal_radius_secret" />
                                    <mat-error
                                        *ngIf="editRouterNetworkForm.get('captive_portal_radius_secret').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <mat-divider class="form2_divider"></mat-divider>
            </mat-list>
            <mat-list>
                <div mat-subheader>Firewall-Einstellungen</div>
                <!-- Keine weiteren Einstellungen möglich -->
                <mat-list-item
                    *ngIf="!editRouterNetworkForm.get('gateway').value && editRouterNetworkForm.get('network_type').value !== 'management'">
                    Keine weiteren Einstellungen möglich
                </mat-list-item>
                <ng-container *ngIf="editRouterNetworkForm.get('gateway').value">
                    <!-- Toggle: Jugendschutzfilterung im Netzwerk aktivieren -->
                    <mat-list-item>
                        <mat-slide-toggle formControlName="filtering">
                            Jugendschutzfilterung im Netzwerk aktivieren
                        </mat-slide-toggle>
                    </mat-list-item>
                    <!-- Toggle: Zugriff auf Mediennetzwerke erlauben -->
                    <mat-list-item
                        *ngIf="editRouterNetworkForm.get('network_type').value === 'byod' || editRouterNetworkForm.get('network_type').value === 'paed' || editRouterNetworkForm.get('network_type').value === 'custom'">
                        <mat-slide-toggle formControlName="media_access"
                            [disabled]="editRouterNetworkForm.get('network_type').value === 'management' && editRouterNetworkForm.get('dhcp_server_enabled').value === true">
                            Zugriff auf Mediennetzwerke erlauben
                        </mat-slide-toggle>
                    </mat-list-item>
                    <!-- Toggle: Alles beim Zugriff ins Internet erlauben -->
                    <mat-list-item>
                        <mat-slide-toggle formControlName="inet_access_allowed"
                            [disabled]="editRouterNetworkForm.get('network_type').value === 'management'">
                            Alles beim Zugriff ins Internet erlauben
                        </mat-slide-toggle>
                    </mat-list-item>
                    <!-- Checkboxes: Alles beim Zugriff ins Internet erlauben -->
                    <mat-list-item *ngIf="!editRouterNetworkForm.get('inet_access_allowed').value">
                        <div style="padding-left: 42px; display: flex; flex-direction: column;">
                            <mat-checkbox formControlName="allow_dns"
                                [disabled]="editRouterNetworkForm.get('filtering').value">
                                DNS erlauben
                            </mat-checkbox>
                            <mat-checkbox formControlName="allow_web"
                                [disabled]="editRouterNetworkForm.get('filtering').value">
                                Web erlauben
                            </mat-checkbox>
                            <mat-checkbox formControlName="allow_email">
                                Email erlauben
                            </mat-checkbox>
                            <mat-checkbox formControlName="allow_push">
                                Push erlauben
                            </mat-checkbox>
                        </div>
                    </mat-list-item>
                </ng-container>
                <!-- Toggle: Zugriff auf alle Schulnetzwerke erlauben -->
                <mat-list-item *ngIf="editRouterNetworkForm.get('network_type').value === 'management'">
                    <mat-slide-toggle [checked]="true" disabled>
                        Zugriff auf alle Schulnetzwerke erlauben
                    </mat-slide-toggle>
                </mat-list-item>
            </mat-list>
        </div>
        <mat-card class="custom-config-info-card" *ngIf="showCustomConfigInfo">
            <mat-card-content>
                <p>
                    Durch unseren Support wurden individuelle Konfigurationen auf dem Router vorgenommen - bitte
                    beachten Sie,
                    dass Änderungen an bestehenden Router-Netzwerken zu Komplikationen im Betrieb führen können. Sprechen
                    Sie
                    gerne mit unserem Support, um mehr zu erfahren.
                </p>
            </mat-card-content>
        </mat-card>
        <!-- Speichern u. Löschen Buttons -->
        <button mat-raised-button color="primary" [disabled]="background_working" (click)="saveRouterNetwork()"
            style="margin-right: 15px;">Speichern</button>
        <button mat-raised-button color="danger" [disabled]="background_working"
            (click)="removeRouterNetworkClick()">Löschen</button>
    </form>
</ng-container>
<ng-container *ngIf="!routerNetworkID">
    <mat-list>
        <!-- Hinzufügen Button -->
        <mat-list-item>
            <button mat-raised-button color="primary" [disabled]="background_working"
                (click)="addRouterNetworkClick()">Hinzufügen</button>
        </mat-list-item>
    </mat-list>
</ng-container>
