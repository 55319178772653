export class UmlautConverter {
    static umlautMap = {
        '\u00dc': 'UE',
        '\u00c4': 'AE',
        '\u00d6': 'OE',
        '\u00fc': 'ue',
        '\u00e4': 'ae',
        '\u00f6': 'oe',
        '\u00df': 'ss',
        '\u00cc': 'I',
        '\u00cd': 'I',
        '\u00ce': 'I',
        '\u00cf': 'I',
        '\u00ec': 'i',
        '\u00ed': 'i',
        '\u00ee': 'i',
        '\u00ef': 'i',
        '\u00c0': 'A',
        '\u00c1': 'A',
        '\u00c2': 'A',
        '\u00c3': 'A',
        '\u00e0': 'a',
        '\u00e1': 'a',
        '\u00e2': 'a',
        '\u00e3': 'a',
        '\u00c8': 'E',
        '\u00c9': 'E',
        '\u00cA': 'E',
        '\u00cB': 'E',
        '\u00e8': 'e',
        '\u00e9': 'e',
        '\u00eA': 'e',
        '\u00eB': 'e',
        '\u00d2': 'O',
        '\u00d3': 'O',
        '\u00d4': 'O',
        '\u00d5': 'O',
        '\u00f2': 'o',
        '\u00f3': 'o',
        '\u00f4': 'o',
        '\u00f5': 'o',
    }

    constructor() {}

    static replaceUmlaute(string: string): string {
        return string
          .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
            const big = this.umlautMap[a.slice(0, 1)];
            return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
          })
          .replace(new RegExp('['+Object.keys(this.umlautMap).join('|')+']',"g"),
            (a) => this.umlautMap[a]
          );
      }
}