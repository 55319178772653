<mat-toolbar>
	<span class="user-select-none">(Internet-)Freischaltung von
		<br *ngIf="appService.isAppScreen()">Endger&auml;ten</span>
	<span class="example-spacer"></span>
</mat-toolbar>

<div class="content-container" id="UserDeviceList_div">
	<mat-card>
		<div class="table-div">
			<mat-form-field>
				<mat-label>In Tabelle suchen</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
			</mat-form-field>
			<mat-table (matSortChange)="sortDataChanged($event)" [dataSource]="userDevices" class="table"
				id="UserDevicesTable" matSort>
				<ng-container matColumnDef="hostname">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Hostname </mat-header-cell>
					<mat-cell *matCellDef="let userDevice"> {{userDevice.hostname}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="ip">
					<mat-header-cell *matHeaderCellDef mat-sort-header> IP-Adresse </mat-header-cell>
					<mat-cell *matCellDef="let userDevice"> {{userDevice.ip}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="mac">
					<mat-header-cell *matHeaderCellDef mat-sort-header> MAC-Adresse</mat-header-cell>
					<mat-cell *matCellDef="let userDevice"> {{userDevice.mac}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="owner">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Aktuell-Benutzer </mat-header-cell>
					<mat-cell *matCellDef="let userDevice"> {{userDevice.owner}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="filtered">
					<mat-header-cell *matHeaderCellDef> Internet-Zugang </mat-header-cell>
					<mat-cell *matCellDef="let userDevice">
						<mat-checkbox (change)="switchFilter(userDevice)" [(ngModel)]="!userDevice.filtered">
						</mat-checkbox>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let userDevice; columns: displayedColumns;"></mat-row>
			</mat-table>
			<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20,50,100]" [showFirstLastButtons]="true">
			</mat-paginator>
		</div>
	</mat-card>
</div>
