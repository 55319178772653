import { ElementRef, ViewChild, Component, OnInit } from '@angular/core';
import { Circle } from './cirlce';
import { Rectangle } from './rectangle';
import { Stroke } from './stroke';
import { Text } from './text';
import { SvgImage } from './svg-image';
import { Shape } from './shape';
import { GraphicElement } from './graphic-element';

export type GraphElement = GraphicElement | Stroke | Text;
export enum CONTENT_TYPE {
    TEXT = 0,
    IMAGE = 1,
    ICON = 2,
};
export type Content = { type: CONTENT_TYPE, value: string, color: string };

@Component({
    selector: 'endoo-canvas',
    templateUrl: './canvas.component.html',
    styleUrls: ['canvas.component.css'],
})
export class CanvasComponent implements OnInit {
    @ViewChild('canvas', { static: true }) private canvasRef: ElementRef;
    private context: CanvasRenderingContext2D;
    private elements: GraphElement[] = [];

    ngOnInit() {
        this.context = this.canvasRef.nativeElement.getContext('2d');
    }

    /**
     * Gibt dem Canvas eine neue Größe und skaliert es wieder auf 1 zu 1.
     */
    public resize(width: number, height: number): void {
        this.canvasRef.nativeElement.width = width;
        this.canvasRef.nativeElement.height = height;
        this.context.scale(1, 1);
        this.reDraw();
    }

    /**
     * Leert das Canvas und entfernt alle Objekte.
     */
    public reset(): void {
        this.elements = [];
        this.clear();
    }

    /**
     * Diese Methode leert lediglich das Canvas. Die Objekte bleiben unberührt, sodass sie mit @see CanvasComponent:draw wieder gezeichnet werden können.
     */
    private clear(): void {
        this.context.clearRect(0, 0, this.canvasRef.nativeElement.width, this.canvasRef.nativeElement.height);
    }

    /**
     * Fügt ein Element der Komponente hinzu.
     * @param element Ein zu zeichnendes Element.
     * @param reDraw Soll hiernach neu gezeichent werden, also das ELement sofort angezeigt werden?
     */
    public addElement(element: GraphicElement | Stroke | Text, reDraw: boolean = false): void {
        this.elements.push(element);

        if (reDraw) {
            this.reDraw();
        }
    }

    /**
     * Entfernt ein ELement aus der Komponente.
     * @param element Das Element welches entfernt werden soll.
     * @param reDraw Soll hiernach neu gezeichent werden, also das ELement gleich entfernt werden?
     */
    public removeElement(element: Element, reDraw: boolean = false): void {
        // TODO
    }

    /**
     * Aktualisiert ein Element in der Komponente.
     * @param element Ein bereits bestehendes Element im aktualisierten Zustand.
     * @param reDraw Soll hiernach neu gezeichent werden, also das ELement gleich entfernt werden?
     */
    public updateElement(element: Element, reDraw: boolean = false): void {
        // TODO
    }

    /**
     * Zeichnet alle Elemente in das Canvas. Für Neuzeichnungen ist die @see CanvasComponent:reDraw Methode zugänglich.
     */
    public async draw(): Promise<void> {
        const promises = [];
        this.elements.forEach(element => {
            if (element instanceof SvgImage) {
                promises.push(this.preDraw(element));
            }
        });
        await Promise.all(promises);

        await this.postDraw();
    }

    /**
     * Gewisse Elemente werden asynchron gezeichnet. Hiermit kann auf das Zeichnen gewartet werden.
     * Notwendig damit das Canvas nicht hinterher überschrieben wird.
     */
    private async preDraw(element: GraphElement): Promise<void> {
        return new Promise((resolve, reject) => {
            this.context.beginPath();

            if (element instanceof SvgImage) {
                element.image.onload = () => {
                    this.context.fillStyle = 'lightblue';
                    this.context.drawImage(element.image, 173, 9, 35, 23, element.x, element.y, 35 * 5, 23 * 5);
                    this.context.globalCompositeOperation = 'source-atop';
                    this.context.fillRect(element.x, element.y, 220, 220);
                    this.context.globalCompositeOperation = 'source-over';

                    this.context.stroke();
                    if (element instanceof GraphicElement) {
                        this.drawElementContent(element);
                    }
                    resolve();
                };
            }
        });
    }

    /**
     * Synchrone Zeichnungen werden hier durchgeführt.
     */
    private postDraw(): void {
        this.elements.forEach(element => {
            this.context.beginPath();
            if (element instanceof Shape) {
                this.drawElement(element);
                this.context.lineWidth = element.lineWidth;
                this.context.strokeStyle = element.strokeStyle;
            }
            if (element instanceof Stroke) {
                this.context.moveTo(element.x, element.y);
                this.context.lineWidth = element.lineWidth;
                this.context.strokeStyle = element.strokeStyle;
                this.context.lineTo(element.xx, element.yy);
            }
            if (element instanceof Text) {
                this.context.font = element.font;
                this.context.fillStyle = element.fillStyle;
                this.context.textAlign = element.textAlign;
                this.context.textBaseline = element.textBaseLine;
                this.context.fillText(element.value, element.x, element.y);
            }
            this.context.stroke();

            if (element instanceof GraphicElement) {
                this.drawElementContent(element);
            }
        });
    }

    /**
     * Leert das Canvas und zeichnet alle Elemente neu.
     */
    public reDraw(): void {
        this.clear();
        this.draw();
    }

    /**
     * Dies legt fest, wie GraphicElemente ausssehen sollen.
     * @param element
     */
    private drawElement(element: GraphElement): void {
        if (element instanceof Shape) {
            this.context.beginPath();

            if (element instanceof Circle) {
                this.context.arc(element.x + element.radius, element.y + element.radius, element.radius, 0, 2 * Math.PI, false);
            }

            if (element instanceof Rectangle) {
                this.context.rect(element.x, element.y, element.width, element.height);
            }
            this.context.fillStyle = element.fillStyle;
            this.context.fill();
            this.context.closePath();

            this.context.stroke();
            this.drawElementContent(element);
        }
    }

    /**
     * Hier werden Inhalte des Elements, wie bspw. ein Text gezeichnet.
     * @param element
     */
    private drawElementContent(element: GraphicElement): void {
        let textX;
        let textY;

        switch (element.content.type) {
            case CONTENT_TYPE.TEXT:
                if (element instanceof Text) {
                    this.context.font = element.font;
                }
                break;

            case CONTENT_TYPE.ICON:
                this.context.font = '24pt Material Icons';
                break;
        }

        this.context.fillStyle = element.content.color;
        this.context.textAlign = 'center';
        this.context.textBaseline = 'middle';
        if (element instanceof Circle) {
            textX = element.x + element.radius;
            textY = element.y + element.radius;
        }
        if (element instanceof Rectangle) {
            textX = element.x + element.width / 2;
            textY = element.y + element.height / 2;
        }
        if (element instanceof SvgImage) {
            textX = element.x + element.width / 2;
            textY = element.y + element.height / 2;
        }

        this.context.fillText(element.content.value, textX, textY);
    }
}
