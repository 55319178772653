import { Component, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewWifiNetworkDialogComponent } from '../NewWifiNetwork/newWifiNetwork.dialog.component';
import { WifiNetworkService } from '../NetworkService/WifiNetwork.service';
import { WifiNetwork } from '../../data/WifiNetwork';
import { AuthenticationService } from '../../Login/authentication.service';
import { AppService } from 'app/Services/app.service';

@Component({
    templateUrl: './WifiNetworkManagement.component.html',
})
export class WifiNetworkManagementComponent implements AfterViewInit {
    networks: WifiNetwork[];
    showTabContent: boolean;

    constructor(
        public authenticationService: AuthenticationService,
        private wifinetworkService: WifiNetworkService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        public appService: AppService
    ) { }

    ngAfterViewInit() {
        this.getNetworks();
    }

    async getNetworks(): Promise<void> {
        // Get the wifisettings and set the scope variable so that the view will work.
        try {
            this.networks = await this.wifinetworkService.getWifiNetworks().toPromise();
        } catch (err) {
            this.snackBar.open('Die WLAN-Netzwerke konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    reloadTabs(): void {
        this.showTabContent = false;
        setTimeout(() => this.showTabContent = true, 500);
    }

    newWifiNetworkBtClick(): void {
        const dialogRef = this.dialog.open(NewWifiNetworkDialogComponent);

        dialogRef.afterClosed().subscribe(created => {
            if (created) {
                this.getNetworks();
            }
        });
    }

    networkChanged(): void {
        this.getNetworks();
    }
}
