import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from '../AdminService/admin.service';
import { DBAdmin, DBRole } from '../../data/endooSpotDB_objects';
import { AuthenticationService } from '../../Login/authentication.service';

@Component({
    selector: 'NewAdminComponent',
    templateUrl: './newAdmin.component.html',
})
export class newAdminDialogComponent {
    private new_admin: DBAdmin;
    newAdminForm: FormGroup;
    customer_roles: DBRole[] = [];
    authorized_roles: DBRole[] = [];

    /**
     * @param adminService
     * @param authService
     * @param router
     * @param formBuilder
     * @param dialogRef
     * @param snackBar
     */
    constructor(private adminService: AdminService, public authService: AuthenticationService, private formBuilder: FormBuilder, private dialogRef: MatDialogRef<newAdminDialogComponent>, private snackBar: MatSnackBar) {
        // creating form validator for the new User form
        this.newAdminForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9-.]+$/)]],
            description: [''],
            password: ['', [Validators.required, Validators.minLength(6)]],
            email: ['', [Validators.required, Validators.email]]
        });
        this.newAdminForm.get('password').disable();
        this.newAdminForm.get('password').setValue(this.generatePassword());
    }

    generatePassword(length: number = 10): string {
        const possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&!-';
        let password = '';
        for (let i = 0; i < length; i++) {
            password += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
        }
        return password;
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.newAdminForm.get(formControl).hasError(error);
    }

    /**
     * Function to receive all roles an administrator holds and all functions the customer is authorized for.
     */
    async getFunctions(): Promise<void> {
        try {
            this.customer_roles = await this.adminService.getCustomerRoles().toPromise();
            this.authorized_roles = await this.adminService.getRoles(this.new_admin.username).toPromise();
        } catch (err) {
            this.snackBar.open('Rollen konnten nicht abgerufen werden: ' + err, 'OK');
        }
    }

    /**
     * Function to reset a stepper.
     * @param stepper
     */
    resetStepper(stepper): void {
        stepper.reset();
    }

    /**
     * Close the dialog the component belongs to.
     */
    closeDialogBtClick(): void {
        this.dialogRef.close();
    }

    /**
     * Function which will be triggered at the end of the creation stepper.
     * @param newAdminStepper Stepper which has asked for all admin parameters
     */
    async createNewAdminBtClick(newAdminStepper): Promise<void> {
        if (this.newAdminForm.get('email').value.length < 6 || !this.newAdminForm.get('email').value.includes('@') || !this.newAdminForm.get('email').value.includes('.')) {
            this.snackBar.open('Prüfen Sie das Feld E-Mail Adresse und probieren Sie es erneut', 'OK');
            return;
        }

        // Create new admin object and send it to the server side to create it. Then connect it with the customer
        this.new_admin = new DBAdmin();
        this.new_admin.description = this.newAdminForm.get('description').value;
        this.new_admin.username = this.newAdminForm.get('username').value + '@' + this.authService.getCustomerId(); // username is by convention the username @ customer short
        this.new_admin.email = this.newAdminForm.get('email').value;
        try {
            await this.adminService.createAdmin(this.new_admin, this.newAdminForm.get('password').value).toPromise();
            await this.adminService.makeAdminSchoolAdmin(this.new_admin.username, this.authService.getCustomerId(), [] /* Roles will be set in the next step */).toPromise();

            this.getFunctions();
            newAdminStepper.next();
        } catch (err) {
            this.snackBar.open('Administrator konnte nicht angelegt werden: ' + err, 'OK');
            this.dialogRef.close();
        }
    }

    /**
     * Returns if a customer has a specified role
     * @param role_id String representation of the role
     */
    hasCustomerRole(role_id: string): boolean {
        for (let i = 0; i < this.customer_roles.length; i++) {
            const role = this.customer_roles[i];
            if (role.id === role_id) {
                return true;
            }
        }
        return false;
    }

    /**
     * if a specific role area has different permission levels function will return permission level
     * @param area area where level should be returned for.
     */
    permission(area: string): string {
        for (let i = 0; i < this.authorized_roles.length; i++) {
            const splitted = this.authorized_roles[i].id.split('-');
            if (splitted[0] === area) {
                switch (splitted[1]) {
                    case 'MANAGER':
                        return '1';
                    case 'ADMINISTRATOR':
                        return '2';
                }
            }
        }
        return '0';
    }

    /**
     * Function to change a permission of an administrator. Will be triggered by the frontend when user clicks the radio button. There are three levels of permissions.
     * @param role_prefix Permission area the level should be changed for.
     * @param event event object of the triggered event
     */
    async changePermission(role_prefix: string, event): Promise<void> {
        switch (event.value) {
            case '0': // if the user should not have any permissions all roles should be deactivated.
                try {
                    await this.adminService.deauthorizeAdmin(this.new_admin.username, role_prefix + '-MANAGER').toPromise();
                } catch (err) { }
                try {
                    await this.adminService.deauthorizeAdmin(this.new_admin.username, role_prefix + '-ADMINISTRATOR').toPromise();
                } catch (err) {
                    this.snackBar.open('Die Administrator-Rolle konnte nicht zugewiesen werden: ' + err, 'OK');
                }
                break;
            case '1': // on first level the manager role for this area will be activated
                try {
                    await this.adminService.deauthorizeAdmin(this.new_admin.username, role_prefix + '-ADMINISTRATOR').toPromise();
                } catch (err) { }
                try {
                    await this.adminService.authorizeAdmin(this.new_admin.username, role_prefix + '-MANAGER').toPromise();
                } catch (err) {
                    this.snackBar.open('Die Manager-Rolle konnte nicht zugewiesen werden: ' + err, 'OK');
                }
                break;
            case '2': // when second level will be chosen the admin will be given the administrator role for this area.
                try {
                    await this.adminService.deauthorizeAdmin(this.new_admin.username, role_prefix + '-MANAGER').toPromise();
                } catch (err) { }
                try {
                    await this.adminService.authorizeAdmin(this.new_admin.username, role_prefix + '-ADMINISTRATOR').toPromise();
                } catch (err) {
                    this.snackBar.open('Die Administrator-Rolle konnte nicht zugewiesen werden: ' + err, 'OK');
                }
                break;
        }
    }

    /**
     * Check if the admin has the role with given role_id
     * @param role_id role_id the permissions should be checked for.
     */
    hasAdminRole(role_id: string): boolean {
        for (let i = 0; i < this.authorized_roles.length; i++) {
            if (this.authorized_roles[i].id === role_id) {
                return true;
            }
        }
        return false
    }

    /**
     * Function which will change a distinct permission based on user's click event in html
     * @param event event when slied toogle will be clicked
     * @param role_id
     */
    async changeRole(event, role_id: string): Promise<void> {
        if (event.checked) { // received permission
            try {
                await this.adminService.authorizeAdmin(this.new_admin.username, role_id).toPromise();
            } catch (err) {
                this.snackBar.open('Administrator Rolle konnte nicht geändert werden: ' + err, 'OK');
            }
        } else {
            for (let i = 0; i < this.authorized_roles.length; i++) {
                try {
                    const element: DBRole = this.authorized_roles[i];
                    if (element.id === role_id) {
                        await this.adminService.deauthorizeAdmin(this.new_admin.username, role_id).toPromise();
                    }
                } catch (err) {
                    this.snackBar.open('Dem Administrator konnte die Rolle ' + this.authorized_roles[i].name + ' nicht entzogen werden: ' + err, 'OK');
                }
            }
        }
        this.getFunctions();
    }
}
