import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Group } from '../../data/Group';
import { GroupService } from '../GroupService/group.service';
import { groupDetailDialogComponent } from './GroupDetailDialog.component'
import { deleteGroupDialogComponent } from './GroupListDialogs.component';
import { AuthenticationService } from '../../Login/authentication.service';
import { newGroupComponent } from '../NewGroup/NewGroup.component';
import { AppService } from 'app/Services/app.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
    templateUrl: './GroupList.component.html',
    styleUrls: ['./GroupList.component.css'],
})
export class groupListComponent implements AfterViewInit {
    displayedColumns: string[] = ['selection', 'groupname', 'description'];

    // implement table selection
    selection = new SelectionModel<Group>(allowMultiSelect, initialSelection);
    groups: MatTableDataSource<Group>; // Use mattabledatasource for easy sorting
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    group: Group = new Group();
    groupname: string = '';
    searchstring: string = '';
    changedGroupname: boolean = false;
    changedDescription: boolean = false;
    members_to_remove = [];

    constructor(
        public authService: AuthenticationService,
        private groupService: GroupService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public appService: AppService
    ) {
        this.groups = new MatTableDataSource();
        this.groups.sort = this.sort;
        this.groups.paginator = this.paginator;
        this.selection = new SelectionModel<Group>(allowMultiSelect, initialSelection);
    }

    ngAfterViewInit() {
        this.getAllGroups();
        this.groups.connect().subscribe(() => {
            this.resetSelection();
        });
    }

    /**
      * Function which will be called when table sort has been changed by the user. Because then pagination should be set to 1
      * @param event
      */
    sortDataChanged(event): void {
        this.groups.paginator.pageIndex = 0;
    }

    async getGroupList(): Promise<void> {
        try {
            this.groups.data = await this.groupService.listGroup(this.searchstring).toPromise();
            this.groups.sort = this.sort;
            this.groups.paginator = this.paginator;
            this.preparePaginator();
        } catch (err) {
            this.snackBar.open('Die Gruppen konnten nicht aufgelistet werden: ' + err, 'OK');
        }
    }
    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('groupList.pageSize')) {
            this.groups.paginator.pageSize = Number(localStorage.getItem('groupList.pageSize'));
            this.groups.paginator = this.paginator; // Update the paginator
        }
        this.groups.paginator._changePageSize = pageSize => {
            localStorage.setItem('groupList.pageSize', String(pageSize));
            this.groups.paginator.pageSize = pageSize;
            this.groups.paginator = this.paginator; // Update the paginator
        };
    }

    /**
     * Function to receive all groups from server and store it in the MatTableDataSource element
    */
    async getAllGroups(): Promise<void> {
        try {
            this.groups.data = await this.groupService.getAll().toPromise();
            this.groups.sort = this.sort;
            this.groups.paginator = this.paginator;
            this.preparePaginator();
        } catch (err) {
            this.snackBar.open('Die Gruppen konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    /** 
     * Easy to get search filtered data from the current page
     */
    getEntriesOnPage() {
        return this.groups._pageData(this.groups._orderData(this.groups.filteredData));
    }

    /**
     * All entries on current page are selected?
     */
    isAllSelected() {
        let isAllSelected = true;
        const data = this.getEntriesOnPage();
        data.forEach(v => {
            if (!this.selection.isSelected(v)) {
                isAllSelected = false;
            }
        });
        return isAllSelected;
    }

    /**
     * Select all on page if not all selected otherwise clear
     */
    masterToggle(event: MatCheckboxChange) {
        event.checked === false ? this.resetSelection() : this.selectRows();
    }

    resetSelection() {
        this.selection.clear();
    }

    /**
     * Select all rows on page
     */
    selectRows() {
        this.getEntriesOnPage().forEach(v => {
            this.selection.select(v);
        });
    }

    async selectGroup(groupname: string): Promise<void> {
        const dialogRef = this.dialog.open(groupDetailDialogComponent, {
            data: { groupname: groupname }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getAllGroups();
        });
    }

    deleteSelectedGroupsBtClick(): void {
        const dialogRef = this.dialog.open(deleteGroupDialogComponent, {
            width: '250px',
            data: { groups: this.selection.selected }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.resetSelection();
            this.getAllGroups();
        });
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.groups.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Function to open a new group dialog
     */
    newGroupBtClick(): void {
        const dialogRef = this.dialog.open(newGroupComponent);

        dialogRef.afterClosed().subscribe(result => {
            this.getAllGroups();
        });
    }
}
