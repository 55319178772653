export class SwitchSettings {

    constructor(
        public global_settings: boolean,
        public spanning_tree_protocol_enabled: boolean,
        public spanning_tree_protocol: string,
        public bridge_priority: string,
        public lldp: boolean
    ) { }
}
