export class Stroke {
    x: number;
    y: number;
    xx: number;
    yy: number;
    lineWidth: number;
    strokeStyle: string;

    /**
     * 
     * @param x Die X-Komponente des Startpunktes.
     * @param y Die Y-Komponente des Startpunktes.
     * @param xx Die X-Komponente des Endpunktes.
     * @param yy Die X-Komponente des Endpunktes.
     * @param lineWidth Die Strichstärke.
     * @param strokeStyle Die Strichfarbe.
     */
    constructor(x: number, y: number, xx: number, yy: number, lineWidth: number, strokeStyle: string) {
        this.x = x;
        this.y = y;
        this.xx = xx;
        this.yy = yy;
        this.lineWidth = lineWidth;
        this.strokeStyle = strokeStyle;
    }
}
