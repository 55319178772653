<mat-toolbar>
	<span class="user-select-none">Benutzerverwaltung</span>
	<span class="example-spacer"></span>
	<ng-container *ngIf="appService.isAppScreen()">
		<button mat-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" (click)="newUserBtClick()">Neuer
				Benutzer</a>
			<a mat-menu-item *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')"
				(click)="importUserBtClick()">Benutzerimport</a>
		</mat-menu>
	</ng-container>
	<ng-container *ngIf="!appService.isAppScreen()">
		<button mat-raised-button *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" style="margin-left: 10px"
			(click)="newUserBtClick()">Neuer Benutzer</button>
		<button mat-raised-button *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" style="margin-left: 10px"
			(click)="importUserBtClick()">Benutzerimport</button>
	</ng-container>
</mat-toolbar>

<div class="content-container" id="UserList_div">
	<mat-card>
		<div class="table-div">
			<mat-form-field>
				<mat-label>In Tabelle suchen</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
			</mat-form-field>
			<mat-table (matSortChange)="sortDataChanged($event)" [dataSource]="persons" class="table"
				id="UserList_table" matSort>
				<ng-container matColumnDef="selection">
					<mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="masterToggle($event)"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *matCellDef="let person">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(person) : null"
							[checked]="selection.isSelected(person)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="username">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Benutzername </mat-header-cell>
					<mat-cell class="clickable_cell username-cell" (click)="selectUser(person.username)"
						*matCellDef="let person">
						{{person.username}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="givenname">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </mat-header-cell>
					<mat-cell class="clickable_cell firstname-cell" (click)="selectUser(person.username)"
						*matCellDef="let person">
						{{person.givenname}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="surname">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </mat-header-cell>
					<mat-cell class="clickable_cell lastname-cell" (click)="selectUser(person.username)"
						*matCellDef="let person">
						{{person.surname}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="leavingyear">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Abschlussjahr </mat-header-cell>
					<mat-cell class="clickable_cell leavingyear-cell" (click)="selectUser(person.username)"
						*matCellDef="let person">
						{{person.leavingyear}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="wlan">
					<mat-header-cell *matHeaderCellDef> WLAN </mat-header-cell>
					<mat-cell *matCellDef="let person">
						<mat-checkbox (change)="wlanChanged(person)" [(ngModel)]="person.wlan"
							class="checkbox-wifistate">
						</mat-checkbox>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let person; columns: displayedColumns;"></mat-row>
			</mat-table>
			<mat-paginator #paginator (page)="resetSelection()" [pageSize]="10" [pageSizeOptions]="[10,50,100,200]"
				[showFirstLastButtons]="true">
			</mat-paginator>

		</div>
		<div class="button-row">
			<button mat-raised-button color="primary" [disabled]="selection.selected.length === 0" title="Gew&auml;hlte Benutzer zu
			Gruppe hinzuf&uuml;gen" (click)="addSelectedPersonstoGroupBtClick()">Gew&auml;hlte Benutzer zu
				Gruppe hinzuf&uuml;gen
			</button>
			<button *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" mat-raised-button color="warn"
				[disabled]="selection.selected.length === 0" title="Gew&auml;hlte Benutzer l&ouml;schen"
				(click)="deleteSelectedPersonsBtClick()">Gew&auml;hlte Benutzer
				l&ouml;schen
			</button>
		</div>
	</mat-card>
</div>