<h1 *ngIf="action === 'NewNetwork'" mat-dialog-title>Neues Schulnetzwerk erstellen</h1>
<h1 *ngIf="action !== 'NewNetwork'" mat-dialog-title>Schulnetzwerk festlegen</h1>
<div mat-dialog-content>
	<p *ngIf="action === 'ChangeNetwork'">
		Sie wollen ein Untagged-Netzwerk als Tagged definieren.
		<br>
		Bevor Sie das gewählte Netzwerk ändern,
		<br>
		müssen Sie zunächst ein neues Untagged-Netzwerk festlegen.
	</p>
	<p *ngIf="action === 'DeleteNetwork'">
		Sie wollen ein Untagged-Netzwerk löschen.
		<br>
		Bevor Sie das gewählte Netzwerk löschen können,
		<br>
		müssen Sie zunächst ein neues Untagged-Netzwerk festlegen.
	</p>
	<p *ngIf="action === 'VisitSite'">
		Bevor Sie Schulnetzwerke verwalten können,
		<br>
		müssen Sie zunächst ein Untagged-Netzwerk festlegen.
	</p>
	<div *ngIf="action !== 'NewNetwork'">
		<button class="button-margin-left button-margin-bottom" mat-raised-button (click)="chooseUntaggedNetwork()">
			Schulnetzwerk auswählen
		</button>
		<br>
		oder
	</div>
	<mat-horizontal-stepper linear=true #newLogicalNetworkStepper="matHorizontalStepper">
		<mat-step [stepControl]="form" style="padding-bottom:20px;" label="Schulnetzwerk erstellen">
			<form [formGroup]="form">
				<div class="fullwidth_form" style="margin-top: 20px;">
					<section class="toggle-section">
						<mat-slide-toggle formControlName="activated">
							Aktiviert
						</mat-slide-toggle>
					</section>
					<mat-form-field appearance="outline">
						<mat-label>Name</mat-label>
						<input formControlName="name" matInput required />
						<mat-error *ngIf="hasError('name', 'required')">Netzwerkname
							erforderlich
						</mat-error>
					</mat-form-field>
					<mat-divider></mat-divider>
					<section class="toggle-section" style="margin-top: 20px;">
						<mat-slide-toggle formControlName="untagged">Untagged-/Verwaltungsnetzwerk
						</mat-slide-toggle>
					</section>
					<mat-form-field appearance="outline">
						<mat-label>VLAN-ID</mat-label>
						<input matInput type="number" min="2" max="4093" formControlName="vlan_id" required />
						<mat-error *ngIf="hasError('vlan_id', 'required')">VLAN-ID
							erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('vlan_id', 'min') || hasError('vlan_id', 'max')">VLAN-ID
							muss zwischen 2 bis 4093 liegen
						</mat-error>
					</mat-form-field>
				</div>
				<mat-divider></mat-divider>
				<div style="padding-bottom: 30px;">
					<h5>Farb-Zuordnung *</h5>
					<color-picker [colors]="logicalNetworkService.Colors" [deactivatedColors]="usedColors"
						formControlName="color">
					</color-picker>
					<mat-error *ngIf="hasError('color', 'invalid')">
						Wählen Sie eine Farbe aus
					</mat-error>
				</div>
				<mat-divider></mat-divider>
				<div>
					<button mat-button matStepperNext>Weiter</button>
				</div>
			</form>
		</mat-step>
		<mat-step label="Eingaben &uuml;berpr&uuml;fen">
			<div class="fullwidth_form" style="margin-top: 20px;">
				<section class="toggle-section">
					<mat-slide-toggle disabled [ngModel]="form.get('activated').value">
						Aktiviert
					</mat-slide-toggle>
				</section>
				<mat-form-field appearance="outline">
					<mat-label>Name</mat-label>
					<input disabled matInput [ngModel]="form.get('name').value" />
				</mat-form-field>
				<mat-divider></mat-divider>
				<section class="toggle-section" style="margin-top: 20px;">
					<mat-slide-toggle disabled [ngModelOptions]="{standalone: true}"
						[ngModel]="form.get('untagged').value">Untagged</mat-slide-toggle>
				</section>
				<mat-form-field appearance="outline">
					<mat-label>VLAN-ID</mat-label>
					<input matInput disabled [ngModel]="form.get('vlan_id').value" />
				</mat-form-field>
				<mat-divider></mat-divider>
				<div style="padding-bottom: 30px;">
					<h4>Farb-Zuordnung</h4>
					<color-picker [colors]="logicalNetworkService.Colors" [deactivatedColors]="usedColors"
						[ngModel]="form.get('color').value" disabled>
					</color-picker>
				</div>
				<mat-divider></mat-divider>
			</div>
			<div>
				<button class="button-margin-right" mat-button matStepperPrevious>Zur&uuml;ck</button>
				<button mat-button (click)="createNewLogicalNetworkBtClick()">{{ action === 'DeleteNetwork' ? 'Löschen'
					+
					' und neues Netzwerk anlegen' : 'Netzwerk anlegen' }}
				</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>