import { Injectable } from '@angular/core';
import { CustomerFunctionPropertyService } from '../../endooSpotApplication/Services/CustomerFunctionPropertyService';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';
import { SwitchSettings } from 'app/data/SwitchSettings';
import { DBProperty } from '../../data/endooSpotDB_objects';

@Injectable()
export class GlobalSwitchSettingsService {
    private static readonly switchSettingsFunction: string = 'SWITCH_SETTINGS';

    constructor(private cfpService: CustomerFunctionPropertyService) { }

    getGlobalSwitchSettings(): Observable<SwitchSettings | null> {
        return this.cfpService.getCustomerFunctionProperties(GlobalSwitchSettingsService.switchSettingsFunction).pipe(map(properties => {
            if (properties.length > 0) {
                const stpEnabled = properties.find(cfp => cfp.property === DBProperty.switch_settings_spanning_tree_protocol_enabled)?.value === 'true' || false;
                const stp = properties.find(cfp => cfp.property === DBProperty.switch_settings_spanning_tree_protocol)?.value || '';
                const bridgePriority = properties.find(cfp => cfp.property === DBProperty.switch_settings_bridge_priority)?.value || '';
                const lldp = properties.find(cfp => cfp.property === DBProperty.switch_settings_lldp)?.value === 'true' ||false;
                return new SwitchSettings(null, stpEnabled, stp, bridgePriority, lldp);
            }
            return new SwitchSettings(null, true, 'rstp', '32768', true); // Set standard settings if no global settings exist for customer
        }));
    }

    saveGlobalSwitchSettings(switchSettings: SwitchSettings): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(GlobalSwitchSettingsService.switchSettingsFunction, DBProperty.switch_settings_spanning_tree_protocol_enabled, switchSettings.spanning_tree_protocol_enabled.toString());
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(GlobalSwitchSettingsService.switchSettingsFunction, DBProperty.switch_settings_spanning_tree_protocol, switchSettings.spanning_tree_protocol);
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(GlobalSwitchSettingsService.switchSettingsFunction, DBProperty.switch_settings_bridge_priority, switchSettings.bridge_priority);
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(GlobalSwitchSettingsService.switchSettingsFunction, DBProperty.switch_settings_lldp, switchSettings.lldp.toString());
        return forkJoin(setRequests).pipe(mergeAll());
    }
}
