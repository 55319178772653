<h1 mat-dialog-title>Gruppe anlegen</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper linear=true #newGroupStepper="matHorizontalStepper">
        <mat-step [stepControl]="newGroupForm" style="padding-bottom:20px;" label="Daten eingeben">
            <form [formGroup]="newGroupForm">
                <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>Gruppenname</mat-label>
                        <input formControlName="groupname" matInput class="fullwidth" type="text"
                            [(ngModel)]="group_new.groupname" required />
                        <mat-error *ngIf="hasError('groupname', 'required')">Gruppenname erforderlich
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Beschreibung</mat-label>
                        <input formControlName="description" matInput placeholder="Beschreibung" type="text"
                            [(ngModel)]="group_new.description" required />
                        <mat-error *ngIf="hasError('description', 'required')">Beschreibung erforderlich
                        </mat-error>
                    </mat-form-field>
                </div>
            </form>
            <mat-divider></mat-divider>
            <div>
                <button mat-button matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step label="Eingaben &uuml;berpr&uuml;fen">
            <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                <mat-form-field appearance="outline">
                    <mat-label>Gruppenname</mat-label>
                    <input disabled matInput class="fullwidth" type="text" [(ngModel)]="group_new.groupname" />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Beschreibung</mat-label>
                    <input disabled matInput type="text" [(ngModel)]="group_new.description" />
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div>
                <button mat-button matStepperPrevious>Zur&uuml;ck</button>
                <button mat-button (click)="saveNewGroupBtClick(newGroupStepper)">Gruppe anlegen</button>
            </div>
        </mat-step>

        <mat-step label="Ergebnis">
            <div id="vertraege_div" style="margin-top: 20px; margin-bottom: 20px;">
                <button mat-button mat-raised-button (click)="resetStepper(newGroupStepper)">Weitere Gruppe
                    anlegen</button>
            </div>
            <mat-divider></mat-divider>
            <div>
                <button mat-button (click)="closeDialogBtClick()">Schließen</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
