<mat-card id="registerCard">
    <mat-card-header>
        <h2>Neue Schule anlegen</h2>
    </mat-card-header>
    <mat-card-content>
        <mat-horizontal-stepper linear #stepper="matHorizontalStepper">
            <!-- Schritt 1: Schule registrieren (Customer) -->
            <mat-step [stepControl]="createCustomerForm" [label]="STEP_CUSTOMER">
                <form [formGroup]="createCustomerForm">
                    <mat-list>
                        <h3 mat-subheader style="margin-top: 20px; margin-bottom: 20px;">Schule</h3>
                        <mat-list-item class="mat-list-item-input">
                            <mat-form-field appearance="outline" class="fullwidth">
                                <mat-label>Name der Schule</mat-label>
                                <input formControlName="name" matInput autofocus required />
                                <mat-error *ngIf="createCustomerForm.get('name').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error *ngIf="createCustomerForm.get('name').hasError('pattern')">
                                    Folgende Zeichen sind erlaubt: Buchstaben, Zahlen, Bindestriche
                                    (-), Punkte (.), Leerzeichen
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <mat-list-item class="mat-list-item-input input-margin-top">
                            <mat-form-field appearance="outline" class="fullwidth">
                                <mat-label>Schul-kürzel</mat-label>
                                <input formControlName="id" matInput required />
                                <mat-error *ngIf="createCustomerForm.get('id').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error *ngIf="createCustomerForm.get('id').hasError('pattern')">
                                    Folgende Zeichen sind erlaubt: Buchstaben, Zahlen, Bindestriche (-), Unterstriche
                                    (_)
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <mat-list-item class="mat-list-item-input input-margin-top">
                            <mat-form-field appearance="outline" class="fullwidth">
                                <mat-label>Kontakt E-Mail Adresse</mat-label>
                                <input formControlName="email" matInput type="text" required />
                                <mat-error *ngIf="createCustomerForm.get('email').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error *ngIf="createCustomerForm.get('email').hasError('email')">
                                    Es muss eine gültige E-Mail Adresse eingegeben werden
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <h3 mat-subheader style="margin-top: 20px;">Anschrift</h3>
                        <mat-list-item style="margin-top: 20px;">
                            <mat-form-field appearance="outline" class="fullwidth">
                                <mat-label>Straße</mat-label>
                                <input formControlName="street" matInput required />
                                <mat-error *ngIf="createCustomerForm.get('street').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <mat-list-item class="input-margin-top">
                            <mat-form-field appearance="outline" style="width: 50%; padding-right: 15px;">
                                <mat-label>Postleitzahl</mat-label>
                                <input formControlName="zip" matInput required />
                                <mat-error *ngIf="createCustomerForm.get('zip').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error *ngIf="createCustomerForm.get('zip').hasError('pattern')">
                                    Geben Sie eine gültige Postleitzahl ein
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" style="width: 50%;">
                                <mat-label>Ort</mat-label>
                                <input formControlName="city" matInput required />
                                <mat-error *ngIf="createCustomerForm.get('city').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <mat-list-item class="mat-list-item-input input-margin-top">
                            <mat-form-field appearance="outline" class="fullwidth">
                                <mat-label>Land</mat-label>
                                <mat-select formControlName="country" matInput>
                                    <mat-option *ngFor="let country of countries" [value]="country">
                                        {{country}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-list-item>
                        <h3 mat-subheader style="margin-top: 20px; margin-bottom: 20px;">Lizensschlüssel</h3>
                        <mat-list-item class="mat-list-item-input input-margin-bottom">
                            <mat-form-field appearance="outline" class="fullwidth">
                                <mat-label>Lizenzschlüssel</mat-label>
                                <input formControlName="license_key" matInput required />
                                <mat-error *ngIf="createCustomerForm.get('license_key').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                    </mat-list>
                    <div>
                        <button mat-button matStepperNext class="stepper-btn">Weiter</button>
                    </div>
                </form>
            </mat-step>
            <!-- Schritt 2: Admin anlegen -->
            <mat-step [stepControl]="createAdminForm" [label]="STEP_ADMIN">
                <form [formGroup]="createAdminForm">
                    <mat-list>
                        <h3 mat-subheader style="margin-top: 20px; margin-bottom: 20px;">Administrator</h3>
                        <mat-list-item class="mat-list-item-input">
                            <mat-form-field appearance="outline" class="fullwidth fullwidth_form">
                                <mat-label>Benutzername</mat-label>
                                <b matSuffix>@{{ createCustomerForm.get('id').value }}</b>
                                <input formControlName="username" matInput required />
                                <mat-error *ngIf="createAdminForm.get('username').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error *ngIf="createAdminForm.get('username').hasError('pattern')">
                                    Es dürfen nur Buchstaben und Zahlen eingegeben werden
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <mat-list-item class="mat-list-item-input input-margin-top">
                            <mat-form-field appearance="outline" class="fullwidth fullwidth_form">
                                <mat-label>Passwort</mat-label>
                                <input formControlName="password" matInput type="password" #password required />
                                <mat-error *ngIf="createAdminForm.get('password').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <mat-list-item class="mat-list-item-input input-margin-top input-margin-bottom">
                            <mat-form-field appearance="outline" class="fullwidth fullwidth_form">
                                <mat-label>E-Mail Adresse</mat-label>
                                <input formControlName="email" matInput type="text" required />
                                <mat-error *ngIf="createAdminForm.get('email').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error *ngIf="createAdminForm.get('email').hasError('email')">
                                    Es muss eine gültige E-Mail Adresse eingegeben werden
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                    </mat-list>
                    <div>
                        <button mat-button matStepperPrevious class="stepper-btn">Zurück</button>
                        <button mat-button class="stepper-btn" (click)="create()">Abschließen</button>
                    </div>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card-content>
</mat-card>