import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiNetwork } from '../../data/WifiNetwork';
import { WifiNetworkService } from '../NetworkService/WifiNetwork.service';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { AuthenticationService } from '../../Login/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ipAddress } from '../../endooSpotApplication/Validators/ipAddress';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PskWarningDialogComponent } from '../PskWarningDialog/pskWarning.dialog.component';

@Component({
    selector: 'WifiNetworkSecurity',
    templateUrl: './WifiNetworkSecurity.component.html',
})
export class WifiNetworkSecurityComponent {
    showKey: boolean = false;
    background_working: boolean = false;
    logicalNetworks: LogicalNetwork[];
    form: FormGroup;
    @Input('network') network: WifiNetwork;
    @Input('index') index: number;
    @Output() changed: EventEmitter<void> = new EventEmitter();

    constructor(public authenticationService: AuthenticationService, private logicalNetworkService: LogicalNetworkService,
        private wifiNetworkService: WifiNetworkService, private snackBar: MatSnackBar, private fb: FormBuilder, private dialog: MatDialog) {
        this.getLogicalNetworks();
        this.form = this.fb.group({
            encryption: [],
            encryption_mode: ['', [Validators.required]],
            encryption_key: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(63)]],
            encryption_radius_ip: ['', [Validators.required, ipAddress()]],
            encryption_radius_port: ['', [Validators.required, Validators.min(1), Validators.max(65535)]],
            encryption_radius_secret: ['', [Validators.required]]
        });
        this.form.get('encryption').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('encryption_mode').enable();
                this.form.get('encryption_key').enable();
                this.form.get('encryption_radius_ip').enable();
                this.form.get('encryption_radius_port').enable();
                this.form.get('encryption_radius_secret').enable();
            } else {
                this.form.get('encryption_mode').setValue('');
                this.form.get('encryption_mode').disable();
                this.form.get('encryption_key').setValue('');
                this.form.get('encryption_key').disable();
                this.form.get('encryption_radius_ip').setValue('');
                this.form.get('encryption_radius_ip').disable();
                this.form.get('encryption_radius_port').setValue('');
                this.form.get('encryption_radius_port').disable();
                this.form.get('encryption_radius_secret').setValue('');
                this.form.get('encryption_radius_secret').disable();
            }
        });
        this.form.get('encryption_mode').valueChanges.subscribe(value => {
            if (value === 'psk') {
                this.form.get('encryption_key').enable();
                this.form.get('encryption_radius_ip').setValue('');
                this.form.get('encryption_radius_ip').disable();
                this.form.get('encryption_radius_port').setValue('');
                this.form.get('encryption_radius_port').disable();
                this.form.get('encryption_radius_secret').setValue('');
                this.form.get('encryption_radius_secret').disable();
            }
            if (value === 'enterprise') {
                this.form.get('encryption_key').setValue('');
                this.form.get('encryption_key').disable();
                this.form.get('encryption_radius_ip').enable();
                this.form.get('encryption_radius_port').enable();
                this.form.get('encryption_radius_secret').enable();
            }
        });
    }

    async getLogicalNetworks(): Promise<void> {
        try {
            this.logicalNetworks = await this.logicalNetworkService.getLogicalNetworks().toPromise();
        } catch (err) {
            this.snackBar.open('Die logischen Netzwerke konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    async saveWifiNetworkBtClick(): Promise<void> {
        if (!this.form.valid) {
            return;
        }

        // Used to ensure user wants to enter an PSK with length lower than 20
        let confirmSaveObservable = new Observable(subscriber => {
            subscriber.next();
        });
        if (this.form.get('encryption_mode').value === 'psk' && this.form.get('encryption_key').value.length < 20) {
            confirmSaveObservable = new Observable(subscriber => {
                const dialogRef = this.dialog.open(PskWarningDialogComponent);
                dialogRef.afterClosed().subscribe(confirmed => {
                    if (confirmed) {
                        subscriber.next();
                    }
                });
            });
        }

        confirmSaveObservable.subscribe(async () => {
            this.background_working = true;
            try {
                await this.wifiNetworkService.saveWifiNetwork(this.index, this.network).toPromise();
                this.changed.emit();
                this.snackBar.open('WLAN-Netzwerk wurde erfolgreich gespeichert', 'OK');
            } catch (err) {
                this.snackBar.open('Das WLAN-Netzwerk konnte nicht gespeichert werden: ' + err, 'OK');
            }
            this.background_working = false;
        });
    }

    /**
     * Function for validating the input of the user for changing a property of a user object (only ascii chars allowed )
     */
    validateWifiKeyInput(event: any): void {
        const pattern = /^[\x00-\x7F]*$/; // ASCII-Code 0-127
        const inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
