import { GraphicElement } from './graphic-element';
import { Content } from './canvas.component';

export abstract class Shape extends GraphicElement {
    fillStyle: string;
    lineWidth: number;
    strokeStyle: string;
    font: string;

    /**
     * @param fillStyle Die Füllfarbe des Elements.
     * @param lineWidth Die Strichstärke für seine Außenlinien.
     * @param strokeStyle Die Strichfarbe für seine Außenlinien.
     * @param font Die Schriftart des Inhaltes.
     * @param content Der Inhalt.
     */
    constructor(x: number, y: number, fillStyle: string, lineWidth: number, strokeStyle: string, font: string, content: Content) {
        super(x, y, content);

        this.fillStyle = fillStyle;
        this.lineWidth = lineWidth;
        this.strokeStyle = strokeStyle;
        this.font = font;
        this.content = content;
    }
}
