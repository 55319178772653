import { Component, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../data/User';
import { UserService } from '../UserService/user.service';
import { deleteUserDialogComponent, NewPasswordDialogComponent } from './UserListDialogs.component';
import { AuthenticationService } from '../../Login/authentication.service';
import { Group } from '../../data/Group';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
    selector: 'user-detail-dialog',
    templateUrl: './UserDetail.dialog.component.html',
    styleUrls: ['./UserDetail.dialog.component.css'],
})
export class UserDetailDialogComponent implements AfterViewInit {

    // member table attributes
    displayedColumns: string[] = ['name', 'description'];
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    edit_user: boolean = false;
    user$: Observable<User>;
    groups: MatTableDataSource<Group>;
    changedWlan: boolean;
    changedSurname: boolean;
    changedGivenname: boolean;
    changedLeavingyear: boolean;
    changedLogin: boolean;
    changedPassword: boolean;

    constructor(public authService: AuthenticationService, private dialog: MatDialog, private userService: UserService, private dialogRef: MatDialogRef<UserDetailDialogComponent>, private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) private data: any) { }

    ngAfterViewInit() {
        this.groups = new MatTableDataSource();
        this.loadTableData();
    }

    async loadTableData(): Promise<void> {
        try {
            await this.getUser(this.data.username);
        } catch (err) {
            this.snackBar.open('Der Benutzer konnte nicht geladen werden: ' + err, 'OK');
        }
        await this.getUserGroups(this.data.username);
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('userDetail.pageSize')) {
            this.groups.paginator.pageSize = Number(localStorage.getItem('userDetail.pageSize'));
            this.groups.paginator = this.paginator; // Update the paginator
        }
        this.groups.paginator._changePageSize = pageSize => {
            localStorage.setItem('userDetail.pageSize', String(pageSize));
            this.groups.paginator.pageSize = pageSize;
            this.groups.paginator = this.paginator; // Update the paginator
        };
    }

    fieldChanged = (fieldName: string): void => {
        this['changed' + fieldName] = true;
    }

    /**
     * async function which will fetch the user object from user service.
     * @param username username of user which should be fetched.
     */
    getUser(username: string): Promise<User> {
        this.user$ = this.userService.getUser(username);
        this.edit_user = false;
        return this.user$.toPromise();
    }

    /**
     * async function which will fetch the groups a user belongs to
     * @param username username of user which should be fetched.
     */
    async getUserGroups(username: string): Promise<void> {
        try {
            this.groups.data = await this.userService.getUserGroups(username).pipe(delay(1000)).toPromise(); // 1 sek warten, damit die Paginator Komponente vorher geladen wird
            this.groups.sort = this.sort;
            this.groups.paginator = this.paginator;
            this.preparePaginator();
        } catch (err) {
            this.snackBar.open('Die Benutzergruppen konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    closeBtClick(): void {
        this.dialogRef.close();
    }

    /**
     * Function for the save button when an user has been changed. 
     * Will check what has been changed and trigger the serverside functions.
     */
    async saveUserBtClick(user: User): Promise<void> {
        this.edit_user = false;

        let result = true;
        try {
            // the following part will change all parts at the server which the user has change in the app
            if (this.changedGivenname) {
                try {
                    result = await this.userService.changeGivenName(user.username, user.givenname).toPromise();
                } catch (err) {
                    this.UserError('Der Vorname konnte nicht gespeichert werden', err);
                }
                this.changedGivenname = false;
            }
            if (this.changedSurname) {
                try {
                    result = await this.userService.changeSurname(user.username, user.surname).toPromise();
                } catch (err) {
                    this.UserError('Der Nachname konnte nicht gespeichert werden', err);
                }
                this.changedSurname = false;
            }
            if (this.changedLeavingyear) {
                try {
                    result = await this.userService.changeLeavingyear(user.username, user.leavingyear).toPromise();
                } catch (err) {
                    this.UserError('Das Abschlussjahr konnte nicht gespeichert werden', err);
                }
                this.changedLeavingyear = false;
            }
            if (this.changedWlan) {
                try {
                    result = await this.userService.changeWLAN(user.username, user.wlan).toPromise();
                } catch (err) {
                    if (user.wlan) {
                        this.UserError('WLAN konnte nicht aktiviert werden', err);
                    } else {
                        this.UserError('WLAN konnte nicht deaktiviert werden', err);
                    }
                }
                this.changedWlan = false;
            }
            if (this.changedLogin) {
                try {
                    result = await this.userService.changeLogin(user.username, user.login).toPromise();
                } catch (err) {
                    if (user.login) {
                        this.UserError('Benutzer konnte nicht für Login freigeschaltet werden', err);
                    } else {
                        this.UserError('Benutzer konnte nicht für Login gesperrt werden', err);
                    }
                }
                this.changedLogin = false;
            }
        } catch (err) {
            result = false;
        }
        if (result) {
            this.snackBar.open('Die Benutzerdaten wurden gespeichert', 'OK');
        }
    }

    /**
    * Function for Click Event on EditUserBt
    */
    editUserBtClick(): void {
        this.edit_user = true;
    }

    /**
    * Function for Click Event on Cancel when an user has been edited.
    */
    async cancelBtClick(): Promise<void> {
        this.edit_user = false;
    }

    /**
     * Function for delete button. Will open a modal so the user has to check if the user object should be deleted.
     */
    deleteUserBtClick(user: User): void {
        this.edit_user = false;
        const dialogRef = this.dialog.open(deleteUserDialogComponent, {
            width: '250px',
            data: { username: user.username }
        });

        dialogRef.afterClosed().subscribe(finished => {
            if (finished) {
                this.dialogRef.close();
            }
        });
    }

    /**
     * Function for the new Password button. Function will generate a new password and display it.
     * Will only be stored if the user clicks save.
     */
    newPasswordBtClick(user: User): void {
        this.dialog.open(NewPasswordDialogComponent, {
            data: { username: user.username }
        });
    }

    /**
    * Function which is called when the user is changed (ajax.success)
    */
    async UserError(msg: string, err: string): Promise<void> {
        if (msg) {
            // if user was not changed get values from database
            this.edit_user = false;
            this.snackBar.open(msg + ': ' + err, 'OK');
        }
    }

    /**
     * Function for validating the input of the user for changing a property of a user object (only allowed chars are a-zA-Z0-9 )
     */
    validateChangeUserInput(event: any): void {
        const valid =
            event.charCode === 32 || event.charCode === 8 || // spacebar backspace
            (event.charCode >= 37 && event.charCode <= 40) || // arrows keys
            (event.charCode > 47 && event.charCode < 58) || // number keys
            (event.charCode >= 65 && event.charCode <= 90) || // BIG letter keys
            (event.charCode >= 97 && event.charCode <= 122); // small letters keys
        if (!valid) {
            event.preventDefault();
        }
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.groups.filter = filterValue.trim().toLowerCase();
    }
}
