<h1 mat-dialog-title>Neues WLAN-Netzwerk erstellen</h1>
<div mat-dialog-content>
	<mat-horizontal-stepper linear=true #newWifiNetworkStepper="matHorizontalStepper">
		<mat-step [stepControl]="form" label="WLAN-Einstellungen">
			<form [formGroup]="form">
				<mat-list>
					<h3 mat-subheader>SSID</h3>
					<mat-list-item style="margin-top: 15px; margin-bottom: 20px;">
						<mat-form-field appearance="outline">
							<mat-label>SSID-Name</mat-label>
							<input formControlName="ssid" matInput [(ngModel)]="network_new.ssid" required />
							<mat-error *ngIf="hasError('ssid', 'required')">
								SSID-Name erforderlich
							</mat-error>
							<mat-error *ngIf="hasError('ssid', 'maxlength')">
								SSID-Name darf höhstens 32 Zeichen beinhalten
							</mat-error>
						</mat-form-field>
					</mat-list-item>
					<mat-divider></mat-divider>
					<h3 mat-subheader>Erlauben von Kommunikation zwischen Ger&auml;ten untereinander (wird z.B. für
						AirPlay ben&ouml;tigt)</h3>
					<mat-list-item style="margin-bottom: 10px;">
						<mat-slide-toggle formControlName="client2client" [(ngModel)]="network_new.client2client">
							Client2Client Kommunikation
						</mat-slide-toggle>
					</mat-list-item>
					<mat-divider></mat-divider>
					<h3 mat-subheader>Zugehöriges Schulnetzwerk</h3>
					<ng-container
						*ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-MANAGER')">
						<mat-list-item>
							<a (click)="openNewLogicalNetworkDialog()" mat-button mat-raised-button>
								Erstellen
							</a>
						</mat-list-item>
						<mat-list-item>
							oder
						</mat-list-item>
					</ng-container>
					<mat-list-item style="margin-top: 20px; margin-bottom: 20px;">
						<mat-form-field appearance="outline">
							<mat-label>Logisches Netzwerk</mat-label>
							<mat-select formControlName="logicalNetworkId" required>
								<mat-option *ngFor="let logicalNetwork of logicalNetworks"
									[value]="logicalNetwork.id_number">{{logicalNetwork.name}}</mat-option>
							</mat-select>
							<mat-error *ngIf="hasError('logicalNetworkId', 'required')">Logisches Netzwerk
								erforderlich
							</mat-error>
						</mat-form-field>
					</mat-list-item>
					<mat-divider></mat-divider>
					<h3 mat-subheader>Verschl&uuml;sselung des Netzwerks</h3>
					<mat-list-item style="margin-bottom: 20px;">
						<mat-slide-toggle formControlName="encryption" [(ngModel)]="network_new.encryption">
							Verschl&uuml;sselung</mat-slide-toggle>
					</mat-list-item>
					<mat-list-item *ngIf="network_new.encryption" style="margin-top: 30px; margin-bottom: 35px;">
						<mat-form-field appearance="outline">
							<mat-label>Verschlüsselungsmodus</mat-label>
							<mat-select formControlName="encryption_mode" [(ngModel)]="network_new.encryption_mode"
								required>
								<mat-option value="psk">
									WPA2 Pre-Shared Key
								</mat-option>
								<mat-option value="enterprise">
									WPA2-Enterprise
								</mat-option>
							</mat-select>
							<mat-error *ngIf="hasError('encryption_mode', 'required')">Verschlüsselungsmodus
								erforderlich
							</mat-error>
						</mat-form-field>
					</mat-list-item>
					<mat-list-item *ngIf="network_new.encryption && network_new.encryption_mode === 'psk'"
						style="margin-bottom: 30px;">
						<mat-form-field appearance="outline">
							<mat-label>WLAN-Schlüssel</mat-label>
							<input matInput formControlName="encryption_key" name="encryption_key"
								[(ngModel)]="network_new.encryption_key" [type]="showKey ? 'text' : 'password'"
								required />
							<mat-icon matSuffix onMouseOver="this.style.cursor='pointer'" (click)="showKey = !showKey">
								{{showKey ? 'visibility' : 'visibility_off'}}
							</mat-icon>
							<mat-hint>8-63 Zeichen</mat-hint>
							<mat-error *ngIf="hasError('encryption_key', 'required')">WLAN-Schlüssel
								erforderlich
							</mat-error>
							<mat-error
								*ngIf="hasError('encryption_key', 'minlength') || hasError('encryption_key', 'maxlength')">
								WLAN-Schlüssel <strong>8-63 Zeichen</strong>
							</mat-error>
							<mat-error *ngIf="hasError('encryption_key', 'pattern')">
								WLAN-Schlüssel enthält nicht erlaubte Zeichen
							</mat-error>
						</mat-form-field>
					</mat-list-item>
					<mat-list-item *ngIf="network_new.encryption && network_new.encryption_mode === 'enterprise'"
						style="margin-bottom: 50px;">
						<mat-form-field appearance="outline">
							<mat-label>Radius-Server IP-Adresse</mat-label>
							<input matInput formControlName="encryption_radius_ip"
								[(ngModel)]="network_new.encryption_radius_ip" required />
							<mat-error *ngIf="hasError('encryption_radius_ip', 'required')">
								Radius-Server IP-Adresse erforderlich
							</mat-error>
							<mat-error
								*ngIf="hasError('encryption_radius_ip', 'invalid_ip') && !hasError('encryption_radius_ip', 'required')">
								Ungültige IP-Adresse
							</mat-error>
						</mat-form-field>
					</mat-list-item>
					<mat-list-item *ngIf="network_new.encryption && network_new.encryption_mode === 'enterprise'"
						style="margin-bottom: 50px;">
						<mat-form-field appearance="outline">
							<mat-label>Radius-Server Port</mat-label>
							<input matInput type="number" min="1" max="65535" formControlName="encryption_radius_port"
								[(ngModel)]="network_new.encryption_radius_port" required />
							<mat-error *ngIf="hasError('encryption_radius_port', 'required')">Radius-Server Port
								erforderlich
							</mat-error>
						</mat-form-field>
					</mat-list-item>
					<mat-list-item *ngIf="network_new.encryption && network_new.encryption_mode === 'enterprise'"
						style="margin-bottom: 35px;">
						<mat-form-field appearance="outline">
							<mat-label>Radius-Server Shared Secret</mat-label>
							<input matInput formControlName="encryption_radius_secret"
								[(ngModel)]="network_new.encryption_radius_secret" required />
							<mat-error *ngIf="hasError('encryption_radius_secret', 'required')">Radius-Server Shared
								Secret erforderlich
							</mat-error>
						</mat-form-field>
					</mat-list-item>
				</mat-list>
			</form>
			<mat-divider></mat-divider>
			<div>
				<button mat-button matStepperNext>Weiter</button>
			</div>
		</mat-step>
		<mat-step label="Zeitsteuerung">
			<endoo-times-control [times]="timesControlData" [alwaysFullWidth]="true"
				(onChange)="timeSettingsChanged($event)">
			</endoo-times-control>
			<mat-divider></mat-divider>
			<div>
				<button class="button-margin-right" mat-button matStepperPrevious>Zur&uuml;ck</button>
				<button mat-button matStepperNext>Weiter</button>
			</div>
		</mat-step>
		<mat-step label="Eingaben &uuml;berpr&uuml;fen">
			<mat-accordion multi="true">
				<mat-expansion-panel expanded="true">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon class="mat-icon-left">network_wifi
							</mat-icon>
							<span>WLAN-Netzwerkeinstellungen</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<mat-list>
						<h3 mat-subheader>SSID</h3>
						<mat-list-item style="margin-top: 30px; margin-bottom: 20px;">
							<mat-form-field appearance="outline">
								<mat-label>SSID-Name</mat-label>
								<input disabled matInput [(ngModel)]="network_new.ssid" />
							</mat-form-field>
						</mat-list-item>
						<mat-divider></mat-divider>
						<h3 mat-subheader>Erlauben von Kommunikation zwischen Ger&auml;ten untereinander (wird z.B. für
							AirPlay ben&ouml;tigt)</h3>
						<mat-list-item>
							<mat-slide-toggle disabled [(ngModel)]="network_new.client2client">
								Client2Client Kommunikation</mat-slide-toggle>
						</mat-list-item>
						<mat-divider></mat-divider>
						<h3 mat-subheader>Zugehöriges Schulnetzwerk</h3>
						<mat-list-item style="margin-top: 30px; margin-bottom: 20px;">
							<mat-form-field appearance="outline">
								<mat-label>Logisches Netzwerk</mat-label>
								<mat-select disabled [value]="form.get('logicalNetworkId').value">
									<mat-option *ngFor="let logicalNetwork of logicalNetworks"
										[value]="logicalNetwork.id_number">{{logicalNetwork.name}}</mat-option>
								</mat-select>
							</mat-form-field>
						</mat-list-item>
						<mat-divider></mat-divider>
						<h3 mat-subheader>Verschl&uuml;sselung des Netzwerks</h3>
						<mat-list-item>
							<mat-slide-toggle disabled [(ngModel)]="network_new.encryption">
								Verschl&uuml;sselung</mat-slide-toggle>
						</mat-list-item>
						<mat-list-item *ngIf="network_new.encryption" style="margin-top: 30px; margin-bottom: 40px;">
							<mat-form-field appearance="outline" class="fullwidth_form margintop_form">
								<mat-label>Verschlüsselungsmodus</mat-label>
								<mat-select disabled [(ngModel)]="network_new.encryption_mode">
									<mat-option value="psk">
										WPA2 Pre-Shared Key
									</mat-option>
									<mat-option value="enterprise">
										WPA2-Enterprise
									</mat-option>
								</mat-select>
							</mat-form-field>
						</mat-list-item>
						<mat-list-item *ngIf="network_new.encryption_mode === 'psk'"
							style="margin-top: 40px; margin-bottom: 10px;">
							<mat-form-field appearance="outline">
								<mat-label>WLAN-Schlüssel</mat-label>
								<input matInput disabled [(ngModel)]="network_new.encryption_key"
									[type]="showKey ? 'text' : 'password'" />
								<mat-icon matSuffix onMouseOver="this.style.cursor='pointer'"
									(click)="showKey = !showKey">
									{{showKey ? 'visibility' : 'visibility_off'}}
								</mat-icon>
							</mat-form-field>
						</mat-list-item>
						<mat-list-item *ngIf="network_new.encryption_mode === 'enterprise'" appearance="outline"
							style="margin-top: 30px; margin-bottom: 20px;">
							<mat-form-field appearance="outline">
								<mat-label>Radius-Server IP-Adresse</mat-label>
								<input matInput disabled [(ngModel)]="network_new.encryption_radius_ip" />
							</mat-form-field>
						</mat-list-item>
						<mat-list-item *ngIf="network_new.encryption_mode === 'enterprise'"
							style="margin-top: 30px; margin-bottom: 20px;">
							<mat-form-field appearance="outline">
								<mat-label>Radius-Server Port</mat-label>
								<input matInput disabled [(ngModel)]="network_new.encryption_radius_port" />
							</mat-form-field>
						</mat-list-item>
						<mat-list-item *ngIf="network_new.encryption_mode === 'enterprise'"
							style="margin-top: 30px; margin-bottom: 10px;">
							<mat-form-field appearance="outline">
								<mat-label>Radius-Server Shared Secret</mat-label>
								<input matInput disabled [(ngModel)]="network_new.encryption_radius_secret" />
							</mat-form-field>
						</mat-list-item>
					</mat-list>
				</mat-expansion-panel>
				<mat-expansion-panel expanded="true" style="margin-bottom: 15px;">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<mat-icon class="mat-icon-left">alarm
							</mat-icon>
							<span>WLAN-Zeitsteuerung</span>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<ng-container *ngIf="timesControlData.active">
						<div *ngFor="let weekday of timesControlData.weekdays; let i = index">
							{{weekday}}: {{timesControlData.times[i].onValue.substring(0, 2) + ':' +
							timesControlData.times[i].onValue.substring(2, 4)}} -
							{{timesControlData.times[i].offValue.substring(0, 2) + ':' +
							timesControlData.times[i].offValue.substring(2, 4)}} Uhr
						</div>
					</ng-container>
					<ng-container *ngIf="!timesControlData.active">
						Zeitsteuerung deaktiviert
					</ng-container>
				</mat-expansion-panel>
			</mat-accordion>
			<mat-divider></mat-divider>
			<div>
				<button class="button-margin-right" mat-button matStepperPrevious>Zur&uuml;ck</button>
				<button mat-button (click)="createNewWifiNetworkBtClick()">Netzwerk anlegen</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>