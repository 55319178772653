import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PlotlyGraphDataResponse } from './Plotly/graph-data-response';
import { GaugeData, ScatterData } from './dashboard-service.responses';
import { EndooHttpService } from 'app/Services/endooHttp.service';

@Injectable()
export class DashboardService {
    private readonly graphDataURL = '/api/graph-data/';

    /**
     * @param http http client object injected by angular.
     */
    constructor(private http: EndooHttpService) { }

    /**
     * Gibt x und y Werte zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     * @param time Zeitangabe für seit dem Daten bis jetzt zurückgegebnen werden sollen
     * Zeitangaben-Beispiele: 10s, 30m, 72h
     */
    getNetworkClientCount2_4GhzTimeline(inventoryNumber: string, time: string): Observable<ScatterData> {
        return this.http.
            get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'client-count/' +
                inventoryNumber +
                '/2_4-ghz/',
                { params: { time: time }, observe: 'response', responseType: 'json' },
                false,
                true
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                const x = [];
                const y = [];
                response.forEach((v, i) => {
                    if (i === 0 || i === response.length - 1 || i % 5 === 0) { // Only keep first, last and every 5 minutes object
                        x.push(new Date(v.timestamp));
                        y.push(v.count);
                    }
                });
                return { x: x, y: y };
            }));
    }

    /**
     * Gibt einen Wert für den aktuellen Zeitpunkt zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     */
    getNetworkClientCount2_4GhzCurrent(inventoryNumber: string): Observable<GaugeData> {
        return this.http.
            get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'client-count/' +
                inventoryNumber +
                '/2_4-ghz/current',
                { observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                return { value: response[0].count };
            }));
    }

    /**
     * Gibt x und y Werte zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     * @param time Zeitangabe für seit dem Daten bis jetzt zurückgegebnen werden sollen
     * Zeitangaben-Beispiele: 10s, 30m, 72h
     */
    getNetworkClientCount5GhzTimeline(inventoryNumber: string, time: string): Observable<ScatterData> {
        return this.http
            .get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'client-count/' +
                inventoryNumber +
                '/5-ghz/',
                { params: { time: time }, observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                const x = [];
                const y = [];
                response.forEach((v, i) => {
                    if (i === 0 || i === response.length - 1 || i % 5 === 0) {
                        x.push(new Date(v.timestamp));
                        y.push(v.count);
                    }
                });
                return { x: x, y: y };
            }));
    }

    /**
     * Gibt einen Wert für den aktuellen Zeitpunkt zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     */
    getNetworkClientCount5GhzCurrent(inventoryNumber: string): Observable<GaugeData> {
        return this.http
            .get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'client-count/' +
                inventoryNumber +
                '/5-ghz/current',
                { observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                return { value: response[0].count };
            }));
    }

    /**
     * Gibt x und y Werte zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     * @param time Zeitangabe für seit dem Daten bis jetzt zurückgegebnen werden sollen
     * Zeitangaben-Beispiele: 10s, 30m, 72h
     */
    getAccessPointCpuLoadTimeline(inventoryNumber: string, time: string): Observable<ScatterData> {
        return this.http
            .get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'cpu_load/' +
                inventoryNumber +
                '/',
                { params: { time: time }, observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                const x = [];
                const y = [];
                response.forEach(v => {
                    x.push(new Date(v.timestamp));
                    y.push(v.count);
                });
                return { x: x, y: y };
            }));
    }

    /**
     * Gibt einen Wert für den aktuellen Zeitpunkt zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     */
    getAccessPointCpuLoadCurrent(inventoryNumber: string): Observable<Partial<GaugeData>> {
        return this.http
            .get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'cpu_load/' +
                inventoryNumber +
                '/current/',
                { observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                return { value: response[0].count };
            }));
    }

    /**
     * Gibt x und y Werte zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     * @param time Zeitangabe für seit dem Daten bis jetzt zurückgegebnen werden sollen
     * Zeitangaben-Beispiele: 10s, 30m, 72h
     */
    getAccessPointRamLoadTimeline(inventoryNumber: string, time: string): Observable<ScatterData> {
        return this.http
            .get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'ram_load/' +
                inventoryNumber +
                '/',
                { params: { time: time }, observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                const x = [];
                const y = [];
                response.forEach(v => {
                    x.push(new Date(v.timestamp));
                    y.push(v.count);
                });
                return { x: x, y: y };
            }));
    }

    /**
     * Gibt einen Wert für den aktuellen Zeitpunkt zurück
     * @param inventoryNumber Inventarnummer des Gerätes
     */
    getAccessPointRamLoadCurrent(inventoryNumber: string): Observable<GaugeData> {
        return this.http
            .get<PlotlyGraphDataResponse[]>(
                this.graphDataURL +
                'ram_load/' +
                inventoryNumber +
                '/current/',
                { observe: 'response', responseType: 'json' },
                false
            )
            .pipe(map((response: PlotlyGraphDataResponse[]) => {
                return { value: response[0].count };
            }));
    }
}
