import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';

@Injectable()
export class AppService {
  isAppScreenProperty: boolean;

  constructor(
    private breakpointObserver: BreakpointObserver,
  ) {
    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isAppScreenProperty = true;
      } else {
        this.isAppScreenProperty = false;
      }
    });
  }

  isTouchScreen(): boolean {
    return window.matchMedia("(pointer: coarse)").matches;
  }

  isAppScreen(): boolean {
    return this.isAppScreenProperty;
  }

  isEdgeBrowser(): boolean {
    return window.navigator.userAgent.indexOf('Edge') > -1;
  }

    openSupportWidgetContactFormular(event: PointerEvent): void {
        event.preventDefault();
        document.getElementsByClassName('dock')[0].dispatchEvent(new Event('click'));
        document.getElementsByClassName('contact')[0].dispatchEvent(new Event('click'));
    }
}
