import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiSettingsService } from '../../APService/WifiSettings.service';
import { WifiNetwork } from '../../../data/WifiNetwork';
import { TimesControlData } from 'app/Components/times-control/times-control-data';

@Component({
    selector: 'WifiGlobalTimeSettings',
    templateUrl: './apGlobalTimeSettings.component.html',
})
export class apGlobalTimeSettingsComponent implements OnInit {
    @Input('wifiSettings') wifiSettings: WifiNetwork;
    background_working: boolean = true; // variable which will be true when app is waiting for REST Service
    timesControlData: TimesControlData;

    constructor(private wifiSettingsService: WifiSettingsService, private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.loadWifiSettings();
    }

    async saveWifiTimeSettings(): Promise<void> {
        this.background_working = true;

        this.wifiSettings.wifitime_active = this.timesControlData.active;
        this.wifiSettings.wifitime_mon_on = this.timesControlData.times[0].onValue;
        this.wifiSettings.wifitime_tue_on = this.timesControlData.times[1].onValue;
        this.wifiSettings.wifitime_wed_on = this.timesControlData.times[2].onValue;
        this.wifiSettings.wifitime_thu_on = this.timesControlData.times[3].onValue;
        this.wifiSettings.wifitime_fri_on = this.timesControlData.times[4].onValue;
        this.wifiSettings.wifitime_sat_on = this.timesControlData.times[5].onValue;
        this.wifiSettings.wifitime_sun_on = this.timesControlData.times[6].onValue;
        this.wifiSettings.wifitime_mon_off = this.timesControlData.times[0].offValue;
        this.wifiSettings.wifitime_tue_off = this.timesControlData.times[1].offValue;
        this.wifiSettings.wifitime_wed_off = this.timesControlData.times[2].offValue;
        this.wifiSettings.wifitime_thu_off = this.timesControlData.times[3].offValue;
        this.wifiSettings.wifitime_fri_off = this.timesControlData.times[4].offValue;
        this.wifiSettings.wifitime_sat_off = this.timesControlData.times[5].offValue;
        this.wifiSettings.wifitime_sun_off = this.timesControlData.times[6].offValue;

        try {
            await this.wifiSettingsService.saveEndooNetwork(this.wifiSettings).toPromise();
            this.snackBar.open('Die Zeitsterungseinstellungen wurden erfolgreich gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Das Netzwerk konnte nicht gespeichert werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    /**
     * function which will be called when ajax request for the wifitime config has been loaded.
     */
    private loadWifiSettings(): void {
        this.background_working = true; // button should be disabled etc.
        this.timesControlData = new TimesControlData(false, []);
        this.timesControlData.active = this.wifiSettings.wifitime_active;
        this.timesControlData.times.push({ weekdayId: 0, onValue: this.wifiSettings.wifitime_mon_on, offValue: this.wifiSettings.wifitime_mon_off });
        this.timesControlData.times.push({ weekdayId: 1, onValue: this.wifiSettings.wifitime_tue_on, offValue: this.wifiSettings.wifitime_tue_off });
        this.timesControlData.times.push({ weekdayId: 2, onValue: this.wifiSettings.wifitime_wed_on, offValue: this.wifiSettings.wifitime_wed_off });
        this.timesControlData.times.push({ weekdayId: 3, onValue: this.wifiSettings.wifitime_thu_on, offValue: this.wifiSettings.wifitime_thu_off });
        this.timesControlData.times.push({ weekdayId: 4, onValue: this.wifiSettings.wifitime_fri_on, offValue: this.wifiSettings.wifitime_fri_off });
        this.timesControlData.times.push({ weekdayId: 5, onValue: this.wifiSettings.wifitime_sat_on, offValue: this.wifiSettings.wifitime_sat_off });
        this.timesControlData.times.push({ weekdayId: 6, onValue: this.wifiSettings.wifitime_sun_on, offValue: this.wifiSettings.wifitime_sun_off });
        this.background_working = false;
    }

    timeSettingsChanged(e: TimesControlData): void {
        this.timesControlData = e;
    }
}
