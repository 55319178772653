import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { RouterSettingsService } from 'app/RouterManagement/RouterSettingsService/RouterSettings.service';
import { NewLogicalNetworkDialogComponent } from '../NewLogicalNetwork/newLogicalNetwork.dialog.component';
import { MessageDialogComponent } from 'app/Components/message-dialog-component/message-dialog.component';

@Component({
    templateUrl: './deleteLogicalNetwork.dialog.component.html',
})
export class DeleteLogicalNetworkDialogComponent {
    network: LogicalNetwork;
    excludeNetworkFromSelection: number;

    constructor(
        private logicalNetworkService: LogicalNetworkService,
        private routerSettingsService: RouterSettingsService,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<DeleteLogicalNetworkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {
        if (data.network) {
            this.network = data.network;
            this.excludeNetworkFromSelection = data.excludeNetworkFromSelection;
        }
    }

    /**
     * Method to delete network. Will make an ajax call to the server to delete network
     */
    async deleteNetworkClick_dialog(): Promise<void> {
        if (!this.network) {
            return;
        }

        const untaggedNetwork = this.logicalNetworkService.getUntaggedNetwork();
        if ((await untaggedNetwork).id_number === this.network.id_number) {
            const dialogRef = this.dialog.open(NewLogicalNetworkDialogComponent, {
                data: {
                    action: 'DeleteNetwork',
                    excludeNetworkFromSelection: this.excludeNetworkFromSelection
                }
            });
            dialogRef.afterClosed().subscribe(async created => {
                if (created) {
                    await this.delete();
                }
            });
        } else {
            await this.delete();
        }
    }

    private async delete(): Promise<void> {
        try {
            const res = await this.logicalNetworkService.deleteLogicalNetwork(this.network).toPromise();
            if (res.successfull) {
                const routerNetwork = await this.routerSettingsService.getRouterNetworkByLogicalNetwork(this.network.id_number.toString()).toPromise();
                if (routerNetwork !== null) {
                    const captivePortal = await this.routerSettingsService.getCaptivePortalById(routerNetwork.captive_portal_id).toPromise();
                    await this.routerSettingsService.deleteCaptivePortal(captivePortal).toPromise();
                    await this.routerSettingsService.deleteRouterNetwork(routerNetwork).toPromise();
                }
                this.snackBar.open('Schulnetzwerk wurde gelöscht ', 'OK');
                this.dialogRef.close(true);
            } else {
                let title = 'Information';
                let message = 'Das Schulnetzwerk wurde nicht gelöscht. Das hat folgende Ursachen. ';
                let i = 1;
                // Is this LogicalNetwork connected with a WifiNetwork?
                if (res.connectedFunctionProperties.filter(n => n.startsWith('WifiNetwork')).length > 0) {
                    message += ` ${i}. Gehen Sie bitte zu dem Menüpunkt WLAN-Netzwerke und lösen Sie die Verknüpfung mt dem WLAN-Netzwerk.`;
                    i++;
                }
                // Is this LogicalNetwork connected with a PortConfiguration of a device?
                if (res.connectedFunctionProperties.filter(n => n.startsWith('ACCESS_POINT_PORT') || n.startsWith('SWITCH_PORT')).length > 0) {
                    message += ` ${i}. Ein oder mehrere Geräte haben dieses Netzwerk in ihrer Portkonfiguration. Bitte ändern Sie dies zu Management Port oder wählen Sie ein anderes Schulnetzwerk für den Port.`;
                    i++;
                }
                // Is this LogicalNetwork connected with his RouterNetwork?
                if (res.connectedFunctionProperties.filter(n => n.startsWith('RouterNetwork')).length > 0) {
                    message += ` ${i}. Die zugehörigen endooConnect Einstellungen müssen zuerst entfernt werden.`;
                    i++;
                }
                // Show message dialog
                this.dialog.open(MessageDialogComponent, {
                    data: {
                        title,
                        message
                    }
                });
                this.dialogRef.close(false);
            }
        } catch (err) {
            console.log(err);
            this.snackBar.open('Schulnetzwerk konnte nicht gelöscht werden', 'OK');
            this.dialogRef.close(false);
        }
    }

    /**
     * Method to close dialog if user pressed cancel
     */
    cancelDeleteNetworkClick_dialog(): void {
        this.dialogRef.close(false);
    }
}
