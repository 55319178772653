export class WifiCard1 {
    enabled:boolean;
    channel:string;
    channel_width:string;
    tx_power:string;


   constructor(enabled: boolean, channel: string, channel_width: string, tx_power:string) {
       this.enabled = enabled;
       this.channel = channel;
       this.channel_width = channel_width;
       this.tx_power = tx_power;
       
    }


}
