import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { finishResponse, userDevicesResponse } from './userDeviceService.responses';
import { UserDevice } from '../../data/UserDevice';
import { EndooHttpService } from 'app/Services/endooHttp.service';

@Injectable()
export class UserDeviceService {
    private readonly userDeviceURL = '/api/userdevices/';

    constructor(private http: EndooHttpService, private authService: AuthenticationService) { }

    /**
     * Function which returns all UserDevices from Server
     */
    getUserDevices(): Observable<UserDevice[]> {
        return this.http.get<userDevicesResponse>(this.userDeviceURL + this.authService.getCustomerId() + '/all', { observe: 'response', responseType: 'json' })
            .pipe(map((response: userDevicesResponse) => {
                return response.userdevices;
            }));
    }

    setFilter(userDevice: UserDevice, new_filter: boolean): Observable<boolean> {
        return this.http.post<finishResponse>(this.userDeviceURL + this.authService.getCustomerId() + '/' + userDevice.mac_id + '/loginmask', { loginmask: new_filter }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }
}
