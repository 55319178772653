import { Injectable } from '@angular/core';
import { loginsResponse, finishResponse, loginResponse } from './groupService.responses';
import { Login } from 'app/data/Login';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ShortTermLoginService {
    private readonly shortTermLoginURL = '/api/user/short_term/';

    constructor(private http: EndooHttpService) { }

    /**
     * Function which returns all short term logins from server.
     */
    getAll(): Observable<Login[]> {
        return this.http.get<loginsResponse>(this.shortTermLoginURL + 'all', { observe: 'response', responseType: 'json' })
            .pipe(map((response: loginsResponse) => {
                const logins: Login[] = response.logins;
                if (logins) {
                    return logins;
                } else {
                    return [];
                }
            }));
    }

    /**
     * Function to delete a short term login from the system.
     * @param username username of the login which should be deleted.
     */
    delete(username: string): Observable<boolean> {
        return this.http.delete<finishResponse>(this.shortTermLoginURL + username, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to create a short term login in the system.
     * @param wlan Wlan state while creation.
     */
    create(wlan: boolean): Observable<Login> {
        return this.http.put<loginResponse>(this.shortTermLoginURL, { wlan: wlan }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: loginResponse) => {
                return response.user;
            }));
    }

    /**
     * Function to change wlan state of user.
     * @param username
     * @param wlan
     */
    changeWLAN(username: string, wlan: boolean): Observable<boolean> {
        return this.http.post<finishResponse>(this.shortTermLoginURL + username + '/wlan', { wlan: wlan }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to change expiration date of user.
     * @param username Username of short term account where expiration should be changed
     * @param expires_time Time in format HH:mm when account should expire
     * @param expires_date Date when account should expire
     */
    changeExpires(username: string, expires_time: string, expires_date: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.shortTermLoginURL + username + '/expiration', { expires_time: expires_time, expires_date: expires_date }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }
}
