import { Component, ViewChild, Inject, ApplicationRef, AfterViewInit } from '@angular/core';
import { MatAutocompleteTrigger, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../data/User';
import { UserService } from '../UserService/user.service';
import { GroupSearchResult } from '../../data/GroupSearchResult';
import { GroupService } from '../../GroupManagement/GroupService/group.service';

@Component({
    templateUrl: './addUserToGroup.dialog.component.html',
    styleUrls: ['./UserListDialogs.component.css'],
})
export class addUserToGroupDialogComponent implements AfterViewInit {
    displayedColumns: string[] = ['username', 'displayname'];
    selected_users: MatTableDataSource<User>;
    found_groups: GroupSearchResult[];
    groupnameSearchstring: string;
    isGroupSelected: boolean;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteInput: MatAutocompleteTrigger;

    constructor(private groupService: GroupService, private ref: ApplicationRef, private dialogRef: MatDialogRef<addUserToGroupDialogComponent>, private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.selected_users = new MatTableDataSource<User>();
        this.selected_users.data = data.users;
    }

    ngAfterViewInit() {
        this.selected_users.sort = this.sort;
        this.selected_users.paginator = this.paginator;
        this.preparePaginator();
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('addUserToGroup.pageSize')) {
            this.selected_users.paginator.pageSize = Number(localStorage.getItem('addUserToGroup.pageSize'));
            this.selected_users.paginator = this.paginator; // Update the paginator
        }
        this.selected_users.paginator._changePageSize = pageSize => {
            localStorage.setItem('addUserToGroup.pageSize', String(pageSize));
            this.selected_users.paginator.pageSize = pageSize;
            this.selected_users.paginator = this.paginator; // Update the paginator
        };
    }

    cancelAddPersonsToGroupBtClick(): void {
        this.dialogRef.close();
    }

    async saveAddPersonsToGroupBtClick(): Promise<void> {
        let result = true;
        for (let i = 0; i < this.selected_users.data.length; i++) {
            const user = this.selected_users.data[i];
            try {
                result = result && (await this.groupService.addPersontoGroup(this.groupnameSearchstring, user.username).toPromise());
            } catch (err) {
                result = false;
                this.snackBar.open('Der Benutzer ' + user.username + ' konnte nicht hinzufügt werden: ' + err, 'OK');
            }
        }
        if (result) {
            this.snackBar.open('Die Benutzer wurden hinzugefügt ', 'OK');
        }
        this.dialogRef.close();
    }

    async changeGroupSearchstring(): Promise<void> {
        try {
            this.found_groups = await this.groupService.search(this.groupnameSearchstring).toPromise();
            this.isGroupSelected = this.found_groups.some(result => result.value === this.groupnameSearchstring);
        } catch (err) {
            this.snackBar.open('Bei der Suche von Gruppen ist ein Fehler aufgetreten: ' + err, 'OK');
        }
    }

    searchGroupBtClick(): void {
        setTimeout(() => {
            this.autoCompleteInput.openPanel();
            this.ref.tick();
        }, 100);
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.selected_users.filter = filterValue.trim().toLowerCase();
    }

    selected(e: MatAutocompleteSelectedEvent) {
        this.groupnameSearchstring = e.option.value;
        this.isGroupSelected = this.found_groups.some(result => result.value === this.groupnameSearchstring);
    }
}

@Component({
    templateUrl: './deleteUser.dialog.component.html',
})
export class deleteUserDialogComponent {
    username: string;
    users: User[];

    constructor(private userService: UserService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<deleteUserDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.username) {
            this.username = data.username;
        } else {
            this.users = data.users;
        }
    }

    async deleteUsersClick_dialog(): Promise<void> {
        let result = true;
        if (this.username) {
            try {
                result = await this.userService.delete(this.username).toPromise();
                if (result) {
                    this.snackBar.open('Der Benutzer wurde gelöscht ', 'OK');
                    this.dialogRef.close(true);
                } else {
                    this.snackBar.open('Der Benutzer konnte nicht gelöscht werden', 'OK');
                    this.dialogRef.close(false);
                }
            } catch (err) {
                this.snackBar.open('Der Benutzer konnte nicht gelöscht werden: ' + err, 'OK');
                this.dialogRef.close(false);
            }
        } else {
            for (let i = 0; i < this.users.length; i++) {
                const user = this.users[i];
                try {
                    result = result && (await this.userService.delete(user.username).toPromise());
                } catch (err) {
                    result = false;
                    this.snackBar.open('Der Benutzer ' + user.username + ' konnte nicht gelöscht werden: ' + err, 'OK');
                }
            }
            if (result) {
                this.snackBar.open('Die Benutzer wurden gelöscht ', 'OK');
            }
            this.dialogRef.close();
        }
    }

    cancelDeleteUsersClick_dialog(): void {
        this.dialogRef.close(false);
    }
}

@Component({
    templateUrl: './NewPassword.dialog.component.html',
})
export class NewPasswordDialogComponent {
    username: string;
    newPassword: string;

    constructor(private userService: UserService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<NewPasswordDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.username) {
            this.username = data.username;
        }
    }

    async saveNewPassword(): Promise<void> {
        if (this.newPassword.length < 10) {
            this.snackBar.open('Das Passwort hat nicht das passende Format (mind. 10 Zeichen)', 'OK');
            return;
        }

        let result = true;
        try {
            result = await this.userService.changePassword(this.username, this.newPassword).toPromise();
            if (result) {
                this.snackBar.open('Das Passwort wurde übernommen', 'OK');
                this.dialogRef.close(true);
            } else {
                this.snackBar.open('Das Passwort konnte nicht übernommen werden', 'OK');
                this.dialogRef.close(false);
            }
        } catch (err) {
            this.snackBar.open('Das Passwort konnte nicht übernommen werden: ' + err, 'OK');
            this.dialogRef.close(false);
        }
    }

    async cancelNewPassword(): Promise<void> {
        this.dialogRef.close(false);
    }
}
