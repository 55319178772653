<div class="graph-div">
    <ng-container *ngIf="!loading">
        <div *ngIf="!devicesGraph.hasDashboardSupport" class="message-div">
            Gerät hat keine Dashboard Unterstützung
        </div>
        <div *ngIf="devicesGraph.hasDashboardSupport && devicesGraph.needsFirmwareUpdate" class="message-div">
            Bitte wenden Sie sich für Dashboard Funktionen an den <a href="#" class="support-link"
                (click)="openSupportWidgetContactFormular($event)">Support</a>
        </div>

        <div [class.disabled]="!devicesGraph.hasDashboardSupport || devicesGraph.needsFirmwareUpdate">
            <plotly-plot #ref [data]="devicesGraph.plotData" [layout]="devicesGraph.layout"></plotly-plot>
        </div>
    </ng-container>

    <div *ngIf=" loading" class="loading-div">
        <div class="loader"></div>
    </div>
</div>
