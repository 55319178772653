export class LogicalNetwork {
    id_number: number;
    name: string = '';
    activated: boolean;
    color: string;
    untagged: boolean;
    vlan_id: number;

    constructor(id_number: number, name: string, activated: boolean, color: string, untagged: boolean = true, vlan_id: number = 0) {
        this.id_number = id_number;
        this.name = name;
        this.activated = activated;
        this.color = color;
        this.untagged = untagged;
        this.vlan_id = vlan_id;
    }
}
