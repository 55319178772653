import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { User } from '../../data/User';
import { UserService } from '../UserService/user.service';
import { GroupService } from '../../GroupManagement/GroupService/group.service';
import { UserDetailDialogComponent } from './UserDetailDialog.component';
import { newUserDialogComponent } from '../NewUser/newUser.dialog.component';
import { importUserDialogComponent } from '../UserImport/importUser.dialog.component';
import { addUserToGroupDialogComponent, deleteUserDialogComponent } from './UserListDialogs.component';
import { AuthenticationService } from '../../Login/authentication.service';
import { AppService } from 'app/Services/app.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
    selector: 'change-user-list',
    templateUrl: './changeUserList.component.html',
    styleUrls: ['./changeUserList.component.css'],
})
export class ChangeUserListComponent implements AfterViewInit {
    displayedColumns = ['selection', 'username', 'surname', 'givenname', 'leavingyear', 'wlan'];

    // implement table selection
    selection = new SelectionModel<User>(allowMultiSelect, initialSelection);
    members: MatTableDataSource<User>;

    searchstring: string = '';
    persons: MatTableDataSource<User>; // Use mattabledatasource for easy sorting
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    selected_persons: User[] = [];
    persons_selected: boolean = false; // shows if person is selected and button should not be disabled
    addGroupGroupname: string = ''; // name of Group where selected persons should be added to

    constructor(
        public authService: AuthenticationService,
        private userService: UserService,
        private groupService: GroupService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public appService: AppService
    ) {
        this.persons = new MatTableDataSource();
        this.persons.sort = this.sort;
        this.persons.paginator = this.paginator;
    }

    ngAfterViewInit() {
        this.getUserList();
        this.persons.connect().subscribe(() => { //reset Selection when datasource is changed
            this.resetSelection();
        });
    }

    /**
     * Function to get a list of filtered users. It will be displayed by material datatable
    */
    async getUserList(): Promise<void> {
        try {
            if (this.searchstring !== null && this.searchstring !== '') {
                this.persons.data = await this.userService.searchList(this.searchstring).toPromise();
            } else {
                this.persons.data = await this.userService.getAll().toPromise();
            }

            this.persons.sort = this.sort;
            this.persons.paginator = this.paginator;
            this.preparePaginator();
            this.selection = new SelectionModel<User>(allowMultiSelect, initialSelection);
        } catch (err) {
            this.snackBar.open('Die Benutzer konnten nicht aufgelistet werden: ' + err, 'OK');
        }
    };

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('userList.pageSize')) {
            this.persons.paginator.pageSize = Number(localStorage.getItem('userList.pageSize'));
            this.persons.paginator = this.paginator; // Update the paginator
        }
        this.persons.paginator._changePageSize = pageSize => {
            localStorage.setItem('userList.pageSize', String(pageSize));
            this.persons.paginator.pageSize = pageSize;
            this.persons.paginator = this.paginator; // Update the paginator
        };
    }

    /**
     * Function which will be called when table sort has been changed by the user. Because then pagination should be set to 1
     * @param event
     */
    sortDataChanged(event): void {
        this.persons.paginator.pageIndex = 0;
    }

    /**
     * Easy to get search filtered data from the current page
     */
     getEntriesOnPage() {
        return this.persons._pageData(this.persons._orderData(this.persons.filteredData));
    }

    /**
     * All entries on current page are selected?
     */
    isAllSelected() {
        let isAllSelected = true;
        const data = this.getEntriesOnPage();
        data.forEach(v => {
            if (!this.selection.isSelected(v)) {
                isAllSelected = false;
            }
        });
        return isAllSelected;
    }

    /**
     * Select all on page if not all selected otherwise clear
     */
    masterToggle(event: MatCheckboxChange) {
        event.checked === false ? this.resetSelection() : this.selectRows();
    }

    resetSelection() {
        this.selection.clear();
    }

    /**
     * Select all rows on page
     */
    selectRows() {
        this.getEntriesOnPage().forEach(v => {
            this.selection.select(v);
        });
    }

    /**
     * Function which is used when a user has been selected in the table
     * @param username username of the selected user (as String)
     */
    selectUser(username: string): void {
        const dialogRef = this.dialog.open(UserDetailDialogComponent, {
            data: { username: username }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getUserList();
        });
    }

    // Function which is called when the user is changed (ajax.success)
    UserListChanged(result: boolean): void {
        this.getUserList();

        if (!result) {
            this.snackBar.open('Der Benutzer konnte nicht geändert werden', 'OK');
        }
    }

    /**
     * Function which will be triggered from view component when checkbox for wifi status has been changed
     * @param person Person object where the wlan is changed
     */
    async wlanChanged(person: User): Promise<void> {
        try {
            const changeResult = await this.userService.changeWLAN(person.username, person.wlan).toPromise();
            this.UserListChanged(changeResult);
        } catch (err) {
            this.snackBar.open('WLAN-Einstellung konnte nicht geändert werden: ' + err, 'OK');
            this.UserListChanged(false);
        }
    }

    /**
     * Function which will be triggered when person will be marked by checkbox. The function will change the selected_persons array so that array contains always all selected persons.
     * @param person Person which has been selected or deselected
     */
    add_removeSelectedPersonstoGroup(person: User): void {
        const selected_index: number = this.selected_persons.indexOf(person);
        if (selected_index !== -1) {
            this.selected_persons.splice(selected_index, 1);
        } else {
            this.selected_persons.push(person);
        }
    }

    /**
     * Function which is triggered by the click on the button to add the selected persons to a group. A dialogue with group selection will be opened.
     */
    addSelectedPersonstoGroupBtClick(): void {
        const dialogRef = this.dialog.open(addUserToGroupDialogComponent, {
            data: { users: this.selection.selected }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getUserList();
        });
    }

    /**
     * Function for delete button. Will open a modal so the user has to check if the users should be deleted.
     */
    deleteSelectedPersonsBtClick(): void {
        const dialogRef = this.dialog.open(deleteUserDialogComponent, {
            width: '250px',
            data: { users: this.selection.selected }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.resetSelection();
            this.getUserList();
        });
    }

    cancelAddPersonsToGroupBtClick(): void {
        this.addGroupGroupname = '';
    }

    async saveAddPersonsToGroupBtClick(): Promise<void> {
        if (this.addGroupGroupname === '') {
            return;
        }

        for (const person of this.selected_persons) {
            try {
                const result_value = await this.groupService.addPersontoGroup(this.addGroupGroupname, person.username).toPromise();
                if (!result_value) {
                    this.snackBar.open('Benutzer ' + person.username + ' konnte nicht zur Gruppe ' + this.addGroupGroupname + ' hinzugefügt werden. (Ggfs schon Mitglied der Gruppe)', 'OK');
                }
            } catch (err) {
                this.snackBar.open('Benutzer konnten nicht zur Gruppe ' + this.addGroupGroupname + ' hinzugefügt werden: ' + err, 'OK');
            }
        }
        this.addGroupGroupname = '';
    }

    /**
     * Function for the Delete Button in the alert dialog if an user should be deleted (deletion will be triggered serverside)
     */
    async deleteUsersClick_dialog(): Promise<void> {
        for (const person of this.selected_persons) {
            try {
                const result = await this.userService.delete(person.username).toPromise();
                if (!result) {
                    this.snackBar.open('Benutzer ' + person.username + ' konnte nicht gelöscht werden', 'OK');
                }
            } catch (err) {
                this.snackBar.open('Ein Benutzer konnte nicht gelöscht werden: ' + err, 'OK');
            }
        }
        this.getUserList();
    }

    /**
     * function to open a new User Dialog
    */
    newUserBtClick(): void {
        const dialogRef = this.dialog.open(newUserDialogComponent, { disableClose: true });

        dialogRef.afterClosed().subscribe(result => {
            this.getUserList();
        });
    }

    /**
     * function to open a new User Dialog
    */
    importUserBtClick(): void {
        const dialogRef = this.dialog.open(importUserDialogComponent, { disableClose: true });

        dialogRef.afterClosed().subscribe(result => {
            this.getUserList();
        });
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.persons.filter = filterValue.trim().toLowerCase();
    }
}
