import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { RouterNetwork } from 'app/data/RouterNetwork';
import { RouterSettingsService } from 'app/RouterManagement/RouterSettingsService/RouterSettings.service';
import { LogicalNetwork } from 'app/data/LogicalNetwork';
import { ipAddress } from 'app/endooSpotApplication/Validators/ipAddress';
import { CaptivePortal } from 'app/data/CaptivePortal';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { subnetmaskAddress } from 'app/endooSpotApplication/Validators/subnetmaskAddress';
import { merge } from 'rxjs';
import { MatHorizontalStepper } from '@angular/material/stepper';

@Component({
    selector: 'newRouterNetworkComponent',
    templateUrl: './newRouterNetwork.dialog.component.html',
    styleUrls: ['./newRouterNetwork.dialog.component.css'],
})
export class NewRouterNetworkDialogComponent {
    form: FormGroup;
    lNetwork: LogicalNetwork;
    @ViewChild('stepper') stepper: MatHorizontalStepper;

    constructor(
        private routerNetworkService: RouterSettingsService,
        private _formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<NewRouterNetworkDialogComponent>,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.lNetwork = data;
        this.form = this._formBuilder.group({
            page1: this._formBuilder.group({
                network_type: ['', Validators.required],
                network_ip_type: ['', Validators.required],
                interface_ip: ['', [Validators.required, ipAddress()]],
                network_subnet: ['', [Validators.required, subnetmaskAddress()]],
                gateway: [false],
                gateway_ip: ['', [Validators.required, ipAddress()]],
                preferred_uplink_network: [false] // TODO with backend
            }),
            page2: this._formBuilder.group({
                dhcp_server_enabled: [false],
                dhcp_server_start_address: ['', [Validators.required, ipAddress()]],
                dhcp_server_end_address: ['', [Validators.required, ipAddress()]],
                captive_portal_enabled: [false],
                captive_portal_max_client_up_speed: ['', [Validators.required]],
                captive_portal_max_client_down_speed: ['', [Validators.required]],
                captive_portal_endoo_auth: [''],
                captive_portal_radius_ip: ['', [Validators.required, ipAddress()]],
                captive_portal_radius_port: ['', [Validators.required]],
                captive_portal_radius_secret: ['', [Validators.required]]
            }),
            page3: this._formBuilder.group({
                filtering: [false],
                media_access: [false],
                inet_access_allowed: [true], // TODO with backend
                allow_dns: [true], // TODO with backend
                allow_web: [true], // TODO with backend
                allow_email: [true], // TODO with backend
                allow_push: [true] // TODO with backend
            })
        });

        this.registrateFormListeners();
    }

    registrateFormListeners() {
        // enable/disable fields by dependence of chosen settings
        this.form.get('page1').get('network_type').valueChanges.subscribe(value => {
            // reset settings
            this.resetForm(value);
            this.form.get('page2').get('captive_portal_enabled').setValue(false); // disable cp text fields
            this.form.get('page3').get('inet_access_allowed').setValue(true);

            switch (value) {
                case 'wan':
                    this.form.get('page1').get('network_ip_type').setValue('static');
                    this.form.get('page1').get('interface_ip').setValue('10.0.0.1');
                    this.form.get('page1').get('network_subnet').setValue('255.255.0.0');
                    this.form.get('page1').get('preferred_uplink_network').setValue(false);
                    break;

                case 'management':
                    this.form.get('page1').get('network_ip_type').setValue('static');
                    this.form.get('page1').get('interface_ip').setValue('10.50.0.1');
                    this.form.get('page1').get('network_subnet').setValue('255.255.0.0');
                    this.form.get('page1').get('gateway').setValue(true);
                    this.form.get('page2').get('dhcp_server_enabled').setValue(true);
                    this.form.get('page2').get('dhcp_server_start_address').setValue('10.50.1.100');
                    this.form.get('page2').get('dhcp_server_end_address').setValue('10.50.254.250');
                    break;

                case 'byod':
                    this.form.get('page1').get('network_ip_type').setValue('static');
                    this.form.get('page1').get('interface_ip').setValue('10.100.0.1');
                    this.form.get('page1').get('network_subnet').setValue('255.255.0.0');
                    this.form.get('page1').get('gateway').setValue(true);
                    this.form.get('page2').get('dhcp_server_enabled').setValue(true);
                    this.form.get('page2').get('dhcp_server_start_address').setValue('10.100.1.100');
                    this.form.get('page2').get('dhcp_server_end_address').setValue('10.100.254.250');
                    this.form.get('page3').get('filtering').setValue(true);
                    this.form.get('page2').get('captive_portal_enabled').setValue(true);
                    this.form.get('page2').get('captive_portal_max_client_up_speed').setValue('20');
                    this.form.get('page2').get('captive_portal_max_client_down_speed').setValue('20');
                    break;

                case 'media':
                    this.form.get('page1').get('network_ip_type').setValue('static');
                    this.form.get('page1').get('interface_ip').setValue('10.150.0.1');
                    this.form.get('page1').get('network_subnet').setValue('255.255.0.0');
                    this.form.get('page1').get('gateway').setValue(true);
                    this.form.get('page2').get('dhcp_server_enabled').setValue(true);
                    this.form.get('page2').get('dhcp_server_start_address').setValue('10.150.1.100');
                    this.form.get('page2').get('dhcp_server_end_address').setValue('10.150.254.250');
                    break;

                case 'paed':
                    this.form.get('page1').get('network_ip_type').setValue('dhcp');
                    this.form.get('page3').get('filtering').setValue(true);
                    this.form.get('page3').get('media_access').setValue(true);
                    break;

                case 'custom':
                    this.form.get('page1').get('network_ip_type').setValue('static');
                    this.form.get('page1').get('interface_ip').setValue('10.250.0.1');
                    this.form.get('page1').get('network_subnet').setValue('255.255.0.0');
                    this.form.get('page1').get('gateway').setValue(true);
                    this.form.get('page2').get('dhcp_server_enabled').setValue(true);
                    this.form.get('page2').get('dhcp_server_start_address').setValue('10.250.1.100');
                    this.form.get('page2').get('dhcp_server_end_address').setValue('10.250.254.250');
                    this.form.get('page3').get('media_access').setValue(false);
                    break;
            }
        });
        this.form.get('page1').get('network_ip_type').valueChanges.subscribe(value => {
            if (value === 'static') {
                this.form.get('page1').get('interface_ip').enable();
                this.form.get('page1').get('network_subnet').enable();
                this.form.get('page1').get('gateway').enable();
                this.form.get('page1').get('gateway_ip').enable();
                this.form.get('page2').get('dhcp_server_enabled').enable();
            } else {
                this.form.get('page1').get('interface_ip').setValue('');
                this.form.get('page1').get('interface_ip').disable();
                this.form.get('page1').get('network_subnet').setValue('');
                this.form.get('page1').get('network_subnet').disable();
                this.form.get('page1').get('gateway').setValue(false);
                this.form.get('page1').get('gateway').disable();
                this.form.get('page1').get('gateway_ip').setValue('');
                this.form.get('page1').get('gateway_ip').disable();
                this.form.get('page2').get('dhcp_server_enabled').setValue(false);
                this.form.get('page2').get('dhcp_server_enabled').disable();
            }
        });
        this.form.get('page1').get('gateway').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('page1').get('gateway_ip').setValue('');
                this.form.get('page1').get('gateway_ip').disable();
            } else {
                this.form.get('page1').get('gateway_ip').enable();
            }
        });
        this.form.get('page2').get('dhcp_server_enabled').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('page2').get('dhcp_server_start_address').enable();
                this.form.get('page2').get('dhcp_server_end_address').enable();
                if (this.form.get('page1').get('network_type').value === 'management') {
                    this.form.get('page3').get('filtering').setValue(false);
                    this.form.get('page3').get('media_access').setValue(false);
                }
            } else {
                this.form.get('page2').get('dhcp_server_start_address').setValue('');
                this.form.get('page2').get('dhcp_server_start_address').disable();
                this.form.get('page2').get('dhcp_server_end_address').setValue('');
                this.form.get('page2').get('dhcp_server_end_address').disable();
            }
        });
        this.form.get('page2').get('captive_portal_enabled').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('page2').get('captive_portal_max_client_up_speed').enable();
                this.form.get('page2').get('captive_portal_max_client_down_speed').enable();
                this.form.get('page2').get('captive_portal_endoo_auth').enable();
                this.form.get('page2').get('captive_portal_endoo_auth').setValue('endoo-users');
            } else {
                this.form.get('page2').get('captive_portal_max_client_up_speed').setValue('');
                this.form.get('page2').get('captive_portal_max_client_up_speed').disable();
                this.form.get('page2').get('captive_portal_max_client_down_speed').setValue('');
                this.form.get('page2').get('captive_portal_max_client_down_speed').disable();
                this.form.get('page2').get('captive_portal_endoo_auth').setValue('');
                this.form.get('page2').get('captive_portal_endoo_auth').disable();
            }
        });
        this.form.get('page2').get('captive_portal_endoo_auth').valueChanges.subscribe(value => {
            switch (value) {
                case '':
                case 'endoo-users':
                case 'endoo-guest':
                    this.form.get('page2').get('captive_portal_radius_ip').setValue('');
                    this.form.get('page2').get('captive_portal_radius_ip').disable();
                    this.form.get('page2').get('captive_portal_radius_port').setValue('');
                    this.form.get('page2').get('captive_portal_radius_port').disable();
                    this.form.get('page2').get('captive_portal_radius_secret').setValue('');
                    this.form.get('page2').get('captive_portal_radius_secret').disable();
                    break;

                case 'custom':
                    this.form.get('page2').get('captive_portal_radius_ip').enable();
                    this.form.get('page2').get('captive_portal_radius_port').enable();
                    this.form.get('page2').get('captive_portal_radius_secret').enable();
                    break;
            }
        });
        this.form.get('page3').get('inet_access_allowed').valueChanges.subscribe(value => {
            this.form.get('page3').get('allow_dns').setValue(true);
            this.form.get('page3').get('allow_web').setValue(true);
            this.form.get('page3').get('allow_email').setValue(true);
            this.form.get('page3').get('allow_push').setValue(true);
        });
        this.form.get('page3').get('filtering').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('page3').get('allow_dns').setValue(true);
                this.form.get('page3').get('allow_web').setValue(true);
            }
        });
        // Make IP checks on every valueChanges event
        merge(
            this.form.get('page1').get('interface_ip').valueChanges,
            this.form.get('page1').get('network_subnet').valueChanges,
            this.form.get('page1').get('gateway_ip').valueChanges,
            this.form.get('page2').get('dhcp_server_end_address').valueChanges,
            this.form.get('page2').get('dhcp_server_start_address').valueChanges
        ).subscribe(() => {
            this.ipChecks();
        });
    }

    resetForm(networkType: string) {
        this.form.get('page3').reset({}, { emitEvent: false });
        this.form.get('page2').reset({}, { emitEvent: false });
        this.form.get('page1').reset({}, { emitEvent: false });
        this.form.get('page1').get('network_type').setValue(networkType, { emitEvent: false }); // re-set the network type
    }

    onStepperNext() {
        if (!this.form.valid) {
            // this should make all invalid fields light up in red
            this.form.markAllAsTouched();
            return;
        }
        this.stepper.next();
    }

    async createNewRouterNetworkBtClick(): Promise<void> {
        const hasCaptivePortal = this.form.get('page1').get('gateway').value && this.form.get('page1').get('network_type').value !== 'management';

        const network_new = new RouterNetwork(
            (await this.routerNetworkService.getMaxRouterNetworkId()) + 1,
            this.lNetwork.id_number,
            this.form.get('page1').get('network_ip_type').value,
            this.form.get('page1').get('interface_ip').value,
            this.form.get('page1').get('network_subnet').value,
            this.form.get('page1').get('gateway').value,
            this.form.get('page1').get('gateway_ip').value,
            this.form.get('page2').get('dhcp_server_enabled').value,
            this.form.get('page2').get('dhcp_server_start_address').value,
            this.form.get('page2').get('dhcp_server_end_address').value,
            this.form.get('page3').get('filtering').value,
            hasCaptivePortal ? (await this.routerNetworkService.getMaxCaptivePortalId()) + 1 : null,
            this.form.get('page1').get('network_type').value,
            this.form.get('page3').get('media_access').value,
            this.form.get('page1').get('preferred_uplink_network').value,
            this.form.get('page3').get('inet_access_allowed').value,
            this.form.get('page3').get('allow_dns').value,
            this.form.get('page3').get('allow_web').value,
            this.form.get('page3').get('allow_email').value,
            this.form.get('page3').get('allow_push').value
        );
        const captivePortal = new CaptivePortal(
            hasCaptivePortal ? (await this.routerNetworkService.getMaxCaptivePortalId()) + 1 : undefined,
            this.form.get('page2').get('captive_portal_enabled').value,
            this.form.get('page2').get('captive_portal_max_client_up_speed').value,
            this.form.get('page2').get('captive_portal_max_client_down_speed').value,
            this.form.get('page2').get('captive_portal_endoo_auth').value,
            this.form.get('page2').get('captive_portal_radius_ip').value,
            this.form.get('page2').get('captive_portal_radius_port').value,
            this.form.get('page2').get('captive_portal_radius_secret').value
        );

        try {
            await this.routerNetworkService.createRouterNetwork(network_new).toPromise();
            if (hasCaptivePortal) {
                await this.routerNetworkService.createCaptivePortal(captivePortal).toPromise();
            }
            this.snackBar.open('Die endooConnect Einstellungen wurden gespeichert', 'OK');
            this.dialogRef.close(true);
        } catch (err) {
            this.snackBar.open('Die endooConnect Einstellungen konnten nicht gespeichert werden: ' + err, 'OK');
        }
    }

    ipChecks() {
        // Remove old IP check hints for page1
        ['interface_ip', 'gateway_ip',].forEach(controlName => {
            const errors = { ...this.form.get('page1').get(controlName).errors };
            delete errors.notInSameSubnet;
            delete errors.ipIsNetworkAddress;
            delete errors.ipIsBroadcastAddress;
            this.form.get('page1').get(controlName).setErrors(Object.keys(errors).length ? errors : null);
        });
        // Remove old IP check hints for page2
        ['dhcp_server_start_address', 'dhcp_server_end_address'].forEach(controlName => {
            const err = { ...this.form.get('page2').get(controlName).errors };
            delete err.dhcpRangeInvalid;
            this.form.get('page2').get(controlName).setErrors(Object.keys(err).length ? err : null);
        });

        // If subnet is invalid we can't do any IP checks
        if (this.form.get('page1').get('network_subnet').invalid) {
            return;
        }

        // Get network and broadcast address for Router ip
        let networkIpRouter;
        let broadcastIpRouter;
        if (this.form.get('page1').get('interface_ip').valid) {
            networkIpRouter = this.getNetworkIp(this.form.get('page1').get('interface_ip').value, this.form.get('page1').get('network_subnet').value);
            broadcastIpRouter = this.getBroadcastIp(networkIpRouter, this.form.get('page1').get('network_subnet').value);
        }

        // Get network and broadcast address for Gateway ip
        let networkIpGateway;
        let broadcastIpGateway;
        if (this.form.get('page1').get('gateway_ip').valid) {
            networkIpGateway = this.getNetworkIp(this.form.get('page1').get('gateway_ip').value, this.form.get('page1').get('network_subnet').value);
            broadcastIpGateway = this.getBroadcastIp(networkIpGateway, this.form.get('page1').get('network_subnet').value);
        }

        // Error: Not in same subnet
        if (networkIpRouter !== undefined && networkIpGateway !== undefined && networkIpRouter !== networkIpGateway) {
            // Router ip
            let errors = { ...this.form.get('page1').get('interface_ip').errors, notInSameSubnet: true };
            this.form.get('page1').get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);

            // Gateway ip
            errors = { ...this.form.get('page1').get('gateway_ip').errors, notInSameSubnet: true };
            this.form.get('page1').get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: Router IP is Network IP
        if (this.form.get('page1').get('interface_ip').value === networkIpRouter) {
            const errors = { ...this.form.get('page1').get('interface_ip').errors, ipIsNetworkAddress: true };
            this.form.get('page1').get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);
        }
        // Error: Router IP is Broadcast IP
        if (this.form.get('page1').get('interface_ip').value === broadcastIpRouter) {
            const errors = { ...this.form.get('page1').get('interface_ip').errors, ipIsBroadcastAddress: true };
            this.form.get('page1').get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: Router IP and Gateway IP is same
        if (this.form.get('page1').get('interface_ip').value?.trim() === this.form.get('page1').get('gateway_ip').value?.trim()) {
            let errors = { ...this.form.get('page1').get('interface_ip').errors, routerIpAndGatewayIpAreSame: true };
            this.form.get('page1').get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);

            errors = { ...this.form.get('page1').get('gateway_ip').errors, routerIpAndGatewayIpAreSame: true };
            this.form.get('page1').get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: Gateway IP is Network IP
        if (this.form.get('page1').get('gateway_ip').value === networkIpGateway) {
            const errors = { ...this.form.get('page1').get('gateway_ip').errors, ipIsNetworkAddress: true };
            this.form.get('page1').get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }
        // Error: Gateway IP is Broadcast IP
        if (this.form.get('page1').get('gateway_ip').value === broadcastIpGateway) {
            const errors = { ...this.form.get('page1').get('gateway_ip').errors, ipIsBroadcastAddress: true };
            this.form.get('page1').get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: DHCP-Start IP is Network IP
        if (this.form.get('page2').get('dhcp_server_start_address').value === networkIpRouter) {
            const errors = { ...this.form.get('page2').get('dhcp_server_start_address').errors, ipIsNetworkAddress: true };
            this.form.get('page2').get('dhcp_server_start_address').setErrors(Object.keys(errors).length ? errors : null);
        }
        // Error: DHCP-Start IP is Broadcast IP
        if (this.form.get('page2').get('dhcp_server_start_address').value === broadcastIpRouter) {
            const errors = { ...this.form.get('page2').get('dhcp_server_start_address').errors, ipIsBroadcastAddress: true };
            this.form.get('page2').get('dhcp_server_start_address').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: DHCP-End IP is Network IP
        if (this.form.get('page2').get('dhcp_server_end_address').value === networkIpRouter) {
            const errors = { ...this.form.get('page2').get('dhcp_server_end_address').errors, ipIsNetworkAddress: true };
            this.form.get('page2').get('dhcp_server_end_address').setErrors(Object.keys(errors).length ? errors : null);
        }
        // Error: DHCP-End IP is Broadcast IP
        if (this.form.get('page2').get('dhcp_server_end_address').value === broadcastIpRouter) {
            const errors = { ...this.form.get('page2').get('dhcp_server_end_address').errors, ipIsBroadcastAddress: true };
            this.form.get('page2').get('dhcp_server_end_address').setErrors(Object.keys(errors).length ? errors : null);
        }

        // DHCP range check on page 2
        if (this.form.get('page2').get('dhcp_server_start_address').valid && this.form.get('page2').get('dhcp_server_end_address').valid) {
            if (!this.isInRange(this.form.get('page1').get('interface_ip').value, this.form.get('page1').get('network_subnet').value, this.form.get('page2').get('dhcp_server_start_address').value) || !this.isInRange(this.form.get('page1').get('interface_ip').value, this.form.get('page1').get('network_subnet').value, this.form.get('page2').get('dhcp_server_end_address').value)) {
                // Set range error for "dhcp_server_start_address"
                let errors = { ...this.form.get('page2').get('dhcp_server_start_address').errors, dhcpRangeInvalid: true };
                this.form.get('page2').get('dhcp_server_start_address').setErrors(Object.keys(errors).length ? errors : null);

                // Set range error for "dhcp_server_end_address"
                errors = { ...this.form.get('page2').get('dhcp_server_end_address').errors, dhcpRangeInvalid: true };
                this.form.get('page2').get('dhcp_server_end_address').setErrors(Object.keys(errors).length ? errors : null);
            }
        }
    }

    isInRange(inet_ip: string, subnetmask: string, ip: string) {
        const network_ip_as_number = parseInt(this.getIpAsBinary(this.getNetworkIp(inet_ip, subnetmask)), 2);
        const broadcast_ip_as_number = parseInt(this.getIpAsBinary(this.getBroadcastIp(inet_ip, subnetmask)), 2);
        const check_ip_as_number = parseInt(this.getIpAsBinary(ip), 2);
        return check_ip_as_number > network_ip_as_number && check_ip_as_number < broadcast_ip_as_number;
    }

    getNetworkIp(hostIp: string, subnetmask: string) {
        const ipNumbers = hostIp.split('.').map(v => Number(v));
        const subnetmaskNumbers = subnetmask.split('.').map(v => Number(v));
        const networkIpNumbers = ipNumbers.map((v, i) => v & subnetmaskNumbers[i]);
        return networkIpNumbers.join('.');
    }

    getBroadcastIp(networkIp: string, subnetmask: string) {
        // Find host part index
        const split_index_network_part = this.getIpAsBinary(subnetmask).indexOf('0');
        // Get network part as binary
        let bNetworkPart = this.getIpAsBinary(networkIp).substring(0, split_index_network_part);
        // Fill right part with ones to get broadcast ip numbers
        while (bNetworkPart.length < 32) {
            bNetworkPart = bNetworkPart + '1';
        }
        // return broadcast ip address
        return parseInt(bNetworkPart.substring(0, 8), 2)
            + '.' + parseInt(bNetworkPart.substring(8, 16), 2)
            + '.' + parseInt(bNetworkPart.substring(16, 24), 2)
            + '.' + parseInt(bNetworkPart.substring(24, 32), 2);
    }

    getIpAsBinary(ipAddress: string) {
        return ipAddress.split('.').map(v => Number(v).toString(2)).map(v => {
            while (v.length < 8) {
                v = '0' + v;
            }
            return v;
        }).join('');
    }
}
