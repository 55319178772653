import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { DBCustomerFunctionProperty, DBProperty } from '../../data/endooSpotDB_objects';
import { WifiNetwork } from '../../data/WifiNetwork';
import { forkJoin, Observable } from 'rxjs';
import { mergeAll, map } from 'rxjs/operators';
import { CustomerFunctionPropertyService } from '../../endooSpotApplication/Services/CustomerFunctionPropertyService';
import { EndooHttpService } from 'app/Services/endooHttp.service';

@Injectable()
export class WifiSettingsService {
    private static readonly network1_function = 'WifiNetwork1';
    private readonly wifiSettingsURL = '/api/customers/';

    constructor(private http: EndooHttpService, private authService: AuthenticationService, private cfpService: CustomerFunctionPropertyService) { }

    /**
    * Function which returns all devices from Server
    */
    getendooNetwork(): Observable<WifiNetwork> {
        return this.http.get<DBCustomerFunctionProperty[]>(this.wifiSettingsURL + this.authService.getCustomerId() + '/functions/' + WifiSettingsService.network1_function, { observe: 'response', responseType: 'json' })
            .pipe(map((response: DBCustomerFunctionProperty[]) => {
                // get WifiSettings from response
                if (response.length > 0) {
                    const result: WifiNetwork = new WifiNetwork( // use wrapper object to work with customer function properties
                        0, // wifi network id
                        Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_logical_networkID)), // network_id of logical network
                        (this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_client2client) || 'true') === 'true', // clienttoclient
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_ssid), // ssid
                        (this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_enabled) || 'true') === 'true', // activated
                        (this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_hidessid) || 'false') === 'true', // hide_ssid
                        (this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_encryption) || 'false') === 'true', // encryption
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_encryption_mode), // encryption_mode
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_encryption_key), // encryption_key
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_radius_ip), // radius_ip
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_radius_secret), // radius_secret
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_radius_port), // radius_port
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifitime_active) === 'true', // wifitime active
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_mon_on), // wifi_time_mon_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_tue_on), // wifi_time_tue_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_wed_on), // wifi_time_wed_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_thu_on), // wifi_time_thu_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_fri_on), // wifi_time_fri_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sat_on), // wifi_time_sat_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sun_on), // wifi_time_sun_on
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_mon_off), // wifi_time_mon_off
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_tue_off), // wifi_time_tue_off
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_wed_off), // wifi_time_wed_off
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_thu_off), // wifi_time_thu_off
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_fri_off), // wifi_time_fri_off
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sat_off), // wifi_time_sat_off
                        this.cfpService.getCustomerFunctionPropertyValue(response, WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sun_off), // wifi_time_sun_off
                    );
                    return result;
                } else {
                    return null;
                }
            }));
    }

    /**
     * @param new_network
     */
    saveEndooNetwork(new_network: WifiNetwork): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_id_property, new_network.wifi_network_id.toString()); // network_id of logical network
        setRequests[24] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_logical_networkID, new_network.logical_network_id.toString()); // network_id of logical network
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_client2client, String(new_network.client2client)); // client2client
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_ssid, new_network.ssid); // ssid
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_encryption, String(new_network.encryption)); // encryption
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_encryption_mode, new_network.encryption_mode); // encryption_mode
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_encryption_key, new_network.encryption_key); // encryption_key
        setRequests[6] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_radius_ip, new_network.encryption_radius_ip); // radius_ip
        setRequests[7] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_radius_secret, new_network.encryption_radius_secret); // vlan_id
        setRequests[8] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_radius_port, new_network.encryption_radius_port); // vlan_id
        setRequests[9] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifitime_active, String(new_network.wifitime_active)); // wifitime active
        setRequests[10] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_mon_on, new_network.wifitime_mon_on); // wifi_time_mon_on
        setRequests[11] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_tue_on, new_network.wifitime_tue_on); // wifi_time_tue_on
        setRequests[12] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_wed_on, new_network.wifitime_wed_on); // wifi_time_wed_on
        setRequests[13] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_thu_on, new_network.wifitime_thu_on); // wifi_time_thu_on
        setRequests[14] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_fri_on, new_network.wifitime_fri_on); // wifi_time_fri_on
        setRequests[15] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sat_on, new_network.wifitime_sat_on); // wifi_time_sat_on
        setRequests[16] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sun_on, new_network.wifitime_sun_on); /// wifi_time_sun_on
        setRequests[17] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_mon_off, new_network.wifitime_mon_off); /// wifi_time_mon_off
        setRequests[18] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_tue_off, new_network.wifitime_tue_off); // wifi_time_tue_off
        setRequests[19] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_wed_off, new_network.wifitime_wed_off); // wifi_time_wed_off
        setRequests[20] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_thu_off, new_network.wifitime_thu_off); // wifi_time_thu_off
        setRequests[21] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_fri_off, new_network.wifitime_fri_off); // wifi_time_fri_off
        setRequests[22] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sat_off, new_network.wifitime_sat_off); // wifi_time_sat_off
        setRequests[23] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_wifi_time_sun_off, new_network.wifitime_sun_off); // wifi_time_sun_off
        setRequests[25] = this.cfpService.setCustomerFunctionProperty(WifiSettingsService.network1_function, DBProperty.wifi_network_hidessid, String(new_network.hide_ssid)); // hide ssid
        return forkJoin(setRequests).pipe(mergeAll());
    }
}
