import { Injectable } from '@angular/core';
import { ApDeviceService } from 'app/APManagement/APService/apDevice.service';
import { ModelService } from 'app/APManagement/APService/model.service';
import { DBDevice, DBModel } from 'app/data/endooSpotDB_objects';
import { defer, Observable } from 'rxjs';
import { PlotData } from './Plotly/graph-data-response';
import { PlotlyGraph } from './Plotly/plotly-graph';
import { PlotlyGraphGauge } from './Plotly/plotly-graph-gauge';
import { PlotlyGraphScatter } from './Plotly/plotly-graph-scatter';

export type DevicePlotlyGraphInput<T extends PlotlyGraph> = {
    plotlyGraph: T,
    requests: DevicePlotlyGraphRequest[]
};

export type DevicePlotlyGraphRequest = {
    device: DBDevice,
    plotData$: Observable<PlotData>
};

export type DevicePlotlyGraphOutput<T extends PlotlyGraph> = {
    plotlyGraph: T,
    responses: DevicePlotlyGraphResponse[]
};

export type DevicePlotlyGraphResponse = {
    device: DBDevice,
    hasDashboardSupport: boolean,
    isOnline: boolean,
    hasPlotData: boolean
};

@Injectable()
export class DevicePlotlyGraphService {

    /**
     * @param modelService
     * @param deviceService
     */
    constructor(
        private modelService: ModelService,
        private deviceService: ApDeviceService
    ) { }

    /**
     * @param input
     */
    getData<T extends PlotlyGraph>(input: DevicePlotlyGraphInput<T>, isSingleDeviceGraph: boolean = false): Observable<DevicePlotlyGraphOutput<T>> {
        return defer(async () => {
            // Models aller enthaltenden Devices fetchen
            const models: DBModel[] = [];
            for (const request of input.requests) {
                if (models[request.device.model] === undefined) {
                    models[request.device.model] = await this.modelService.getModel(request.device.model).toPromise();
                }
            }

            const responses: DevicePlotlyGraphResponse[] = [];
            let resultValue = 0; // Ergebnis Variable des Gauge Graphs
            const values = []; // Plotdata Array für den Scatter Graph aller Graphwerte (Geräte)
            for (let i = 0; i < input.requests.length; i++) {
                const request = input.requests[i];
                const hasDashboardSupport = this.modelService.hasModelFunction(models[request.device.model], 'Dashboard');
                const isOnline = this.deviceService.isDeviceOnline(request.device);

                let data;
                if (!hasDashboardSupport || (input.plotlyGraph instanceof PlotlyGraphGauge && !isOnline)) {
                    data = { device: request.device, hasDashboardSupport: hasDashboardSupport, isOnline: isOnline, hasPlotData: false };
                } else {
                    try {
                        const plotData = await request.plotData$.toPromise();
                        if (input.plotlyGraph instanceof PlotlyGraphGauge) { // Wenn der Graph ein Tachometer ist ...
                            // ... summiere seinen Wert zu der Ergebnis Variable
                            resultValue += plotData.value;
                        } else if (input.plotlyGraph instanceof PlotlyGraphScatter) { // Wenn der Graph ein Liniendiagramm ist ...
                            values.push({ ...plotData, ...input.plotlyGraph.plotData[i] }); // ... merge die Graphdaten mit dem PlotLayout
                        }
                        data = { device: request.device, hasDashboardSupport: hasDashboardSupport, isOnline: isOnline, hasPlotData: true };
                    } catch (error) {
                        data = { device: request.device, hasDashboardSupport: hasDashboardSupport, isOnline: isOnline, hasPlotData: false };
                    }
                }
                responses.push(data);
            }

            if (input.plotlyGraph instanceof PlotlyGraphGauge) {
                input.plotlyGraph.plotData = [{ ...{ value: resultValue }, ...input.plotlyGraph.plotData[0] }]; // Merge die Graphdaten mit dem PlotLayout
            } else if (input.plotlyGraph instanceof PlotlyGraphScatter) {
                input.plotlyGraph.plotData = values;
            }
            if (isSingleDeviceGraph) {
                if (!responses[0].isOnline && input.plotlyGraph instanceof PlotlyGraphGauge) {
                    input.plotlyGraph.plotData[0].title.text = input.plotlyGraph.plotData[0].title.text + ' [offline]';
                }
                input.plotlyGraph.hasDashboardSupport = responses[0].hasDashboardSupport;
                input.plotlyGraph.needsFirmwareUpdate = responses[0].hasDashboardSupport && responses[0].isOnline && !responses[0].hasPlotData;
            }

            return { plotlyGraph: input.plotlyGraph as T, responses: responses };
        });
    }
}
