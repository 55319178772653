import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { DBDevice } from 'app/data/endooSpotDB_objects';
import { ApDeviceService } from 'app/APManagement/APService/apDevice.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { interval, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
    selector: 'connected-stations-component',
    templateUrl: './connected-stations.component.html',
    styleUrls: ['./connected-stations.component.scss'],
})
export class ConnectedStationsComponent implements AfterViewInit, OnDestroy {
    @Input() device: DBDevice;
    displayedColumns: string[] = ['mac', 'txRate', 'channel', 'rssi', 'assocTime', 'updatedAt'];
    data: MatTableDataSource<any> = new MatTableDataSource();
    reloadInterval: Subscription;

    constructor(private snackBar: MatSnackBar, private apDeviceService: ApDeviceService) { }

    ngAfterViewInit() {
        this.fetchData();
        this.reloadInterval = interval(0.5 * 1000 * 60).pipe(map(() => {
            this.fetchData();
        })).subscribe();
    }

    ngOnDestroy() {
        if (this.reloadInterval) {
            this.reloadInterval.unsubscribe();
        }
    }

    async fetchData(): Promise<void> {
        try {
            this.data.data = await this.apDeviceService.getAllStations(this.device.inventoryNumber).toPromise();
            this.data.data = this.data.data.filter(stats => moment().diff(stats.timestamp, 'minutes') <= 5);
            this.data.data = this.data.data.map(stats => {
                stats.timestamp = moment(stats.timestamp).format('DD.MM.YYYY HH:mm');
                return stats;
            });
        } catch (err) {
            this.snackBar.open('Die Geräteinformationen konnten nicht abgerufen werden: ' + err, 'OK');
        }
    }
}
