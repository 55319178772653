<form [formGroup]="form" (ngSubmit)="saveWifiNetworkBtClick()">
	<mat-list>
		<mat-list-item>
			<mat-slide-toggle [(ngModel)]="network.activated" [ngModelOptions]="{standalone: true}">Aktiviert
			</mat-slide-toggle>
		</mat-list-item>
		<h3 mat-subheader>
			SSID
		</h3>
		<mat-list-item style="padding-top: 20px; padding-bottom: 20px;">
			<mat-form-field appearance="outline">
				<mat-label>SSID-Name</mat-label>
				<input formControlName="ssid_name"
					[disabled]="!authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" matInput
					[(ngModel)]="network.ssid" required />
				<mat-error *ngIf="hasError('ssid_name', 'required')">
					SSID-Name erforderlich
				</mat-error>
				<mat-error *ngIf="hasError('ssid_name', 'maxlength')">
					SSID-Name darf höhstens 32 Zeichen beinhalten
				</mat-error>
			</mat-form-field>
		</mat-list-item>
		<mat-list-item>
			<mat-slide-toggle [disabled]="!authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
				[(ngModel)]="network.hide_ssid" [ngModelOptions]="{standalone: true}">
				SSID verstecken</mat-slide-toggle>
		</mat-list-item>
		<mat-divider></mat-divider>
		<h3 *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-subheader>Zugeh&ouml;riges
			logisches Netzwerk</h3>
		<mat-list-item style="padding-top: 20px; padding-bottom: 10px;">
			<mat-form-field appearance="outline">
				<mat-label>Logisches Netzwerk</mat-label>
				<mat-select formControlName="logical_network_id" [(ngModel)]="network.logical_network_id" required>
					<mat-option *ngFor="let logicalNetwork of logicalNetworks" [value]="logicalNetwork.id_number">
						{{logicalNetwork.name}}</mat-option>
				</mat-select>
			</mat-form-field>
		</mat-list-item>
		<mat-divider></mat-divider>
		<h3 *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-subheader>
			Kommunikation zwischen Ger&auml;ten (wird z.B. für AirPlay ben&ouml;tigt)
		</h3>
		<mat-list-item *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')">
			<mat-slide-toggle [(ngModel)]="network.client2client" [ngModelOptions]="{standalone: true}">Client2Client
				Kommunikation
			</mat-slide-toggle>
		</mat-list-item>
		<mat-divider *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"></mat-divider>
		<mat-list-item>
			<div mat-line>
				<button mat-raised-button class="button-margin-bottom button-margin-right" color="primary"
					title="Speichern" [disabled]="background_working" type="submit">
					Speichern
				</button>
				<button *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-raised-button
					class="button-margin-bottom button-margin-top" type="button" (click)="deleteWifiNetworkBtClick()">
					Netzwerk l&ouml;schen
				</button>
			</div>
		</mat-list-item>
	</mat-list>
</form>