<h1 mat-dialog-title>Administrator ändern</h1>
<form [formGroup]="form">
    <div class="fullwidth_form" mat-dialog-content>
        <mat-form-field appearance="outline">
            <mat-label>Benutzername</mat-label>
            <input matInput type="text" id="admin_username_tx" formControlName="username" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Beschreibung</mat-label>
            <input matInput type="text" id="description_tx" formControlName="description" />
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>E-Mail Adresse</mat-label>
            <input matInput type="text" id="description_tx" formControlName="email" />
            <mat-error *ngIf="hasError('email', 'required')">E-Mail Adresse erforderlich
            </mat-error>
            <mat-error *ngIf="hasError('email', 'email')">Ungültige E-Mail Adresse</mat-error>
        </mat-form-field>
        <button color="primary" mat-raised-button id="SaveUser_Bt" type="button" title="Speichern"
            (click)="saveAdminBtClick()" [disabled]="!isOwnAdminOfCustomer()">Speichern</button>
        <mat-list>
            <mat-list-item *ngIf="hasCustomerRole('BYOD-MANAGER')">
                <h4>endooSpot-Netzwerk</h4>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('BYOD-MANAGER')">
                <mat-button-toggle-group style="flex-wrap: wrap;"
                    [value]="permission('BYOD')" (change)="changePermission('BYOD', $event)">
                    <mat-button-toggle value="0">
                        Keine Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="1">
                        Eingeschränkte Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="2">
                        Alle Rechte
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-MANAGER') || hasCustomerRole('HARDWARE-ENDOOSPOT')">
                <h4>endooSpot-Hardware</h4>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-MANAGER')">
                <mat-button-toggle-group *ngIf="hasCustomerRole('HARDWARE-MANAGER')" style="flex-wrap: wrap;"
                    [value]="permission('HARDWARE')" (change)="changePermission('HARDWARE', $event)">
                    <mat-button-toggle value="0">
                        Keine Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="1">
                        Eingeschr&auml;nkte Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="2">
                        Alle Rechte
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-ENDOOSPOT')">
                <mat-slide-toggle [checked]="hasAdminRole('HARDWARE-ENDOOSPOT')"
                    (change)="changeRole($event,'HARDWARE-ENDOOSPOT')"> Verwaltung von endooSpot-WLAN
                </mat-slide-toggle>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-DASHBOARD')">
                <h4>Geräte-Auslastung</h4>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-DASHBOARD')">
                <mat-slide-toggle [checked]="hasAdminRole('HARDWARE-DASHBOARD')"
                    (change)="changeRole($event,'HARDWARE-DASHBOARD')"> Zugriff auf Dashboard und Auslastung der
                    Geräte
                </mat-slide-toggle>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-ADMINISTRATOR')">
                <h4>Administratorverwaltung</h4>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-ADMINISTRATOR')">
                <mat-slide-toggle [checked]="hasAdminRole('ADMINISTRATOR-ADMINISTRATOR')"
                    [disabled]="authService.getUsername() === admin.username"
                    (change)="changeRole($event,'ADMINISTRATOR-ADMINISTRATOR')"> Bearbeiten von Administratoren
                </mat-slide-toggle>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-PORTCONFIG')">
                <h4>Portkonfigurationen</h4>
            </mat-list-item>
            <mat-list-item *ngIf="hasCustomerRole('HARDWARE-PORTCONFIG')">
                <mat-slide-toggle [checked]="hasAdminRole('HARDWARE-PORTCONFIG')"
                    (change)="changeRole($event,'HARDWARE-PORTCONFIG')"> Portkonfiguration von Geräten ändern
                </mat-slide-toggle>
            </mat-list-item>
            <!--<mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-CONTRACTS')">
                <h4>Vertragsverwaltung</h4>
            </mat-list-item>
            <mat-list-item>
                <mat-slide-toggle [checked]="hasAdminRole('ADMINISTRATOR-CONTRACTS')"
                    (change)="changeRole($event,'ADMINISTRATOR-CONTRACTS')"> Berechtigung zur Vorlage von Verträgen
                    und deren Signierung
                </mat-slide-toggle>
            </mat-list-item>-->
            <!--<mat-list-item *ngIf="hasCustomerRole('ROUTERNETWORK-ADMINISTRATOR')">
            <h4>Router-Netzwerke</h4>
        </mat-list-item>
        <mat-list-item *ngIf="hasCustomerRole('ROUTERNETWORK-ADMINISTRATOR')">
            <mat-slide-toggle [checked]="hasAdminRole('ROUTERNETWORK-ADMINISTRATOR')"
                (change)="changeRole($event,'ROUTERNETWORK-ADMINISTRATOR')"> Rolle für das Erstellen und
                Verwalten von Router-Netzwerken
            </mat-slide-toggle>
        </mat-list-item>-->
        </mat-list>
    </div>
</form>
<div mat-dialog-actions>
    <button mat-raised-button id="DeleteUser_Bt" type="button" title="Löschen"
        [disabled]="authService.getUsername() === admin.username"
        *ngIf="isOwnAdminOfCustomer()"
        (click)="deleteAdminBtClick()">L&ouml;schen</button>
    <button mat-raised-button id="DeleteUser_Bt" type="button" title="Entfernen"
        *ngIf="!isOwnAdminOfCustomer()"
        (click)="removeAdminBtClick()">Aus Schule entfernen</button>
    <button mat-raised-button id="NewPassword_Bt" type="button" title="Neues Passwort" (click)="newPasswordBtClick()"
        [disabled]="!isOwnAdminOfCustomer()">Neues Passwort</button>
    <button mat-raised-button (click)="closeBtClick()">Schlie&szlig;en</button>
</div>