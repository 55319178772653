import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from 'app/Components/loading-dialog-component/loading-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class LoadingService {
    loadings$: Observable<any>[] = [];
    dialogRef: MatDialogRef<LoadingDialogComponent>;

    constructor(private dialog: MatDialog) { }

    addLoading(request: Observable<any>): void {
        if (this.loadings$.length === 0) {
            this.dialogRef = this.dialog.open(LoadingDialogComponent, {
                disableClose: true,
            });
        }
        this.loadings$.push(request);
    }

    removeLoading(request: Observable<any>): void {
        this.loadings$ = this.loadings$.filter(os => os !== request);
        if (this.loadings$.length === 0) {
            if (this.dialogRef !== undefined) {
                this.dialogRef.close();
                this.dialogRef = undefined;
            }
        }
    }
}
