<h1 mat-dialog-title>Mehrfachbearbeitung von Geräten bestätigen?</h1>
<div mat-dialog-content>
	<div>
		Die folgenden Geräte sind von den Änderungen betroffen:
	</div>
	<ul>
		<li *ngFor="let inventoryNumber of inventoryNumbers">
			<span style="text-transform: uppercase;">{{inventoryNumber}}</span>
		</li>
	</ul>
	<div style="margin-bottom: 30px;">
		<button mat-raised-button style="margin-right: 10px;" (click)="cancel()">Abbrechen</button>
		<button mat-raised-button color="primary" (click)="confirm()">Bestätigen</button>
	</div>
</div>
