import { GraphicElement } from './graphic-element';
import { Content } from './canvas.component';

export class SvgImage extends GraphicElement {
    image: HTMLImageElement;
    content: Content;

    width: number;
    height: number;

    constructor(x: number, y: number, href: string, content: Content) {
        super(x, y, content);

        this.image = new Image();
        this.image.src = href;
        this.content = content;
    }
}
