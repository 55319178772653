import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DBDevice, DBModel } from '../../data/endooSpotDB_objects';
import { ApDeviceService } from '../APService/apDevice.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'app/Login/authentication.service';
import { QrCodeReaderComponent } from 'app/Components/qr-code-reader-component/qr-code-reader.component';
import { ModelService } from '../APService/model.service';
import { MatHorizontalStepper } from '@angular/material/stepper';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'AddApComponent',
    templateUrl: './AddAp.component.html',
})
export class AddApDialogComponent {
    @ViewChild('stepper') stepper: MatHorizontalStepper;
    firstForm: FormGroup;
    new_device: DBDevice = new DBDevice();
    models: DBModel[] = [];
    selectedModels: DBModel[];

    constructor(
        public authenticationService: AuthenticationService,
        private apService: ApDeviceService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<AddApDialogComponent>,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private modelService: ModelService
    ) {
        // creating form validator for the new User form.
        this.firstForm = this.fb.group({
            serial_number: ['', [Validators.required, Validators.pattern(/[a-zA-Z0-9\-]/)]],
            inventory_number: ['', [Validators.required]],
            mac: ['', [Validators.required, Validators.pattern(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/)]],
            model_id: ['', [Validators.required]],
        });
        this.firstForm.get('model_id').valueChanges.subscribe((value: string) => {
            if (value === undefined) {
                return;
            }
            const selectedModel = this.models.find(model => model.modelId.toLowerCase() === value.toLowerCase());
            if (selectedModel) {
                if (selectedModel.serial_number_available) {
                    this.firstForm.get('serial_number').enable();
                } else {
                    this.firstForm.get('serial_number').setValue(undefined);
                    this.firstForm.get('serial_number').disable();
                }
                if (selectedModel.mac_available) {
                    this.firstForm.get('mac').enable();
                    this.firstForm.get('serial_number').setValue(undefined);
                    this.firstForm.get('serial_number').disable();
                } else {
                    this.firstForm.get('mac').setValue(undefined);
                    this.firstForm.get('mac').disable();
                }
            }
        });
        this.firstForm.get('mac').valueChanges.subscribe((value: string) => {
            if (value === undefined) {
                return;
            }
            let newValue = '';
            value = value.replace(/\:/g, '');
            for (let i = 0; i < value.length; i += 2) {
                newValue += value.substring(i, i + 2) + ':';
            }
            if (newValue.charAt(newValue.length - 1) === ':') {
                newValue = newValue.substring(0, newValue.length - 1);
            }
            newValue = newValue.substring(0, 17);
            this.firstForm.get('mac').setValue(newValue, { emitEvent: false });
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.firstForm.get(formControl).hasError(error);
    }

    async ngOnInit() {
        await this.getModels();
        await this.models.sort((a, b) => a.modelId.localeCompare(b.modelId));
        this.initFilterForAutocomplete();
    }

    openQrCodeReaderDialog(): void {
        const dialogRef = this.dialog.open(QrCodeReaderComponent);
        dialogRef.afterClosed().subscribe((qrCodeContent: string) => {
            if (!qrCodeContent) {
                return;
            }
            const splitted = qrCodeContent.split(';'); // structure: v1;inventoryNumber;macAddress-or-na;serialNumber-or-na;modelId
            this.firstForm.get('inventory_number').setValue(splitted[1]);
            this.firstForm.get('mac').setValue(splitted[2]);
            this.firstForm.get('serial_number').setValue(splitted[3]);
            this.firstForm.get('model_id').setValue(splitted[4]);
            this.nextStep();
        });
    }

    async addDevice(): Promise<void> {
        try {
            this.buildDevice();
            const response = await this.apService.addDevice(this.new_device).toPromise();
            if (!response.deviceCorrect) {
                throw new Error('Inventarnummer, MAC-Adresse oder Seriennummer falsch');
            }
            if (!response.deviceAvailable) {
                throw new Error('Dieses Gerät steht nicht zur Verfügung');
            }
            this.snackBar.open('Das Gerät wurde in die Geräteliste hinzugefügt', 'OK');
            this.dialogRef.close();
        } catch (err) {
            this.snackBar.open('Das Gerät konnte nicht hinzugefügt werden: ' + err, 'OK');
        }
    }

    // Method to make a step in the HorizontalStepper if qr-code is scanned (and variables are filled)- make two steps to check up the data
    nextStep(): void {
        // Check page 1 data
        if (this.stepper.selectedIndex === 0 && this.firstForm.get('model_id').value !== '') {
            this.stepper.next();
            // Step further if qr-code is scanned correctly
            if (this.firstForm.get('inventory_number').valid && (this.firstForm.get('mac').valid || this.firstForm.get('serial_number').valid)) {
                this.stepper.next();
            }
            return;
        }
        // Check page 2 data
        if (this.stepper.selectedIndex === 1 && this.firstForm.get('inventory_number').value !== '' && (this.firstForm.get('mac').value !== '' || this.firstForm.get('serial_number').value !== '')) {
            this.stepper.next();
        }
    }

    async getModels(): Promise<void> {
        try {
            this.models = await this.modelService.getAll().toPromise();
        } catch (err) {
            this.snackBar.open('Die Geräte konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    async buildDevice(): Promise<void> {
        if (this.firstForm.get('model_id')) {
            this.new_device.model = this.firstForm.get('model_id').value;
        }
        if (this.firstForm.get('mac')) {
            this.new_device.mac = this.firstForm.get('mac').value;
        }
        if (this.firstForm.get('serial_number')) {
            this.new_device.serial_number = this.firstForm.get('serial_number').value;
        }
        if (this.firstForm.get('inventory_number')) {
            this.new_device.inventoryNumber = this.firstForm.get('inventory_number').value;
        }
    }

  /**
   *
   * @param value the input value from the form
   * @param arrayToFilter which array should be fitlert
   * @returns an array which contains the filtered objects
   */
   private _filter(value: string): DBModel[] {
        if(value !== undefined && typeof value === 'string') {
            const filterValue = value.toLowerCase();
            return this.models.filter(model => model.modelId.toLowerCase().includes(filterValue))
        }
    }

    private async initFilterForAutocomplete() {
        this.firstForm.get('model_id').valueChanges.pipe(
            startWith(''),
            map( value => {
                if(typeof value === 'string') {
                    this.selectedModels = this._filter(value);
                }}
            )).subscribe();
        }

    // Display the modelId for the mat-autocomplete
    displayNameOfTheModel(model_id?: string): string {
        return model_id ? model_id : '';
    }
}
