<h1 mat-dialog-title>Neuer Administrator</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper linear=true #newadminstepper="matHorizontalStepper">
        <mat-step [stepControl]="newAdminForm" style="padding-bottom:20px;" label="Daten eingeben">
            <form [formGroup]="newAdminForm">
                <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                    <mat-form-field appearance="outline" style="margin-bottom: 18px;">
                        <mat-label>Benutzername</mat-label>
                        <input matInput formControlName="username" type="text" class="fullwidth" required />
                        <b matSuffix>@{{authService.getCustomerId()}}</b>
                        <mat-hint>Besteht aus Buchstaben oder Zahlen</mat-hint>
                        <mat-error *ngIf="hasError('username', 'required')">Benutzername erforderlich
                        </mat-error>
                        <mat-error *ngIf="hasError('username', 'pattern')">Ungültige Eingabe</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Beschreibung</mat-label>
                        <input matInput formControlName="description" type="text" />
                    </mat-form-field>
                    <mat-form-field appearance="outline" style="margin-bottom: 18px;">
                        <mat-label>Passwort</mat-label>
                        <input formControlName="password" matInput type="text" />
                        <mat-hint>Initiales Passwort</mat-hint>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>E-Mail Adresse</mat-label>
                        <div style="display: flex;">
                            <input matInput formControlName="email" type="text" required />
                        </div>
                        <mat-error *ngIf="hasError('email', 'required')">E-Mail Adresse erforderlich
                        </mat-error>
                        <mat-error *ngIf="hasError('email', 'email')">Ungültige E-Mail Adresse</mat-error>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div>
                    <button mat-button matStepperNext>Weiter</button>
                </div>
            </form>
        </mat-step>
        <mat-step label="Eingaben &uuml;berpr&uuml;fen">
            <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                <mat-form-field appearance="outline">
                    <mat-label>Benutzername</mat-label>
                    <input matInput class="fullwidth" type="text"
                        [value]="newAdminForm.get('username').value + '@' + authService.getCustomerId()" disabled />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Beschreibung</mat-label>
                    <textarea matInput cdkTextareaAutosize [value]="newAdminForm.get('description').value"
                        disabled></textarea>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Passwort</mat-label>
                    <input matInput type="text" [value]="newAdminForm.get('password').value" disabled />
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>E-Mail Adresse</mat-label>
                    <input matInput type="text" [value]="newAdminForm.get('email').value" disabled />
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div>
                <button mat-button matStepperPrevious>Zur&uuml;ck</button>
                <button mat-button (click)="createNewAdminBtClick(newadminstepper)">Administrator anlegen</button>
            </div>
        </mat-step>
        <mat-step label="Berechtigungen">
            <mat-list>
                <mat-list-item *ngIf="hasCustomerRole('BYOD-MANAGER')">
                    <h4>endooSpot-Netzwerk</h4>
                </mat-list-item>
                <mat-button-toggle-group *ngIf="hasCustomerRole('BYOD-MANAGER')" style="flex-wrap: wrap;"
                    [value]="permission('BYOD')" (change)="changePermission('BYOD', $event)">
                    <mat-button-toggle value="0">
                        Keine Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="1">
                        Eingeschr&auml;nkte Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="2">
                        Alle Rechte
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-MANAGER')">
                    <h4>endooSpot-Hardware</h4>
                </mat-list-item>
                <mat-button-toggle-group *ngIf="hasCustomerRole('HARDWARE-MANAGER')" style="flex-wrap: wrap;"
                    [value]="permission('HARDWARE')" (change)="changePermission('HARDWARE', $event)">
                    <mat-button-toggle value="0">
                        Keine Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="1">
                        Eingeschr&auml;nkte Rechte
                    </mat-button-toggle>
                    <mat-button-toggle value="2">
                        Alle Rechte
                    </mat-button-toggle>
                </mat-button-toggle-group>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-ENDOOSPOT')">
                    <h4>endooSpot-Hardware</h4>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-ENDOOSPOT')">
                    <mat-slide-toggle [checked]="hasAdminRole('HARDWARE-ENDOOSPOT')"
                        (change)="changeRole($event,'HARDWARE-ENDOOSPOT')"> Verwaltung von endooSpot-WLAN
                    </mat-slide-toggle>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-DASHBOARD')">
                    <h4>Ger&auml;te-Auslastung</h4>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-DASHBOARD')">
                    <mat-slide-toggle [checked]="hasAdminRole('HARDWARE-DASHBOARD')"
                        (change)="changeRole($event,'HARDWARE-DASHBOARD')"> Zugriff auf Dashboard und Auslastung der
                        Ger&auml;te
                    </mat-slide-toggle>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-ADMINISTRATOR')">
                    <h4>Administratorverwaltung</h4>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-ADMINISTRATOR')">
                    <mat-slide-toggle [checked]="hasAdminRole('ADMINISTRATOR-ADMINISTRATOR')"
                        (change)="changeRole($event,'ADMINISTRATOR-ADMINISTRATOR')"> Bearbeiten von Administratoren
                    </mat-slide-toggle>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-PORTCONFIG')">
                    <h4>Portkonfigurationen</h4>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('HARDWARE-PORTCONFIG')">
                    <mat-slide-toggle [checked]="hasAdminRole('HARDWARE-PORTCONFIG')"
                        (change)="changeRole($event,'HARDWARE-PORTCONFIG')"> Portkonfiguration von Geräten ändern
                    </mat-slide-toggle>
                </mat-list-item>
                <!--<mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-CONTRACTS')">
                    <h4>Vertragsverwaltung</h4>
                </mat-list-item>
                <mat-list-item>
                    <mat-slide-toggle [checked]="hasAdminRole('ADMINISTRATOR-CONTRACTS')"
                        (change)="changeRole($event,'ADMINISTRATOR-CONTRACTS')"> Berechtigung zur Vorlage von Verträgen
                        und deren Signierung
                    </mat-slide-toggle>
                </mat-list-item>-->
                <mat-list-item *ngIf="hasCustomerRole('ROUTERNETWORK-ADMINISTRATOR')">
                    <h4>Router-Netzwerke</h4>
                </mat-list-item>
                <mat-list-item *ngIf="hasCustomerRole('ROUTERNETWORK-ADMINISTRATOR')">
                    <mat-slide-toggle [checked]="hasAdminRole('ROUTERNETWORK-ADMINISTRATOR')"
                        (change)="changeRole($event,'ROUTERNETWORK-ADMINISTRATOR')"> Rolle für das Erstellen und
                        Verwalten von Router-Netzwerken
                    </mat-slide-toggle>
                </mat-list-item>
            </mat-list>
            <mat-divider></mat-divider>
            <div>
                <button mat-button (click)="closeDialogBtClick()">Schlie&szlig;en</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>