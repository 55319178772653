<h1 mat-dialog-title>
    Neuer Registrierungsschlüssel
</h1>
<div mat-dialog-content>
    <mat-card>
        <form style="margin-bottom: 10px;">
            <div class="fullwidth_form" style="margin-bottom: 10px;">
                <mat-form-field appearance="outline">
                    <mat-label>Beschreibung</mat-label>
                    <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="description" />
                </mat-form-field>
            </div>
            <div id="flex" class="fullwidth_form">
                <mat-form-field appearance="outline">
                    <mat-label>Generierter Schlüssel</mat-label>
                    <input matInput [ngModelOptions]="{standalone: true}" [(ngModel)]="licenseKey" readonly
                        #licenseKeyInput />
                </mat-form-field>
                <div id="copy-button">
                    <button mat-mat-icon-button (click)="copyKeyToClipboard()"
                        [disabled]="!licenseKey || licenseKey === 'wird generiert' || copied">
                        <mat-icon *ngIf="!copied || licenseKey === 'wird generiert'">content_copy</mat-icon>
                        <mat-icon *ngIf="copied">check</mat-icon>
                    </button>
                </div>
            </div>
        </form>
        <button mat-raised-button [disabled]="licenseKey.length > 0" (click)="generateKey()">
            Schlüssel generieren
        </button>
    </mat-card>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions class="button-margin-top">
    <button mat-raised-button (click)="closeDialog()">Schließen</button>
</div>
