import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminService } from '../AdminService/admin.service';
import { DBAdmin, DBRole } from '../../data/endooSpotDB_objects';
import { AuthenticationService, CustomerNameAndId } from '../../Login/authentication.service';

@Component({
    selector: 'AddAdminToCustomerComponent',
    templateUrl: './AddAdminToCustomer.component.html',
})
export class addAdminToCustomerDialogComponent implements OnInit {
    form: FormGroup;
    customers: CustomerNameAndId[] = [];
    customer_roles: DBRole[] = [];
    admins: DBAdmin[] = [];
    selectedAdmins: DBAdmin[] = [];

    /**
     * @param adminService
     * @param authService
     * @param router
     * @param formBuilder
     * @param dialogRef
     * @param snackBar
     */
    constructor(
        private adminService: AdminService,
        public authService: AuthenticationService,
        private formBuilder: FormBuilder,
        private dialogRef: MatDialogRef<addAdminToCustomerDialogComponent>,
        private snackBar: MatSnackBar
    ) {
        // creating form validator for the new User form
        this.form = this.formBuilder.group({
            admin_id: ['', [Validators.required]],
            'BYOD': [''],
            'HARDWARE': [''],
            'HARDWARE-ENDOOSPOT': [false],
            'HARDWARE-DASHBOARD': [false],
            'ADMINISTRATOR-ADMINISTRATOR': [false],
            'HARDWARE-PORTCONFIG': [false],
            'ADMINISTRATOR-CONTRACTS': [false],
            'ROUTERNETWORK-ADMINISTRATOR': [false],
        });
    }

    async ngOnInit(): Promise<void> {
        this.customer_roles = await this.adminService.getCustomerRoles().toPromise();
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }

    /**
     * Function to reset a stepper.
     * @param stepper
     */
    resetStepper(stepper): void {
        stepper.reset();
    }

    /**
     * Close the dialog the component belongs to.
     */
    closeDialogBtClick(): void {
        this.dialogRef.close();
    }

    /**
     * Function which will be triggered at the end of the creation stepper.
     */
    async addNewAdminBtClick(): Promise<void> {
        const byod = this.form.get('BYOD').value ?? undefined;
        const hardware = this.form.get('HARDWARE').value ?? undefined;
        const request = {
            customerId: this.authService.getCustomerId(),
            username: this.form.get('admin_id').value,
            roles: []
        };
        if (byod !== undefined) {
            request.roles.push(byod);
        }
        if (hardware !== undefined) {
            request.roles.push(hardware);
        }
        if (this.form.get('HARDWARE-ENDOOSPOT').value === true) {
            request.roles.push('HARDWARE-ENDOOSPOT');
        }
        if (this.form.get('HARDWARE-DASHBOARD').value === true) {
            request.roles.push('HARDWARE-DASHBOARD');
        }
        if (this.form.get('ADMINISTRATOR-ADMINISTRATOR').value === true) {
            request.roles.push('ADMINISTRATOR-ADMINISTRATOR');
        }
        if (this.form.get('HARDWARE-PORTCONFIG').value === true) {
            request.roles.push('HARDWARE-PORTCONFIG');
        }
        if (this.form.get('ADMINISTRATOR-CONTRACTS').value === true) {
            request.roles.push('ADMINISTRATOR-CONTRACTS');
        }
        if (this.form.get('ROUTERNETWORK-ADMINISTRATOR').value === true) {
            request.roles.push('ROUTERNETWORK-ADMINISTRATOR');
        }
        try {
            await this.adminService.makeAdminSchoolAdmin(request.username, request.customerId, request.roles).toPromise();
            this.closeDialogBtClick();
        } catch (err) {
            this.snackBar.open('Administrator konnte nicht hinzugefügt werden: ' + err, 'OK');
        }
    }

    /**
     * Returns if a customer has a specified role
     * @param role_id String representation of the role
     */
    hasCustomerRole(role_id: string): boolean {
        for (let i = 0; i < this.customer_roles.length; i++) {
            const role = this.customer_roles[i];
            if (role.id === role_id) {
                return true;
            }
        }
        return false;
    }
}
