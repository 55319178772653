import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, Optional } from '@angular/core';
import { AuthenticationService, CustomerNameAndId } from './authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'customerComponent',
    templateUrl: 'chooseCustomer.component.html',
    styleUrls: ['chooseCustomer.component.scss'],
})
export class ChooseCustomerComponent implements OnInit {
    customers: CustomerNameAndId[] = [];
    filteredCustomers: Observable<CustomerNameAndId[]>;
    selectedCustomer: string;
    loadingCustomers: boolean = true;
    buttonLoading: boolean = false;
    @Output() CustomerSelected: EventEmitter<string> = new EventEmitter();
    schoolSelection: FormControl = new FormControl();

    constructor(
        public authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,
        @Optional() private dialogRef: MatDialogRef<ChooseCustomerComponent>
    ) { }

    ngOnInit() {
        // Define emitters
        this.fetchAdminCustomers();
        this.filteredCustomers = this.schoolSelection.valueChanges.pipe(
            startWith(''),
            map(value => {
                this.selectedCustomer = null;
                if (this.customers.some(customer => customer.id === value)) {
                    this.selectedCustomer = value;
                }
                return this._filter(value);
            })
        );
    }

    async fetchAdminCustomers(): Promise<void> {
        try {
            this.customers = await this.authenticationService.getAdminCustomers().toPromise();
            this.selectedCustomer = this.authenticationService.getCustomerId();
            this.customers.sort((a, b) => a.id.toLowerCase().localeCompare(b.id.toLowerCase()));
        } catch (err) {
            this.snackBar.open('Die Liste der Schulen konnte nicht geladen werden: ' + err, 'OK');
            this.authenticationService.logout();
        } finally {
            this.loadingCustomers = false;
        }
    }

    private _filter(value: string): CustomerNameAndId[] {
        const filterValue = value.toLowerCase();
        return this.customers.filter(option => option.id.toLowerCase().includes(filterValue) || option.name.toLowerCase().includes(filterValue));
    }

    async selectCustomer(): Promise<void> {
        this.buttonLoading = true;
        await this.authenticationService.setCustomerId(this.selectedCustomer);
        this.CustomerSelected.emit(this.selectedCustomer);
        this.buttonLoading = false;
        if (this.dialogRef !== null) {
            this.dialogRef.close();
        }
    }

    customerSelected(event: MatAutocompleteSelectedEvent): void {
        this.selectedCustomer = this.customers.find(customer => customer.id === event.option.value).id;
    }
}
