import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthenticationService } from './authentication.service';

@Component({
    selector: 'loginComponent',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {
    @ViewChild('usernameField') usernameField: ElementRef;
    @ViewChild('passwordField') passwordField: ElementRef;
    username: string = '';
    password: string = '';
    loading: boolean = false;
    appTitle: string = environment.appTitle;
    showRegisterLink: boolean = true;

    constructor(
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        this.username = this.authenticationService.getLastUsernameLoggedIn();
        setTimeout(() => {
            if (this.username) {
                this.passwordField.nativeElement.focus();
            } else {
                this.usernameField.nativeElement.focus();
            }
        }, 500);
    }

    async login(): Promise<void> {
        this.loading = true;
        this.username = this.username.trim();
        await this.authenticationService.login(this.username, this.password).toPromise();
        this.loading = false;
    }
}
