<mat-toolbar>
    <span class="user-select-none">WLAN-Netzwerke</span>
    <span class="example-spacer"></span>

    <ng-container *ngIf="appService.isAppScreen()">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
                id="newWifiNetworkButton" (click)="newWifiNetworkBtClick()">Neues WLAN-Netzwerk</a>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="!appService.isAppScreen()">
        <button *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-raised-button
            id="newWifiNetworkButton" (click)="newWifiNetworkBtClick()">Neues WLAN-Netzwerk</button>
    </ng-container>
</mat-toolbar>

<mat-tab-group>
    <mat-tab *ngFor="let WifiNetwork of networks; index as i">
        <ng-template mat-tab-label>
            <mat-icon *ngIf="WifiNetwork?.activated" class="mat-icon-left">wifi</mat-icon>
            <mat-icon *ngIf="!WifiNetwork?.activated" class="mat-icon-left">wifi_off</mat-icon>
            <span>{{WifiNetwork?.ssid}} </span><br>
        </ng-template>
        <ng-container>
            <!-- possible to use with material 6.0 -->
            <ng-template matTabContent>
                <div class="content-container">
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="mat-icon-left">
                                        settings</mat-icon>
                                    <span>Allgemein</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <WifiNetworkSettings (changed)="networkChanged($event)" [index]="i" [network]="WifiNetwork">
                            </WifiNetworkSettings>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="mat-icon-left">
                                        wifi_lock</mat-icon>
                                    <span>Verschl&uuml;sselung</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <WifiNetworkSecurity (changed)="networkChanged($event)" [index]="i" [network]="WifiNetwork">
                            </WifiNetworkSecurity>
                        </mat-expansion-panel>
                        <mat-expansion-panel expanded="false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon class="mat-icon-left">
                                        alarm</mat-icon>
                                    <span>Zeitsteuerung</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <WifiNetworkTimeSettings (changed)="networkChanged($event)" [index]="i"
                                [network]="WifiNetwork"></WifiNetworkTimeSettings>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </ng-template>
        </ng-container>
    </mat-tab>
</mat-tab-group>
