<h1 mat-dialog-title>Benutzer ändern</h1>

<div style="max-height: 80vh;">
	<ng-container *ngIf="user$ | async as user; else loading">
		<mat-accordion>
			<mat-expansion-panel expanded style="margin-top:10px; margin-bottom:10px;">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="mat-icon-left"></mat-icon>
						<span>Benutzerdaten</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<div class="fullwidth_form" mat-dialog-content>
					<mat-form-field appearance="outline">
						<mat-label>Vorname</mat-label>
						<input matInput type="text" id="givenname_tx" [(ngModel)]="user.givenname"
							(change)="fieldChanged('Givenname')" [disabled]="!edit_user"
							(keypress)="validateChangeUserInput($event)" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Nachname</mat-label>
						<input matInput type="text" id="surname_tx" [(ngModel)]="user.surname"
							(change)="fieldChanged('Surname')" [disabled]="!edit_user"
							(keypress)="validateChangeUserInput($event)" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Benutzername</mat-label>
						<input matInput type="text" id="username_tx" [(ngModel)]="user.username" disabled />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Abschlussjahr</mat-label>
						<input matInput type="text" id="leavingyear_tx" [(ngModel)]="user.leavingyear"
							(change)="fieldChanged('Leavingyear')" [disabled]="!edit_user"
							(keypress)="validateChangeUserInput($event)" />
					</mat-form-field>
					<section class="toggle-section" style="padding-left: 5px;">
						<mat-checkbox id="wlan_cb" [(ngModel)]="user.wlan" name="Wlan" (change)="fieldChanged('Wlan')"
							[disabled]="!edit_user">WLAN</mat-checkbox>
					</section>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel style="margin-top:10px; margin-bottom:10px;">
				<mat-expansion-panel-header>
					<mat-panel-title>
						<mat-icon class="mat-icon-left"></mat-icon>
						<span>Benutzergruppen (Änderungen über Gruppen-Verwaltung)</span>
					</mat-panel-title>
				</mat-expansion-panel-header>
				<mat-form-field>
					<mat-label>Filter</mat-label>
					<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
				</mat-form-field>
				<mat-table [dataSource]="groups" class="table" id="UserList_table" matSort>
					<ng-container matColumnDef="name">
						<mat-header-cell *matHeaderCellDef mat-sort-header> Gruppenname </mat-header-cell>
						<mat-cell *matCellDef="let group"> {{group.name}} </mat-cell>
					</ng-container>
					<ng-container matColumnDef="description">
						<mat-header-cell *matHeaderCellDef mat-sort-header> Beschreibung </mat-header-cell>
						<mat-cell *matCellDef="let group"> {{group.description}} </mat-cell>
					</ng-container>
					<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
					<mat-row *matRowDef="let member; columns: displayedColumns;"></mat-row>
				</mat-table>
				<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
				</mat-paginator>
			</mat-expansion-panel>
		</mat-accordion>

		<div mat-dialog-actions>
			<button mat-raised-button id="EditUser_Bt" type="button" title="Bearbeiten" (click)="editUserBtClick()"
				[disabled]="edit_user">Bearbeiten</button>
			<button mat-raised-button id="DeleteUser_Bt" type="button" title="Loeschen"
				(click)="deleteUserBtClick(user)"
				[disabled]="authService.hasPermission('BYOD-ADMINISTRATOR')">L&ouml;schen</button>
			<button mat-raised-button id="NewPassword_Bt" type="button" title="Neues Passwort"
				(click)="newPasswordBtClick(user)">Neues Passwort</button>
			<button mat-raised-button id="CancelUser_Bt" type="button" title="Abbrechen" (click)="cancelBtClick(user)"
				[disabled]="!edit_user">Abbrechen</button>
			<button mat-raised-button id="SaveUser_Bt" type="button" title="Speichern" (click)="saveUserBtClick(user)"
				[disabled]="!edit_user">Speichern</button>
			<button mat-raised-button (click)="closeBtClick()">Schlie&szlig;en</button>
		</div>
	</ng-container>
</div>

<ng-template #loading>
	Benutzerdaten werden geladen...
</ng-template>
