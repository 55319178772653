import { Component, Output, EventEmitter, Input } from '@angular/core';
import { TimesControlData } from './times-control-data';
import { WifiTime } from './wifi-time';

@Component({
    selector: 'endoo-times-control',
    templateUrl: './times-control.component.html',
    styleUrls: ['./times-control.component.css'],
})
export class TimesControlComponent {
    @Input() alwaysFullWidth: boolean = false;
    @Input() times: TimesControlData;
    @Output() onChange: EventEmitter<TimesControlData> = new EventEmitter();

    timespanChanged(e: WifiTime, weekdayId: number): void {
        this.times.times.find(t => t.weekdayId === weekdayId).onValue = e.onValue;
        this.times.times.find(t => t.weekdayId === weekdayId).offValue = e.offValue;
        this.onChange.emit(this.times);
    }

    activatedChanged(): void {
        this.onChange.emit(this.times);
    }
}
