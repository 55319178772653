<mat-card>
    <mat-table matSortActive="mac" [dataSource]="data" matSort id="connected-stations-table">
        <ng-container matColumnDef="mac">
            <mat-header-cell *matHeaderCellDef>MAC-Adresse</mat-header-cell>
            <mat-cell *matCellDef="let device">
                {{device.mac}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="txRate">
            <mat-header-cell *matHeaderCellDef>Übertragungsrate</mat-header-cell>
            <mat-cell *matCellDef="let device">
                {{device.txRate}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="channel">
            <mat-header-cell *matHeaderCellDef>Kanal</mat-header-cell>
            <mat-cell *matCellDef="let device">
                {{device.chan}} {{device.chan <= 13 ? ' (2,4 Ghz)' : ' (5 Ghz)' }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rssi">
            <mat-header-cell *matHeaderCellDef>Signalstärke</mat-header-cell>
            <mat-cell *matCellDef="let device">
                <mat-icon class="user-select-none text-background" [class.wifi-low-signal]="device.rssi < -70"
                    [class.wifi-moderate-signal]="device.rssi >= -70 && device.rssi < -50"
                    [class.wifi-high-signal]="device.rssi >= -50">wifi</mat-icon>
                {{device.rssi}} dBm
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="assocTime">
            <mat-header-cell *matHeaderCellDef>Verbunden seit</mat-header-cell>
            <mat-cell *matCellDef="let device">
                {{device.assocTime}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="updatedAt">
            <mat-header-cell *matHeaderCellDef>Aktualisiert am</mat-header-cell>
            <mat-cell *matCellDef="let device">
                {{device.timestamp}}
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let device; columns: displayedColumns;"></mat-row>
    </mat-table>
</mat-card>