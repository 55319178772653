<div id="times-control-container">
    <mat-slide-toggle [(ngModel)]="times.active" (change)="activatedChanged()">
        Zeitsteuerung aktivieren
    </mat-slide-toggle>
    <div *ngIf="times.active" id="timespan-container">
        <ng-container *ngFor="let weekday of times.weekdays let i = index">
            <div class="timespan-div" [class.timespan-div-flex]="!alwaysFullWidth">
                <div class="weekday">{{weekday}}</div>
                <div [class.timespan-element]="!alwaysFullWidth">
                    <endoo-timespan [weekdayId]="i" [timespan]="times" (changed)="timespanChanged($event, i)">
                    </endoo-timespan>
                </div>
            </div>
        </ng-container>
    </div>
</div>