import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { WifiNetwork } from 'app/data/WifiNetwork';
import { LogicalNetwork } from 'app/data/LogicalNetwork';
import { LogicalNetworkService } from 'app/NetworkManagement/NetworkService/LogicalNetwork.service';
import { WifiNetworkService } from 'app/NetworkManagement/NetworkService/WifiNetwork.service';
import { TimesControlData } from 'app/Components/times-control/times-control-data';
import { of, Observable, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { ipAddress } from './ipAddress';
import { MatStepper } from '@angular/material/stepper';

@Component({
    templateUrl: './initialNetworkSetup.component.html',
    styleUrls: ['./initialNetworkSetup.component.css'],
})
export class initialNetworkSetupComponent {
    @ViewChild(MatStepper) stepper: MatStepper;
    byodLogicalNetwork: LogicalNetwork;
    paedLogicalNetwork: LogicalNetwork;
    byodWifiNetwork: WifiNetwork;
    paedWifiNetwork: WifiNetwork;
    schoolServerForm: FormGroup;
    ByodAndWifiNetworkForm: FormGroup;
    PaedAndWifiNetworkForm: FormGroup;
    buttons_disabled: boolean;
    timesControlDataByod: TimesControlData;
    timesControlDataPaed: TimesControlData;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private logicalNetworkService: LogicalNetworkService,
        private wifiNetworkService: WifiNetworkService
    ) {
        this.timesControlDataByod = new TimesControlData(false, []);
        for (let i = 0; i < 7; i++) {
            this.timesControlDataByod.times.push({ weekdayId: i, onValue: '0000', offValue: '2330' });
        }
        this.timesControlDataPaed = new TimesControlData(false, []);
        for (let i = 0; i < 7; i++) {
            this.timesControlDataPaed.times.push({ weekdayId: i, onValue: '0000', offValue: '2330' });
        }

        this.byodLogicalNetwork = new LogicalNetwork(1, 'BYOD', true, logicalNetworkService.Colors[15], true, 0);
        this.byodWifiNetwork = new WifiNetwork(1, 1, true, '');

        this.paedLogicalNetwork = new LogicalNetwork(2, 'PAED', true, logicalNetworkService.Colors[10], true, 0);
        this.paedWifiNetwork = new WifiNetwork(2, 2, true, '');

        this.createSchoolServerForm();

        this.ByodAndWifiNetworkForm = this.createNetworkForm();
        this.ByodAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
        this.ByodAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
        this.ByodAndWifiNetworkForm.get('ssid').setValue('byod');

        this.PaedAndWifiNetworkForm = this.createNetworkForm();
        this.PaedAndWifiNetworkForm.get('ssid').setValue('intern');

        // Logic for disabled tagged toggle because only one network of this two can be untagged
        this.ByodAndWifiNetworkForm.get('tagged').valueChanges.subscribe(value => {
            if (value) {
                if (this.PaedAndWifiNetworkForm.get('createLogicalNetwork').value === true) {
                    this.PaedAndWifiNetworkForm.get('tagged').enable({ emitEvent: false });
                }
            } else {
                this.PaedAndWifiNetworkForm.get('tagged').setValue(true);
                this.PaedAndWifiNetworkForm.get('tagged').disable({ emitEvent: false });
            }
        });
        this.ByodAndWifiNetworkForm.get('createLogicalNetwork').valueChanges.subscribe(value => {
            if (value) {
                if (this.PaedAndWifiNetworkForm.get('createLogicalNetwork').value === false || this.PaedAndWifiNetworkForm.get('tagged').value === true) {
                    this.ByodAndWifiNetworkForm.get('tagged').enable({ emitEvent: false });
                }
                if (this.PaedAndWifiNetworkForm.get('createLogicalNetwork').value === true && this.PaedAndWifiNetworkForm.get('tagged').value === false) {
                    this.ByodAndWifiNetworkForm.get('tagged').setValue(true);
                    this.ByodAndWifiNetworkForm.get('tagged').disable({ emitEvent: false });
                }
            } else {
                if (this.PaedAndWifiNetworkForm.get('createLogicalNetwork').value === true) {
                    this.PaedAndWifiNetworkForm.get('tagged').enable({ emitEvent: false });
                }
            }
        });
        this.PaedAndWifiNetworkForm.get('tagged').valueChanges.subscribe(value => {
            if (value) {
                if (this.ByodAndWifiNetworkForm.get('createLogicalNetwork').value === true) {
                    this.ByodAndWifiNetworkForm.get('tagged').enable({ emitEvent: false });
                }
            } else {
                this.ByodAndWifiNetworkForm.get('tagged').setValue(true);
                this.ByodAndWifiNetworkForm.get('tagged').disable({ emitEvent: false });
            }
        });
        this.PaedAndWifiNetworkForm.get('createLogicalNetwork').valueChanges.subscribe(value => {
            if (value) {
                if (this.ByodAndWifiNetworkForm.get('createLogicalNetwork').value === false || this.ByodAndWifiNetworkForm.get('tagged').value === true) {
                    this.PaedAndWifiNetworkForm.get('tagged').enable({ emitEvent: false });
                }
                if (this.ByodAndWifiNetworkForm.get('createLogicalNetwork').value === true && this.ByodAndWifiNetworkForm.get('tagged').value === false) {
                    this.PaedAndWifiNetworkForm.get('tagged').setValue(true);
                    this.PaedAndWifiNetworkForm.get('tagged').disable({ emitEvent: false });
                }
            } else {
                if (this.ByodAndWifiNetworkForm.get('createLogicalNetwork').value === true) {
                    this.ByodAndWifiNetworkForm.get('tagged').enable({ emitEvent: false });
                }
            }
        });
    }

    createSchoolServerForm() {
        // Create form
        this.schoolServerForm = this.fb.group({
            hasSchoolServer: [false],
            schoolServerType: [undefined, [Validators.required]],
        });

        // Disable schoolServerType at the beginning
        this.schoolServerForm.get('schoolServerType').disable();

        // Reset on Schoolserver disabling
        this.schoolServerForm.get('hasSchoolServer').valueChanges.subscribe(value => {
            if (value) {
                this.schoolServerForm.get('schoolServerType').enable();
                this.PaedAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
                this.PaedAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
            } else {
                this.schoolServerForm.get('schoolServerType').disable();
                this.PaedAndWifiNetworkForm.get('createWifiNetwork').setValue(false);
                this.PaedAndWifiNetworkForm.get('createLogicalNetwork').setValue(false);

                this.PaedAndWifiNetworkForm.get('ssid').setValue('intern');

                this.ByodAndWifiNetworkForm.get('createLogicalNetwork').setValue(false);
                this.ByodAndWifiNetworkForm.get('createWifiNetwork').setValue(false);
            }
        });

        // Change WifiSetting according to Schoolserver type
        this.schoolServerForm.get('schoolServerType').valueChanges.subscribe(value => {
            if (value === undefined) {
                return;
            }

            let paed_encryption_mode = '';
            const ssid: string = this.PaedAndWifiNetworkForm.get('ssid').value;
            switch (value) {
                case 'iserv':
                    paed_encryption_mode = 'enterprise';

                    if (ssid === '' || ssid.endsWith('-intern') || ssid === 'intern') { // change ssid if the user has not changed its value
                        this.PaedAndWifiNetworkForm.get('ssid').setValue(value + '-intern');
                    }
                    this.ByodAndWifiNetworkForm.get('createLogicalNetwork').setValue(false);
                    this.ByodAndWifiNetworkForm.get('createWifiNetwork').setValue(false);
                    this.PaedAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
                    this.PaedAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
                    break;
                case 'ucs':
                    paed_encryption_mode = 'enterprise';

                    if (ssid === '' || ssid.endsWith('-intern') || ssid === 'intern') { // change ssid if the user has not changed its value
                        this.PaedAndWifiNetworkForm.get('ssid').setValue(value + '-intern');
                    }
                    this.ByodAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
                    this.ByodAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
                    this.PaedAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
                    this.PaedAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
                    break;

                case 'other':
                    paed_encryption_mode = 'psk';

                    if (ssid === '' || ssid.endsWith('-intern') || ssid === 'intern') { // change ssid if the user has not changed its value
                        this.PaedAndWifiNetworkForm.get('ssid').setValue('intern');
                    }
                    this.ByodAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
                    this.ByodAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
                    this.PaedAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
                    this.PaedAndWifiNetworkForm.get('createWifiNetwork').setValue(true);
            }

            this.PaedAndWifiNetworkForm.get('encryption').setValue(true);
            this.PaedAndWifiNetworkForm.get('encryption_mode').setValue(paed_encryption_mode);

            if (paed_encryption_mode === 'enterprise') {
                this.PaedAndWifiNetworkForm.get('enterprise_radius_port').setValue('1812');
            }

            this.PaedAndWifiNetworkForm.get('createLogicalNetwork').setValue(true);
        });
    }

    createNetworkForm(): FormGroup {
        const form = this.fb.group({
            createLogicalNetwork: [false],
            tagged: [false],
            vlan_id: ['', [Validators.required, Validators.min(2), Validators.max(4093)]],
            createWifiNetwork: [false],
            ssid: ['', [Validators.required, Validators.maxLength(32)]],
            client2client: [true],
            encryption: [false],
            encryption_mode: [undefined, [Validators.required]], // ssid: ucs => ucs_extern, sonstiges => extern
            psk_key: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(63), Validators.pattern(/^[\u0020-\u007e]+$/)]],
            enterprise_radius_ip: ['', [Validators.required, ipAddress()]],
            enterprise_radius_port: [
                '',
                [
                    Validators.required,
                    Validators.pattern(
                        '^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$'
                    ),
                    Validators.min(0),
                    Validators.max(65535),
                ],
            ],
            enterprise_radius_secret: ['', [Validators.required]],
        });

        // Disable some inputs at the beginning
        form.get('createWifiNetwork').disable();
        form.get('vlan_id').disable();
        form.get('ssid').disable();
        form.get('encryption_mode').disable();
        form.get('psk_key').disable();
        form.get('enterprise_radius_ip').disable();
        form.get('enterprise_radius_port').disable();
        form.get('enterprise_radius_secret').disable();

        form.get('createLogicalNetwork').valueChanges.subscribe(value => {
            if (value) {
                form.get('createWifiNetwork').enable();
            } else {
                form.get('tagged').disable();
                form.get('createWifiNetwork').setValue(false);
                form.get('createWifiNetwork').disable();
            }
        });

        form.get('tagged').valueChanges.subscribe(value => {
            if (value) {
                form.get('vlan_id').enable();
            } else {
                form.get('vlan_id').disable();
            }
        });

        form.get('createWifiNetwork').valueChanges.subscribe(value => {
            if (value) {
                form.get('ssid').enable();
                form.get('encryption').enable();
            } else {
                form.get('ssid').disable();
                form.get('encryption').setValue(false);
                form.get('encryption').disable();
            }
        });

        form.get('encryption').valueChanges.subscribe(value => {
            if (value) {
                form.get('encryption_mode').enable();
            } else {
                form.get('encryption_mode').disable();

            }
        });

        form.get('encryption_mode').valueChanges.subscribe(value => {
            form.get('psk_key').disable();

            form.get('enterprise_radius_ip').disable();
            form.get('enterprise_radius_port').disable();
            form.get('enterprise_radius_secret').disable();

            if (form.get('encryption_mode').disabled) {
                return;
            }

            switch (value) {
                case 'psk':
                    form.get('psk_key').enable();
                    break;

                case 'enterprise':
                    form.get('enterprise_radius_ip').enable();
                    form.get('enterprise_radius_port').enable();
                    form.get('enterprise_radius_secret').enable();
                    break;
            }
        });

        return form;
    }

    resetForm(value: boolean, form: FormGroup) {
        if (!value) {
            form.reset();
        }
    }

    timeSettingsChanged(e: TimesControlData, network: WifiNetwork) {
        network.wifitime_active = e.active;
        network.wifitime_mon_on = e.times[0].onValue;
        network.wifitime_tue_on = e.times[1].onValue;
        network.wifitime_wed_on = e.times[2].onValue;
        network.wifitime_thu_on = e.times[3].onValue;
        network.wifitime_fri_on = e.times[4].onValue;
        network.wifitime_sat_on = e.times[5].onValue;
        network.wifitime_sun_on = e.times[6].onValue;
        network.wifitime_mon_off = e.times[0].offValue;
        network.wifitime_tue_off = e.times[1].offValue;
        network.wifitime_wed_off = e.times[2].offValue;
        network.wifitime_thu_off = e.times[3].offValue;
        network.wifitime_fri_off = e.times[4].offValue;
        network.wifitime_sat_off = e.times[5].offValue;
        network.wifitime_sun_off = e.times[6].offValue;
    }

    isTimesControlSettingsValid(network: WifiNetwork): boolean {
        return (
            !network.wifitime_active ||
            (network.wifitime_mon_on !== '' &&
                network.wifitime_mon_off !== '' &&
                network.wifitime_tue_on !== '' &&
                network.wifitime_tue_off !== '' &&
                network.wifitime_wed_on !== '' &&
                network.wifitime_wed_off !== '' &&
                network.wifitime_thu_on !== '' &&
                network.wifitime_thu_off !== '' &&
                network.wifitime_fri_on !== '' &&
                network.wifitime_fri_off !== '' &&
                network.wifitime_sat_on !== '' &&
                network.wifitime_sat_off !== '' &&
                network.wifitime_sun_on !== '' &&
                network.wifitime_sun_off !== '')
        );
    }

    async createNetworks() {
        const firstResult = await this.createLogicalAndWifiNetworks(
            this.ByodAndWifiNetworkForm,
            this.byodLogicalNetwork,
            this.byodWifiNetwork
        );
        if (firstResult) {
            await this.logicalNetworkService.getLogicalNetworks().toPromise();
            await this.wifiNetworkService.getWifiNetworks().toPromise();
            await this.createLogicalAndWifiNetworks(
                this.PaedAndWifiNetworkForm,
                this.paedLogicalNetwork,
                this.paedWifiNetwork
            );
        }
    }

    async createLogicalAndWifiNetworks(
        form: FormGroup,
        logicalNetwork: LogicalNetwork,
        wifiNetwork: WifiNetwork
    ): Promise<boolean> {
        let valid = this.PaedAndWifiNetworkForm.valid;
        if (!this.isTimesControlSettingsValid(wifiNetwork)) {
            valid = false;
        }
        if (!valid) {
            return false;
        }
        // If all are valid, send data and go on to the app

        this.buttons_disabled = true;

        // Dummy Observables in case a network is not going to be created. of() without arguments won't emit either.
        let createLogicalNetworkRequest: Observable<void> = of(null);
        let createWifiNetworkRequest: Observable<void> = of(null);

        // First create the logical network
        if (form.get('createLogicalNetwork').value) {
            logicalNetwork.untagged = !form.get('tagged').value;
            if (!logicalNetwork.untagged) {
                logicalNetwork.vlan_id = form.get('vlan_id').value;
            }

            createLogicalNetworkRequest = this.logicalNetworkService.createLogicalNetwork(logicalNetwork);
        }

        // Then create Wifi network
        if (form.get('createWifiNetwork').value) {
            if (form.get('createLogicalNetwork').value) {
                wifiNetwork.logical_network_id = logicalNetwork.id_number;
            }

            wifiNetwork.client2client = form.get('client2client').value;
            wifiNetwork.ssid = form.get('ssid').value;
            wifiNetwork.encryption = form.get('encryption').value;
            if (wifiNetwork.encryption) {
                wifiNetwork.encryption_mode = wifiNetwork.encryption_mode = form.get('encryption_mode').value;
                if (wifiNetwork.encryption_mode === 'psk') {
                    wifiNetwork.encryption_key = form.get('psk_key').value;
                }
                if (wifiNetwork.encryption_mode === 'enterprise') {
                    wifiNetwork.encryption_radius_ip = form.get('enterprise_radius_ip').value;
                    wifiNetwork.encryption_radius_port = form.get('enterprise_radius_port').value;
                    wifiNetwork.encryption_radius_secret = form.get('enterprise_radius_secret').value;
                }
            }

            createWifiNetworkRequest = this.wifiNetworkService.createWifiNetwork(wifiNetwork);
        }

        try {
            await forkJoin([createLogicalNetworkRequest, createWifiNetworkRequest]).toPromise();
            this.router.navigate(['app']);
        } catch (err) {
            this.buttons_disabled = false;
        }
        return true;
    }
}
