export class Translate {

   static german = {
      'givenname': 'Vorname',
      'surname': 'Nachname',
      'birthday': 'Geburtstag',
      'short': 'Kürzel',
      'group1': 'Gruppe 1',
      'group2': 'Gruppe 2',
      'group3': 'Gruppe 3',
      'group4': 'Gruppe 4',
      'group5': 'Gruppe 5',
      'group6': 'Gruppe 6',
      'group7': 'Gruppe 7',
      'group8': 'Gruppe 8',
      'group9': 'Gruppe 9',
      'group10': 'Gruppe 10'
   };
}
