<h1 mat-dialog-title> Passwort ändern</h1>
<div mat-dialog-content>
	<mat-form-field appearance="outline">
		<mat-label>neues Passwort</mat-label>
		<input matInput type="text" [(ngModel)]="newPassword">
	</mat-form-field>
</div>
<mat-dialog-actions>
	<button mat-button title="Abbrechen" (click)="cancelNewPassword()">Abbrechen</button>
	<button mat-button color="primary" title="Passwort Speichern" (click)="saveNewPassword()">Speichern</button>
</mat-dialog-actions>