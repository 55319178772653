import { AbstractControl, ValidatorFn } from '@angular/forms';

export function subnetmaskAddress(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const validMask = new RegExp(/(^(255)\.(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255)\.(0|128|192|224|240|248|252|254|255))$/g);
        if (validMask.test(control.value)) {
            let error = false;
            const ipNumbers = control.value.split('.').map(v => Number(v));
            for (let i = 1; i < 3; i++) {
                if (ipNumbers[i] !== 255 && ipNumbers[i + 1] !== 0) {
                    error = true;
                }
            }
            if (ipNumbers[3] > 252) {
                error = true;
            }
            return error ? { 'invalid_subnet': { value: control.value } } : null;
        }
        return { 'invalid_subnet': { value: control.value } };
    };
}
