import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiNetworkService } from '../NetworkService/WifiNetwork.service';
import { WifiNetwork } from '../../data/WifiNetwork';

@Component({
    templateUrl: './deleteWifiNetwork.dialog.component.html',
})
export class DeleteWifiNetworkDialogComponent {
    network: WifiNetwork;

    constructor(private wifiNetworkService: WifiNetworkService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<DeleteWifiNetworkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.network) {
            this.network = data.network;
        }
    }

    /**
     * Method to delete network. Will make an ajax call to the server to delete network
     */
    async deleteNetworkClick_dialog(): Promise<void> {
        if (this.network) {
            try {
                await this.wifiNetworkService.deleteWifiNetwork(this.network).toPromise();
                this.snackBar.open('Das WLAN-Netzwerk wurde erfolgreich gelöscht ', 'OK');
                this.dialogRef.close(true);
            } catch (err) {
                this.snackBar.open('Das WLAN-Netzwerk konnte nicht gelöscht werden:' + err, 'OK');
                this.dialogRef.close(false);
            }
        }
    }

    /**
     * Method to close dialog if user pressed cancel
     */
    async cancelDeleteNetworkClick_dialog(): Promise<void> {
        this.dialogRef.close(false);
    }
}
