import { Component, Input } from '@angular/core';

@Component({
    selector: 'wifi-qr-code-generator',
    templateUrl: 'wifi-qr-code-generator.component.html',
})
export class WifiQrCodeGeneratorComponent {
    text: string = '';
    private ssid: string;
    private encryption: string = 'WPA';
    private psk: string;
    private hidden: boolean;

    @Input() set Ssid(ssid: string) {
        this.ssid = this.escapeChars(ssid);
        this.makeCode();
    }

    @Input() set Encryption(encryption: string) {
        // No escaping required, since encryption string can only be one of "nopass/WEP/WPA"
        this.encryption = encryption;
        this.makeCode();
    }
    
    @Input() set Psk(psk: string) {
        this.psk = this.escapeChars(psk);
        this.makeCode();
    }

    @Input() set Hidden(hidden: boolean) {
        if (hidden === null || hidden === undefined) {
            hidden = false;
        }
        this.hidden = hidden;
        this.makeCode();
    }

    private makeCode(): void {
        const hidden = this.hidden !== undefined ? this.hidden : false;
        this.text = `WIFI:T:${this.encryption};S:${this.ssid};P:${this.psk};H:${hidden.valueOf()};`;
    }

    private escapeChars(text: string): string {
        // Escape special characters for wifi QR-Codes with a backslash. See https://en.wikipedia.org/wiki/QR_code#Wi-Fi_network_login
        text = text.replace(/\\/g, '\\\\'); // replace single backslash with double backslash
        text = text.replace(/"/g, '\\"');
        text = text.replace(/;/g, '\\;');
        text = text.replace(/,/g, '\\,');
        text = text.replace(/:/g, '\\:');
        return text;
    }
}
