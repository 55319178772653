export class WifiNetwork {
    wifi_network_id: number;
    activated: boolean = true;
    logical_network_id: number;
    client2client: boolean = true;
    ssid: string;
    hide_ssid: boolean;
    encryption: boolean = true;
    encryption_mode = '';
    encryption_key: string = '';
    encryption_radius_ip: string = '';
    encryption_radius_secret: string = '';
    encryption_radius_port: string = '';
    wifitime_active: boolean = false;
    wifitime_mon_on: string = '0000';
    wifitime_tue_on: string = '0000';
    wifitime_wed_on: string = '0000';
    wifitime_thu_on: string = '0000';
    wifitime_fri_on: string = '0000';
    wifitime_sat_on: string = '0000';
    wifitime_sun_on: string = '0000';
    wifitime_mon_off: string = '0000';
    wifitime_tue_off: string = '0000';
    wifitime_wed_off: string = '0000';
    wifitime_thu_off: string = '0000';
    wifitime_fri_off: string = '0000';
    wifitime_sat_off: string = '0000';
    wifitime_sun_off: string = '0000';

    constructor(wifi_network_id: number, network: number, client2client: boolean, ssid: string,
        activated: boolean = true, hidessid = false, encryption: boolean = false, encryption_mode: string = '', encryption_key: string = '',
        encryption_radius_ip: string = '', encryption_radius_secret: string = '', encryption_radius_port: string = '', wifitime_active = false,
        wifitime_mon_on: string = '0000', wifitime_tue_on: string = '0000', wifitime_wed_on: string = '0000', wifitime_thu_on: string = '0000', wifitime_fri_on: string = '0000',
        wifitime_sat_on: string = '0000', wifitime_sun_on: string = '0000',
        wifitime_mon_off: string = '0000', wifitime_tue_off: string = '0000', wifitime_wed_off: string = '0000', wifitime_thu_off: string = '0000', wifitime_fri_off: string = '0000',
        wifitime_sat_off: string = '0000', wifitime_sun_off: string = '0000') {
        this.wifi_network_id = wifi_network_id;
        this.activated = activated;
        this.logical_network_id = network;
        this.client2client = client2client;
        this.ssid = ssid;
        this.hide_ssid = hidessid;
        this.encryption = encryption;
        this.encryption_mode = encryption_mode;
        this.encryption_key = encryption_key;
        this.encryption_radius_ip = encryption_radius_ip;
        this.encryption_radius_secret = encryption_radius_secret;
        this.encryption_radius_port = encryption_radius_port;
        this.wifitime_active = wifitime_active;
        this.wifitime_mon_on = wifitime_mon_on;
        this.wifitime_tue_on = wifitime_tue_on;
        this.wifitime_wed_on = wifitime_wed_on;
        this.wifitime_thu_on = wifitime_thu_on;
        this.wifitime_fri_on = wifitime_fri_on;
        this.wifitime_sat_on = wifitime_sat_on;
        this.wifitime_sun_on = wifitime_sun_on;
        this.wifitime_mon_off = wifitime_mon_off;
        this.wifitime_tue_off = wifitime_tue_off;
        this.wifitime_wed_off = wifitime_wed_off;
        this.wifitime_thu_off = wifitime_thu_off;
        this.wifitime_fri_off = wifitime_fri_off;
        this.wifitime_sat_off = wifitime_sat_off;
        this.wifitime_sun_off = wifitime_sun_off;
    }
}
