import { Shape } from './shape';
import { Content } from './canvas.component';

export class Circle extends Shape {
    radius: number; // Radius des  Kreises.

    /**
     * @param x Die X-Komponente welche in der Mitte des Elements liegen soll.
     * @param y Die Y-Komponente welche in der Mitte des Elements liegen soll.
     * @param fillStyle Die Füllfarbe des Elements.
     * @param lineWidth Die Strichstärke für seine Außenlinien.
     * @param strokeStyle Die Strichfarbe für seine Außenlinien.
     * @param font Die Schriftart des Inhaltes.
     * @param content Der Inhalt.
     * @param radius Der Radius.
     */
    constructor(x: number, y: number, fillStyle: string, lineWidth: number, strokeStyle: string, font: string, content: Content, radius: number) {
        super(x, y, fillStyle, lineWidth, strokeStyle, font, content);
        this.radius = radius;
    }
}
