import { Shape } from './shape';
import { Content } from './canvas.component';

export class Rectangle extends Shape {
    width: number; // Breite des Rechtecks.
    height: number; // Höhe des Rechtecks.

    constructor(x: number, y: number, fillStyle: string, lineWidth: number, strokeStyle: string, font: string, content: Content, width: number, height: number) {
        super(x, y, fillStyle, lineWidth, strokeStyle, font, content);
        this.width = width;
        this.height = height;
    }
}
