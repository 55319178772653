export class RouterNetwork {

    constructor(public id_number: number,
        public lnetworkid: number,
        public network_ip_type: string,
        public interface_ip: string,
        public network_subnet: string,
        public gateway: boolean,
        public gateway_ip: string,
        public dhcp_server_enabled: boolean,
        public dhcp_server_start_address: string,
        public dhcp_server_end_address: string,
        public filtering: boolean,
        public captive_portal_id: number,
        public network_type: string,
        public media_access: boolean,
        public preferred_uplink_network: boolean,
        public inet_access_allowed: boolean,
        public allow_dns: boolean,
        public allow_web: boolean,
        public allow_email: boolean,
        public allow_push: boolean
    ) { }
}
