import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: 'confirm-dialog.component.html',
})
export class ConfirmDialogComponent {
    title: string;
    message: string;
    yesButtonText: string;
    noButtonText: string;

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title;
        this.message = data.message;
        this.yesButtonText = data.yesButtonText;
        this.noButtonText = data.noButtonText;
    }

    okButtonClicked(): void {
        this.dialogRef.close(true);
    }

    cancelButtonClicked(): void {
        this.dialogRef.close(false);
    }
}
