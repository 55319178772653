import { Component, OnInit, Input, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DBDevice, DBModel } from 'app/data/endooSpotDB_objects';
import { ApDeviceService } from 'app/APManagement/APService/apDevice.service';
import { SwitchPortConfiguration } from 'app/data/SwitchPortConfiguration';
import { LogicalNetworkService } from 'app/NetworkManagement/NetworkService/LogicalNetwork.service';
import { LogicalNetwork } from 'app/data/LogicalNetwork';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { ModelService } from 'app/APManagement/APService/model.service';
import { ApPortConfiguration } from 'app/data/ApPortConfiguration';
import { ApDetailService } from '../apDetail.service';
import { SwitchService } from 'app/APManagement/APService/switch-device.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RouterPortConfiguration } from 'app/data/RouterPortConfiguration';
import { SwitchInformationLocal } from 'app/data/SwitchInformation';

@Component({
    selector: 'port-configuration-component',
    templateUrl: './port-configuration.component.html',
    styleUrls: ['./port-configuration.component.css'],
})
export class PortConfigurationComponent implements OnInit {
    /**
     * Für die Mehrfachbearbeitung ein Wert der aussagt, ob die Komponente gerade geöffnet wurde.
     * Denn sonst wird nach dem Speichern und Laden ohne vorheriges Schließen der Komponente
     * immer wieder die Standardeinstellungen angezeigt, obwohl man Änderungen gemacht hat.
     * Somit werden nach dem Speichern die Änderungen direkt angezeigt.
     */
    private multipleEditStart = true;

    private devices: DBDevice[];
    @Input() set Devices(devices: DBDevice[]) {
        this.devices = devices;
        this.portConfigurations = [];
        if (this.devices.length === 1 || !this.multipleEditStart) {
            if (this.apDeviceService.isDeviceSwitch(this.devices[0])) {
                this.portConfigurations = this.apDeviceService.getSwitchPortConfigurations(this.devices[0]);
                this.getSwitchInformation().subscribe(data => this.switchInformation = data);
            }
            if (this.apDeviceService.isDeviceAP(this.devices[0])) {
                this.portConfigurations = this.apDeviceService.getApPortConfigurations(this.devices[0]);
            }
            if (this.apDeviceService.isDeviceRouter(this.devices[0])) {
                this.portConfigurations = this.apDeviceService.getRouterPortConfigurations(this.devices[0]);
                const model = this.modelService.getModelForDevice(this.devices[0]);
                this.fwanPorts = this.modelService.getRouterFwanPorts(model);
            }
        }
    }
    get Devices() {
        return this.devices;
    }

    portsList: number[];
    // portarrangement holds information how the ports should be displayed
    portArrangement: number[][][];
    @Input() set PortArrangement(portArrangement: number[][][]) {
        this.portArrangement = portArrangement;
    }

    /**
     * Includes informaton like a port is connected etc on switches.
     */
    switchInformation: SwitchInformationLocal;

    model: DBModel;
    @Input() set Model(model: DBModel) {
        this.model = model;
        this.PortArrangement = this.modelService.getModelPortLayout(model);
    }
    selectionApplyMode: boolean;
    portsSelectedForApplication: number[];
    form: FormGroup;

    // logical networks available for customer
    logicalNetworks: LogicalNetwork[];
    // port configurations for ports
    portConfigurations: SwitchPortConfiguration[] | ApPortConfiguration[] | RouterPortConfiguration[];

    @Output() deviceChanged = new EventEmitter();

    // selection of ports
    private selectedPort: number;
    get SelectedPort(): number {
        return this.selectedPort;
    }
    set SelectedPort(portIndex: number) {
        this.selectedPort = portIndex;
        if (portIndex !== undefined) {
            this.handlePortClick(portIndex);
        }
    }
    @ViewChild('exampleImage') exampleImage: TemplateRef<any>;
    @ViewChild('fwanInfo') fwanInfo: TemplateRef<any>;
    @Output() onChange = new EventEmitter();
    fwanPorts: number[] = [];

    constructor(
        private fb: FormBuilder,
        public apDeviceService: ApDeviceService,
        private snackBar: MatSnackBar,
        private logicalNetworkService: LogicalNetworkService,
        private dialog: MatDialog,
        private modelService: ModelService,
        private apDetailService: ApDetailService,
        private switchService: SwitchService
    ) { }

    ngOnInit() {
        // init values and form
        this.selectionApplyMode = false;
        this.SelectedPort = undefined; // stores the port which is selected by user
        this.portsSelectedForApplication = []; // stores ports which should be applied same config as selected port

        this.form = this.fb.group({
            port_label: [''],
            port_comments: [''],
            port_untagged_network_id: [undefined, Validators.required],
            port_tagged_network_ids: [[]],
            port_poe_enabled: [true],
            mgmt_port: [true]
        });

        this.form.get('mgmt_port').valueChanges.subscribe(value => {
            if (value) {
                this.defaultUntaggedNetwork();
                this.form.get('port_tagged_network_ids').setValue([]);
                this.form.get('port_poe_enabled').setValue(true);
            }
        });

        // build up port list
        this.portsList = [];
        for (const array of this.portArrangement) {
            for (const array2 of array) {
                for (const element of array2) {
                    if (element !== -1) { // Skip div placeholders
                        this.portsList.push(element + 1);
                    }
                }
            }
        }
        this.portsList.sort((a, b) => a > b ? 1 : -1);

        // get informations from server side
        this.logicalNetworks = [];
        this.initComponent();
    }

    async initComponent(): Promise<void> {
        await this.fetchLogicalNetworks();
        this.defaultUntaggedNetwork();
    }

    getSwitchInformation(): Observable<SwitchInformationLocal> {
        const switchInformation: SwitchInformationLocal = { connected: [], uplinkPort: undefined, link_speed_neg: [] };
        if (!this.devices[0].serial_number) {
            return of(undefined);
        }
        const portCount = this.modelService.getModelPortCount(this.modelService.getModelForDevice(this.devices[0]));
        return this.switchService.getSwitchInformation(this.devices[0].serial_number).pipe(map(data => {
            data.ports.sort((a, b) => a.id - b.id); // Sort ports array in asc order
            switchInformation.connected = data.ports.slice(0, portCount).map(port => port.status === 1);
            switchInformation.link_speed_neg = data.ports.slice(0, portCount).map(port => port.link_speed_neg);
            switchInformation.uplinkPort = data.uplink_port;
            return switchInformation;
        }), catchError(error => {
            console.error(error);
            return of(switchInformation);
        }));
    }

    getSwitchLinkSpeedColor(portIndex: number): string {
        if (this.switchInformation.connected[portIndex] === true) {
            switch (this.switchInformation.link_speed_neg[portIndex]) {
                case 1:
                    return '#AAFF00';

                case 2:
                    return '#FFC000';

                case 3:
                    return '#50C878';

                case 4:
                    return '#50C878';

                case 5:
                    return '#50C878';

                case 6:
                    return '#097969';

                default:
                    return 'transparent';
            }
        }
        return 'darkgray';
    }

    async fetchLogicalNetworks(): Promise<void> {
        this.logicalNetworks = await this.logicalNetworkService.getLogicalNetworks().toPromise();
    }

    defaultUntaggedNetwork(): Promise<void> {
        const untaggedNetwork = this.logicalNetworks.filter(network => network.untagged);
        if (untaggedNetwork.length === 0) {
            return;
        }
        this.form.get('port_untagged_network_id').setValue(untaggedNetwork[0].id_number);
    }

    /**
     * Function to fill the form when a port has been selected
     */
    fillForm(pc: SwitchPortConfiguration | ApPortConfiguration | RouterPortConfiguration): void {
        this.form.get('port_label').setValue(pc.label);
        this.form.get('port_untagged_network_id').setValue(pc.untaggedLogicalNetworkId);
        this.form.get('port_tagged_network_ids').setValue(pc.taggedLogicalNetworkIds);
        if (pc instanceof SwitchPortConfiguration) {
            this.form.get('port_comments').setValue(pc.comments);
            this.form.get('port_poe_enabled').setValue(pc.poe_enabled);
            this.form.get('mgmt_port').setValue(pc.mgmt_port);
        }
        if (pc instanceof ApPortConfiguration) {
            this.form.get('mgmt_port').setValue(pc.default_config);
        }
        if (pc instanceof RouterPortConfiguration) {
            this.form.get('port_comments').setValue(pc.comments);
            this.form.get('mgmt_port').setValue(pc.mode);
        }
    }

    /**
     * Function which will be called when a port has been clicked. Two cases: port should be changed or ports are beeing selected for multiple ports
     * @param portIndex index of port
     */
    handlePortClick(portIndex: number): void {
        if ((!this.apDeviceService.isDeviceRouter(this.devices[0]) && portIndex !== 0) || (this.apDeviceService.isDeviceRouter(this.devices[0]) && !this.fwanPorts.includes(portIndex + 1))) { // port 1 is not changeable except routers. fwan ports are never changeable
            if (!this.selectionApplyMode) {
                if (this.SelectedPort !== portIndex) {
                    this.SelectedPort = portIndex;
                }
                let pc;
                // Create new config and set default settings if port has no configuration on server
                if (this.apDeviceService.isDeviceAP(this.devices[0])) {
                    pc = (this.portConfigurations as ApPortConfiguration[]).find(pc => pc.port === portIndex + 1);
                    if (!pc) {
                        pc = new ApPortConfiguration();
                        pc.port = portIndex + 1;
                        pc.label = '';
                        pc.untaggedLogicalNetworkId = this.logicalNetworks.filter(network => network.untagged)[0]?.id_number;
                        pc.taggedLogicalNetworkIds = [];
                        pc.default_config = true;
                    }
                } else if (this.apDeviceService.isDeviceSwitch(this.devices[0])) {
                    pc = (this.portConfigurations as SwitchPortConfiguration[]).find(pc => pc.port === portIndex + 1);
                    if (!pc) {
                        pc = new SwitchPortConfiguration();
                        pc.port = portIndex + 1;
                        pc.label = '';
                        pc.comments = '';
                        pc.untaggedLogicalNetworkId = this.logicalNetworks.filter(network => network.untagged)[0]?.id_number;
                        pc.taggedLogicalNetworkIds = [];
                        pc.poe_enabled = true;
                        pc.mgmt_port = true;
                    }
                } else if (this.apDeviceService.isDeviceRouter(this.devices[0])) {
                    pc = (this.portConfigurations as RouterPortConfiguration[]).find(pc => pc.port === portIndex + 1);
                    if (!pc) {
                        pc = new RouterPortConfiguration();
                        pc.port = portIndex + 1;
                        pc.label = '';
                        pc.comments = '';
                        pc.untaggedLogicalNetworkId = this.logicalNetworks.filter(network => network.untagged)[0]?.id_number;
                        pc.taggedLogicalNetworkIds = [];
                        pc.mode = true;
                    }
                }
                this.fillForm(pc);
            } else if (portIndex !== this.SelectedPort) { // only do something if user clicks on a port which is not the port which is already selected for the applied config
                if (this.portsSelectedForApplication.includes(portIndex + 1)) { // if port has been selected -> remove
                    this.portsSelectedForApplication = this.portsSelectedForApplication.filter(port => port !== portIndex + 1);
                } else { // otherwise add port
                    this.portsSelectedForApplication.push(portIndex + 1);
                }
            }
        }
    }

    /**
     * Checks if for a port index poe is available
     */
    isPoeReady(portIndex: number): boolean {
        return this.modelService.hasModelFunction(this.model, 'SWITCH_PORT' + (portIndex + 1) + '_POE');
    }

    /**
     * Checks if for a port index poe is enabled
     */
    isPoeEnabled(portIndex: number): boolean {
        for (const pc of this.portConfigurations) {
            if (pc.port === portIndex + 1) {
                return (pc as SwitchPortConfiguration).poe_enabled;
            }
        }
        return true;
    }

    /**
     * Function is used to show selection of port with background color
     * @param portIndex port to determin selection color for
     */
    determinePortBackgroundColor(portIndex: number): string {
        if (portIndex === this.SelectedPort) {
            return 'gainsboro';
        }
        if (this.portsSelectedForApplication.includes(portIndex + 1)) {
            return '#D4EFDF';
        }
        return 'transparent';
    }

    determineProfileApplicationButtonText(): string {
        return this.selectionApplyMode ? 'Übertragung abschließen' : 'Profile übertragen';
    }

    /**
     * Function to determine for a port index which color it should have as background (untagged color)
     */
    determinePortUntaggedNetworkColor(portIndex: number): string {
        if (this.apDeviceService.isDeviceRouter(this.devices[0]) && this.fwanPorts.includes(portIndex + 1)) {
            return 'black';
        }
        const untaggedNetwork = this.logicalNetworks.filter(network => network.untagged);
        if (untaggedNetwork.length === 0) {
            return 'gainsboro';
        }
        for (const pc of this.portConfigurations) {
            if (pc.port === portIndex + 1) {
                for (const network of this.logicalNetworks) {
                    if (network.id_number === pc.untaggedLogicalNetworkId) {
                        return network.color;
                    }
                }
            }
        }
        return untaggedNetwork[0].color;
    }

    /**
     * Gibt die Netzwerkfarbe aller zugeordneten Tagged Netzwerke zurück.
     * @param portIndex
     */
    getTaggedNetworkColors(portIndex: number): string[] {
        if (this.fwanPorts.includes(portIndex + 1)) { // If port is a FWAN port. FWAN ports have no tgagged networks so don't display them
            return [];
        }
        const colors = [];
        if (portIndex === 0 && this.apDeviceService.isDeviceAP(this.devices[0])) { // If port is Port 1 and device is AP then show all tagged networks on default
            const taggedNetworks = this.logicalNetworks.filter(network => network.untagged === false);
            for (const network of taggedNetworks) {
                colors.push(network.color);
            }
            return colors;
        } else {
            for (const pc of this.portConfigurations) {
                if (pc.port === portIndex + 1) {
                    for (const networkId of pc.taggedLogicalNetworkIds) {
                        for (const network of this.logicalNetworks) {
                            if (network.id_number === networkId) {
                                colors.push(network.color);
                                break;
                            }
                        }
                    }
                    return colors;
                }
            }
        }
        return [];
    }

    /**
     * When untagged network changed it should not be possible to select it as tagged as well
     */
    selectedUntaggedNetworkChanged(selectedNetworkId: number): void {
        this.form.get('port_tagged_network_ids').setValue(
            this.form.get('port_tagged_network_ids').value.filter(networkId => networkId !== selectedNetworkId)
        );
    }

    selectedTaggedNetworksChanged(listChangeEvent: MatListOption): void {
        // Neu zur Verfügung stehende Tagged Netzwerke bestimmen
        if (!listChangeEvent.selected) {
            this.form.get('port_tagged_network_ids').setValue(
                this.form.get('port_tagged_network_ids').value.filter(networkId => networkId !== listChangeEvent.value)
            );
        }
    }

    async applyProfiles(): Promise<void> {
        if (this.selectionApplyMode && this.portsSelectedForApplication.length > 0) {
            // Zu übertragendes PortConfiguration-Objekt finden
            let basePc: SwitchPortConfiguration | ApPortConfiguration | RouterPortConfiguration;
            for (const pc of this.portConfigurations) {
                if (pc.port === this.selectedPort + 1) {
                    basePc = pc;
                    break;
                }
            }
            try {
                for (const changePcPort of this.portsSelectedForApplication) {
                    // Ziel-PortConfiguration suchen
                    let changePc: SwitchPortConfiguration | ApPortConfiguration | RouterPortConfiguration;
                    for (const pc of this.portConfigurations) {
                        if (pc.port === changePcPort) {
                            changePc = pc;
                            break;
                        }
                    }
                    // Falls es noch keine Einstellungen gibt, neue erstellen
                    if (!changePc) {
                        if (this.modelService.isModelSwitch(this.model)) {
                            changePc = new SwitchPortConfiguration();
                            changePc.comments = '';
                            changePc.mgmt_port = true;
                            changePc.poe_enabled = true;
                        }
                        if (this.modelService.isModelAP(this.model)) {
                            changePc = new ApPortConfiguration();
                            changePc.default_config = true;
                        }
                        if (this.modelService.isModelRouter(this.model)) {
                            changePc = new RouterPortConfiguration();
                            changePc.comments = '';
                            changePc.mode = true;
                        }
                        changePc.port = changePcPort;
                        changePc.label = 'Port ' + changePcPort;
                    }
                    const untaggedNetwork = this.logicalNetworks.filter(network => network.untagged);
                    for (let device of this.devices) {
                        if (changePc instanceof SwitchPortConfiguration) {
                            changePc.mgmt_port = basePc === undefined ? true : (basePc as SwitchPortConfiguration).mgmt_port;
                            if (changePc.mgmt_port) {
                                changePc.untaggedLogicalNetworkId = untaggedNetwork[0].id_number;
                                changePc.taggedLogicalNetworkIds = [];
                                changePc.poe_enabled = true;
                            } else {
                                changePc.untaggedLogicalNetworkId = basePc === undefined ? untaggedNetwork[0].id_number : basePc.untaggedLogicalNetworkId;
                                changePc.taggedLogicalNetworkIds = basePc === undefined ? [] : basePc.taggedLogicalNetworkIds;
                                changePc.poe_enabled = this.isPoeReady(changePcPort - 1) ? (this.isPoeReady(this.SelectedPort) ? (basePc === undefined ? true : (basePc as SwitchPortConfiguration).poe_enabled) : changePc.poe_enabled) : false;
                            }
                            await this.apDeviceService.saveSwitchPortConfiguration(this.model, device.inventoryNumber, changePc as SwitchPortConfiguration).toPromise();
                        }
                        if (changePc instanceof ApPortConfiguration) {
                            changePc.default_config = basePc === undefined ? true : (basePc as ApPortConfiguration).default_config;
                            if (changePc.default_config) {
                                changePc.untaggedLogicalNetworkId = untaggedNetwork[0].id_number;
                                changePc.taggedLogicalNetworkIds = [];
                            } else {
                                changePc.untaggedLogicalNetworkId = basePc.untaggedLogicalNetworkId;
                                changePc.taggedLogicalNetworkIds = basePc.taggedLogicalNetworkIds;
                            }
                            await this.apDeviceService.saveApPortConfiguration(device.inventoryNumber, changePc as ApPortConfiguration).toPromise();
                        }
                        if (changePc instanceof RouterPortConfiguration) {
                            changePc.mode = basePc === undefined ? true : (basePc as RouterPortConfiguration).mode;
                            if (changePc.mode) {
                                changePc.untaggedLogicalNetworkId = untaggedNetwork[0].id_number;
                                changePc.taggedLogicalNetworkIds = [];
                            } else {
                                changePc.untaggedLogicalNetworkId = basePc.untaggedLogicalNetworkId;
                                changePc.taggedLogicalNetworkIds = basePc.taggedLogicalNetworkIds;
                            }
                            await this.apDeviceService.saveRouterPortConfiguration(device.inventoryNumber, changePc as RouterPortConfiguration).toPromise();
                        }
                    }
                }
                this.onChange.emit({ saved: true });
                this.snackBar.open('Die Port-Konfiguration wurde übernommen', 'OK');
            } catch (err) {
                this.snackBar.open('Die Port-Konfiguration konnte nicht gespeichert werden: ' + err, 'OK');
            } finally {
                this.deviceChanged.emit();
            }
        }
        if (this.selectionApplyMode) {
            if (this.portsSelectedForApplication.length === 0) {
                this.snackBar.open('Sie müssen zunächst die zu übertragenden Ports auswählen', 'OK');
            }
        }
        this.selectionApplyMode = !this.selectionApplyMode;
        this.portsSelectedForApplication = [];
    }

    /**
     * Save port configuration by getting form values and send them to server
     */
    async savePortConfiguration(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        let pc: SwitchPortConfiguration | ApPortConfiguration | RouterPortConfiguration;
        if (this.apDeviceService.isDeviceSwitch(this.devices[0])) {
            pc = new SwitchPortConfiguration();
        } else if (this.apDeviceService.isDeviceAP(this.devices[0])) {
            pc = new ApPortConfiguration();
        } else if (this.apDeviceService.isDeviceRouter(this.devices[0])) {
            pc = new RouterPortConfiguration();
        }
        pc.port = this.selectedPort + 1;
        pc.label = this.form.get('port_label').value === '' ? 'Port ' + (this.selectedPort + 1) : this.form.get('port_label').value;
        if (pc instanceof SwitchPortConfiguration) {
            pc.comments = this.form.get('port_comments').value;
            pc.mgmt_port = this.form.get('mgmt_port').value;

            if (pc.mgmt_port) {
                const untaggedNetwork = this.logicalNetworks.filter(network => network.untagged);
                pc.untaggedLogicalNetworkId = untaggedNetwork[0].id_number;
                pc.taggedLogicalNetworkIds = [];
                pc.poe_enabled = true;
            } else {
                pc.untaggedLogicalNetworkId = this.form.get('port_untagged_network_id').value;
                pc.taggedLogicalNetworkIds = this.form.get('port_tagged_network_ids').value;
                pc.poe_enabled = this.form.get('port_poe_enabled').value;
            }
        }
        if (pc instanceof ApPortConfiguration) {
            pc.default_config = this.form.get('mgmt_port').value;

            if (pc.default_config) {
                const untaggedNetwork = this.logicalNetworks.filter(network => network.untagged);
                pc.untaggedLogicalNetworkId = untaggedNetwork[0].id_number;
                pc.taggedLogicalNetworkIds = [];
            } else {
                pc.untaggedLogicalNetworkId = this.form.get('port_untagged_network_id').value;
                pc.taggedLogicalNetworkIds = this.form.get('port_tagged_network_ids').value;
            }
        }
        if (pc instanceof RouterPortConfiguration) {
            pc.comments = this.form.get('port_comments').value;
            pc.mode = this.form.get('mgmt_port').value;

            if (pc.mode) {
                const untaggedNetwork = this.logicalNetworks.filter(network => network.untagged);
                pc.untaggedLogicalNetworkId = untaggedNetwork[0].id_number;
                pc.taggedLogicalNetworkIds = [];
            } else {
                pc.untaggedLogicalNetworkId = this.form.get('port_untagged_network_id').value;
                pc.taggedLogicalNetworkIds = this.form.get('port_tagged_network_ids').value;
            }
        }

        try {
            if (await this.apDetailService.confirmChange(this.devices)) {
                for (let device of this.devices) {
                    if (pc instanceof SwitchPortConfiguration) {
                        await this.apDeviceService.saveSwitchPortConfiguration(this.model, device.inventoryNumber, pc).toPromise();
                    }
                    if (pc instanceof ApPortConfiguration) {
                        await this.apDeviceService.saveApPortConfiguration(device.inventoryNumber, pc).toPromise();
                    }
                    if (pc instanceof RouterPortConfiguration) {
                        await this.apDeviceService.saveRouterPortConfiguration(device.inventoryNumber, pc).toPromise();
                    }
                }
                this.multipleEditStart = false;
                this.deviceChanged.emit();
                this.onChange.emit({ saved: true });
                this.snackBar.open('Die Port-Konfiguration wurde übernommen', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Die Port-Konfiguration konnte nicht gespeichert werden: ' + err, 'OK');
        }
    }

    showHelp(): void {
        this.dialog.open(this.exampleImage);
    }

    showFwanInfo(): void {
        this.dialog.open(this.fwanInfo, {
            maxWidth: '500px'
        });
    }

    saved(saved: boolean): void {
        this.onChange.emit({ saved });
    }

    async save(): Promise<void> {
        await this.savePortConfiguration();
    }
}
