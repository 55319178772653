import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { ApDeviceService } from 'app/APManagement/APService/apDevice.service';
import { DBDevice } from 'app/data/endooSpotDB_objects';
import { PlotlyGraphGauge } from './Plotly/plotly-graph-gauge';
import { PlotlyGraphScatter } from './Plotly/plotly-graph-scatter';
import { interval, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { AppService } from 'app/Services/app.service';
import { DevicePlotlyGraphOutput, DevicePlotlyGraphRequest, DevicePlotlyGraphService } from './device-plotly-graph.service';
import { DevicesCount } from './Canvas/DevicesGraph/devices-graph.component';
import { ModelService } from 'app/APManagement/APService/model.service';
import { PlotlyGraph } from './Plotly/plotly-graph';

@Component({
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements AfterViewInit, OnDestroy {
    reloadInterval: Subscription;
    devicesCount: DevicesCount;
    graphClientCount2_4Ghz: PlotlyGraphGauge;
    graphClientCount5Ghz: PlotlyGraphGauge;
    graphClientCount2_4GhzTimeline: PlotlyGraphScatter;
    graphClientCount5GhzTimeline: PlotlyGraphScatter;
    /**
     * Eine Liste aller APs, welche kein Dashboard unterstürtzen und somit nicht in die Bewertungen einbezogen sind. Diese dem Nutzer anzeigen.
     */
    notSupportedModels: string[] = [];

    constructor(
        private dashboardService: DashboardService,
        private apDeviceService: ApDeviceService,
        private modelService: ModelService,
        public appService: AppService,
        private devicePlotlyGraphService: DevicePlotlyGraphService,
        private snackBar: MatSnackBar
    ) { }

    ngAfterViewInit() {
        setTimeout(() => {
            this.fetchData();
        }, 1000);
        this.reloadInterval = interval(2 * 1000 * 60).pipe(map(() => {
            this.devicesCount = undefined;
            this.notSupportedModels = [];
            this.fetchData();
        })).subscribe();
    }

    ngOnDestroy() {
        if (this.reloadInterval) {
            this.reloadInterval.unsubscribe();
        }
    }

    async fetchData(): Promise<void> {
        try {
            const devices = await this.apDeviceService.getAll().toPromise();

            // Daten für die Geräteüersicht bekommen (Übersicht oben im Dashboard)
            // Variablen für die DevicesGraphComponent
            this.devicesCount = { routers: { overall: 0, online: 0, error: 0 }, switches: { overall: 0, online: 0, error: 0 }, accessPoints: { overall: 0, online: 0, error: 0 }, userDevices: { overall: 0 } };

            // Eine Kopie des devicesCount machen, damit Angular die Veränderung am DevicesCount erkennt
            const devicesCount: DevicesCount = Object.assign({}, this.devicesCount);
            devices.forEach(device => {
                // Wie viele Geräte sind online oder im Fehlerzustand?
                if (this.apDeviceService.isDeviceRouter(device)) {
                    devicesCount.routers.overall++;
                    if (!this.apDeviceService.hasDeviceError(device) && this.apDeviceService.isDeviceOnline(device)) {
                        devicesCount.routers.online++;
                    }
                    if (this.apDeviceService.hasDeviceError(device)) {
                        devicesCount.routers.error++;
                    }
                } else if (this.apDeviceService.isDeviceSwitch(device)) {
                    devicesCount.switches.overall++;
                    if (!this.apDeviceService.hasDeviceError(device) && this.apDeviceService.isDeviceOnline(device)) {
                        devicesCount.switches.online++;
                    }
                    if (this.apDeviceService.hasDeviceError(device)) { // Nochmal prüfen. Das Gerät kann trotzdem "online" sein. Siehe if Abfrage oben.
                        devicesCount.switches.error++;
                    }
                } else if (this.apDeviceService.isDeviceAP(device)) {
                    devicesCount.accessPoints.overall++;
                    if (!this.apDeviceService.hasDeviceError(device) && this.apDeviceService.isDeviceOnline(device)) {
                        devicesCount.accessPoints.online++;
                    }
                    if (this.apDeviceService.hasDeviceError(device)) {
                        devicesCount.accessPoints.error++;
                    }
                }
            });

            await this.fetchPlotlyData(devices);
            devicesCount.userDevices.overall = this.graphClientCount2_4Ghz.plotData[0].value + this.graphClientCount5Ghz.plotData[0].value;
            this.devicesCount = Object.assign({}, devicesCount); // Refresh devices count for displaying new value
        } catch (err) {
            this.snackBar.open('Die Geräte konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    /**
     * Diese Methode holt sich für die DBDevices die notwendigen Influx-DB Daten.
     * @param devices
     */
    private async fetchPlotlyData(devices: DBDevice[]): Promise<void> {
        const graphClientCount2_4Ghz: DevicePlotlyGraphRequest[] = [];
        const graphClientCount5Ghz: DevicePlotlyGraphRequest[] = [];

        const graphClientCount2_4GhzTimeline: DevicePlotlyGraphRequest[] = [];
        const graphClientCount5GhzTimeline: DevicePlotlyGraphRequest[] = [];

        // Die Legendennamen im Liniendiagramm
        const scatterNames: string[] = [];
        let aps_count = 0;
        devices.forEach(device => {
            if (this.apDeviceService.isDeviceAP(device)) { // only get information for APs at the moment
                scatterNames.push(device.inventoryNumber.toUpperCase());

                graphClientCount2_4Ghz[aps_count] = {
                    device: device,
                    plotData$: this.dashboardService.getNetworkClientCount2_4GhzCurrent(device.inventoryNumber)
                };
                graphClientCount5Ghz[aps_count] = {
                    device: device,
                    plotData$: this.dashboardService.getNetworkClientCount5GhzCurrent(device.inventoryNumber)
                };

                graphClientCount2_4GhzTimeline[aps_count] = {
                    device: device,
                    plotData$: this.dashboardService.getNetworkClientCount2_4GhzTimeline(device.inventoryNumber, '6h')
                };
                graphClientCount5GhzTimeline[aps_count] = {
                    device: device,
                    plotData$: this.dashboardService.getNetworkClientCount5GhzTimeline(device.inventoryNumber, '6h')
                };

                aps_count++;
            }
        });

        let output;
        output = await this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphGauge({ title: 'Aktuell: 2,4 Ghz Netzwerk', range: { min: 0, max: aps_count > 0 ? aps_count * 30 : 30 } }), requests: graphClientCount2_4Ghz }).toPromise();
        this.graphClientCount2_4Ghz = output.plotlyGraph;
        // Find devices that do not support the dashboard feature yet and show its modelIds.
        let notSupportedModels = output.responses.filter(response => !response.hasDashboardSupport || (response.isOnline && !response.hasPlotData)).map(response => {
            return this.modelService.getModelForDevice(response.device).modelId;
        });
        // Remove double modelIds
        notSupportedModels = [...new Set(notSupportedModels)];
        // Sort
        notSupportedModels.sort();
        this.notSupportedModels = notSupportedModels;

        output = await this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphGauge({ title: 'Aktuell: 5 Ghz Netzwerk', range: { min: 0, max: aps_count > 0 ? aps_count * 30 : 30 } }), requests: graphClientCount5Ghz }).toPromise();
        this.graphClientCount5Ghz = output.plotlyGraph;

        const getHighestNumber = (output) => {
            let highestNumber = 0;
            (output.plotlyGraph as PlotlyGraph).plotData.forEach(pd => {
                pd.y.forEach(n => {
                    if (n > highestNumber) {
                        highestNumber = n;
                    }
                });
            });
            return highestNumber;
        }

        output = await this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphScatter({ title: '2,4 Ghz Netzwerk', traceNames: scatterNames }), requests: graphClientCount2_4GhzTimeline }).toPromise();
        this.graphClientCount2_4GhzTimeline = output.plotlyGraph;
        this.graphClientCount2_4GhzTimeline.layout.yaxis.range = [0, getHighestNumber(output) + 10];

        output = await this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphScatter({ title: '5 Ghz Netzwerk', traceNames: scatterNames }), requests: graphClientCount5GhzTimeline }).toPromise();
        this.graphClientCount5GhzTimeline = output.plotlyGraph;
        this.graphClientCount5GhzTimeline.layout.yaxis.range = [0, getHighestNumber(output) + 10];
    }
}
