<mat-toolbar>
    <span class="user-select-none">Kurzfristige Kennungen</span>
    <span class="example-spacer"></span>

    <ng-container *ngIf="appService.isTouchScreen()">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="createLogin()">Erzeuge kurzfristige Kennung</a>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="!appService.isTouchScreen()">
        <button mat-raised-button (click)="createLogin()">Erzeuge kurzfristige Kennung</button>
    </ng-container>
</mat-toolbar>

<div class="content-container" id="ShortTermLoginList_div">
    <mat-card class="info-alert">
        <p style="text-align: justify;">
            Benutzer mit der Rolle Lehrer können über die Lehreranwendung kurzfristige Kennungen erstellen und
            verwalten.
        </p>
    </mat-card>
    <mat-card>
        <div class="table-div">
            <mat-table [dataSource]="logins" class="table" id="GroupList_table" matSort>
                <ng-container matColumnDef="selection">
                    <mat-header-cell *matHeaderCellDef>
                    </mat-header-cell>
                    <mat-cell class="clickable_cell" *matCellDef="let group">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(group) : null" [checked]="selection.isSelected(group)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Benutzername </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectLogin(login)" *matCellDef="let login">
                        {{login.username}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Ersteller </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectLogin(login)" *matCellDef="let login">
                        {{login.owner}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="expiration">
                    <mat-header-cell *matHeaderCellDef mat-sort-header="expiration_iso"> Ablaufdatum </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectLogin(login)" *matCellDef="let login">
                        {{login.expires_date}} {{login.expires_time}} Uhr</mat-cell>
                </ng-container>
                <ng-container matColumnDef="wlan">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> WLAN </mat-header-cell>
                    <mat-cell *matCellDef="let login">
                        <mat-checkbox (change)="changeWLAN(login)" [(ngModel)]="login.wlan"> </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let login; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 50, 100, 200]" [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
    </mat-card>
</div>
