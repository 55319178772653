<mat-toolbar>
	<span class="user-select-none">Gruppenverwaltung</span>

	<span class="example-spacer"></span>

	<ng-container *ngIf="appService.isAppScreen()">
		<button mat-button [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" style="margin-left: 10px"
				(click)="newGroupBtClick()">Neue Gruppe anlegen</a>
		</mat-menu>
	</ng-container>
	<ng-container *ngIf="!appService.isAppScreen()">
		<button mat-raised-button *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" style="margin-left: 10px"
			(click)="newGroupBtClick()">Neue Gruppe anlegen</button>
	</ng-container>
</mat-toolbar>

<div class="content-container" id="GroupList_div">
	<mat-card>
		<div class="table-div">
			<mat-form-field>
				<mat-label>In Tabelle suchen</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
			</mat-form-field>
			<mat-table (matSortChange)="sortDataChanged($event)" [dataSource]="groups" class="table"
				id="GroupList_table" matSort>
				<ng-container matColumnDef="selection">
					<mat-header-cell *matHeaderCellDef>
						<mat-checkbox (change)="masterToggle($event)"
							[checked]="selection.hasValue() && isAllSelected()"
							[indeterminate]="selection.hasValue() && !isAllSelected()">
						</mat-checkbox>
					</mat-header-cell>
					<mat-cell *matCellDef="let group">
						<mat-checkbox (click)="$event.stopPropagation()"
							(change)="$event ? selection.toggle(group) : null" [checked]="selection.isSelected(group)">
						</mat-checkbox>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="groupname">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Gruppenname </mat-header-cell>
					<mat-cell class="clickable_cell cell-groupname" (click)="selectGroup(group.groupname)"
						*matCellDef="let group">
						{{group.groupname}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="description">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Beschreibung </mat-header-cell>
					<mat-cell class="clickable_cell cell-description" (click)="selectGroup(group.groupname)"
						*matCellDef="let group">
						{{group.description}} </mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let group; columns: displayedColumns;"></mat-row>
			</mat-table>
			<mat-paginator #paginator (page)="resetSelection()" [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 200]" [showFirstLastButtons]="true">
			</mat-paginator>
		</div>
		<button mat-raised-button color="warn" *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')"
			[disabled]="selection.selected.length === 0" title="Loeschen" (click)="deleteSelectedGroupsBtClick()"
			id="Delete_selected_Groups_Bt">Gew&auml;hlte Gruppen l&ouml;schen</button>
	</mat-card>
</div>
