import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UmlautConverter } from '../../data/UmlautConverter';
import { User } from '../../data/User';
import { UserService } from '../UserService/user.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'NewUserComponent',
    templateUrl: './newUser.component.html',
    styleUrls: ['newUser.component.scss'],
})
export class newUserDialogComponent {
    newUserForm: FormGroup;
    contract_link: string = '';
    user_new: User = new User();

    constructor(private userService: UserService, private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<newUserDialogComponent>, private snackBar: MatSnackBar) {
        // creating form validator for the new User form.
        this.newUserForm = this._formBuilder.group({
            usergroup: ['', [Validators.required]],
            surname: ['', [Validators.required, Validators.pattern(/[a-zA-Z ]/)]],
            givenname: ['', [Validators.required, Validators.pattern(/[a-zA-Z ]/)]],
            birthyear: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/[0-9]/)]],
            leavingyear: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern(/[0-9]/)]],
            shorten: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z ]/)]],
            username: ['', [Validators.required, Validators.pattern(/[0-9a-zA-Z ]/)]],
            password: ['', [Validators.required, Validators.minLength(10)]]
        });
        this.newUserForm.get('usergroup').valueChanges.subscribe(value => {
            switch (value) {
                case 'pupil':
                    this.newUserForm.get('leavingyear').enable();
                    this.newUserForm.get('birthyear').enable();
                    this.newUserForm.get('shorten').setValue('');
                    this.newUserForm.get('shorten').disable();
                    this.newUserForm.get('username').setValue('');
                    this.newUserForm.get('username').disable();
                    break;

                case 'teacher':
                    this.newUserForm.get('leavingyear').setValue('');
                    this.newUserForm.get('leavingyear').disable();
                    this.newUserForm.get('birthyear').setValue('');
                    this.newUserForm.get('birthyear').disable();
                    this.newUserForm.get('shorten').enable();
                    this.newUserForm.get('username').setValue('');
                    this.newUserForm.get('username').disable();
                    break;

                case 'external':
                    this.newUserForm.get('leavingyear').setValue('');
                    this.newUserForm.get('leavingyear').disable();
                    this.newUserForm.get('birthyear').setValue('');
                    this.newUserForm.get('birthyear').disable();
                    this.newUserForm.get('shorten').setValue('');
                    this.newUserForm.get('shorten').disable();
                    this.newUserForm.get('username').enable();
                    break;
            }
        });
    }

    resetStepper(stepper): void {
        stepper.reset();
    }

    closeDialogBtClick(): void {
        this.dialogRef.close();
    }

    async createNewUserBtClick(newUserStepper): Promise<void> {
        let checked = true;
        const regex_year: RegExp = new RegExp(/[0-9]/);
        const regex_name: RegExp = new RegExp(/[a-zA-Z0-9 ]/);
        this.user_new.givenname = UmlautConverter.replaceUmlaute(this.user_new.givenname);
        this.user_new.surname = UmlautConverter.replaceUmlaute(this.user_new.surname);
        checked = checked && regex_name.test(this.user_new.givenname) && regex_name.test(this.user_new.surname);
        if (this.user_new.group === 'pupil') {
            checked = checked && regex_year.test(this.user_new.leavingyear) && regex_year.test(this.user_new.birthyear);
            if (checked) {
                newUserStepper.next();
                try {
                    const result_value = await this.userService.createPupil(this.user_new).toPromise();
                    this.UserAdded(result_value);
                } catch (err) {
                    this.UserAdded({ finished: false }, err);
                }
            }
        } else if (this.user_new.group === 'teacher') {
            checked = checked && regex_name.test(this.user_new.shorten);
            if (checked) {
                newUserStepper.next();
                try {
                    const result_value = await this.userService.createTeacher(this.user_new).toPromise();
                    this.UserAdded(result_value);
                } catch (err) {
                    this.UserAdded({ finished: false }, err);
                }
            }
        } else {
            checked = checked && regex_name.test(this.user_new.username);
            if (checked) {
                newUserStepper.next();
                try {
                    const result_value = await this.userService.createExternalPerson(this.user_new).toPromise();
                    this.UserAdded(result_value);
                } catch (err) {
                    this.UserAdded({ finished: false }, err);
                }
            }
        }
    };

    // Function which is called when the user was added (ajax.success)
    UserAdded = (data, msg?): void => {
        'use strict';
        if (data.finished === true) {
            this.user_new = new User();
            if (!window.navigator || !window.navigator.msSaveOrOpenBlob) { // IE does not provide possibility to show inline blob so user service offers save dialog for them
                this.contract_link = data.filename;
            }
        } else {
            this.snackBar.open('Der Benutzer konnte nicht angelegt werden: ' + msg, 'OK');
        }
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.newUserForm.get(formControl).hasError(error);
    }
}
