import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { endooSpotManagementAppRoutingModule } from './endooSpotManagementApp-routing.module';
import { endooSpotAppComponent } from './endooSpotApplication.component';
import { endooSpotUserManagementModule } from '../UserManagement/endooSpotUserManagement.module';
import { endooSpotAPManagementModule } from '../APManagement/apManagement.module';
import { endooSpotNetworkManagementModule } from '../NetworkManagement/endooSpotNetworkManagement.module';
import { endooSpotGroupManagementModule } from '../GroupManagement/endooSpotGroupManagement.module';
import { endooSpotUserDeviceManagementModule } from '../UserDeviceManagement/endooSpotUserDeviceManagement.module';
import { endooSpotManagementAuthModule } from '../Login/endooSpotManagementAuth.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { endooSpotAdminManagementModule } from '../AdminManagement/endooSpotAdminManagement.module';
import { endooSpotUserDeviceRouterManagementModule } from '../UserDeviceManagementRouter/endooSpotUserDeviceManagementRouter.module';
import { endooSpotDashboardModule } from 'app/Dashboard/endooSpotDashboard.module';
import { endooSpotPlotlyGraphModule } from 'app/Dashboard/Plotly/endooSpotPlotlyGraph.module';
import { endooSpotCanvasGraphModule } from 'app/Dashboard/Canvas/endooSpotCanvasGraph.module';
import { endooSpotServicesModule } from 'app/Services/endooSpotServices.module';
import { endooSpotComponentsModule } from 'app/Components/endooSpotComponents.module';
import { endooSpotManagementRegisterCustomerModule } from 'app/RegisterCustomer/endooSpotManagementRegisterCustomer.module';
import { endooSpotManagementInitalNetworkSetupModule } from 'app/InitialNetworksSetup/endooSpotManagementInitialNetworkSetup.module';
import { DemoAccessModule } from 'app/DemoAccess/demo-access.module';
import { MatPaginatorIntlDE } from 'app/localization/MatPaginatorIntlDE';
import { StartpageComponent } from 'app/Startpage/startpage.component';
import { endooSpotShortTermLoginModule } from 'app/ShortTermLogin/endooSpotShortTermLogin.module';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatSelectModule,
        MatSidenavModule,
        MatToolbarModule,
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,
        endooSpotUserManagementModule,
        endooSpotGroupManagementModule,
        endooSpotAPManagementModule,
        endooSpotNetworkManagementModule,
        endooSpotUserDeviceManagementModule,
        endooSpotUserDeviceRouterManagementModule,
        endooSpotManagementAuthModule,
        endooSpotAdminManagementModule,
        endooSpotManagementAppRoutingModule,
        HttpClientModule,
        endooSpotDashboardModule,
        endooSpotPlotlyGraphModule,
        endooSpotCanvasGraphModule,
        endooSpotServicesModule,
        endooSpotComponentsModule,
        endooSpotManagementRegisterCustomerModule,
        endooSpotManagementInitalNetworkSetupModule,
        DemoAccessModule,
        endooSpotShortTermLoginModule,
    ],
    declarations: [
        endooSpotAppComponent,
        StartpageComponent,
    ],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDE },
        { provide: LOCALE_ID, useValue: 'de' },
    ],
})
export class endooSpotManagementAppModule { }
