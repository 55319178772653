import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { AuthenticationService } from './Login/authentication.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private authService: AuthenticationService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else {
                        return child.snapshot.data;
                    }
                }
                return {};
            })
        ).subscribe((data: any) => {
            if (data === {}) {
                return;
            }

            if (data['title']) {
                this.titleService.setTitle(data['title'] + ' - ' + environment.appTitle);
            }
            let accessGranted = true;
            if (data['rolesAll'] && data['rolesAll'].length > 0) {
                accessGranted = (data['rolesAll'] as string[]).every(role => this.authService.hasPermission(role));
            }
            if (data['rolesOneOf'] && data['rolesOneOf'].length > 0) {
                accessGranted = accessGranted && (data['rolesOneOf'] as string[]).some(role => this.authService.hasPermission(role));
            }
            if (data['rolesNoneOf'] && data['rolesNoneOf'].length > 0) {
                accessGranted = accessGranted && (data['rolesNoneOf'] as string[]).every(role => !this.authService.hasPermission(role));
            }
            if (!accessGranted) {
                this.router.navigate(['/']);
            }
        });
    }
}
