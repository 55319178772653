import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { registerCustomerComponent } from './registerCustomer.component';

const endooSpotRegisterRoutes: Routes = [
    {
        path: 'register',
        component: registerCustomerComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(endooSpotRegisterRoutes),
    ],
    exports: [
        RouterModule,
    ],
})
export class endooSpotManagementRegisterCustomerRoutingModule { }
