<div *ngIf="enableInternalApp" id="endooSpotApp">
    <mat-sidenav-container fullscreen>
        <mat-sidenav mode="side" id="side-nav" #sidenav opened disableClose>
            <navigation-menu></navigation-menu>
        </mat-sidenav>
        <mat-sidenav-content>
            <mat-toolbar id="header_toolbar" color="primary">
                <span id="endoo-control" class="user-select-none">{{appTitle}}</span>
                <span class="flexible-spacer-middle"></span>
                <ng-container *ngIf="appService.isAppScreen()">
                    <button mat-button [matMenuTriggerFor]="menu">
                        <mat-icon>menu</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <a *ngIf="authenticationService.hasPermission('ADMINISTRATOR-ADMINISTRATOR')"
                            routerLink="/app/adminManagement" mat-button>Administratorverwaltung</a>
                        <button mat-menu-item [matMenuTriggerFor]="helpMenu">
                            Hilfe
                        </button>
                        <button mat-menu-item [matMenuTriggerFor]="customerMenu">
                            {{authenticationService.getUsername()}} / {{authenticationService.getCustomerId()}}
                        </button>
                    </mat-menu>
                    <mat-menu #helpMenu="matMenu">
                        <a mat-menu-item target="_blank"
                            href="https://endoospot.de/wp-content/uploads/2019/08/2019_05_endoo_Verwaltung.pdf">
                            <mat-icon class="entry-icon">article</mat-icon>
                            Anleitung
                        </a>
                        <a mat-menu-item target="_blank" href="https://support.endoospot.de/de/faq">
                            <mat-icon class="entry-icon">help</mat-icon>
                            FAQ
                        </a>
                        <a mat-menu-item target="_blank" href="https://support.endoospot.de/de/fachbegriffe">
                            <mat-icon class="entry-icon">info</mat-icon>
                            Fachbegriffe
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-menu-item target="_blank" href="https://featurewunsch.endoospot.de">
                            <mat-icon class="entry-icon">wb_iridescent</mat-icon>
                            Funktionswunsch
                        </a>
                        <a mat-menu-item target="_blank" href="https://status.endoo.eu">
                            <mat-icon class="entry-icon">storage</mat-icon>
                            Server-Status
                        </a>
                    </mat-menu>
                    <mat-menu #customerMenu="matMenu">
                        <a mat-menu-item href="#" (click)="logout($event)">
                            <mat-icon>close</mat-icon>
                            Logout
                        </a>
                        <a mat-menu-item *ngIf="customers.length > 1" href="#" (click)="changeCustomer($event)">
                            <mat-icon>login</mat-icon>
                            Schule auswählen
                        </a>
                        <a mat-menu-item href="#" (click)="openChangePasswordApp($event)">
                            <mat-icon>password</mat-icon>
                            Passwort ändern
                        </a>
                        <mat-divider *ngIf="authenticationService.isEndooAdmin()"></mat-divider>
                        <!--<a mat-menu-item href="#" (click)="$event.preventDefault(); gotoInitialSetupPage()"
                            matTooltip="Einrichtungsassistent öffnen">
                            <mat-icon>assignment_turned_in</mat-icon>
                            Erstmalige Einrichtung
                        </a>-->
                        <a mat-menu-item *ngIf="authenticationService.isEndooAdmin()" href="#"
                            (click)="openGenerateLicenseKeyDialog($event)"
                            matTooltip="Schulträgern Demo-Zugang gewähren">
                            <mat-icon>vpn_key</mat-icon>
                            Neuer Registrierungsschlüssel
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-menu-item target="_blank" href="https://endoospot.de/category/changelog/">
                            <mat-icon class="entry-icon">feed</mat-icon>
                            Neuigkeiten
                        </a>
                    </mat-menu>
                </ng-container>
                <ng-container *ngIf="!appService.isAppScreen()">
                    <a *ngIf="authenticationService.hasPermission('ADMINISTRATOR-ADMINISTRATOR')"
                        routerLink="/app/adminManagement" mat-button>Administratorverwaltung</a>
                    <button mat-button [matMenuTriggerFor]="menu">
                        Hilfe
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
                        <a mat-menu-item target="_blank"
                            href="https://support.endoo.eu/de/anleitungen-downloads/article/anleitung-endoocontrol-verwaltungsanwendung">
                            <mat-icon class="entry-icon">article</mat-icon>
                            Anleitung
                        </a>
                        <a mat-menu-item target="_blank" href="https://support.endoo.eu/de/faq">
                            <mat-icon class="entry-icon">help</mat-icon>
                            FAQ
                        </a>
                        <a mat-menu-item target="_blank" href="https://support.endoo.eu/de/fachbegriffe">
                            <mat-icon class="entry-icon">info</mat-icon>
                            Fachbegriffe
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-menu-item target="_blank" href="https://featurewunsch.endoospot.de">
                            <mat-icon class="entry-icon">wb_iridescent</mat-icon>
                            Funktionswunsch
                        </a>
                        <a mat-menu-item target="_blank" href="https://status.endoo.eu">
                            <mat-icon class="entry-icon">storage</mat-icon>
                            Server-Status
                        </a>
                    </mat-menu>
                    <button mat-button [matMenuTriggerFor]="customerMenu">
                        {{authenticationService.getUsername()}} / {{authenticationService.getCustomerId()}}
                        <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <mat-menu #customerMenu="matMenu" yPosition="above" xPosition="before">
                        <a mat-menu-item href="#" (click)="logout($event)">
                            <mat-icon>close</mat-icon>
                            Logout
                        </a>
                        <a mat-menu-item *ngIf="customers.length > 1" href="#" (click)="changeCustomer($event)">
                            <mat-icon>login</mat-icon>
                            Schule auswählen
                        </a>
                        <a mat-menu-item href="#" (click)="openChangePasswordApp($event)">
                            <mat-icon>password</mat-icon>
                            Passwort ändern
                        </a>
                        <mat-divider *ngIf="authenticationService.isEndooAdmin()"></mat-divider>
                        <!--<a mat-menu-item href="#" (click)="$event.preventDefault(); gotoInitialSetupPage()"
                            matTooltip="Einrichtungsassistent öffnen">
                            <mat-icon>assignment_turned_in</mat-icon>
                            Erstmalige Einrichtung
                        </a>-->
                        <a mat-menu-item *ngIf="authenticationService.isEndooAdmin()" href="#"
                            (click)="openGenerateLicenseKeyDialog($event)"
                            matTooltip="Schulträgern Demo-Zugang gewähren">
                            <mat-icon>vpn_key</mat-icon>
                            Neuer Registrierungsschlüssel
                        </a>
                        <mat-divider></mat-divider>
                        <a mat-menu-item target="_blank" href="https://endoospot.de/category/changelog/">
                            <mat-icon class="entry-icon">feed</mat-icon>
                            Neuigkeiten
                        </a>
                    </mat-menu>
                </ng-container>
            </mat-toolbar>
            <div id="page-padding-bottom">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>