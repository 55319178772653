<div style="max-height: 80vh; max-width: 723px;">
    <div style="display: flex; justify-content: space-between;">
        <div>
            <h1 *ngIf="isMultipleEdit()" mat-dialog-title>Mehrfachbearbeitung</h1>
            <h1 mat-dialog-title>
                <ng-container>{{devices | devicesList}}</ng-container>
            </h1>
        </div>
        <div *ngIf="!isMultipleEdit()">
            <!-- Show grey icon if user is offline -->
            <ng-container *ngIf="!loggedIn">
                <div style="width: 28px; height: 28px; border-radius: 50%; background-color: grey;"
                    matTooltip="Anmeldung erforderlich" matTooltipPosition="above">
                </div>
            </ng-container>
            <ng-container *ngIf="loggedIn">
                <!-- Show green dot if device is online and up to date -->
                <div *ngIf="deviceService.getDeviceStatusSymbol(devices[0], vpn_last_contact_time1, vpn_last_contact_time2) === 'ok'"
                    style="width: 28px; height: 28px; border-radius: 50%; background-color: green;"
                    matTooltip="Gerät ist online" matTooltipPosition="above">
                </div>
                <!-- Show red dot if device is offline (last contact time is above 10 min) -->
                <div *ngIf="deviceService.getDeviceStatusSymbol(devices[0], vpn_last_contact_time1, vpn_last_contact_time2) === 'offline'"
                    style="width: 28px; height: 28px; border-radius: 50%; background-color: red;"
                    matTooltip="Gerät ist offline" matTooltipPosition="above">
                </div>
                <!-- Show spinner icon if device is updating itself currently -->
                <div *ngIf="deviceService.getDeviceStatusSymbol(devices[0], vpn_last_contact_time1, vpn_last_contact_time2) === 'loading'"
                    [matTooltip]="devices[0].status === 'UPDATING' ? 'Gerät wird aktualisiert' : ''"
                    matTooltipPosition="above" style="width: 24px; height: 24px;" class="loader">
                </div>
                <!-- Show green question icon if the online status is unknown (last contact time of device is 5-10 min away) -->
                <div *ngIf="deviceService.getDeviceStatusSymbol(devices[0], vpn_last_contact_time1, vpn_last_contact_time2) === 'online_unknown'"
                    matTooltip="Gerätestatus wird erkannt" matTooltipPosition="above"
                    style="font-size: 31px; color: green;" class="material-icons">
                    help
                </div>
                <!-- Show yellow warning icon if device is online and has an error -->
                <div *ngIf="deviceService.getDeviceStatusSymbol(devices[0], vpn_last_contact_time1, vpn_last_contact_time2) === 'error'"
                    matTooltip="Gerät im Fehlerzustand" matTooltipPosition="above"
                    style="font-size: 28px; color: orange;" class="material-icons">
                    warning
                </div>
            </ng-container>
        </div>
    </div>
    <hr />
    <div *ngIf="!isMultipleEdit()" style="padding-bottom: 124px;">
        <b>MAC-Adresse: {{devices[0].mac}}<br><br></b>
        <b>IP-Adresse: {{devices[0].lastContactIp}}<br><br></b>
        <b *ngIf="deviceService.isDeviceRouter(devices[0])">VPN IP-Adressen: {{vpnAdressesText()}}<br><br></b>
        <b *ngIf="deviceService.isDeviceRouter(devices[0])">WAN IP-Adresse: {{vpn_wan_ip ? vpn_wan_ip :
            'n.a.'}}<br><br></b>
        <b>Zuletzt angemeldet: {{devices[0].lastContactTime | datetime}}<br><br></b>
        <!-- Show version here if device is NOT a router -->
        <b *ngIf="!deviceService.isDeviceRouter(devices[0])">Gerätversion: {{devices[0].version}}<br><br></b>
        <mat-expansion-panel *ngIf="deviceService.isDeviceRouter(devices[0])">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Weitere Informationen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <b>Zuletzt angemeldet am VPN:
                <ng-container *ngIf="!vpn_last_contact_time1 && !vpn_last_contact_time2">
                    n.a.
                </ng-container>
                <ng-container *ngIf="vpn_last_contact_time1 && !vpn_last_contact_time2">
                    {{vpn_last_contact_time1 | datetime}}
                </ng-container>
                <ng-container *ngIf="vpn_last_contact_time2 && !vpn_last_contact_time1">
                    {{vpn_last_contact_time2 | datetime}}
                </ng-container>
                <ng-container *ngIf="vpn_last_contact_time1 && vpn_last_contact_time2">
                    {{vpn_last_contact_time1 > vpn_last_contact_time2 ? (vpn_last_contact_time1 | datetime) :
                    vpn_last_contact_time2 | datetime}}
                </ng-container>
                <br><br>
            </b>
            <!-- Show version here if device is a router -->
            <b>Gerätversion: {{devices[0].version}}<br><br></b>
            <b>VPN-Client ID: {{vpn_client_cert_id}}<br><br></b>
            <b>VPN-Client Ablaufdatum: {{vpn_client_cert_valid_time | datetime}}<br><br></b>
        </mat-expansion-panel><br>
        <div>
            <mat-form-field appearance="outline" class="wide-text-field">
                <mat-label>Installierter Ort</mat-label>
                <input matInput [(ngModel)]="devices[0].location" (input)="locationChanged = true" />
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" class="wide-text-field">
                <mat-label>Kommentar</mat-label>
                <textarea matInput cdkTextareaAutosize rows="4" [(ngModel)]="devices[0].comment"
                    (input)="commentChanged = true"></textarea>
            </mat-form-field>
            <div class="save-button">
                <button mat-raised-button color="primary" [disabled]="!locationChanged && !commentChanged "
                    (click)="changeCommentAndLocation()">Speichern</button>
            </div>
        </div>
    </div>
    <div style="text-align:center;" *ngIf="model">
        <img style="max-width: 120px;" [src]="model.image_link" draggable="false">
    </div>
    <mat-tab-group *ngIf="model" (selectedTabChange)="selectedTabChange()">
        <mat-tab *ngIf="modelService.hasModelFunction(model, 'WifiCard1')" label="2,4 GHz">
            <div class="mat-tab-body-content">
                <mat-card>
                    <wifiCard1-component [devices]="devices"
                        (onChange)="dataChanged({ name: '2,4 Ghz', changed: !$event.saved })" #wifiCard1>
                    </wifiCard1-component>
                    <ng-container *ngIf="!isMultipleEdit()">
                        <endoo-plotly-graph *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD')"
                            [DevicesGraph]="graphClientCount2_4GhzCurrent" #graph1>
                        </endoo-plotly-graph>
                        <endoo-plotly-graph *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD')"
                            [DevicesGraph]="graphClientCount2_4GhzTimeline" #graph2>
                        </endoo-plotly-graph>
                    </ng-container>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab *ngIf="modelService.hasModelFunction(model, 'WifiCard2')" label="5 GHz">
            <div class="mat-tab-body-content">
                <mat-card>
                    <wifiCard2-component [devices]="devices"
                        (onChange)="dataChanged({ name: '5 Ghz', changed: !$event.saved })" #wifiCard2>
                    </wifiCard2-component>
                    <ng-container *ngIf="!isMultipleEdit()">
                        <endoo-plotly-graph *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD')"
                            [DevicesGraph]="graphClientCount5GhzCurrent" #graph3>
                        </endoo-plotly-graph>
                        <endoo-plotly-graph *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD')"
                            [DevicesGraph]="graphClientCount5GhzTimeline" #graph4>
                        </endoo-plotly-graph>
                    </ng-container>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="CPU"
            *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD') && modelService.isModelAP(model) && !isMultipleEdit()">
            <div class="mat-tab-body-content">
                <mat-card>
                    <endoo-plotly-graph [DevicesGraph]="graphCpuLoadCurrent" #graph5>
                    </endoo-plotly-graph>
                    <endoo-plotly-graph [DevicesGraph]="graphCpuLoadTimeline" #graph6>
                    </endoo-plotly-graph>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="RAM"
            *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD') && modelService.isModelAP(model) && !isMultipleEdit()">
            <div class="mat-tab-body-content">
                <mat-card>
                    <endoo-plotly-graph [DevicesGraph]="graphRamLoadCurrent" #graph7>
                    </endoo-plotly-graph>
                    <endoo-plotly-graph [DevicesGraph]="graphRamLoadTimeline" #graph8>
                    </endoo-plotly-graph>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab
            *ngIf="modelService.hasModelFunction(model, 'WifiCard1') || modelService.hasModelFunction(model, 'WifiCard2')"
            label="WLAN-Netzwerke">
            <mat-card>
                <ssids-component [devices]="devices"
                    (onChange)="dataChanged({ name: 'WLAN-Netzwerke', changed: !$event.saved })" #ssids>
                </ssids-component>
            </mat-card>
        </mat-tab>
        <mat-tab *ngIf="!isMultipleEdit() && modelService.hasModelFunction(model, 'StationStats')"
            label="Verbundene Geräte">
            <div class="mat-tab-body-content">
                <mat-card>
                    <connected-stations-component [device]="devices[0]"></connected-stations-component>
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab
            *ngIf="modelService.getModelPortLayout(model).length > 0 && authenticationService.hasPermission('HARDWARE-PORTCONFIG')"
            label="Physische Portkonfigurationen">
            <mat-card>
                <port-configuration-component [Devices]="devices" [Model]="model" (deviceChanged)="reloadDevice()"
                    (onChange)="dataChanged({ name: 'Physische Portkonfigurationen', changed: !$event.saved })"
                    #portConfiguration>
                </port-configuration-component>
            </mat-card>
        </mat-tab>
        <!-- Siehe "T-0378 Switch Functions auf Geräte-Ebene auskommentieren" -->
        <!--<mat-tab
            *ngIf="deviceService.isDeviceSwitch(devices[0]) && authenticationService.hasPermission('ENDOO-DEVELOPMENT')"
            label="Switch Einstellungen">
            <div class="mat-tab-body-content">
                <mat-card>
                    <switch-settings-component [Device]="devices[0]"></switch-settings-component>
                </mat-card>
            </div>
        </mat-tab>-->
    </mat-tab-group>
    <div style="background-color: white; position: sticky; bottom: 0; z-index: 1000;">
        <hr />
        <div class="button-container button-bar">
            <button style="margin-top: 15px; margin-right: 8px;" mat-raised-button
                (click)="closeBtClick()">Schlie&szlig;en</button>
            <span class="button-container" matTooltip="In der Mehrfachbearbeitung nicht verfügbar"
                matTooltipPosition="above" [matTooltipDisabled]="!isMultipleEdit()">
                <button mat-raised-button style="margin-top: 15px; margin-right: 8px;"
                    *ngIf="authenticationService.isEndooAdmin()" [disabled]="isMultipleEdit()"
                    (click)="printModelLabel()">
                    Label drucken
                </button>
            </span>
            <span class="button-container" matTooltip="In der Mehrfachbearbeitung nicht verfügbar"
                matTooltipPosition="above" [matTooltipDisabled]="!isMultipleEdit()">
                <button mat-raised-button style="margin-top: 15px; margin-right: 8px;"
                    (click)="removeDevice(devices[0])" [disabled]="isMultipleEdit()">
                    Gerät entfernen
                </button>
            </span>
            <button mat-raised-button style="margin-top: 15px; margin-right: 8px;" [matMenuTriggerFor]="menu">
                <mat-icon>menu</mat-icon>
                Weitere Aktionen
            </button>
            <mat-menu #menu="matMenu" yPosition="above" xPosition="before">
                <mat-button mat-menu-item (click)="setDeviceStatus('NEWCONFIG')" [disabled]="!hasMoreOptions(devices)">
                    <mat-icon class="entry-icon">settings_suggest</mat-icon>
                    Konfiguration erneut senden
                </mat-button>
                <mat-button mat-menu-item (click)="setDeviceStatus('REBOOT')" [disabled]="!hasMoreOptions(devices)">
                    <mat-icon class="entry-icon">restart_alt</mat-icon>
                    Gerät neustarten
                </mat-button>
            </mat-menu>
        </div>
    </div>
    <!-- This QR Code is used for label printing -->
    <div [hidden]="true">
        <qrcode [qrdata]="qrData ? qrData : 'error'" [width]="72" [errorCorrectionLevel]="'M'" [elementType]="'svg'">
        </qrcode>
    </div>
</div>