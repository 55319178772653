<h1 mat-dialog-title>Administrator hinzufügen</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper linear=true #newadminstepper="matHorizontalStepper">
        <form [formGroup]="form">
            <mat-step [stepControl]="form" style="padding-bottom:20px;" label="Benutzer auswählen zum hinzufügen">
                <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>Benutzernamen eingeben *</mat-label>
                        <input matInput placeholder="Benutzernamen eingeben" type="text"
                            formControlName="admin_id" placeholder="admin@testschule" />
                        <mat-error *ngIf="hasError('admin_id', 'required')">
                            Eingabe eines Admins erforderlich
                        </mat-error>
                    </mat-form-field>
                </div>
                <mat-divider></mat-divider>
                <div>
                    <button mat-button matStepperNext>Weiter</button>
                </div>
            </mat-step>
            <mat-step label="Berechtigungen">
                <mat-list>
                    <mat-list-item *ngIf="hasCustomerRole('BYOD-MANAGER')">
                        <h4>endooSpot-Netzwerk</h4>
                    </mat-list-item>
                    <mat-button-toggle-group *ngIf="hasCustomerRole('BYOD-MANAGER')" style="flex-wrap: wrap;"
                        formControlName="BYOD">
                        <mat-button-toggle value="">
                            Keine Rechte
                        </mat-button-toggle>
                        <mat-button-toggle value="BYOD-MANAGER">
                            Eingeschr&auml;nkte Rechte
                        </mat-button-toggle>
                        <mat-button-toggle value="BYOD-ADMINISTRATOR">
                            Alle Rechte
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-MANAGER')">
                        <h4>endooSpot-Hardware</h4>
                    </mat-list-item>
                    <mat-button-toggle-group *ngIf="hasCustomerRole('HARDWARE-MANAGER')" style="flex-wrap: wrap;"
                    formControlName="HARDWARE">
                        <mat-button-toggle value="">
                            Keine Rechte
                        </mat-button-toggle>
                        <mat-button-toggle value="HARDWARE-MANAGER">
                            Eingeschr&auml;nkte Rechte
                        </mat-button-toggle>
                        <mat-button-toggle value="HARDWARE-ADMINISTRATOR">
                            Alle Rechte
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-ENDOOSPOT')">
                        <h4>endooSpot-Hardware</h4>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-ENDOOSPOT')">
                        <mat-slide-toggle formControlName="HARDWARE-ENDOOSPOT">
                            Verwaltung von endooSpot-WLAN
                        </mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-DASHBOARD')">
                        <h4>Ger&auml;te-Auslastung</h4>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-DASHBOARD')">
                        <mat-slide-toggle formControlName="HARDWARE-DASHBOARD">
                            Zugriff auf Dashboard und Auslastung der Ger&auml;te
                        </mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-ADMINISTRATOR')">
                        <h4>Administratorverwaltung</h4>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-ADMINISTRATOR')">
                        <mat-slide-toggle formControlName="ADMINISTRATOR-ADMINISTRATOR">
                            Bearbeiten von Administratoren
                        </mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-PORTCONFIG')">
                        <h4>Portkonfigurationen</h4>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('HARDWARE-PORTCONFIG')">
                        <mat-slide-toggle formControlName="HARDWARE-PORTCONFIG">
                            Portkonfiguration von Geräten ändern
                        </mat-slide-toggle>
                    </mat-list-item>
                    <!--<mat-list-item *ngIf="hasCustomerRole('ADMINISTRATOR-CONTRACTS')">
                        <h4>Vertragsverwaltung</h4>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle formControlName="ADMINISTRATOR-CONTRACTS">
                            Berechtigung zur Vorlage von Verträgen und deren Signierung
                        </mat-slide-toggle>
                    </mat-list-item>-->
                    <mat-list-item *ngIf="hasCustomerRole('ROUTERNETWORK-ADMINISTRATOR')">
                        <h4>Router-Netzwerke</h4>
                    </mat-list-item>
                    <mat-list-item *ngIf="hasCustomerRole('ROUTERNETWORK-ADMINISTRATOR')">
                        <mat-slide-toggle formControlName="ROUTERNETWORK-ADMINISTRATOR">
                            Rolle für das Erstellen und Verwalten von Router-Netzwerken
                        </mat-slide-toggle>
                    </mat-list-item>
                </mat-list>
                <mat-divider></mat-divider>
                <div>
                    <button mat-button (click)="addNewAdminBtClick()">Speichern</button>
                </div>
            </mat-step>
        </form>
    </mat-horizontal-stepper>
</div>  