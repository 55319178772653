import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'
import { endooSpotManagementAuthModule } from '../Login/endooSpotManagementAuth.module'
import { ChangeUserListComponent } from './UserList/UserList.component';
import { addUserToGroupDialogComponent, deleteUserDialogComponent, NewPasswordDialogComponent } from './UserList/UserListDialogs.component';
import { UserDetailDialogComponent } from './UserList/UserDetailDialog.component'
import { newUserDialogComponent } from './NewUser/newUser.dialog.component';
import { importUserDialogComponent } from './UserImport/importUser.dialog.component';
import { UserService } from './UserService/user.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatToolbarModule,
        MatExpansionModule,
        MatMenuModule,
        FormsModule, ReactiveFormsModule,
        HttpClientModule,
        endooSpotManagementAuthModule,
    ],
    declarations: [
        ChangeUserListComponent, addUserToGroupDialogComponent, deleteUserDialogComponent,
        UserDetailDialogComponent, NewPasswordDialogComponent,
        newUserDialogComponent,
        importUserDialogComponent,
    ],
    providers: [
        UserService,
    ],
    exports: [
        ChangeUserListComponent
    ]
})
export class endooSpotUserManagementModule { }
