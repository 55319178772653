import { Component, Inject } from '@angular/core';
import { RouterNetwork } from 'app/data/RouterNetwork';
import { RouterSettingsService } from '../../RouterSettingsService/RouterSettings.service';
import { CaptivePortal } from 'app/data/CaptivePortal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './deleteRouterNetwork.dialog.component.html',
})
export class DeleteRouterNetworkDialogComponent {
    routerNetwork: RouterNetwork;
    captivePortal: CaptivePortal;

    constructor(private routerNeworkService: RouterSettingsService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<DeleteRouterNetworkDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        if (data) {
            this.routerNetwork = data.routerNetwork;
            this.captivePortal = data.captivePortal;
        }
    }

    /**
     * Method to delete network. Will make an ajax call to the server to delete network
     */
    async deleteNetworkClick_dialog(): Promise<void> {
        if (this.routerNetwork) {
            try {
                await this.routerNeworkService.deleteRouterNetwork(this.routerNetwork).toPromise();
                if (this.captivePortal !== null) { // CP exists for router network?
                    await this.routerNeworkService.deleteCaptivePortal(this.captivePortal).toPromise();
                }
                this.snackBar.open('Die endooConnect Einstellungen wurden entfernt', 'OK');
                this.dialogRef.close(true);
            } catch (err) {
                this.snackBar.open('Die endooConnect Einstellungen konnten nicht entfernt werden: ' + err, 'OK');
                this.dialogRef.close(false);
            }
        }
    }

    /**
     * Method to close dialog if user pressed cancel
     */
    cancelDeleteNetworkClick_dialog(): void {
        this.dialogRef.close(false);
    }
}
