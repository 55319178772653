<mat-toolbar>
	<span class="user-select-none">Geräteverwaltung</span>
</mat-toolbar>
<nav mat-tab-nav-bar>
	<a *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-MANAGER')"
		mat-tab-link [routerLink]="'/app/apList'">
		Ger&auml;te&uuml;bersicht
	</a>
	<a mat-tab-link [routerLink]="'/app/apGlobal'" active=true>
		Globale AP Einstellungen
	</a>
	<a *ngIf="authenticationService.hasPermission('HARDWARE-PORTCONFIG')" mat-tab-link [routerLink]="'/app/switchGlobal'">
		Globale Switch Einstellungen
	</a>
	<a *ngIf="authenticationService.hasPermission('ENDOO-PREVIEW')" mat-tab-link [routerLink]="'/app/routerGlobal'">
		Globale Router Einstellungen
	</a>
</nav>
<div class="content-container" *ngIf="wifiSettings !== undefined">
	<mat-accordion>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon class="mat-icon-left">settings
					</mat-icon>
					<span>Allgemeine Einstellungen</span>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<WifiGlobalBasicSettings [wifiSettings]="wifiSettings">
			</WifiGlobalBasicSettings>
		</mat-expansion-panel>
		<mat-expansion-panel>
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon class="mat-icon-left">access_time
					</mat-icon>
					<span>WLAN-Zeitsteuerung</span>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<WifiGlobalTimeSettings [wifiSettings]="wifiSettings">
			</WifiGlobalTimeSettings>
		</mat-expansion-panel>
	</mat-accordion>
</div>
