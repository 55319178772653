import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlotlyGraphComponent } from './PlotlyGraph/plotly-graph.component';
import { PlotlyViaWindowModule } from 'angular-plotly.js';

@NgModule({
    imports: [
        CommonModule,
        PlotlyViaWindowModule,
    ],
    declarations: [
        PlotlyGraphComponent,
    ],
    exports: [
        PlotlyGraphComponent,
    ],
})
export class endooSpotPlotlyGraphModule { }
