import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: './pskWarning.dialog.component.html',
})
export class PskWarningDialogComponent {

    constructor(private dialogRef: MatDialogRef<PskWarningDialogComponent>) { }

    async confirm(): Promise<void> {
        this.dialogRef.close(true);
    }

    async cancel(): Promise<void> {
        this.dialogRef.close(false);
    }
}
