<h1 mat-card-title style="text-align: center !important;">{{title}}</h1>
<div mat-card-content>
    <div style="max-width: 350px;">
    <div style="margin-top: 25px; display: flex; flex-direction: column; align-items: center; gap: 30px;">
        <div style="padding: 25px;;">
            <p style="text-align: justify;">{{message}}</p>
        </div>
        <div>
            <button mat-raised-button (click)="okButtonClicked()" style="margin-right: 25px;">{{yesButtonText ? yesButtonText : 'Bestätigen'}}</button>
            <button mat-raised-button (click)="cancelButtonClicked()">{{noButtonText ? noButtonText : 'Abbrechen'}}</button>
        </div>
    </div>
    </div>
</div>
