<h1 mat-dialog-title>Kurzfristige Kennung</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <div class="fullwidth_form">
            <mat-form-field appearance="outline">
                <mat-label>Benutzername</mat-label>
                <input matInput type="text" formControlName="username" [disabled]="true" class="formcolor" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Passwort</mat-label>
                <input matInput type="text" formControlName="password" [disabled]="true" class="formcolor" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Format (dd.mm.yyyy hh:ss)"
                    [formControl]="form.get('exprires_datetime')" readonly>
                <mat-icon matSuffix onMouseOver="this.style.cursor='pointer'"
                    (click)="$event.stopPropagation(); picker.open()">
                    calendar_today
                </mat-icon>
                <ngx-mat-datetime-picker #picker [showSeconds]="false" [touchUi]="appService.isTouchScreen()">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
        <div class="form-padding">
            <mat-slide-toggle formControlName="wlan" (change)="wlanChanged()">WLAN-Zugang
            </mat-slide-toggle>
        </div>
    </form>
</div>
<div mat-dialog-actions>
    <button mat-raised-button title="Abbrechen" (click)="closeGroupDetailBtClick()">Schlie&szlig;en</button>
    <button mat-raised-button color="warn" title="Kennung loeschen" (click)="deleteShortTermLoginClick()">Kennung
        l&ouml;schen</button>
</div>