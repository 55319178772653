<mat-toolbar>
	<span class="user-select-none">Geräteverwaltung</span>
</mat-toolbar>
<nav mat-tab-nav-bar>
	<a mat-tab-link [routerLink]="'/app/apList'">
		Ger&auml;te&uuml;bersicht
	</a>
	<a *ngIf="authenticationService.hasPermission('HARDWARE-ENDOOSPOT')" mat-tab-link [routerLink]="'/app/apGlobal'">
		Globale AP Einstellungen
	</a>
	<a mat-tab-link *ngIf="authenticationService.hasPermission('HARDWARE-PORTCONFIG')" [routerLink]="'/app/switchGlobal'" active=true>
		Globale Switch Einstellungen
	</a>
	<a *ngIf="authenticationService.hasPermission('ENDOO-PREVIEW')" mat-tab-link [routerLink]="'/app/routerGlobal'">
		Globale Router Einstellungen
	</a>
</nav>
<div class="content-container">
	<mat-card>
		<form [formGroup]="form" style="display: flex;">
			<mat-list>
				<mat-list-item class="mat-list-item-input" style="margin-bottom: 30px;">
					<mat-slide-toggle formControlName="spanning_tree_protocol_enabled">Spanning Tree Protocol
					</mat-slide-toggle>
				</mat-list-item>
				<mat-list-item class="mat-list-item-input">
					<mat-form-field appearance="outline">
						<mat-label>Protokoll</mat-label>
						<mat-select matInput formControlName="spanning_tree_protocol">
							<mat-option *ngFor="let stp of spanningTreeProtocols" [value]="stp[0]">{{stp[1]}}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="hasError('spanning_tree_protocol', 'required')">Auswahl
							erforderlich
						</mat-error>
					</mat-form-field>
				</mat-list-item>
				<mat-list-item style="margin-top: 50px;" class="mat-list-item-input">
					<mat-form-field appearance="outline">
						<mat-label>Bridge Priorität</mat-label>
						<mat-select matInput formControlName="bridge_priority">
							<mat-option *ngFor="let priority of bridgePriorities" [value]="priority">{{priority}}
							</mat-option>
						</mat-select>
						<mat-error *ngIf="hasError('bridge_priority', 'required')">Auswahl erforderlich
						</mat-error>
					</mat-form-field>
				</mat-list-item>
				<mat-list-item style="margin-top: 15px;" class="mat-list-item-input">
					<mat-slide-toggle formControlName="lldp">LLDP</mat-slide-toggle>
				</mat-list-item>
				<div style="margin-top: 20px;">
					<button mat-raised-button color="primary" (click)="saveSettings()">Speichern</button>
				</div>
			</mat-list>
			<mat-list>
				<mat-list-item class="mat-list-item-input" style="margin-bottom: 30px;">
					<mat-icon (click)="showHelpForSTPEnabled()" class="help-button">help
					</mat-icon>
				</mat-list-item>
				<mat-list-item class="mat-list-item-input">
					<mat-icon (click)="showHelpForSTP()" class="help-button">help
					</mat-icon>
				</mat-list-item>
				<mat-list-item style="margin-top: 50px;" class="mat-list-item-input">
					<mat-icon (click)="showHelpForBridgePriority()" class="help-button">
						help</mat-icon>
				</mat-list-item>
				<mat-list-item style="margin-top: 15px;" class="mat-list-item-input">
					<mat-icon (click)="showHelpForLLDP()" class="help-button">help
					</mat-icon>
				</mat-list-item>
			</mat-list>
		</form>
	</mat-card>
</div>

<!-- Shows when clicking on the 1. help icon -->
<ng-template #help1>
	Das Spanning Tree Protocol (STP) verhindert in Netzwerken mit mehreren Switches parallele Verbindungen und
	unterbindet dadurch die Bildung von Schleifen. STP funktioniert in beliebig vermaschten Netzstrukturen und erzeugt
	eine Baumtopologie mit eindeutigen Verbindungspfaden.
</ng-template>

<!-- Shows when clicking on the 2. help icon -->
<ng-template #help2>
	Das Spanning Tree Protocol, abgekürzt STP, wurde 1990 in der IEEE-Norm 802.1D standardisiert. Es handelt sich um ein
	Verfahren, das die Schleifenbildung in geswitchten Netzwerkstrukturen verhindert und so eindeutige Pfade zwischen
	den Switches herstellt. Netzwerkphänomene wie beispielsweise Broadcast-Stürme lassen sich durch STP vermeiden.
</ng-template>

<!-- Shows when clicking on the 3. help icon -->
<ng-template #help3>
	Konfiguriert die Bridge-Priorität, die bestimmt, welche Bridge als Root Bridge gewählt wird. Wenn zwei Brücken die
	gleichen Pfadkosten wie die Root Bridge haben, bestimmt die Bridge-Priorität, welche Bridge zur designierten Bridge
	für ein LAN-Segment wird.
</ng-template>

<!-- Shows when clicking on the 4. help icon -->
<ng-template #help4>
	Das Link Layer Discovery Protocol (LLDP) ist ein herstellerunabhängiges Layer-2-Protokoll, das in IEEE-802.1AB-Norm
	definiert ist. Es bietet die Möglichkeit, Informationen zwischen Nachbargeräten (Neighbor Discovery) per Multicast
	auszutauschen. Auf jedem mit LLDP arbeitenden Gerät arbeitet ein LLDP-Agent, der in periodischen Abständen
	Informationen über sich selbst versendet und ständig Informationen von Nachbargeräten empfängt. Dies geschieht
	völlig unabhängig voneinander, es werden keine Verbindungen zu anderen Geräten aufgebaut. Empfangene Informationen
	werden lokal in einer Management Information Base (MIB) gesammelt. Auf diese kann mit SNMP zugegriffen werden.
</ng-template>
