import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from 'app/Login/authentication.service';
import { GlobalSwitchSettingsService } from '../APService/globalSwitchSettings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwitchSettings } from 'app/data/SwitchSettings';
import { MatDialog } from '@angular/material/dialog';

@Component({
    templateUrl: './globalSwitchSettings.component.html',
    styleUrls: ['./globalSwitchSettings.component.css'],
})
export class GlobalSwitchSettingsComponent implements OnInit {
    form: FormGroup;
    spanningTreeProtocols: string[][] = [['rstp', 'Rapid Spanning Tree Protocol (RSTP)'], ['mstp', 'Multiple Spanning Tree Protocol (MSTP)']];
    bridgePriorities: string[] = ['0', '4096', '8192', '12288', '16384', '20480', '24576', '28672', '32768', '36864', '40960', '45056', '49152', '53248', '57344', '61440'];
    @ViewChild('help1') help1: TemplateRef<any>;
    @ViewChild('help2') help2: TemplateRef<any>;
    @ViewChild('help3') help3: TemplateRef<any>;
    @ViewChild('help4') help4: TemplateRef<any>;

    constructor(
        private fb: FormBuilder,
        public authenticationService: AuthenticationService,
        private globalSwitchSettingsService: GlobalSwitchSettingsService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            spanning_tree_protocol_enabled: [],
            spanning_tree_protocol: [null, [Validators.required]],
            bridge_priority: [null, [Validators.required]],
            lldp: []
        });
        this.form.get('spanning_tree_protocol_enabled').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('spanning_tree_protocol').enable();
                this.form.get('bridge_priority').enable();
            } else {
                this.form.get('spanning_tree_protocol').reset();
                this.form.get('bridge_priority').reset();
                this.form.get('spanning_tree_protocol').disable();
                this.form.get('bridge_priority').disable();
            }
        });
        this.globalSwitchSettingsService.getGlobalSwitchSettings().subscribe(switchSettings => {
            this.form.setValue({
                spanning_tree_protocol_enabled: switchSettings.spanning_tree_protocol_enabled,
                spanning_tree_protocol: switchSettings.spanning_tree_protocol,
                bridge_priority: switchSettings.bridge_priority,
                lldp: switchSettings.lldp
            });
        });
    }

    async saveSettings(): Promise<void> {
        if (this.form.invalid) {
            return;
        }

        const stpEnabled = this.form.get('spanning_tree_protocol_enabled').value;
        const stp = stpEnabled ? this.form.get('spanning_tree_protocol').value : '';
        const bridgePriority = stpEnabled ? this.form.get('bridge_priority').value : '';
        const lldp = this.form.get('lldp').value;
        const switchSettings = new SwitchSettings(undefined, stpEnabled, stp, bridgePriority, lldp);
        try {
            await this.globalSwitchSettingsService.saveGlobalSwitchSettings(switchSettings).toPromise();
            this.snackBar.open('Die Einstellungen wurden gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Die Einstellungen konnten nicht gespeichert werden', 'OK');
        }
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }

    showHelpForSTPEnabled(): void {
        this.dialog.open(this.help1);
    }

    showHelpForSTP(): void {
        this.dialog.open(this.help2);
    }

    showHelpForBridgePriority(): void {
        this.dialog.open(this.help3);
    }

    showHelpForLLDP(): void {
        this.dialog.open(this.help4);
    }
}
