<mat-toolbar>
	<span class="user-select-none">Geräteverwaltung</span>
</mat-toolbar>
<nav mat-tab-nav-bar>
	<a mat-tab-link [routerLink]="'/app/apList'">
		Ger&auml;te&uuml;bersicht
	</a>
	<a *ngIf="authenticationService.hasPermission('HARDWARE-ENDOOSPOT')" mat-tab-link [routerLink]="'/app/apGlobal'">
		Globale AP Einstellungen
	</a>
	<a *ngIf="authenticationService.hasPermission('HARDWARE-PORTCONFIG')" mat-tab-link [routerLink]="'/app/switchGlobal'">
		Globale Switch Einstellungen
	</a>
	<a *ngIf="authenticationService.hasPermission('ENDOO-PREVIEW')" mat-tab-link [routerLink]="'/app/routerGlobal'" active=true>
		Globale Router Einstellungen
	</a>
</nav>
<div class="content-container">
	<mat-card>
		<form [formGroup]="form">
			<mat-list>
				<mat-list-item class="mat-list-item-input" style="margin-bottom: 30px;">
					<mat-slide-toggle formControlName="default_gateway">Default WAN (W0) als bevorzugtes WAN / Uplink
						Netzwerk verwenden
					</mat-slide-toggle>
				</mat-list-item>
				<div style="margin-top: 20px;">
					<button mat-raised-button color="primary" (click)="saveSettings()">Speichern</button>
				</div>
			</mat-list>
		</form>
	</mat-card>
</div>
