<form [formGroup]="form" (ngSubmit)="saveWifiNetworkBtClick()">
	<mat-list>
		<h3 mat-subheader>
			Verschl&uuml;sselung des Netzwerks
		</h3>
		<mat-list-item style="margin-bottom: 10px;">
			<mat-slide-toggle formControlName="encryption"
				[disabled]="!authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
				[(ngModel)]="network.encryption">Verschl&uuml;sselung
			</mat-slide-toggle>
		</mat-list-item>
		<div *ngIf="!network.encryption || network.encryption_mode === 'psk'" style="margin-left: 30px;">
			<wifi-qr-code-generator [Ssid]="network.ssid" [Encryption]="!network.encryption ? 'nopass' : 'WPA'"
				[Psk]="network.encryption_key" [Hidden]="network.hide_ssid"></wifi-qr-code-generator>
		</div>
		<div class="margintop_form">
			<mat-list-item *ngIf="network.encryption" style="margin-top: 40px; margin-bottom: 40px;">
				<mat-form-field appearance="outline" class="fullwidth_form">
					<mat-label>Verschlüsselungsmodus</mat-label>
					<mat-select formControlName="encryption_mode" [(ngModel)]="network.encryption_mode">
						<mat-option value="psk">
							WPA2 Pre-Shared Key
						</mat-option>
						<mat-option value="enterprise">
							WPA2-Enterprise
						</mat-option>
					</mat-select>
				</mat-form-field>
			</mat-list-item>
			<mat-list-item *ngIf="network.encryption_mode === 'psk'" style="margin-bottom: 30px;">
				<mat-form-field appearance="outline">
					<mat-label>WLAN-Schlüssel</mat-label>
					<input matInput [type]="showKey ? 'text' : 'password'" formControlName="encryption_key"
						[(ngModel)]="network.encryption_key" required (keypress)="validateWifiKeyInput($event)" />
					<mat-icon matSuffix onMouseOver="this.style.cursor='pointer'" (click)="showKey = !showKey">
						{{showKey ? 'visibility' : 'visibility_off'}}
					</mat-icon>
					<mat-hint>8-63 Zeichen</mat-hint>
				</mat-form-field>
			</mat-list-item>
			<mat-list-item
				*ngIf="network.encryption_mode === 'enterprise' && authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
				style="margin-bottom: 60px;">
				<mat-form-field appearance="outline">
					<mat-label>Radius-Server IP-Adresse</mat-label>
					<input matInput formControlName="encryption_radius_ip" [(ngModel)]="network.encryption_radius_ip"
						required />
					<mat-error *ngIf="hasError('encryption_radius_ip', 'required')">Eingabe erforderlich
					</mat-error>
					<mat-error *ngIf="hasError('encryption_radius_ip', 'invalid_ip')">Ungültige IP-Adresse
					</mat-error>
					<mat-hint>Bsp.: 10.0.0.1</mat-hint>
				</mat-form-field>
			</mat-list-item>
			<mat-list-item
				*ngIf="network.encryption_mode === 'enterprise' && authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
				style="margin-bottom: 60px;">
				<mat-form-field appearance="outline">
					<mat-label>Radius-Server Port</mat-label>
					<input matInput type="number" min="1" max="65535" formControlName="encryption_radius_port"
						[(ngModel)]="network.encryption_radius_port" required />
					<mat-error *ngIf="hasError('encryption_radius_port', 'required')">Eingabe erforderlich
					</mat-error>
					<mat-error
						*ngIf="hasError('encryption_radius_port', 'min') || hasError('encryption_radius_port', 'max')">
						Port muss zwischen 1 und 65535 liegen
					</mat-error>
					<mat-hint>1-65535</mat-hint>
				</mat-form-field>
			</mat-list-item>
			<mat-list-item
				*ngIf="network.encryption_mode === 'enterprise' && authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
				style="margin-bottom: 30px;">
				<mat-form-field appearance="outline">
					<mat-label>Radius-Server Shared Secret</mat-label>
					<input matInput formControlName="encryption_radius_secret"
						[(ngModel)]="network.encryption_radius_secret" required />
					<mat-error *ngIf="hasError('encryption_radius_secret', 'required')">Eingabe erforderlich
					</mat-error>
				</mat-form-field>
			</mat-list-item>
		</div>
		<mat-divider></mat-divider>
		<mat-list-item>
			<div mat-line>
				<button mat-raised-button class="button-margin-top button-margin-right" color="primary"
					title="Speichern" [disabled]="background_working" type="submit">Speichern</button>
			</div>
		</mat-list-item>
	</mat-list>
</form>