<h1 mat-dialog-title>Neues Gerät hinzufügen</h1>
<div mat-dialog-content>
	<mat-horizontal-stepper linear=true #stepper>
		<mat-step style="padding-bottom:20px;" label="Modellinformationen">
			<div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
				<button mat-raised-button type="button" style="margin-bottom: 20px;" matTooltip="Bitte nutzen Sie einen 2D fähigen Barcode-Scanner"
					(click)="openQrCodeReaderDialog()">QR-Code scannen
				</button>
				oder
			</div>
			<form [formGroup]="firstForm">
				<mat-form-field appearance="outline">
					<mat-label>Modell wählen*</mat-label>
					<input matInput placeholder="Modell suchen:" type="text" [matAutocomplete]="autoModel" style="text-transform: uppercase;" formControlName="model_id" />
					<mat-autocomplete autoActiveFirstOption #autoModel="matAutocomplete" [displayWith]="displayNameOfTheModel">
						<mat-option *ngFor="let model of selectedModels" [value]="model.modelId" style="text-transform: uppercase;">
							{{model.modelId}}
						</mat-option>
					</mat-autocomplete>
					<mat-error *ngIf="hasError('model_id', 'required')">Modellauswahl
						erforderlich
					</mat-error>
				</mat-form-field>
			</form>
			<mat-divider></mat-divider>
			<div>
				<button mat-button (click)="nextStep()">Weiter</button>
			</div>
		</mat-step>
		<mat-step style="padding-bottom:20px;" label="Ger&auml;teinformationen">
			<form [formGroup]="firstForm">
				<div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
					<mat-form-field appearance="outline">
						<mat-label>Inventarnummer - Beispiel: EAX1800L2-1234</mat-label>
						<input matInput formControlName="inventory_number" required />
						<mat-error *ngIf="hasError('inventory_number', 'required')">Inventarnummer
							erforderlich - Beispiel: EAX1800L2-1234 oder xd4200-123
						</mat-error>
						<mat-error *ngIf="hasError('inventory_number', 'pattern')">Inventarnummer
							<strong>ungültig</strong>
						</mat-error>
					</mat-form-field>
					<br><br>
					<mat-form-field appearance="outline">
						<mat-label>MAC-Adresse - Beispiel: 00-1D-60-4A-8C-CB</mat-label>
						<input matInput formControlName="mac" required />
						<mat-error *ngIf="hasError('mac', 'required')">MAC-Adresse erforderlich - Beispiel: 00-1D-60-4A-8C-CB
						</mat-error>
						<mat-error *ngIf="hasError('mac', 'pattern')">MAC-Adresse <strong>ungültig</strong>
						</mat-error>
					</mat-form-field>
					<mat-divider></mat-divider>
					<br>
					<mat-form-field appearance="outline">
						<mat-label>Seriennummer</mat-label>
						<input matInput formControlName="serial_number" required />
						<mat-error *ngIf="hasError('serial_number', 'required')">Seriennummer
							erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('serial_number', 'pattern')">Seriennummer <strong>ungültig</strong>
						</mat-error>
					</mat-form-field>
				</div>
			</form>
			<mat-divider></mat-divider>
			<div>
				<button mat-button matStepperPrevious>Zurück</button>
				<button mat-button (click)="nextStep()">Weiter</button>
			</div>
		</mat-step>
		<mat-step label="Eingaben &uuml;berpr&uuml;fen">
			<div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
				<mat-form-field appearance="outline">
					<mat-label>Inventarnummer</mat-label>
					<input disabled matInput [ngModel]="firstForm.get('inventory_number').value" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>MAC-Adresse</mat-label>
					<input disabled matInput [ngModel]="firstForm.get('mac').value" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Seriennummer</mat-label>
					<input disabled matInput [ngModel]="firstForm.get('serial_number').value" />
				</mat-form-field>
			</div>
			<mat-divider></mat-divider>
			<div>
				<button class="button-margin-right" mat-button matStepperPrevious>Zur&uuml;ck</button>
				<button mat-button (click)="addDevice()">Ger&auml;t hinzuf&uuml;gen</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>
