import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DBAdmin } from '../../data/endooSpotDB_objects';
import { AdminService } from '../AdminService/admin.service';
import { AdminDetailDialogComponent } from './AdminDetailDialog.component';
import { deleteAdminDialogComponent } from './DeleteAdminDialogComponent';
import { newAdminDialogComponent } from '../NewAdmin/newAdmin.dialog.component';
import { AppService } from 'app/Services/app.service';
import { AuthenticationService } from '../../Login/authentication.service';
import { addAdminToCustomerDialogComponent } from '../AddAdminToCustomer/AddAdminToCustomer.dialog.component';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
    templateUrl: './adminList.component.html',
    styleUrls: ['./adminList.component.css'],
})
export class adminListComponent implements AfterViewInit {
    displayedColumns: string[] = ['selection', 'username', 'description', 'email'];
    // implement table selection

    selection: SelectionModel<DBAdmin> = new SelectionModel<DBAdmin>(allowMultiSelect, initialSelection);

    admins: MatTableDataSource<DBAdmin>; // Use mattabledatasource for easy sorting
    // view elements of the Table sort and the paginator of the material table holding all admins.
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    selected_admins: DBAdmin[] = [];

    /**
     * Constructor method for the Angular Admin List component. All params will be injected by angular.
     * @param adminService Service object for communication with admin backend.
     * @param dialog Object to handle dialog things. e.g. open a dialog.
     * @param snackBar Object to open snack bar and present information there.
     */
    constructor(
        private adminService: AdminService,
        private authService: AuthenticationService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public appService: AppService
    ) {
        this.admins = new MatTableDataSource();
        this.admins.sort = this.sort;
        this.admins.paginator = this.paginator;
    }

    ngAfterViewInit() {
        this.getAdminList();
    }

    /**
     * Function to get a list of users. It will be displayed by material datatable
     */
    async getAdminList(): Promise<void> {
        try {
            this.admins.data = await this.adminService.getAll().toPromise();
            if (!this.adminService.isEndooAdmin()) {
                this.admins.data = this.admins.data.filter(v => v.username !== 'admin@endoo');
            }
            this.admins.sort = this.sort;
            this.admins.paginator = this.paginator;
            this.preparePaginator();
            this.selection = new SelectionModel<DBAdmin>(allowMultiSelect, initialSelection);
        } catch (err) {
            this.snackBar.open('Administratoren konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('adminList.pageSize')) {
            this.admins.paginator.pageSize = Number(localStorage.getItem('adminList.pageSize'));
            this.admins.paginator = this.paginator; // Update the paginator
        }
        this.admins.paginator._changePageSize = pageSize => {
            localStorage.setItem('adminList.pageSize', String(pageSize));
            this.admins.paginator.pageSize = pageSize;
            this.admins.paginator = this.paginator; // Update the paginator
        };
    }

    /**
     * Function which is used when a admin has been selected in the table
     * @param username username of the selected admin (as String)
     */
    async selectAdmin(admin: DBAdmin): Promise<void> {
        const dialogRef = this.dialog.open(AdminDetailDialogComponent, {
            data: { admin: admin }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getAdminList()
        });
    }

    /**
     * Function to manage array which holds all selected admins. 
     * @param admin Admin which has been selected or deselected so that it needs to be added or removed from the array. 
     */
    add_removeSelectedPersonstoGroup(admin: DBAdmin): void {
        const selected_index: number = this.selected_admins.indexOf(admin);
        if (selected_index !== -1) {
            this.selected_admins.splice(selected_index, 1);
        } else {
            this.selected_admins.push(admin);
        }
    }

    /**
     * Function for delete button. Will open a modal so the user has to check if the users should be deleted. 
     */
    deleteSelectedAdminsBtClick(): void {
        const dialogRef = this.dialog.open(deleteAdminDialogComponent, {
            width: '250px',
            data: { users: this.selection.selected }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.getAdminList();
        });
    }

    /**
     * function to open a new User Dialog
     */
    newAdminBtClick(): void {
        const dialogRef = this.dialog.open(newAdminDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            this.getAdminList();
        });
    }

    /**
     * function to open the add Admin to Customer Dialog
     */
    addAdminBtClick(): void {
        const dialogRef = this.dialog.open(addAdminToCustomerDialogComponent);

        dialogRef.afterClosed().subscribe(result => {
            this.getAdminList();
        });
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.admins.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Ist ein Quick Fix. Sollte zugunsten von E-0047 Support-Zugang dann angepasst werden.
     */
    isOwnAdminOfCustomer(admin: DBAdmin): boolean {
        return admin.username.endsWith('@' + this.authService.getCustomerId());
    }
}
