import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DBDevice, DBProperty } from 'app/data/endooSpotDB_objects';
import { ApDeviceService } from 'app/APManagement/APService/apDevice.service';
import { WifiNetworkService } from 'app/NetworkManagement/NetworkService/WifiNetwork.service';
import { CustomerFunctionPropertyService } from 'app/endooSpotApplication/Services/CustomerFunctionPropertyService';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ApDetailService } from '../apDetail.service';
import { IAPDetailComponent } from '../ApDetailComponent.interface';

type NetworkProperties = {
    ssid: string;
    networkFunction: string;
    enableDeviceSetting: boolean;
    customerWifiNetworkEnabled: boolean;
    deviceWifiNetworkEnabled: boolean;
};

@Component({
    selector: 'ssids-component',
    templateUrl: './ssids.component.html',
    styleUrls: ['./ssids.component.css'],
})
export class SsidsComponent implements OnInit, IAPDetailComponent {
    @Input() devices: DBDevice[];
    displayedColumns: string[] = ['ssid', 'customerLevel', 'enabledDeviceLevel', 'deviceLevel'];
    networkProperties: MatTableDataSource<NetworkProperties> = new MatTableDataSource();
    saveButtonEnabled: boolean = true;
    @Output() onChange = new EventEmitter();

    constructor(
        private snackBar: MatSnackBar,
        private apDeviceService: ApDeviceService,
        private wifiNetworkService: WifiNetworkService,
        private cfps: CustomerFunctionPropertyService,
        private apDetailService: ApDetailService
    ) { }

    ngOnInit() {
        this.getCustomerAndDeviceWifiNetworkEnabledPropertyValues();
    }

    /**
     * Initialize the table with customer and device "wifi enabled" data
     */
    private getCustomerAndDeviceWifiNetworkEnabledPropertyValues(): void {
        this.cfps.getListCustomerFunctionProperties(this.wifiNetworkService.WifiNetworkFunctionIDs).subscribe(customerFunctionProperties => {
            const networkPropertiesArray: NetworkProperties[] = [];

            // Customer level
            for (const networkFunctionProperties of customerFunctionProperties) {
                // If NetworkFunctionProperty exists
                if (networkFunctionProperties.length > 0) {
                    // Create a new NetworkProperties table entry
                    const networkProperties: NetworkProperties = {
                        ssid: undefined,
                        networkFunction: undefined,
                        enableDeviceSetting: undefined,
                        customerWifiNetworkEnabled: undefined,
                        deviceWifiNetworkEnabled: undefined
                    };
                    // Get the WifiNetwork function. e.g. WifiNetwork1
                    networkProperties.networkFunction = networkFunctionProperties[0].function;
                    for (const functionProperty of networkFunctionProperties) {
                        // If property is "wifi_network_ssid"
                        if (functionProperty.property === DBProperty.wifi_network_ssid) {
                            networkProperties.ssid = functionProperty.value;
                        }
                        // If property is "wifi_network_enabled"
                        if (functionProperty.property === DBProperty.wifi_network_enabled) {
                            if (functionProperty.value === 'true') {
                                networkProperties.customerWifiNetworkEnabled = true;
                            }
                            if (functionProperty.value === 'false') {
                                networkProperties.customerWifiNetworkEnabled = false;
                            }
                        }
                    }
                    networkPropertiesArray.push(networkProperties);
                }
            }

            // Device level
            if (this.devices.length === 1) {
                for (const wifiNetworkFunctionID of this.wifiNetworkService.WifiNetworkFunctionIDs) {
                    // Get the "wifi_network_enabled" value
                    const networkWifiEnabledValue = this.apDeviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, wifiNetworkFunctionID, DBProperty.wifi_network_enabled, undefined);
                    // Apply device overrides over customer properties
                    networkPropertiesArray.forEach(np => {
                        if (np.networkFunction === wifiNetworkFunctionID) {
                            np.enableDeviceSetting = networkWifiEnabledValue === 'true' || networkWifiEnabledValue === 'false';
                            if (networkWifiEnabledValue === 'true') {
                                np.deviceWifiNetworkEnabled = true;
                            }
                            if (networkWifiEnabledValue === 'false') {
                                np.deviceWifiNetworkEnabled = false;
                            }
                        }
                    });
                }
            }

            this.networkProperties = new MatTableDataSource(networkPropertiesArray);
        });
    }

    /**
     * Saves the changes made before clicked at the save button.
     */
    async saveChanges(): Promise<void> {
        this.saveButtonEnabled = false;
        if (await this.apDetailService.confirmChange(this.devices)) {
            await this.networkProperties.data.forEach(async networkProperties => {
                try {
                    this.devices.forEach(async device => {
                        if (networkProperties.enableDeviceSetting) {
                            await this.apDeviceService.setDeviceFunctionValue(device.inventoryNumber, networkProperties.networkFunction, DBProperty.wifi_network_enabled, String(networkProperties.deviceWifiNetworkEnabled)).toPromise();
                        } else {
                            await this.apDeviceService.removeDeviceFunctionProperty(device.inventoryNumber, networkProperties.networkFunction, DBProperty.wifi_network_enabled).toPromise();
                        }
                    });
                    this.onChange.emit({ saved: true });
                    this.snackBar.open('Die Einstellungen wurden gespeichert', 'OK');
                } catch (err) {
                    this.snackBar.open('Die Änderungen konnten nicht gespeichert werden: ' + err, 'OK');
                }
            });
        }
        this.saveButtonEnabled = true;
    }

    /**
     * Called when changing the value thats checkbox controls the device level property value
     * @param event
     * @param networkProperties
     */
    updateDeviceWifiNetworkEnabledPropertyValue(event: MatCheckboxChange, networkProperties: NetworkProperties): void {
        // Get the index of the changed element
        const index = this.networkProperties.data.findIndex(np => {
            return np === networkProperties;
        });

        // Update data
        this.networkProperties.data[index].deviceWifiNetworkEnabled = event.checked;

        this.networkProperties = new MatTableDataSource(this.networkProperties.data);
    }

    /**
     * Called when selecting the checkbox that changes to device controlled property value
     * @param event The actual value
     * @param networkProperties Further data that relates to the device wifi
     */
    updateDeviceWifiNetworkEnabled(event: MatCheckboxChange, networkProperties: NetworkProperties): void {
        // Get the index of the changed element
        const index = this.networkProperties.data.findIndex(np => {
            return np === networkProperties;
        });

        // Update data
        this.networkProperties.data[index].enableDeviceSetting = event.checked;
        if (event.checked) {
            this.networkProperties.data[index].deviceWifiNetworkEnabled = false;
        } else {
            this.networkProperties.data[index].deviceWifiNetworkEnabled = false;
        }

        this.networkProperties = new MatTableDataSource(this.networkProperties.data);
    }

    saved(saved: boolean): void {
        this.onChange.emit({ saved });
    }

    async save(): Promise<void> {
        await this.saveChanges();
    }
}
