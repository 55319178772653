import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { endooSpotManagementAuthModule } from '../Login/endooSpotManagementAuth.module';
import { LogicalNetworkBasicSettingsComponent } from './LogicalNetworkBasicSettings/LogicalNetworkBasicSettings.component';
import { LogicalNetworkManagementComponent } from './LogicalNetworkManagementComponent/LogicalNetworkManagement.component';
import { WifiNetworkTimeSettingsComponent } from './WifiNetworkTimeSettings/WifiNetworkTimeSettings.component';
import { WifiNetworkSettingsComponent } from './WifiNetworksSettingsComponent/WifiNetworkSettings.component';
import { LogicalNetworkService } from './NetworkService/LogicalNetwork.service';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NewLogicalNetworkDialogComponent } from './NewLogicalNetwork/newLogicalNetwork.dialog.component';
import { DeleteLogicalNetworkDialogComponent } from './LogicalNetworkManagementComponent/LogicalNetworkManagementDialogs.component';
import { WifiNetworkManagementComponent } from './WifiNetworkManagementComponent/WifiNetworkManagement.component';
import { NewWifiNetworkDialogComponent } from './NewWifiNetwork/newWifiNetwork.dialog.component';
import { WifiNetworkService } from './NetworkService/WifiNetwork.service';
import { DeleteWifiNetworkDialogComponent } from './WifiNetworksSettingsComponent/WifiNetworkManagementDialogs.component';
import { WifiNetworkSecurityComponent } from './WifiNetworksSecurityComponent/WifiNetworkSecurity.component';
import { endooSpotRouterSettingsModule } from 'app/RouterManagement/RouterManagement.module';
import { endooSpotComponentsModule } from 'app/Components/endooSpotComponents.module';
import { MatMenuModule } from '@angular/material/menu';
import { ChooseNewUntaggedNetworkComponent } from './ChooseNewUntaggedNetwork/chooseNewUntaggedNetwork.component';
import { PskWarningDialogComponent } from './PskWarningDialog/pskWarning.dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatExpansionModule,
        MatMenuModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        endooSpotManagementAuthModule,
        endooSpotRouterSettingsModule,
        endooSpotComponentsModule
    ],
    declarations: [
        DeleteLogicalNetworkDialogComponent,
        LogicalNetworkBasicSettingsComponent,
        LogicalNetworkManagementComponent,
        WifiNetworkManagementComponent,
        WifiNetworkSecurityComponent,
        WifiNetworkTimeSettingsComponent,
        WifiNetworkSettingsComponent,
        NewLogicalNetworkDialogComponent,
        NewWifiNetworkDialogComponent,
        DeleteWifiNetworkDialogComponent,
        ChooseNewUntaggedNetworkComponent,
        PskWarningDialogComponent,
    ],
    providers: [
        LogicalNetworkService,
        WifiNetworkService,
    ],
    exports: [
        LogicalNetworkManagementComponent,
        WifiNetworkManagementComponent,
    ],
})
export class endooSpotNetworkManagementModule { }
