import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './login.component';
import { AuthenticationService } from './authentication.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { endooSpotManagementAuthRoutingModule } from './endooSpotManagementAuth-routing.module';
import { ChooseCustomerComponent } from './chooseCustomer.component';
import { endooSpotAuthComponent } from './endooSpotAuth.component';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

@NgModule({
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        endooSpotManagementAuthRoutingModule,
    ],
    declarations: [LoginComponent, ChooseCustomerComponent, endooSpotAuthComponent],
    exports: [LoginComponent],
    providers: [AuthenticationService],
})
export class endooSpotManagementAuthModule { }
