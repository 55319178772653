import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicesGraphComponent } from './DevicesGraph/devices-graph.component';
import { CanvasComponent } from './canvas.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        CanvasComponent,
        DevicesGraphComponent,
    ],
    providers: [],
    exports: [
        CanvasComponent,
        DevicesGraphComponent,
    ],
})
export class endooSpotCanvasGraphModule { }
