import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { WifiNetworkService } from '../NetworkService/WifiNetwork.service';
import { WifiNetwork } from '../../data/WifiNetwork';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../Login/authentication.service';
import { TimesControlData } from 'app/Components/times-control/times-control-data';

@Component({
    selector: 'WifiNetworkTimeSettings',
    templateUrl: './WifiNetworkTimeSettings.component.html',
})
export class WifiNetworkTimeSettingsComponent implements OnInit {
    @Input('network') network: WifiNetwork;
    @Input('index') index: number;
    background_working: boolean = false;
    timesControlData: TimesControlData;
    @Output() changed: EventEmitter<void> = new EventEmitter<void>();

    constructor(public authenticationService: AuthenticationService, public wifiNetworkService: WifiNetworkService, private snackBar: MatSnackBar) { }

    ngOnInit() {
        this.timesControlData = new TimesControlData(false, []);
        this.timesControlData.active = this.network.wifitime_active;
        this.timesControlData.times.push({ weekdayId: 0, onValue: this.network.wifitime_mon_on, offValue: this.network.wifitime_mon_off });
        this.timesControlData.times.push({ weekdayId: 1, onValue: this.network.wifitime_tue_on, offValue: this.network.wifitime_tue_off });
        this.timesControlData.times.push({ weekdayId: 2, onValue: this.network.wifitime_wed_on, offValue: this.network.wifitime_wed_off });
        this.timesControlData.times.push({ weekdayId: 3, onValue: this.network.wifitime_thu_on, offValue: this.network.wifitime_thu_off });
        this.timesControlData.times.push({ weekdayId: 4, onValue: this.network.wifitime_fri_on, offValue: this.network.wifitime_fri_off });
        this.timesControlData.times.push({ weekdayId: 5, onValue: this.network.wifitime_sat_on, offValue: this.network.wifitime_sat_off });
        this.timesControlData.times.push({ weekdayId: 6, onValue: this.network.wifitime_sun_on, offValue: this.network.wifitime_sun_off });
    }

    async saveWifiNetworkBtClick(): Promise<void> {
        this.background_working = true;
        this.network.wifitime_active = this.timesControlData.active;
        this.network.wifitime_mon_on = this.timesControlData.times[0].onValue;
        this.network.wifitime_tue_on = this.timesControlData.times[1].onValue;
        this.network.wifitime_wed_on = this.timesControlData.times[2].onValue;
        this.network.wifitime_thu_on = this.timesControlData.times[3].onValue;
        this.network.wifitime_fri_on = this.timesControlData.times[4].onValue;
        this.network.wifitime_sat_on = this.timesControlData.times[5].onValue;
        this.network.wifitime_sun_on = this.timesControlData.times[6].onValue;
        this.network.wifitime_mon_off = this.timesControlData.times[0].offValue;
        this.network.wifitime_tue_off = this.timesControlData.times[1].offValue;
        this.network.wifitime_wed_off = this.timesControlData.times[2].offValue;
        this.network.wifitime_thu_off = this.timesControlData.times[3].offValue;
        this.network.wifitime_fri_off = this.timesControlData.times[4].offValue;
        this.network.wifitime_sat_off = this.timesControlData.times[5].offValue;
        this.network.wifitime_sun_off = this.timesControlData.times[6].offValue;

        try {
            await this.wifiNetworkService.saveWifiNetwork(this.index, this.network).toPromise();
            this.changed.emit();
            this.snackBar.open('Das WLAN-Netzwerk wurde erfolgreich gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Das WLAN-Netzwerk konnte nicht gespeichert werden: ' + err, 'OK');
        }

        this.background_working = false;
    }

    timeSettingsChanged(e: TimesControlData): void {
        this.timesControlData = e;
    }
}
