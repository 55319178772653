import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { finishResponse } from './userDeviceServiceRouter.responses';
import { UserDevice_new } from 'app/data/UserDevice_new';
import { EndooHttpService } from 'app/Services/endooHttp.service';

@Injectable()
export class UserDeviceServiceRouter {
    private readonly userDeviceURL = '/api/go/router/userdevices/';

    constructor(private http: EndooHttpService, private authService: AuthenticationService) { }

    /**
     * Function which returns all UserDevices from Server
     */
    getUserDevices(): Observable<UserDevice_new[]> {
        return this.http.get<UserDevice_new[]>(this.userDeviceURL + 'customers/' + this.authService.getCustomerId(), { observe: 'response', responseType: 'json' });
    }

    setFilter(userDevice: UserDevice_new, new_filter: boolean): Observable<boolean> {
        // if filter should be switched of delete authorized mac Adress on server side otherwise add it
        if (new_filter) {
            return this.http.delete<finishResponse>(this.userDeviceURL + 'customers/' + this.authService.getCustomerId() + '/authorized/' + userDevice.Lease.Mac, { observe: 'response', responseType: 'json' })
                .pipe(map((response: finishResponse) => {
                    // if program reaches here server has rep
                    // return result which the server has send
                    return true;
                }));
        } else {
            return this.http.put<finishResponse>(this.userDeviceURL + 'customers/' + this.authService.getCustomerId() + '/authorized', { mac: userDevice.Lease.Mac }, { observe: 'response', responseType: 'json' })
                .pipe(map((response: finishResponse) => {
                    // get users from response
                    // return result which the server has send
                    return true;
                }));
        }
    }
}
