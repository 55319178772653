import { Component, Inject, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBDevice, DBModel, DBProperty } from '../../data/endooSpotDB_objects';
import { ModelService } from '../APService/model.service';
import { DashboardService } from 'app/Dashboard/dashboard.service';
import { PlotlyGraphGauge } from 'app/Dashboard/Plotly/plotly-graph-gauge';
import { PlotlyGraphScatter } from 'app/Dashboard/Plotly/plotly-graph-scatter';
import { AuthenticationService } from 'app/Login/authentication.service';
import { ApDeviceService } from '../APService/apDevice.service';
import { AppService } from 'app/Services/app.service';
import { RemoveDeviceDialogComponent } from './remove-device-dialog/remove-device-dialog.component';
import { DevicePlotlyGraphService } from 'app/Dashboard/device-plotly-graph.service';
import { NotSavedDialogComponent, NOT_SAVED_RESULT } from './NotSavedDialog/not-saved-dialog.component';
import { IAPDetailComponent } from './ApDetailComponent.interface';
import { ApDetailService } from './apDetail.service';
import * as moment from 'moment';

export type SaveConfirmData = { name: string, changed: boolean };

@Component({
    templateUrl: './APDetailDialog.component.html',
    styleUrls: ['./APDetailDialog.component.css'],
})
export class APDetailDialogComponent implements OnInit {
    devices: DBDevice[];
    model: DBModel; // only show settings for specific model (e.g. only 1 wificard)
    graphClientCount2_4GhzCurrent: PlotlyGraphGauge;
    graphClientCount2_4GhzTimeline: PlotlyGraphScatter;
    graphClientCount5GhzCurrent: PlotlyGraphGauge;
    graphClientCount5GhzTimeline: PlotlyGraphScatter;
    graphCpuLoadCurrent: PlotlyGraphGauge;
    graphCpuLoadTimeline: PlotlyGraphScatter;
    graphRamLoadCurrent: PlotlyGraphGauge;
    graphRamLoadTimeline: PlotlyGraphScatter;
    @ViewChild('graph1') graph1;
    @ViewChild('graph2') graph2;
    @ViewChild('graph3') graph3;
    @ViewChild('graph4') graph4;
    @ViewChild('graph5') graph5;
    @ViewChild('graph6') graph6;
    @ViewChild('graph7') graph7;
    @ViewChild('graph8') graph8;
    @Output() deviceChanged: EventEmitter<DBDevice[]> = new EventEmitter();
    locationChanged: boolean;
    commentChanged: boolean;
    changesBeforeSaved: SaveConfirmData[] = [];
    @ViewChild('wifiCard1') wifiCard1: IAPDetailComponent;
    @ViewChild('wifiCard2') wifiCard2: IAPDetailComponent;
    @ViewChild('ssids') ssids: IAPDetailComponent;
    @ViewChild('portConfiguration') portConfiguration: IAPDetailComponent;
    qrData: string;
    // VPN Client Data
    vpn_client_cert_id: string;
    vpn_client_cert_valid_time: string;
    vpn_last_contact_ip1: string;
    vpn_last_contact_ip2: string;
    vpn_last_contact_time1: number;
    vpn_last_contact_time2: number;
    vpn_wan_ip: string;
    loggedIn: boolean = true;

    /**
     * Constructor function for the detail dialog component of an Access-Point. Except of the data object all objects will be injected by angular.
     * @param authenticationService Service object handling everything regarding authorization of the user which is logged in.
     * @param dialogRef Reference to the dialog this component belongs to.
     * @param data Object with data about the device the details should displayed for. Awaits an object with a device object inside
     *
     */
    constructor(public modelService: ModelService,
        private dialogRef: MatDialogRef<APDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dashboardService: DashboardService,
        private devicePlotlyGraphService: DevicePlotlyGraphService,
        public authenticationService: AuthenticationService,
        public deviceService: ApDeviceService,
        private snackBar: MatSnackBar,
        private appService: AppService,
        private dialog: MatDialog,
        private apDetailService: ApDetailService
    ) {
        this.devices = data.devices;
    }

    ngOnInit() {
        this.init();
        this.dialogRef.backdropClick().subscribe(() => {
            const changes = this.getChanges().filter(component => component.changed);
            if (changes.length > 0) {
                const dialogRef = this.dialog.open(NotSavedDialogComponent, {
                    data: { changesNames: changes.map(component => component.name) },
                    disableClose: true
                });
                dialogRef.afterClosed().subscribe(async result => {
                    if (result === NOT_SAVED_RESULT.SAVE) {
                        await this.saveUnsavedSettings();
                        this.dialogRef.close();
                    }
                    if (result === NOT_SAVED_RESULT.DISPOSE) {
                        this.dialogRef.close();
                    }
                });
            } else {
                this.dialogRef.close();
            }
        });
        this.authenticationService.sessionInvalid.subscribe(() => {
            this.loggedIn = false;
        });
        this.authenticationService.userIsLoggedIn.subscribe(() => {
            this.loggedIn = true;
        });
    }

    async init(): Promise<void> {
        await this.getModel();
        await this.getDeviceVpnInformation();

        if (!this.isMultipleEdit()) {
            this.getGraphs();
        }
    }

    isMultipleEdit(): boolean {
        return this.devices.length > 1;
    }

    closeBtClick(): void {
        this.dialogRef.close();
    }

    reloadDevice(): void {
        this.deviceChanged.emit(this.devices);
    }

    async getModel(): Promise<void> {
        try {
            this.model = await this.modelService.getModel(this.devices[0].model).toPromise();
        } catch (err) {
            this.snackBar.open('Das Gerät konnte nicht geladen werden: ' + err, 'OK');
        }
    }

    getDeviceVpnInformation(): void {
        this.vpn_client_cert_id = this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_client_cert_id, '');
        this.vpn_client_cert_valid_time = this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_client_cert_valid_time, '');
        this.vpn_last_contact_ip1 = this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_last_contact_ip1, '');
        this.vpn_last_contact_ip2 = this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_last_contact_ip2, '');
        this.vpn_last_contact_time1 = moment(this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_last_contact_time1, '')).valueOf();
        this.vpn_last_contact_time2 = moment(this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_last_contact_time2, '')).valueOf();
        this.vpn_wan_ip = this.deviceService.getDeviceFunctionPropertyValue(this.devices[0].deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_wan_ip, '');
    }

    vpnAdressesText(): string {
        if (this.vpn_last_contact_ip1 && this.vpn_last_contact_ip2) {
            return this.vpn_last_contact_ip1 + ' / ' + this.vpn_last_contact_ip2;
        }
        if (this.vpn_last_contact_ip1 && !this.vpn_last_contact_ip2) {
            return this.vpn_last_contact_ip1;
        }
        if (this.vpn_last_contact_ip2 && !this.vpn_last_contact_ip1) {
            return this.vpn_last_contact_ip2;
        }
        if (!this.vpn_last_contact_ip2 && !this.vpn_last_contact_ip1) {
            return 'n.a.';
        }
    }

    getGraphs(): void {
        // Tachometer für 2,4 Ghz (aktuell)
        const graphClientCount2_4GhzCurrent = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getNetworkClientCount2_4GhzCurrent(this.devices[0].inventoryNumber)
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphGauge({ title: 'Anzahl Endgeräte (2,4 Ghz)', range: { min: 0, max: 40 } }), requests: graphClientCount2_4GhzCurrent }, true).subscribe(output => {
            this.graphClientCount2_4GhzCurrent = output.plotlyGraph;
        });

        // Liniendiagramm für 2,4 Ghz (über die Zeit)
        const graphClientCount2_4GhzTimeline = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getNetworkClientCount2_4GhzTimeline(this.devices[0].inventoryNumber, '12h')
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphScatter({ title: 'Anzahl Endgeräte (2,4 Ghz)', traceNames: [this.devices[0].inventoryNumber] }), requests: graphClientCount2_4GhzTimeline }, true).subscribe(output => {
            this.graphClientCount2_4GhzTimeline = output.plotlyGraph;
        });

        // Tachometer für 5 Ghz (aktuell)
        const graphClientCount5GhzCurrent = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getNetworkClientCount5GhzCurrent(this.devices[0].inventoryNumber)
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphGauge({ title: 'Anzahl Endgeräte (5 Ghz)', range: { min: 0, max: 40 } }), requests: graphClientCount5GhzCurrent }, true).subscribe(output => {
            this.graphClientCount5GhzCurrent = output.plotlyGraph;
        });

        // Liniendiagramm für 5 Ghz (über die Zeit)
        const graphClientCount5GhzTimeline = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getNetworkClientCount5GhzTimeline(this.devices[0].inventoryNumber, '12h')
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphScatter({ title: 'Anzahl Endgeräte (5 Ghz)', traceNames: [this.devices[0].inventoryNumber] }), requests: graphClientCount5GhzTimeline }, true).subscribe(output => {
            this.graphClientCount5GhzTimeline = output.plotlyGraph;
        });

        // Tachometer für CPU-Usage (aktuell)
        const graphCpuLoadCurrent = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getAccessPointCpuLoadCurrent(this.devices[0].inventoryNumber)
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphGauge({ title: 'CPU-Auslastung %', range: { min: 0, max: 100 } }), requests: graphCpuLoadCurrent }, true).subscribe(output => {
            this.graphCpuLoadCurrent = output.plotlyGraph;
        });

        // Liniendiagramm für CPU-Usage (über die Zeit)
        const graphCpuLoadTimeline = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getAccessPointCpuLoadTimeline(this.devices[0].inventoryNumber, '12h')
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphScatter({ title: 'CPU-Auslastung', traceNames: [this.devices[0].inventoryNumber] }), requests: graphCpuLoadTimeline }, true).subscribe(output => {
            this.graphCpuLoadTimeline = output.plotlyGraph;
        });

        // Tachometer für RAM-Usage (aktuell)
        const graphRamLoadCurrent = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getAccessPointRamLoadCurrent(this.devices[0].inventoryNumber)
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphGauge({ title: 'RAM-Auslastung %', range: { min: 0, max: 100 } }), requests: graphRamLoadCurrent }, true).subscribe(output => {
            this.graphRamLoadCurrent = output.plotlyGraph;
        });

        // Liniendiagramm für RAM-Usage (über die Zeit)
        const graphRamLoadTimeline = [{
            device: this.devices[0],
            plotData$: this.dashboardService.getAccessPointRamLoadTimeline(this.devices[0].inventoryNumber, '12h')
        }];
        this.devicePlotlyGraphService.getData({ plotlyGraph: new PlotlyGraphScatter({ title: 'RAM-Auslastung', traceNames: [this.devices[0].inventoryNumber] }), requests: graphRamLoadTimeline }, true).subscribe(output => {
            this.graphRamLoadTimeline = output.plotlyGraph;
        });
    }

    async changeCommentAndLocation(): Promise<void> {
        try {
            if (this.commentChanged) {
                await this.deviceService.changeComment(this.devices[0]).toPromise();
            }
            if (this.locationChanged) {
                await this.deviceService.changeDevice(this.devices[0]).toPromise();
            }
            this.reloadDevice();
        } catch (err) {
            let msg;
            if (this.commentChanged && this.locationChanged) {
                msg = 'Kommentar und Installierter Ort konnten nicht geändert werden: ' + err;
            } if (this.commentChanged) {
                msg = 'Kommentar konnte nicht geändert werden: ' + err;
            } else {
                msg = 'Installierter Ort konnte nicht geändert werden: ' + err;
            }
            this.snackBar.open(msg, 'OK');
        }
        this.locationChanged = false;
        this.commentChanged = false;
    }

    async printModelLabel(): Promise<void> {
        const device = this.devices[0];

        const currentModel = await this.modelService.getModel(device.model).toPromise();
        const currentModelId = device.model;
        const labelWindow = open();
        labelWindow.document.write(currentModel.html_label);
        if (labelWindow.document.getElementById('title') !== null) {
            labelWindow.document.getElementById('title').innerHTML = currentModel.fullName;
        }
        labelWindow.document.getElementById('model-id').innerHTML = device.inventoryNumber.toUpperCase();
        if (currentModel.serial_number_available) {
            labelWindow.document.getElementById('serial-no').innerHTML = 'S/N: ' + device.serial_number;
        }
        if (currentModel.mac_available) {
            labelWindow.document.getElementById('mac').innerHTML = 'MAC: ' + device.mac;
        }
        // Generate QR-code
        this.qrData = 'v1;' + device.inventoryNumber + ';' + (!device.mac ? 'na' : device.mac) + ';' + (!device.serial_number ? 'na' : device.serial_number) + ';' + currentModelId;

        // Print off window
        setTimeout(() => {
            labelWindow.document.getElementById('qr-code').innerHTML = document.getElementsByClassName('qrcode')[0].innerHTML;
        }, 200);
        setTimeout(() => {

            labelWindow.print();
            if (!this.appService.isEdgeBrowser()) {
                labelWindow.close();
            }
        }, 700);
    }

    removeDevice(device: DBDevice): void {
        const dialogRef = this.dialog.open(RemoveDeviceDialogComponent, {
            data: {
                device: device
            },
        });
        dialogRef.afterClosed().subscribe(() => {
            this.dialogRef.close();
        });
    }

    async setDeviceStatus(status: string): Promise<void> {
        try {
            if (await this.apDetailService.confirmChange(this.devices)) {
                this.devices.forEach(async device => {
                    const statusChanged = await this.deviceService.setDeviceStatus(device.inventoryNumber, status).toPromise();
                    if (!statusChanged) {
                        throw new Error('Ungültige Aktion');
                    }
                });
                this.snackBar.open('Die Aktion wurde ausgeführt', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Die Aktion konnte nicht ausgeführt werden: ' + err, 'OK');
        }
    }

    getChanges(): SaveConfirmData[] {
        const data: SaveConfirmData[] = [];
        data.push({ name: 'Ort', changed: this.locationChanged });
        data.push({ name: 'Kommentar', changed: this.commentChanged });
        this.changesBeforeSaved.forEach(changeObject => {
            data.push(changeObject);
        })
        return data;
    }

    dataChanged(data: SaveConfirmData): void {
        if (!data.changed) {
            this.changesBeforeSaved = this.changesBeforeSaved.filter(value => value.name !== data.name);
        } else if (!this.changesBeforeSaved.some(value => value.name === data.name)) {
            this.changesBeforeSaved.push(data);
        }
    }

    async saveUnsavedSettings(): Promise<void> {
        this.changesBeforeSaved.forEach(async data => {
            switch (data.name) {
                case '2,4 Ghz':
                    await this.wifiCard1.save();
                    break;

                case '5 Ghz':
                    await this.wifiCard2.save();
                    break;

                case 'WLAN-Netzwerke':
                    await this.ssids.save();
                    break;

                case 'Physische Portkonfigurationen':
                    await this.portConfiguration.save();
                    break;
            }
        });
    }

    selectedTabChange(): void {
        this.graph1?.relayout();
        this.graph2?.relayout();
        this.graph3?.relayout();
        this.graph4?.relayout();
        this.graph5?.relayout();
        this.graph6?.relayout();
        this.graph7?.relayout();
        this.graph8?.relayout();
    }

    hasMoreOptions(devices: DBDevice[]) {
        const areAllDevicesSupported = devices.every(device => {
            return this.modelService.getModelForDevice(device).modelId !== 'pceapu';
        });
        return areAllDevicesSupported;
    }
}
