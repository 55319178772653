import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    templateUrl: 'qr-code-reader.component.html',
})
export class QrCodeReaderComponent implements AfterViewInit {
    @ViewChild('value') valueField: ElementRef;

    constructor(private dialogRef: MatDialogRef<QrCodeReaderComponent>) { }

    ngAfterViewInit() {
        this.valueField.nativeElement.addEventListener('focusout', () => {
            this.valueField.nativeElement.focus();
        });
        this.valueField.nativeElement.addEventListener('keyup', event => {
            if (event.key === 'Enter') {
                this.dialogRef.close(this.valueField.nativeElement.value);
            }
        });
    }
}
