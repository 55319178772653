import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApDeviceService } from '../../APService/apDevice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBDevice } from 'app/data/endooSpotDB_objects';
import { MessageDialogComponent } from 'app/Components/message-dialog-component/message-dialog.component';

@Component({
    templateUrl: './remove-device-dialog.component.html',
})
export class RemoveDeviceDialogComponent {
    form: FormGroup;
    device: DBDevice = new DBDevice();
    isDefect: boolean;
    email: string;
    defectMatter: string;

    constructor(
        private apService: ApDeviceService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<RemoveDeviceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
        this.device = data.device;

        this.form = this.fb.group({
            isDefect: [undefined, [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            defectMatter: ['', [Validators.required]]
        });
        this.form.get('isDefect').valueChanges.subscribe((value: boolean) => {
            if (value) {
                this.form.get('email').enable();
                this.form.get('defectMatter').enable();
            } else {
                this.form.get('email').setValue('');
                this.form.get('email').disable();
                this.form.get('defectMatter').setValue('');
                this.form.get('defectMatter').disable();
            }
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }

    async removeDevice(): Promise<void> {
        let message;
        try {
            await this.apService.removeDevice(this.device, this.isDefect, this.email, this.defectMatter).toPromise();
            if (this.isDefect) {
                message = 'Das Gerät wurde als defekt markiert';
            } else {
                message = 'Die Gerätezuordnung wurde entfernt';
            }
            if (this.isDefect) {
                this.dialog.open(MessageDialogComponent, {
                    data: {
                        title: 'Information',
                        message: 'Vielen Dank! Sie werden in Kürze über die angegebenene E-Mail Adresse kontaktiert.'
                    }
                });
            }
            this.dialogRef.close();
        } catch (err) {
            if (this.isDefect) {
                message = 'Das Gerät konnte nicht als defekt markiert werden: ' + err;
            } else {
                message = 'Das Gerätezuordnung konnte nicht entfernt werden: ' + err;
            }
        } finally {
            this.snackBar.open(message, 'OK');
        }
    }
}
