import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ShortTermLoginService } from '../ShortTermService/ShortTermLoginService';
import { AppService } from 'app/Services/app.service';
import { Login } from 'app/data/Login';

@Component({
    templateUrl: './ShortTermLoginDetail.dialog.component.html',
    styleUrls: ['./ShortTermLoginDetail.dialog.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ShortTermLoginDetailDialogComponent {
    username: string;
    password: string;
    form: FormGroup;

    constructor(
        private shortTermLoginService: ShortTermLoginService,
        private dialogRef: MatDialogRef<ShortTermLoginDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private snackBar: MatSnackBar,
        private fb: FormBuilder,
        public appService: AppService
    ) {
        const login = data.login as Login;
        this.username = login.username;
        this.password = login.password;

        const loginDatetime =
            login.expires_date.substring(6) + '-'
            + login.expires_date.substring(3, 5) + '-'
            + login.expires_date.substring(0, 2) + ' '
            + login.expires_time;
        this.form = this.fb.group({
            username: [login.username, [Validators.required]],
            password: [login.password, [Validators.required]],
            exprires_datetime: [moment(loginDatetime), [Validators.required]],
            wlan: [login.wlan]
        });
        this.form.get('exprires_datetime').valueChanges.subscribe((v: Moment) => {
            this.expiresChanged();
        });
        this.form.get('username').disable();
        this.form.get('password').disable();
    }

    async wlanChanged(): Promise<void> {
        await this.shortTermLoginService.changeWLAN(this.username, this.form.get('wlan').value).toPromise();
    }

    async expiresChanged(): Promise<void> {
        if (!this.form.get('exprires_datetime').errors) {
            const momentDatetime = this.form.get('exprires_datetime').value as Moment;
            const date = momentDatetime.format('DD-MM-yyyy');
            const time = momentDatetime.format('HH:mm');
            await this.shortTermLoginService.changeExpires(this.username, time, date).toPromise();
        }
    }

    /**
     * Function which will be called when User clicks on the cancel button of the new Group dialog. via angular. 
     */
    closeGroupDetailBtClick(): void {
        this.dialogRef.close();
    }

    async deleteShortTermLoginClick(): Promise<void> {
        const finished: boolean = await this.shortTermLoginService.delete(this.username).toPromise();
        if (finished) {
            this.snackBar.open('Kennung wurde gelöscht', 'OK', {
                duration: 2000,
            });
        } else {
            this.snackBar.open('Kennung konnte nicht gelöscht werden', 'OK', {
                duration: 2000,
            });
        }
        this.dialogRef.close();
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
