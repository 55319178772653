import { Component, ViewChild, Input, ElementRef, AfterViewInit } from '@angular/core';
import { Circle } from '../cirlce';
import { CanvasComponent, CONTENT_TYPE } from '../canvas.component';
import { Stroke } from '../stroke';
import { Text } from '../text';
import { SvgImage } from '../svg-image';

export type DevicesCount = {
    routers: {
        overall: number,
        online: number,
        error: number
    }, switches: {
        overall: number,
        online: number,
        error: number
    }, accessPoints: {
        overall: number,
        online: number,
        error: number
    }, userDevices: {
        overall: number
    }
};

@Component({
    selector: 'endoo-devices-graph',
    templateUrl: './devices-graph.component.html',
    styleUrls: ['devices-graph.component.scss'],
})
export class DevicesGraphComponent implements AfterViewInit {
    @ViewChild('container', { static: true }) private container: ElementRef;
    @ViewChild('graph', { static: true }) canvasRef: CanvasComponent;
    loading: boolean = true;
    devicesCount: DevicesCount;
    resizeEventListener: EventListener;

    ngAfterViewInit() {
        setTimeout(() => this.resize(), 100);
        this.resizeEventListener = () => {
            this.resize();
            this.DevicesCount = this.devicesCount;
        };
        addEventListener('resize', this.resizeEventListener);
    }

    ngOnDestroy() {
        removeEventListener('resize', this.resizeEventListener);
    }

    /**
     * Ändert die Größe des Canvas, so dass es passt.
     */
    private resize(): void {
        this.canvasRef.resize(this.getWidth(), this.getHeight());
    }

    /**
     * Gibt die Breite des Canvas-Containers zurück.
     */
    private getWidth(): number {
        return parseInt(getComputedStyle(this.container.nativeElement).width);
    }

    /**
     * Gibt die Breite des Canvas-Containers zurück.
     */
    private getHeight(): number {
        return 220;
    }

    @Input() set DevicesCount(devicesCount: DevicesCount) {
        if (devicesCount === undefined) {
            this.loading = true;
            return;
        }
        this.devicesCount = devicesCount;

        this.canvasRef.reset();

        // Breite der Elemente
        const cloudWidth = 174;
        const cloudHeight = 110;
        const circleRadius = 55//50;

        const circleColorGreen = '#006f3d';
        const circleColorOrange = '#f2a73e';
        const circleColorGrey = 'grey';

        const elementGap = 100; // Abstand für ein Element => schwarze Linien
        const textGap = 15; // Höhenunterschied: Text zum Element

        // Kann angepasst werden wenn Elemente hinzukommen etc.
        const numberOfCircles = 4;

        // Globale Startkoordinate zum Zeichnen
        const middleX = this.getWidth() / 2;
        const middleY = this.getHeight() / 2;

        const graphicWidth = cloudWidth + numberOfCircles * (elementGap + 2 * circleRadius);
        const startX = (this.getWidth() - graphicWidth) / 2;

        /**
         * Internet Wolke
         */
        const cloud = new SvgImage(startX, middleY - cloudHeight / 2, '/assets/Icons2.svg', { type: CONTENT_TYPE.TEXT, value: 'Internet', color: 'black' });
        this.canvasRef.addElement(cloud);

        /**
         * Routers
         */
        // Kreisfarbe bestimmen
        let circle1Color;
        if (devicesCount.routers.error > 0 || devicesCount.routers.online !== devicesCount.routers.overall) {
            circle1Color = circleColorOrange;
        } else if (devicesCount.routers.overall === 0) {
            circle1Color = circleColorGrey;
        } else {
            circle1Color = circleColorGreen;
        }
        // Kreis zeichnen
        const circle1 = new Circle(startX + cloudWidth + elementGap, middleY - circleRadius, circle1Color, 1, 'black', '16pt Trebuchet MS', { type: CONTENT_TYPE.ICON, value: 'storage', color: 'white' }, circleRadius);
        this.canvasRef.addElement(circle1);
        // Überscrift zeichnen
        const text1 = devicesCount.routers.overall === 1 ? ' Router' : ' Routers';
        const textCircle1Name = new Text(startX + cloudWidth + elementGap + circleRadius, middleY - circleRadius - textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.routers.overall + text1);
        this.canvasRef.addElement(textCircle1Name);
        // Online Text zeichnen
        if (devicesCount.routers.overall > 0) {
            const textCircle1Online = new Text(startX + cloudWidth + elementGap + circleRadius, middleY + circleRadius + textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.routers.online + ' online');
            this.canvasRef.addElement(textCircle1Online);
        }
        // Fehler Text zeichnen
        if (devicesCount.routers.error > 0) {
            const textCircle1Error = new Text(startX + cloudWidth + elementGap + circleRadius, middleY + circleRadius + 3 * textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.routers.error + ' Fehler');
            this.canvasRef.addElement(textCircle1Error);
        }
        // Strich zeichnen
        const stroke1 = new Stroke(startX + cloudWidth, middleY, startX + cloudWidth + elementGap, middleY, 1, 'black');
        this.canvasRef.addElement(stroke1);

        /**
         * Switches
         */
        // Kreisfarbe bestimmen
        let circle2Color;
        if (devicesCount.switches.error > 0 || devicesCount.switches.online !== devicesCount.switches.overall) {
            circle2Color = circleColorOrange;
        } else if (devicesCount.switches.overall === 0) {
            circle2Color = circleColorGrey;
        } else {
            circle2Color = circleColorGreen;
        }
        // Kreis zeichnen
        const circle2 = new Circle(startX + cloudWidth + elementGap + 1 * (elementGap + circleRadius * 2), middleY - circleRadius, circle2Color, 1, 'black', '16pt Trebuchet MS', { type: CONTENT_TYPE.ICON, value: 'smart_screen', color: 'white' }, circleRadius);
        this.canvasRef.addElement(circle2);
        // Überscrift zeichnen
        const text2 = devicesCount.switches.overall === 1 ? ' Switch' : ' Switches';
        const textCircle2Name = new Text(startX + cloudWidth + elementGap + 1 * (elementGap + circleRadius * 2) + circleRadius, middleY - circleRadius - textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.switches.overall + text2);
        this.canvasRef.addElement(textCircle2Name);
        // Online Text zeichnen
        if (devicesCount.switches.overall > 0) {
            const textCircle2Online = new Text(startX + cloudWidth + elementGap + 1 * (elementGap + circleRadius * 2) + circleRadius, middleY + circleRadius + textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.switches.online + ' online');
            this.canvasRef.addElement(textCircle2Online);
        }
        // Fehler Text zeichnen
        if (devicesCount.switches.error > 0) {
            const textCircle2Error = new Text(startX + cloudWidth + elementGap + 1 * (elementGap + circleRadius * 2) + circleRadius, middleY + circleRadius + 3 * textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.switches.error + ' Fehler');
            this.canvasRef.addElement(textCircle2Error);
        }
        // Strich zeichnen
        const stroke2 = new Stroke(startX + cloudWidth + elementGap + 2 * circleRadius, middleY, startX + cloudWidth + elementGap + 2 * circleRadius + elementGap, middleY, 1, 'black');
        this.canvasRef.addElement(stroke2);

        /**
         * Access Points
         */
        // Kreisfarbe bestimmen
        let circle3Color;
        if (devicesCount.accessPoints.error > 0 || devicesCount.accessPoints.online !== devicesCount.accessPoints.overall) {
            circle3Color = circleColorOrange;
        } else if (devicesCount.accessPoints.overall === 0) {
            circle3Color = circleColorGrey;
        } else {
            circle3Color = circleColorGreen;
        }
        // Kreis zeichnen
        const circle3 = new Circle(startX + cloudWidth + elementGap + 2 * (elementGap + circleRadius * 2), middleY - circleRadius, circle3Color, 1, 'black', '16pt Trebuchet MS', { type: CONTENT_TYPE.ICON, value: 'router', color: 'white' }, circleRadius);
        this.canvasRef.addElement(circle3);
        // Überscrift zeichnen
        const text3 = devicesCount.accessPoints.overall === 1 ? ' Access Point' : ' Access Points';
        const textCircle3Name = new Text(startX + cloudWidth + elementGap + 2 * (elementGap + circleRadius * 2) + circleRadius, middleY - circleRadius - textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.accessPoints.overall + text3);
        this.canvasRef.addElement(textCircle3Name);
        // Online Text zeichnen
        if (devicesCount.accessPoints.overall > 0) {
            const textCircle3Online = new Text(startX + cloudWidth + elementGap + 2 * (elementGap + circleRadius * 2) + circleRadius, middleY + circleRadius + textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.accessPoints.online + ' online');
            this.canvasRef.addElement(textCircle3Online);
        }
        // Fehler Text zeichnen
        if (devicesCount.accessPoints.error > 0) {
            const textCircle3Error = new Text(startX + cloudWidth + elementGap + 2 * (elementGap + circleRadius * 2) + circleRadius, middleY + circleRadius + 3 * textGap, 'black', 'center', 'middle', '16pt Trebuchet MS', devicesCount.accessPoints.error + ' Fehler');
            this.canvasRef.addElement(textCircle3Error);
        }
        // Strich zeichnen
        const stroke3 = new Stroke(startX + cloudWidth + elementGap + 2 * circleRadius + elementGap + 2 * circleRadius, middleY, startX + cloudWidth + elementGap + 2 * circleRadius + elementGap + 2 * circleRadius + elementGap, middleY, 1, 'black');
        this.canvasRef.addElement(stroke3);

        /**
         * Endgeräte
         */
        // Kreis zeichnen
        const circle4 = new Circle(startX + cloudWidth + elementGap + 3 * (elementGap + circleRadius * 2), middleY - circleRadius, circleColorGreen, 1, 'black', '16pt Trebuchet MS', { type: CONTENT_TYPE.ICON, value: 'devices', color: 'white' }, circleRadius);
        this.canvasRef.addElement(circle4);
        // Überscrift zeichnen
        const text4 = devicesCount.userDevices.overall === 1 ? ' Endgerät' : ' Endgeräte';
        const textCircle4Name = new Text(startX + cloudWidth + elementGap + 3 * (elementGap + circleRadius * 2) + circleRadius, middleY - circleRadius - textGap, 'black', 'center', 'middle', ' 16pt Trebuchet MS', devicesCount.userDevices.overall + text4);
        this.canvasRef.addElement(textCircle4Name);
        // Strich zeichnen
        const stroke4 = new Stroke(startX + cloudWidth + elementGap + 2 * circleRadius + elementGap + 2 * circleRadius + elementGap + 2 * circleRadius, middleY, startX + cloudWidth + elementGap + 2 * circleRadius + elementGap + 2 * circleRadius + elementGap + 2 * circleRadius + elementGap, middleY, 1, 'black');
        this.canvasRef.addElement(stroke4);

        this.canvasRef.draw().then(() => {
            this.loading = false;
        });
    }
}
