import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { LicenseKeyGenerationDialogComponent } from './LicenseKeyGenerationDialog/license-key-generation.dialog.component';
import { DemoAccessService } from './demo-access.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
    ],
    declarations: [
        LicenseKeyGenerationDialogComponent,
    ],
    providers: [
        DemoAccessService,
    ],
})
export class DemoAccessModule { }
