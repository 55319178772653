import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerCustomerComponent } from './registerCustomer.component';
import { RegisterService } from './customerService/register.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatStepperModule } from '@angular/material/stepper';
import { endooSpotManagementRegisterCustomerRoutingModule } from './endooSpotManagementRegisterCustomer-routing.module';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatStepperModule,
        MatSelectModule,
        MatOptionModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        endooSpotManagementRegisterCustomerRoutingModule,
    ],
    declarations: [
        registerCustomerComponent,
    ],
    providers: [
        RegisterService,
    ],
})
export class endooSpotManagementRegisterCustomerModule { }
