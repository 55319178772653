import { Component, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../Login/authentication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ModelService } from 'app/APManagement/APService/model.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './endooSpotAuth.component.html',
    styleUrls: ['./endooSpotAuth.component.css'],
})
export class endooSpotAuthComponent {
    userIsLoggedIn: boolean = true; // if user is not logged in the value will be set to false by can Activate Authguard because if it loads the login page the user will be logged out.

    constructor(
        private router: Router,
        private snackBar: MatSnackBar,
        private authenticationService: AuthenticationService,
        private modelService: ModelService
    ) {
        this.authenticationService.userIsLoggedIn.subscribe(login => {
            this.userIsLoggedIn = login.successful;
            if (this.userIsLoggedIn) {
                // login successful
                if (!this.authenticationService.loginDialogIsOpen && !login.newlyRegistered) {
                    this.router.navigate(['login/chooseCustomer']);
                }
            } else {
                // login failed
                this.snackBar.open('Benutzername oder Passwort nicht korrekt', 'OK');
            }
        }
        );
        this.authenticationService.sessionCustomerEmitter.subscribe(async selectedCustomer => {
            await this.modelService.init();
            if (!this.authenticationService.loginDialogIsOpen && !selectedCustomer.newlyRegistered) {
                this.router.navigate(['app']);
            }
        });
    }

    logout(event): void {
        this.authenticationService.logout();
        event.preventDefault();
    }
}
