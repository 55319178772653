import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortNumbers',
    pure: false,
})
export class SortNumbersPipe implements PipeTransform {

    transform(value: number[]): number[] {
        return value.sort((a, b) => a >= b ? 1 : -1);
    }
}
