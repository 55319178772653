import { Injectable } from '@angular/core';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { DBCustomerFunctionProperty, DBProperty } from '../../data/endooSpotDB_objects';
import { forkJoin, Observable, throwError } from 'rxjs';
import { map, mergeMap, mergeAll } from 'rxjs/operators';
import { CustomerFunctionPropertyService } from '../../endooSpotApplication/Services/CustomerFunctionPropertyService';

export type RemoveCustomerFunctionPropertyResponse = { successfull: boolean, connectedFunctionProperties: string[] };

@Injectable()
export class LogicalNetworkService {
    private logicalNetworkFunctionIDs: string[] = ['LogicalNetwork1', 'LogicalNetwork2', 'LogicalNetwork3', 'LogicalNetwork4',
        'LogicalNetwork5', 'LogicalNetwork6', 'LogicalNetwork7', 'LogicalNetwork8',
        'LogicalNetwork9', 'LogicalNetwork10', 'LogicalNetwork11', 'LogicalNetwork12',
        'LogicalNetwork13', 'LogicalNetwork14', 'LogicalNetwork15', 'LogicalNetwork16'];
    private maxNetworkID: number = 0;
    private numberOfNetworks: number = 0;
    private colors: string[] = [
        'crimson',
        'powderblue',
        'royalblue',
        'darksalmon',
        'mediumpurple',
        'gold',
        'darkkhaki',
        'midnightblue',
        'yellowgreen',
        'sienna',
        'orange',
        'tomato',
        'violet',
        'steelblue',
        'teal',
        'seagreen',
        'gray'
    ];
    get Colors() {
        return this.colors;
    }

    constructor(private cfpService: CustomerFunctionPropertyService) { }

    getLogicalNetworks(): Observable<LogicalNetwork[]> {
        return this.cfpService.getListCustomerFunctionProperties(this.logicalNetworkFunctionIDs).pipe(map(
            (result: DBCustomerFunctionProperty[][]) => {
                const logicalNetworks: LogicalNetwork[] = [];
                this.numberOfNetworks = 0;
                this.maxNetworkID = 0;
                for (let t = 0; t < result.length; t++) {
                    const networkFunctionProperties: DBCustomerFunctionProperty[] = result[t];
                    let logicalNetwork: LogicalNetwork = undefined;
                    if (networkFunctionProperties.length !== 0) {
                        logicalNetwork = new LogicalNetwork(
                            Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, this.logicalNetworkFunctionIDs[t], DBProperty.logical_network_id_property)), // network_id
                            this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, this.logicalNetworkFunctionIDs[t], DBProperty.logical_network_name_property), // name
                            this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, this.logicalNetworkFunctionIDs[t], DBProperty.logical_network_activated_property) === 'true', // activated
                            this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, this.logicalNetworkFunctionIDs[t], DBProperty.logical_network_color_property), // color
                            this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, this.logicalNetworkFunctionIDs[t], DBProperty.logical_network_untagged_property) === 'true', // untagged
                            Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, this.logicalNetworkFunctionIDs[t], DBProperty.logical_network_vlan_id_property))//  vlan_id
                        );
                        if (logicalNetwork.id_number > this.maxNetworkID) { // update max network id if there is an network with higher network_id
                            this.maxNetworkID = logicalNetwork.id_number;
                        }
                        this.numberOfNetworks = t + 1; // if network exists update number of networks to actual index t+1 (because index starts at 0)
                    }
                    if (logicalNetwork !== undefined) {
                        logicalNetworks[t] = logicalNetwork;
                    }
                }
                return logicalNetworks;
            }
        ));
    }

    /**
     * Function to create a new network in the system.
     * @param new_network Object which contains information about the new network that should be added
     */
    createLogicalNetwork(new_network: LogicalNetwork): Observable<void> {
        // Abort if max. count of logical networks reached
        if (this.numberOfNetworks >= this.logicalNetworkFunctionIDs.length) {
            return throwError('Maximale Anzahl an Netzwerken erreicht');
        }

        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[this.numberOfNetworks], DBProperty.logical_network_id_property, new_network.id_number.toString()); // network_id
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[this.numberOfNetworks], DBProperty.logical_network_name_property, new_network.name); // name
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[this.numberOfNetworks], DBProperty.logical_network_activated_property, String(new_network.activated)); // activated
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[this.numberOfNetworks], DBProperty.logical_network_color_property, new_network.color); // color
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[this.numberOfNetworks], DBProperty.logical_network_untagged_property, String(new_network.untagged)); // untagged
        let vlan_id = '';
        if (new_network.vlan_id !== undefined) {
            vlan_id = new_network.vlan_id.toString();
        }
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[this.numberOfNetworks], DBProperty.logical_network_vlan_id_property, vlan_id); // vlan_id
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Function to change a logical network in the system.
     * @param index index of the network (2 for second logical network)
     * @param new_network Object which contains information about the new network that should be added
     */
    saveLogicalNetwork(index: number, new_network: LogicalNetwork): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[index], DBProperty.logical_network_id_property, new_network.id_number.toString()); // network_id
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[index], DBProperty.logical_network_name_property, new_network.name); // name
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[index], DBProperty.logical_network_activated_property, String(new_network.activated)); // activated
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[index], DBProperty.logical_network_color_property, new_network.color); // color
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[index], DBProperty.logical_network_untagged_property, String(new_network.untagged)); // untagged
        let vlan_id = '';
        if (new_network.vlan_id !== undefined) {
            vlan_id = new_network.vlan_id.toString();
        }
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[index], DBProperty.logical_network_vlan_id_property, vlan_id); // vlan_id
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Function to delete a logical network from the the system.
     * @param network Object which contains information about the logical network that should be deleted
     */
    deleteLogicalNetwork(network: LogicalNetwork): Observable<RemoveCustomerFunctionPropertyResponse> {
        return this.getLogicalNetworks().pipe(mergeMap( // go through all logical network and after deleted network has been found all following networks will be stored one network lower
            (result: LogicalNetwork[]) => {
                let deleted: boolean = false;
                const setRequests: Observable<any>[] = [];
                let i = 0;
                for (let t = 0; t < result.length; t++) {
                    const response: LogicalNetwork = result[t];
                    if (deleted) { // if index is higher then the network that has been deleted, store all networks to position with index -1
                        setRequests[i] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[t - 1], DBProperty.logical_network_id_property, response.id_number.toString()); // network_id
                        setRequests[i + 1] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[t - 1], DBProperty.logical_network_name_property, response.name); // name
                        setRequests[i + 2] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[t - 1], DBProperty.logical_network_activated_property, String(response.activated)); // activated
                        setRequests[i + 3] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[t - 1], DBProperty.logical_network_color_property, response.color); // color
                        setRequests[i + 4] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[t - 1], DBProperty.logical_network_untagged_property, String(response.untagged)); // untagged
                        setRequests[i + 5] = this.cfpService.setCustomerFunctionProperty(this.logicalNetworkFunctionIDs[t - 1], DBProperty.logical_network_vlan_id_property, response.vlan_id.toString()); // vlan_id
                        i = i + 6;
                    }
                    if (response.id_number === network.id_number) {
                        deleted = true; // set deleted to true so that after that all networks will be stored one time lower
                    }
                }
                // delete the network with highest index.
                setRequests[i] = this.cfpService.removeCustomerFunctionProperty(this.logicalNetworkFunctionIDs[result.length - 1], DBProperty.logical_network_id_property); // network_id
                setRequests[i + 1] = this.cfpService.removeCustomerFunctionProperty(this.logicalNetworkFunctionIDs[result.length - 1], DBProperty.logical_network_name_property); // name
                setRequests[i + 2] = this.cfpService.removeCustomerFunctionProperty(this.logicalNetworkFunctionIDs[result.length - 1], DBProperty.logical_network_activated_property); // activated
                setRequests[i + 3] = this.cfpService.removeCustomerFunctionProperty(this.logicalNetworkFunctionIDs[result.length - 1], DBProperty.logical_network_color_property); // color
                setRequests[i + 4] = this.cfpService.removeCustomerFunctionProperty(this.logicalNetworkFunctionIDs[result.length - 1], DBProperty.logical_network_untagged_property); // untagged
                setRequests[i + 5] = this.cfpService.removeCustomerFunctionProperty(this.logicalNetworkFunctionIDs[result.length - 1], DBProperty.logical_network_vlan_id_property); // vlan_id
                return forkJoin(setRequests).pipe(map(v => {
                    v = v.filter(n => n !== null);
                    let successfull = v.every(n => n.successfull === true);
                    let connectedFunctionProperties = [];
                    v.forEach(n => {
                        connectedFunctionProperties.push(...n.connectedFunctionProperties);
                    });
                    connectedFunctionProperties = [...new Set(connectedFunctionProperties)];
                    return {
                        successfull,
                        connectedFunctionProperties
                    };
                }));
            }));
    }

    public getMaxNetworkID() {
        return this.maxNetworkID;
    }

    /**
     * Indicates, if an untagged network already exists.
     */
    async hasUntaggedNetwork(): Promise<boolean> {
        return await this.getUntaggedNetwork() !== undefined;
    }

    /**
     * Finds the logical network with property untagged equals true, otherwise undefined.
     */
    async getUntaggedNetwork(): Promise<LogicalNetwork> {
        const networks = await this.getLogicalNetworks().toPromise();
        return networks.find(logicalNetwork => {
            return logicalNetwork.untagged;
        });
    }

    /**
     * Checks whether a given VLAN-ID is available in the logical network.
     */
    async isVlanIdFree(logicalNetwork: LogicalNetwork): Promise<boolean> {
        logicalNetwork.vlan_id = Number.parseInt(logicalNetwork.vlan_id.toString()); // workaround to make valid integers from falsy user inputs
        const networks = await this.getLogicalNetworks().toPromise();
        return !networks.some(network => logicalNetwork.id_number !== network.id_number && network.vlan_id === logicalNetwork.vlan_id);
    }

    /**
     * Determine all colors that are not being used yet by any of the networks. These are usable for new networks or network color changes.
     * @param networks
     */
    getUsedNetworkColors(networks: LogicalNetwork[]): string[] {
        return networks.map(network => {
            return network.color;
        });
    }

    async networkNameExists(logicalNetworkName: string): Promise<boolean> {
        return (await this.getLogicalNetworks().toPromise()).some(logicalNetwork => logicalNetwork.name === logicalNetworkName);
    }
}
