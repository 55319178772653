<mat-toolbar>
    <span class="user-select-none">Geräteverwaltung</span>
    <div *ngIf="background_working" id="reloading"></div>
    <span class="example-spacer"></span>
    <ng-container *ngIf="appService.isAppScreen()">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item *ngIf="authenticationService.isEndooAdmin()" (click)="newApBtClick()">Neues Ger&auml;t
                erstellen</a>
            <a mat-menu-item id="newApDeviceButton"
                *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-ENDOOSPOT')"
                (click)="addApBtClick()">Neues Ger&auml;t hinzuf&uuml;gen</a>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="!appService.isAppScreen()">
        <button *ngIf="authenticationService.isEndooAdmin()" mat-raised-button id="newApDeviceButton"
            (click)="newApBtClick()">Neues Ger&auml;t
            erstellen</button>
        <button
            *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-ENDOOSPOT')"
            mat-raised-button (click)="addApBtClick()" style="margin-left: 10px;">Neues Ger&auml;t
            hinzuf&uuml;gen</button>
    </ng-container>
</mat-toolbar>
<nav mat-tab-nav-bar>
    <a mat-tab-link [routerLink]="'/app/apList'" active=true>
        Ger&auml;te&uuml;bersicht
    </a>
    <a *ngIf="authenticationService.hasPermission('HARDWARE-ENDOOSPOT')" mat-tab-link [routerLink]="'/app/apGlobal'">
        Globale AP Einstellungen
    </a>
    <a *ngIf="authenticationService.hasPermission('HARDWARE-PORTCONFIG')" mat-tab-link [routerLink]="'/app/switchGlobal'">
        Globale Switch Einstellungen
    </a>
    <a *ngIf="authenticationService.hasPermission('ENDOO-PREVIEW')" mat-tab-link [routerLink]="'/app/routerGlobal'">
        Globale Router Einstellungen
    </a>
</nav>
<div class="content-container" id="APList_div">
    <mat-card>
        <div class="table-div">
            <mat-form-field>
                <mat-label>In Tabelle suchen</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
            </mat-form-field>
            <button mat-raised-button [disabled]="!getMultipleEditButtonState()"
                (click)="selectForMultipleEdit()">Mehrfachbearbeitung</button>
            <mat-table (matSortChange)="sortDataChanged($event)" matSortActive="device.inventoryNumber"
                [dataSource]="devicesTable" class="table" id="DevicesTable" matSort>
                <ng-container matColumnDef="selection">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="masterToggle($event)"
                            [disabled]="!getMultipleEditButtonState()" [checked]="isAllSelected()"
                            [indeterminate]="isAtLeaseOneSelected() && !isAllSelected()">
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let devicesTableEntry">
                        <mat-checkbox (click)="$event.stopPropagation()" (change)="toggleSelection(devicesTableEntry)"
                            [checked]="selection.isSelected(devicesTableEntry)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.image">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectDevice(devicesTableEntry.device)"
                        *matCellDef="let devicesTableEntry">
                        <ng-container>
                            <img style="width: 32px; height: auto; user-select: none;"
                                [src]="devicesTableEntry.imageLink$ | async" draggable="false" />
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.status">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectDevice(devicesTableEntry.device)"
                        *matCellDef="let devicesTableEntry">
                        <!-- Show grey icon if user is offline -->
                        <ng-container *ngIf="!loggedIn">
                            <div style="width: 28px; height: 28px; border-radius: 50%; background-color: grey;"
                                matTooltip="Anmeldung erforderlich" matTooltipPosition="above">
                            </div>
                        </ng-container>
                        <ng-container *ngIf="loggedIn">
                            <!-- Show green dot if device is online and up to date -->
                            <ng-container
                                *ngIf="deviceService.getDeviceStatusSymbol(devicesTableEntry.device, devicesTableEntry.vpn_last_contact_time1, devicesTableEntry.vpn_last_contact_time2) === 'ok'">
                                <div style="width: 28px; height: 28px; border-radius: 50%; background-color: green;"
                                    matTooltip="Gerät ist online" matTooltipPosition="above">
                                </div>
                            </ng-container>
                            <!-- Show red dot if device is offline (last contact time is above 10 min) -->
                            <ng-container
                                *ngIf="deviceService.getDeviceStatusSymbol(devicesTableEntry.device, devicesTableEntry.vpn_last_contact_time1, devicesTableEntry.vpn_last_contact_time2) === 'offline'">
                                <div style="width: 28px; height: 28px; border-radius: 50%; background-color: red;"
                                    matTooltip="Gerät ist offline" matTooltipPosition="above">
                                </div>
                            </ng-container>
                            <!-- Show spinner icon if device is updating itself currently -->
                            <ng-container
                                *ngIf="deviceService.getDeviceStatusSymbol(devicesTableEntry.device, devicesTableEntry.vpn_last_contact_time1, devicesTableEntry.vpn_last_contact_time2) === 'loading'">
                                <div [matTooltip]="devicesTableEntry.device.status === 'UPDATING' ? 'Gerät wird aktualisiert' : ''"
                                    matTooltipPosition="above" style="width: 24px; height: 24px;" class="loader">
                                </div>
                            </ng-container>
                            <!-- Show green question icon if the online status is unknown (last contact time of device is 5-10 min away) -->
                            <ng-container
                                *ngIf="deviceService.getDeviceStatusSymbol(devicesTableEntry.device, devicesTableEntry.vpn_last_contact_time1, devicesTableEntry.vpn_last_contact_time2) === 'online_unknown'">
                                <span matTooltip="Gerätestatus wird erkannt" matTooltipPosition="above"
                                    style="user-select: none; font-size: 31px; color: green;" class="material-icons">
                                    help
                                </span>
                            </ng-container>
                            <!-- Show yellow warning icon if device is online and has an error -->
                            <ng-container
                                *ngIf="deviceService.getDeviceStatusSymbol(devicesTableEntry.device, devicesTableEntry.vpn_last_contact_time1, devicesTableEntry.vpn_last_contact_time2) === 'error'">
                                <span matTooltip="Gerät im Fehlerzustand" matTooltipPosition="above"
                                    style="user-select: none; font-size: 28px; color: orange;" class="material-icons">
                                    warning
                                </span>
                            </ng-container>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.inventoryNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Inventarnummer
                    </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectDevice(devicesTableEntry.device)"
                        style="text-transform: uppercase;" *matCellDef="let devicesTableEntry">
                        {{devicesTableEntry.device.inventoryNumber}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.lastContactIp">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>IP-Adresse</mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectDevice(devicesTableEntry.device)"
                        *matCellDef="let devicesTableEntry">
                        {{devicesTableEntry.device.lastContactIp}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.mac">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>MAC-Adresse</mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectDevice(devicesTableEntry.device)"
                        *matCellDef="let devicesTableEntry">
                        {{devicesTableEntry.device.mac}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.location">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Installierter Ort</mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectDevice(devicesTableEntry.device)"
                        *matCellDef="let devicesTableEntry">
                        {{devicesTableEntry.device.location}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="device.wifi">
                    <mat-header-cell *matHeaderCellDef>WLAN-Status</mat-header-cell>
                    <mat-cell *matCellDef="let devicesTableEntry">
                        <mat-checkbox [indeterminate]="determineWifiStatus(devicesTableEntry.device) > 0"
                            *ngIf="isDeviceModelAP(devicesTableEntry.device)"
                            (change)="wifiChanged($event, devicesTableEntry.device)"
                            [checked]="deviceService.getDeviceFunctionPropertyValue(devicesTableEntry.device.deviceFunctionProperties, 'WifiCard1', 'wifi_card_enabled', 'true') === 'true' && deviceService.getDeviceFunctionPropertyValue(devicesTableEntry.device.deviceFunctionProperties, 'WifiCard2', 'wifi_card_enabled', 'true') === 'true'">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let devicesTableEntry; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100, 200]"
                [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
    </mat-card>
</div>