import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { UmlautConverter } from '../../data/UmlautConverter';
import { ImportUser } from '../../data/ImportUser';
import { Translate } from '../../data/Translate';
import { UserService } from '../UserService/user.service';

@Component({
    templateUrl: './importUser.dialog.component.html',
    styleUrls: ['./importUser.dialog.component.css'],
})
export class importUserDialogComponent implements AfterViewInit {
    @ViewChild('importstepper', { static: true }) stepper: MatStepper;
    @ViewChild('csvfileBt', { static: true }) fileBt: MatButton;
    @ViewChild('fileInput') fileInput: ElementRef;
    form1: FormGroup;
    file: File;
    contract_link: string;
    import_running: boolean = false;
    wlan: boolean = false; // should the imported group get WLAN permission
    fileLoaded: boolean = false;
    users_import = [];
    value_table = [];
    group: string = '';
    columns = []; // which column is used for which data
    column_options = []; // which data columns are available
    password_link: string;
    showFileUploadError: boolean = false;

    constructor(
        private userService: UserService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<importUserDialogComponent>,
        private snackBar: MatSnackBar
    ) {
        this.form1 = this.fb.group({
            file: ['', [Validators.required]],
            columns: [3, [Validators.required, Validators.min(3), Validators.max(9)]],
            userGroup: ['', [Validators.required]],
            leavingYear: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]],
            wlan: [false]
        });
        this.form1.get('leavingYear').disable();
        this.form1.get('userGroup').valueChanges.subscribe(value => {
            if (value === 'pupil') {
                this.form1.get('leavingYear').enable();
            } else {
                this.form1.get('leavingYear').disable();
            }
        });
    }

    ngAfterViewInit(): void {
        this.fileInput.nativeElement.addEventListener('change', (event: any) => {
            if (event.target.value.length > 0) {
                this.showFileUploadError = false;
            }
        });
        this.fileBt._elementRef.nativeElement.addEventListener('click', (event: any) => {
            this.fileInput.nativeElement.click();
            if (this.fileInput.nativeElement.files.length === 0) {
                setTimeout(() => {
                    this.showFileUploadError = true;
                }, 1000);
            }
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form1.get(formControl).hasError(error);
    }

    resetStepper(stepper): void {
        stepper.reset();
        this.contract_link = '';
    }

    onFileChanged(event): void {
        if (event.target.files[0] !== undefined) {
            this.file = event.target.files[0];
        }
    }

    closeDialogBtClick(): void {
        this.dialogRef.close();
    }

    get number_columns(): any {
        return this.form1.get('columns').value;
    }

    get leavingyear(): any {
        return this.form1.get('leavingYear').value;
    }

    /**
     * Function for loading the initializing the table and calling the File Reader to load a file
     */
    loadUserFileBt(): void {
        if (this.form1.invalid) {
            return;
        }

        // Testen ob eine Datei ausgewählt wurde und gegebenenfalls importieren
        if (this.fileInput.nativeElement.files.item(0) && typeof FileReader !== undefined) {
            const reader = new FileReader();

            this.column_options[0] = 'givenname';
            this.column_options[1] = 'surname';
            if (this.form1.get('userGroup').value === 'pupil') {
                // create column options for pupils import
                this.column_options[2] = 'birthday';
                for (let i = 3; i < this.number_columns; i++) { // create extra columns for the groups which should be imported
                    const gi = i - 2;
                    this.column_options[i] = 'group' + gi;
                }
            } else if (this.form1.get('userGroup').value === 'teacher') {
                this.column_options[2] = 'short';
                for (let i = 3; i < this.number_columns; i++) { // create extra columns for the groups which should be imported
                    const gi = i - 2;
                    this.column_options[i] = 'group' + gi;
                }
            }
            // load file into the reader
            reader.onload = (e) => {
                this.stepper.selected.completed = true; // set actual step as completed so that next step can be opened
                this.stepper.next(); // Go over to the next step
                this.loadFile(e);
            };
            reader.readAsText(this.fileInput.nativeElement.files.item(0));
        }
    }

    /**
     * Function which is called when FileReader has loaded a file.
     */
    loadFile(e): void {
        // reset the data from old file and read the data coming in from the new file
        this.fileLoaded = false;
        this.value_table = [];
        const text = e.target.result;
        const lines = text.split(/[\r\n|\n]+/); // split the lines

        lines.forEach((value) => {
            if (value.includes('\uFFFD')) { // check if there is a problem with file encoding
                this.snackBar.open('Beim Laden der Datei ist es in einigen Zeilen zu Problemen gekommen. Bitte stellen Sie sicher, dass Sie UTF als Dateicodierung nutzen!', 'OK');
                return;
            }
            const splittet = value.split(/[\t]+/); // bei tab trennen
            if (splittet.length >= 3 && splittet[0] !== '') { // there are always three mandatory values (givenname, surname and birthday) or (givenname, surname and short)
                const r = this.value_table.length;
                this.value_table[r] = []; // init a new array at the end of the table
                for (let i = 0; i < Math.min(this.number_columns, splittet.length); i++) {
                    this.value_table[r][i] = splittet[i];
                }
            }
        });
        if (this.value_table.length >= 1) {
            this.fileLoaded = true;
        }
    }

    // function for importing the users (is triggered when importBt is clicked)
    async importUsersBtClick(): Promise<void> {
        let checkedFields = false;
        if (this.form1.get('userGroup').value === 'pupil') {
            // check if there is every field only one time selected
            checkedFields = (this.indexCount('givenname', this.columns) !== 1) || (this.indexCount('surname', this.columns) !== 1) || (this.indexCount('birthday', this.columns) !== 1);
        } else {
            checkedFields = (this.indexCount('givenname', this.columns) !== 1) || (this.indexCount('surname', this.columns) !== 1) || (this.indexCount('short', this.columns) !== 1);
        }
        if (checkedFields) {
            this.snackBar.open('Zum Importieren von Benutzern wird mindestens Vor- und Nachname sowie bei Lehrern ein Kürzel und bei Schülern der Geburtstag benötigt. Außerdem darf kein Wert doppelt verwendet werden!', 'OK');
            return;
        } else {
            for (let i = 0; i < this.value_table.length; i++) {
                const new_user: ImportUser = new ImportUser(); // create user object from every line in table
                new_user.givenname = UmlautConverter.replaceUmlaute(this.value_table[i][this.columns.indexOf('givenname')]).replace(/[^a-zA-Z ]/g, '');
                new_user.surname = UmlautConverter.replaceUmlaute(this.value_table[i][this.columns.indexOf('surname')]).replace(/[^a-zA-Z ]/g, '');
                if (this.form1.get('userGroup').value === 'pupil') {
                    const birthday: string = this.value_table[i][this.columns.indexOf('birthday')].replace(/[^0-9.]/g, '');
                    const splittet: string[] = birthday.split('.');
                    new_user.birthyear = splittet[2];
                } else if (this.form1.get('userGroup').value === 'teacher') {
                    new_user.username = UmlautConverter.replaceUmlaute(this.value_table[i][this.columns.indexOf('short')]).replace(/[^a-zA-Z0-9]/g, '');
                }
                for (let gi = 0; gi < this.value_table[i].length - 3; gi++) { // only get as much groups as there are available in the current line
                    new_user.groups[gi] = UmlautConverter.replaceUmlaute(this.value_table[i][this.columns.indexOf('group' + (gi + 1))]).replace(/[^a-zA-Z0-9]/g, '');
                }
                this.users_import[i] = new_user;
            }
        }
        this.import_running = true;
        this.stepper.selected.completed = true; // set actual step as completed so that next step can be opened
        this.stepper.next(); // got to contract step
        const wlan = this.form1.get('wlan').value;
        if (this.form1.get('userGroup').value === 'pupil') {
            try {
                const wlan = this.form1.get('wlan').value;
                const result = await this.userService.importPupils(this.users_import, this.leavingyear, wlan).toPromise(); // call userservice to import users into database
                this.userBatchImportFinished(result);
            } catch (err) {
                this.userBatchImportFinished({ finished: false }, err);
            }
        } else if (this.form1.get('userGroup').value === 'teacher') {
            try {
                const result = await this.userService.importTeachers(this.users_import, wlan).toPromise();
                this.userBatchImportFinished(result);
            } catch (err) {
                this.userBatchImportFinished({ finished: false }, err);
            }
        }
    }

    // Function which is called when the users are imported (ajax.success)
    userBatchImportFinished(data, msg?): void {
        this.import_running = false;
        if (!window.navigator || !window.navigator.msSaveOrOpenBlob) {
            this.contract_link = data.filename;
            this.password_link = data.password_link;
        }
        if (!data.finished) {
            this.snackBar.open('Es konnten ggfs. nicht alle Benutzer ordnungsgemäß angelegt werden: ' + msg, 'OK');
        }
    }

    germanTranslation(text: string): void {
        return Translate.german[text];
    }

    private indexCount(element: Object, array): number {
        let i = 0;
        let position = array.indexOf(element);
        while (position !== -1) {
            i++;
            position = array.indexOf(element, position + 1);
        }
        return i;
    }
}
