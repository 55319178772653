import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { endooSpotManagementAuthModule } from '../Login/endooSpotManagementAuth.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AdminService } from './AdminService/admin.service';
import { deleteAdminDialogComponent } from './AdminList/DeleteAdminDialogComponent';
import { AdminDetailDialogComponent } from './AdminList/AdminDetailDialog.component';
import { adminListComponent } from './AdminList/AdminList.component';
import { newAdminDialogComponent } from './NewAdmin/newAdmin.dialog.component';
import { MatMenuModule } from '@angular/material/menu';
import { addAdminToCustomerDialogComponent } from './AddAdminToCustomer/AddAdminToCustomer.dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatCardModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatToolbarModule,
        MatExpansionModule,
        MatMenuModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        endooSpotManagementAuthModule,
    ],
    declarations: [
        adminListComponent,
        deleteAdminDialogComponent,
        AdminDetailDialogComponent,
        newAdminDialogComponent,
        addAdminToCustomerDialogComponent
    ],
    providers: [
        AdminService
    ],
    exports: [adminListComponent]
})
export class endooSpotAdminManagementModule { }
