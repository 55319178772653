import { Component, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { NewLogicalNetworkDialogComponent } from '../NewLogicalNetwork/newLogicalNetwork.dialog.component';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { AuthenticationService } from '../../Login/authentication.service';
import { AppService } from 'app/Services/app.service';

@Component({
    templateUrl: './LogicalNetworkManagement.component.html',
})
export class LogicalNetworkManagementComponent implements AfterViewInit {
    networks: LogicalNetwork[];
    usedColors: string[];

    constructor(
        public authenticationService: AuthenticationService,
        private networkService: LogicalNetworkService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        public appService: AppService
    ) { }

    ngAfterViewInit() {
        this.getNetworks();
    }

    async getNetworks(): Promise<void> {
        // Get the wifisettings and set the scope variable so that the view will work.
        try {
            this.networks = await this.networkService.getLogicalNetworks().toPromise();
            this.usedColors = this.networkService.getUsedNetworkColors(this.networks);

            // If customer has no untagged network, let him create one first
            if (this.authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')) {
                const untaggedNetwork = await this.networkService.getUntaggedNetwork();
                if (untaggedNetwork === undefined) {
                    const dialogRef = this.dialog.open(NewLogicalNetworkDialogComponent, {
                        disableClose: true,
                        data: {
                            action: 'VisitSite'
                        },
                    });
                    dialogRef.afterClosed().subscribe(async created => {
                        if (created) {
                            await this.getNetworks();
                        }
                    });
                }
            }
        } catch (err) {
            this.snackBar.open('Schulnetzwerke konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    /**
     * Function to open a new Network Dialog
     */
    newNetworkBtClick(): void {
        const dialogRef = this.dialog.open(NewLogicalNetworkDialogComponent);
        dialogRef.afterClosed().subscribe(result => {
            this.getNetworks();
        });
    }

    async networkChanged(): Promise<void> {
        await this.getNetworks();
    }
}
