<!-- Alert -->
<mat-card class="info-alert">
    <p style="text-align: justify;">
        Die Darstellung der Ports wurde geändert um die neue Funktion "Link-Geschwindigkeit" besser anzuzeigen.
        Sie finden die Änderungen indem Sie auf das Fragezeichen Symbol unten rechts klicken.
    </p>
</mat-card>
<!-- Toolbar -->
<div id="custom-toolbar">
    <div>
        <mat-form-field id="choose-port-div">
            <mat-label>Portauswahl</mat-label>
            <mat-select matInput [(ngModel)]="SelectedPort" [disabled]="selectionApplyMode">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let port of portsList" [value]="port-1" [disabled]="port === 1">
                    Port {{port}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div id="profile-application-button-div" *ngIf="Devices !== undefined && Devices.length === 1">
            <button mat-raised-button [disabled]="SelectedPort === undefined"
                (click)="applyProfiles()">{{determineProfileApplicationButtonText()}}</button>
        </div>
    </div>
    <div *ngIf="selectionApplyMode" id="profile-application-div">
        {{SelectedPort+1}}
        <mat-icon id="profile-application-icon">east</mat-icon>
        {{(portsSelectedForApplication | sortNumbers).toString().replaceAll(',', ', ')}}
    </div>
    <div>
        <mat-icon id="help-button" (click)="showHelp()">help</mat-icon>
    </div>
</div>

<div id="switch-ports-row-container">
    <div *ngFor="let lineArray of portArrangement; let lineIndex = index" class="switch-ports-row">
        <div *ngFor="let groupArray of lineArray" class="port-group-container">
            <div *ngFor="let portIndex of groupArray">
                <!-- FWAN DIV -->
                <div style="position: relative; width: 0; height: 0">
                    <div *ngIf="apDeviceService.isDeviceRouter(devices[0]) && fwanPorts.includes(portIndex + 1)"
                        style="position: absolute; top: 26px; left: 6px;">
                        <mat-icon class="fwaninfo-button" style="color: white; font-size: 14pt;"
                            (click)="showFwanInfo()">
                            info
                        </mat-icon>
                    </div>
                </div>
                <div [style.visibility]="portIndex === -1 ? 'hidden' : 'initial'"
                    [class]="(!apDeviceService.isDeviceRouter(devices[0]) && portIndex === 0) || (apDeviceService.isDeviceRouter(devices[0]) && fwanPorts.includes(portIndex + 1)) ? 'disabled-port-container' : 'port-container'"
                    [style.backgroundColor]="determinePortBackgroundColor(portIndex)"
                    (mousedown)="handlePortClick(portIndex)">
                    <!-- Port number DIV -->
                    <div *ngIf="lineIndex === 0" class="port-number-div">
                        {{portIndex+1}}
                    </div>
                    <!-- Port DIV -->
                    <div class="port-div" [style.backgroundColor]="determinePortUntaggedNetworkColor(portIndex)">
                        <div *ngIf="lineIndex === 0">
                            <!-- Large light in upper ports -->
                            <ng-container>
                                <div *ngIf="switchInformation !== undefined"
                                    [style.backgroundColor]="getSwitchLinkSpeedColor(portIndex)"
                                    class="div upper-poe-indicator-div">
                                </div>
                            </ng-container>
                            <div class="untagged-indicator-div"
                                [style.height]="switchInformation === undefined ? '40px' : '30px'">
                                <div *ngIf="apDeviceService.isDeviceRouter(devices[0]) && fwanPorts.includes(portIndex + 1)"
                                    style="color: white; user-select: none;">
                                    W0
                                </div>
                                <!-- Color dots in upper ports -->
                                <div
                                    *ngFor="let taggedNetworkColor of getTaggedNetworkColors(portIndex); let i = index">
                                    <div *ngIf="i < 4"
                                        [class.network-name-circle-symbol]="getTaggedNetworkColors(portIndex).length > 12 ? i < 2 : i < 3"
                                        [style.backgroundColor]="i < 3 ? taggedNetworkColor : 'transparent'">
                                        <span *ngIf="i === 3">
                                            {{'+' + (getTaggedNetworkColors(portIndex).length - 3)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Left and right port lights in upper ports -->
                            <div style="display: flex; justify-content: space-between; margin-top: auto;" class="div">
                                <!-- Left light: Test if extra settings available => not multiple edit mode -->
                                <div [style.backgroundColor]="!isPoeReady(portIndex) ? 'transparent' : isPoeEnabled(portIndex) ? 'greenyellow' : 'darkgrey'"
                                    class="small-indicator-div"
                                    [class.under-left-connected-div]="isPoeReady(portIndex)"></div>
                                <!-- Right light: Test if extra settings available => not multiple edit mode -->
                                <ng-container *ngIf="switchInformation !== undefined">
                                    <div [style.backgroundColor]="switchInformation.uplinkPort === (portIndex + 1) ? 'greenyellow' : 'darkgrey'"
                                        class="small-indicator-div under-right-connected-div"></div>
                                </ng-container>
                            </div>
                        </div>
                        <div *ngIf="lineIndex === 1">
                            <!-- Left and right port lights in lower ports -->
                            <div style="display: flex; justify-content: space-between;" class="div">
                                <!-- Left light: Test if extra settings available => not multiple edit mode -->
                                <div [style.backgroundColor]="!isPoeReady(portIndex) ? 'transparent' : isPoeEnabled(portIndex) ? 'greenyellow' : 'darkgrey'"
                                    class="small-indicator-div"
                                    [class.upper-left-connected-div]="isPoeReady(portIndex)"></div>
                                <!-- Right light: Test if extra settings available => not multiple edit mode -->
                                <ng-container *ngIf="switchInformation !== undefined">
                                    <div [style.backgroundColor]="switchInformation.uplinkPort === (portIndex + 1) ? 'greenyellow' : 'darkgrey'"
                                        class="small-indicator-div upper-right-connected-div"></div>
                                </ng-container>
                            </div>
                            <div [style.backgroundColor]="determinePortUntaggedNetworkColor(portIndex)"
                                class="untagged-indicator-div"
                                [style.height]="switchInformation === undefined ? '40px' : '30px'">
                                <!-- Color dots in lower ports -->
                                <div
                                    *ngFor="let taggedNetworkColor of getTaggedNetworkColors(portIndex); let i = index">
                                    <div *ngIf="i < 4"
                                        [class.network-name-circle-symbol]="getTaggedNetworkColors(portIndex).length > 12 ? i < 2 : i < 3"
                                        [style.backgroundColor]="i < 3 ? taggedNetworkColor : 'transparent'">
                                        <span class="port-number-div" *ngIf="i === 3">
                                            {{'+' + (getTaggedNetworkColors(portIndex).length - 3)}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <!-- Large light in lower ports -->
                            <ng-container>
                                <div *ngIf="switchInformation !== undefined"
                                    [style.backgroundColor]="getSwitchLinkSpeedColor(portIndex)"
                                    class="div under-poe-indicator-div">
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <div *ngIf="lineIndex === 1" class="port-number-div">
                        {{portIndex+1}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Port configuration form -->
<div *ngIf="SelectedPort !== undefined" id="port-configuration-div">
    <mat-card id="port-configuration-mat-card">
        <p>Details - Port {{SelectedPort+1}}</p>
        <mat-divider></mat-divider>
        <div class="form-div">
            <form [formGroup]="form">
                <div>
                    <h3>{{form.get('port_label').value && form.get('port_label').value.trim() ?
                        form.get('port_label').value : 'Port ' + (SelectedPort+1)}}
                    </h3>
                </div>
                <div style="margin-bottom: 20px;">
                    <mat-form-field appearance="outline">
                        <mat-label>Port-Bezeichnung</mat-label>
                        <input formControlName="port_label" matInput (change)="saved(false)" />
                    </mat-form-field>
                </div>
                <div *ngIf="apDeviceService.isDeviceSwitch(Devices[0]) || apDeviceService.isDeviceRouter(Devices[0])"
                    style="margin-bottom: 25px;">
                    <mat-form-field appearance="outline">
                        <mat-label>Kommentar</mat-label>
                        <textarea matInput rows="5" formControlName="port_comments"></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <section class="toggle-section">
                        <mat-slide-toggle formControlName="mgmt_port" (change)="saved(false)">{{
                            apDeviceService.isDeviceSwitch(Devices[0]) ?
                            'Management Port' : 'Standardeinstellungen' }}
                        </mat-slide-toggle>
                    </section>
                </div>
                <div *ngIf="!form.get('mgmt_port').value">
                    <div style="margin-top: 20px;">
                        <mat-form-field appearance="outline">
                            <mat-label>Untagged Netzwerk</mat-label>
                            <mat-select matInput formControlName="port_untagged_network_id"
                                (selectionChange)="selectedUntaggedNetworkChanged($event.value); saved(false)">
                                <mat-option *ngFor="let network of logicalNetworks" [value]="network.id_number">
                                    <div class="network-name-circle-symbol" [style.backgroundColor]="network.color">
                                    </div>
                                    {{network.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <p>Tagged Netzwerke</p>
                    </div>
                    <div>
                        <mat-selection-list formControlName="port_tagged_network_ids"
                            (selectionChange)="selectedTaggedNetworksChanged($event.option); saved(false)">
                            <div *ngFor="let network of logicalNetworks">
                                <mat-list-option
                                    *ngIf="network.id_number !== form.get('port_untagged_network_id').value"
                                    [value]="network.id_number">
                                    <div class="network-name-circle-symbol" [style.backgroundColor]="network.color">
                                    </div>
                                    {{network.name}}
                                </mat-list-option>
                            </div>
                        </mat-selection-list>
                    </div>
                    <div *ngIf="isPoeReady(SelectedPort)" style="margin-top: 20px;">
                        <mat-slide-toggle formControlName="port_poe_enabled">PoE aktiv</mat-slide-toggle>
                    </div>
                </div>
                <div style="margin-top: 20px;">
                    <button mat-raised-button color="primary" (click)="savePortConfiguration()">Speichern</button>
                </div>
            </form>
        </div>
    </mat-card>
</div>

<!-- Shows when clicking on the help icon -->
<ng-template #exampleImage>
    <img src="../../../../assets/img/port-configuration-example.png"><br><br>
</ng-template>

<!-- Shows when clicking on the info icon on router port 1 -->
<ng-template #fwanInfo>
    W0 steht für Default WAN und die Einstellungen können unter Globale Router Einstellungen in der
    Geräteverwaltung
    geändert werden. W0 ist immer als DHCP-Client konfiguriert<br><br><br>
</ng-template>