<endoo-times-control [times]="timesControlData" (onChange)="timeSettingsChanged($event)"></endoo-times-control>
<mat-divider></mat-divider>
<mat-list>
	<mat-list-item>
		<div mat-line>
			<button mat-raised-button color="primary" title="Speichern" (click)="saveWifiNetworkBtClick()">
				Speichern
			</button>
		</div>
	</mat-list-item>
</mat-list>