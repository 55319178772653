import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { UserDeviceServiceRouter } from '../UserDeviceServiceRouter/userDevicesRouter.service';
import { UserDevice_new } from 'app/data/UserDevice_new';
import { NestedMatTableDataSource } from './NestedMatTableDataSource';
import { AppService } from 'app/Services/app.service';

@Component({
    templateUrl: './UserDeviceListRouter.component.html',
    styleUrls: ['./UserDeviceListRouter.component.css'],
})
export class userDeviceListRouterComponent implements OnInit {
    displayedColumns: string[] = ['Lease.ClientName', 'Lease.IP', 'Lease.Mac', 'Lease.start', 'filtered']; // columns in frontend which should be displayed
    userDevices: NestedMatTableDataSource<UserDevice_new>;
    background_working: boolean = true;
    // view elements from frontend
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * @param userDeviceService Service which will be injected by angular
     * @param snackBar snackbar object which will be injected by angular
     */
    constructor(
        private userDeviceService: UserDeviceServiceRouter,
        private snackBar: MatSnackBar,
        public appService: AppService
    ) {
        this.userDevices = new NestedMatTableDataSource();
        this.userDevices.sort = this.sort;
        this.userDevices.paginator = this.paginator;
    }

    ngOnInit() {
        this.getUserDevList();
    }

    /**
     * Function to retrieve list of user Devices via Angular Service.
     */
    async getUserDevList(): Promise<void> {
        this.background_working = true;
        try {
            const userDevices = await this.userDeviceService.getUserDevices().toPromise();
            // reload table sort and pagination
            this.userDevicesLoaded(userDevices);
            if (this.userDevices.data.length === 0) {
                this.snackBar.open('Es wurden keine Geräte gefunden', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Die Geräte konnten nicht geladen werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    private userDevicesLoaded(userDevices: UserDevice_new[]) {
        this.userDevices.data = userDevices;
        // reload table sort and pagination
        this.userDevices.sort = this.sort;
        this.userDevices.paginator = this.paginator;
        this.userDevices.filterPredicate = (data, filter) => JSON.stringify(data).includes(filter);
        this.preparePaginator();
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('userDeviceListRouter.pageSize')) {
            this.userDevices.paginator.pageSize = Number(localStorage.getItem('userDeviceListRouter.pageSize'));
            this.userDevices.paginator = this.paginator; // Update the paginator
        }
        this.userDevices.paginator._changePageSize = pageSize => {
            localStorage.setItem('userDeviceListRouter.pageSize', String(pageSize));
            this.userDevices.paginator.pageSize = pageSize;
            this.userDevices.paginator = this.paginator; // Update the paginator
        };
    }

    /**
     * Function which will be called when table sort has been changed by the user. Because then pagination should be set to 1
     * @param event
     */
    sortDataChanged(event): void {
        if (this.userDevices.paginator !== undefined) {
            this.userDevices.paginator.pageIndex = 0;
        }
    }

    /**
     * Function which will be called from frontend when the filter of a userdevice should be changed
     * @param userDevice The user-Device the filter should be switched for
     */
    async switchFilter(userDevice: UserDevice_new): Promise<void> {
        // read information from the checkbox and send then the change command to the server
        const loginmask1 = !userDevice.LoginMask;
        this.background_working = true;
        try {
            await this.userDeviceService.setFilter(userDevice, loginmask1).toPromise();
        } catch (err) {
            if (loginmask1) {
                this.snackBar.open('Das Filter konnte nicht gelöscht werden: ' + err, 'OK');
            } else {
                this.snackBar.open('Das Filter konnte nicht erstellt werden: ' + err, 'OK');
            }
        }
        this.getUserDevList();
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.userDevices.filter = filterValue.trim().toLowerCase();
    }
}
