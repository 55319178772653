import { Component, Input, OnInit } from '@angular/core';
import { RouterSettingsService } from '../RouterSettingsService/RouterSettings.service';
import { RouterNetwork } from 'app/data/RouterNetwork';
import { LogicalNetwork } from 'app/data/LogicalNetwork';
import { NewRouterNetworkDialogComponent } from '../RouterNetworkDialogs/NewRouterNetworkDialog/newRouterNetwork.dialog.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ipAddress } from 'app/endooSpotApplication/Validators/ipAddress';
import { DeleteRouterNetworkDialogComponent } from '../RouterNetworkDialogs/DeleteRouterNetworkDialog/RouterNetworkDialogs.component';
import { CaptivePortal } from 'app/data/CaptivePortal';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { subnetmaskAddress } from 'app/endooSpotApplication/Validators/subnetmaskAddress';
import { merge } from 'rxjs';
import { CustomerFunctionPropertyService } from 'app/endooSpotApplication/Services/CustomerFunctionPropertyService';

@Component({
    selector: 'RouterNetworkSettings',
    templateUrl: './RouterNetwork.component.html',
    styleUrls: ['./RouterNetwork.component.css'],
})
export class RouterNetworkComponent implements OnInit {
    @Input('logicalNetwork') logicalNetwork: LogicalNetwork;
    routerNetworkID: number;
    routerNetworkTypeOnLoad: string;
    routerNetworkCaptivePortalID: number;
    background_working: boolean;
    editRouterNetworkForm: FormGroup;
    showCustomConfigInfo: boolean;

    constructor(
        private routerNetworkService: RouterSettingsService,
        private fb: FormBuilder,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private cfpService: CustomerFunctionPropertyService
    ) {
        this.editRouterNetworkForm = this.fb.group({
            network_type: ['', Validators.required],
            network_ip_type: ['', Validators.required],
            interface_ip: ['', [Validators.required, ipAddress()]],
            network_subnet: ['', [Validators.required, subnetmaskAddress()]],
            gateway: [false],
            gateway_ip: ['', [Validators.required, ipAddress()]],
            preferred_uplink_network: [false], // TODO with backend
            dhcp_server_enabled: [false],
            dhcp_server_start_address: ['', [Validators.required, ipAddress()]],
            dhcp_server_end_address: ['', [Validators.required, ipAddress()]],
            captive_portal_enabled: [false],
            captive_portal_max_client_up_speed: ['', [Validators.required]],
            captive_portal_max_client_down_speed: ['', [Validators.required]],
            captive_portal_endoo_auth: [''],
            captive_portal_radius_ip: ['', [Validators.required, ipAddress()]],
            captive_portal_radius_port: ['', [Validators.required]],
            captive_portal_radius_secret: ['', [Validators.required]],
            filtering: [false],
            media_access: [false],
            inet_access_allowed: [true],
            allow_dns: [true],
            allow_web: [true],
            allow_email: [true],
            allow_push: [true]
        });

        this.registrateFormListeners();
    }

    registrateFormListeners() {
        // enable/disable fields by dependence of chosen settings
        this.editRouterNetworkForm.get('network_type').valueChanges.subscribe(value => {
            // reset settings
            this.resetForm(value);
            this.editRouterNetworkForm.get('captive_portal_enabled').setValue(false); // disable cp text fields
            this.editRouterNetworkForm.get('inet_access_allowed').setValue(true);

            switch (value) {
                case 'wan':
                    this.editRouterNetworkForm.get('network_ip_type').setValue('static');
                    this.editRouterNetworkForm.get('interface_ip').setValue('10.0.0.1');
                    this.editRouterNetworkForm.get('network_subnet').setValue('255.255.0.0');
                    break;

                case 'management':
                    this.editRouterNetworkForm.get('network_ip_type').setValue('static');
                    this.editRouterNetworkForm.get('interface_ip').setValue('10.50.0.1');
                    this.editRouterNetworkForm.get('network_subnet').setValue('255.255.0.0');
                    this.editRouterNetworkForm.get('gateway').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_enabled').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_start_address').setValue('10.50.1.100');
                    this.editRouterNetworkForm.get('dhcp_server_end_address').setValue('10.50.254.250');
                    break;

                case 'byod':
                    this.editRouterNetworkForm.get('network_ip_type').setValue('static');
                    this.editRouterNetworkForm.get('interface_ip').setValue('10.100.0.1');
                    this.editRouterNetworkForm.get('network_subnet').setValue('255.255.0.0');
                    this.editRouterNetworkForm.get('gateway').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_enabled').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_start_address').setValue('10.100.1.100');
                    this.editRouterNetworkForm.get('dhcp_server_end_address').setValue('10.100.254.250');
                    this.editRouterNetworkForm.get('filtering').setValue(true);
                    this.editRouterNetworkForm.get('captive_portal_enabled').setValue(true);
                    this.editRouterNetworkForm.get('captive_portal_max_client_up_speed').setValue('20');
                    this.editRouterNetworkForm.get('captive_portal_max_client_down_speed').setValue('20');
                    break;

                case 'media':
                    this.editRouterNetworkForm.get('network_ip_type').setValue('static');
                    this.editRouterNetworkForm.get('interface_ip').setValue('10.150.0.1');
                    this.editRouterNetworkForm.get('network_subnet').setValue('255.255.0.0');
                    this.editRouterNetworkForm.get('gateway').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_enabled').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_start_address').setValue('10.150.1.100');
                    this.editRouterNetworkForm.get('dhcp_server_end_address').setValue('10.150.254.250');
                    break;

                case 'paed':
                    this.editRouterNetworkForm.get('network_ip_type').setValue('dhcp');
                    this.editRouterNetworkForm.get('filtering').setValue(true);
                    this.editRouterNetworkForm.get('media_access').setValue(true);
                    break;

                case 'custom':
                    this.editRouterNetworkForm.get('network_ip_type').setValue('static');
                    this.editRouterNetworkForm.get('interface_ip').setValue('10.250.0.1');
                    this.editRouterNetworkForm.get('network_subnet').setValue('255.255.0.0');
                    this.editRouterNetworkForm.get('gateway').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_enabled').setValue(true);
                    this.editRouterNetworkForm.get('dhcp_server_start_address').setValue('10.250.1.100');
                    this.editRouterNetworkForm.get('dhcp_server_end_address').setValue('10.250.254.250');
                    this.editRouterNetworkForm.get('media_access').setValue(false);
                    break;
            }
        });
        this.editRouterNetworkForm.get('network_ip_type').valueChanges.subscribe(value => {
            if (value === 'static') {
                this.editRouterNetworkForm.get('interface_ip').enable();
                this.editRouterNetworkForm.get('network_subnet').enable();
                this.editRouterNetworkForm.get('gateway').enable();
                this.editRouterNetworkForm.get('gateway_ip').enable();
                this.editRouterNetworkForm.get('dhcp_server_enabled').enable();
            } else {
                this.editRouterNetworkForm.get('interface_ip').setValue('');
                this.editRouterNetworkForm.get('interface_ip').disable();
                this.editRouterNetworkForm.get('network_subnet').setValue('');
                this.editRouterNetworkForm.get('network_subnet').disable();
                this.editRouterNetworkForm.get('gateway').setValue(false);
                this.editRouterNetworkForm.get('gateway').disable();
                this.editRouterNetworkForm.get('gateway_ip').setValue('');
                this.editRouterNetworkForm.get('gateway_ip').disable();
                this.editRouterNetworkForm.get('dhcp_server_enabled').setValue(false);
                this.editRouterNetworkForm.get('dhcp_server_enabled').disable();
            }
        });
        this.editRouterNetworkForm.get('gateway').valueChanges.subscribe(value => {
            if (value) {
                this.editRouterNetworkForm.get('gateway_ip').setValue('');
                this.editRouterNetworkForm.get('gateway_ip').disable();
            } else {
                this.editRouterNetworkForm.get('gateway_ip').enable();
            }
        });
        this.editRouterNetworkForm.get('dhcp_server_enabled').valueChanges.subscribe(value => {
            if (value) {
                this.editRouterNetworkForm.get('dhcp_server_start_address').enable();
                this.editRouterNetworkForm.get('dhcp_server_end_address').enable();
                if (this.editRouterNetworkForm.get('network_type').value === 'management') {
                    this.editRouterNetworkForm.get('filtering').setValue(false);
                    this.editRouterNetworkForm.get('media_access').setValue(false);
                }
            } else {
                this.editRouterNetworkForm.get('dhcp_server_start_address').setValue('');
                this.editRouterNetworkForm.get('dhcp_server_start_address').disable();
                this.editRouterNetworkForm.get('dhcp_server_end_address').setValue('');
                this.editRouterNetworkForm.get('dhcp_server_end_address').disable();
            }
        });
        this.editRouterNetworkForm.get('captive_portal_enabled').valueChanges.subscribe(value => {
            if (value) {
                this.editRouterNetworkForm.get('captive_portal_max_client_up_speed').enable();
                this.editRouterNetworkForm.get('captive_portal_max_client_down_speed').enable();
                this.editRouterNetworkForm.get('captive_portal_endoo_auth').enable();
                this.editRouterNetworkForm.get('captive_portal_endoo_auth').setValue('endoo-users');
            } else {
                this.editRouterNetworkForm.get('captive_portal_max_client_up_speed').setValue('');
                this.editRouterNetworkForm.get('captive_portal_max_client_up_speed').disable();
                this.editRouterNetworkForm.get('captive_portal_max_client_down_speed').setValue('');
                this.editRouterNetworkForm.get('captive_portal_max_client_down_speed').disable();
                this.editRouterNetworkForm.get('captive_portal_endoo_auth').setValue('');
                this.editRouterNetworkForm.get('captive_portal_endoo_auth').disable();
            }
        });
        this.editRouterNetworkForm.get('captive_portal_endoo_auth').valueChanges.subscribe(value => {
            switch (value) {
                case '':
                case 'endoo-users':
                case 'endoo-guest':
                    this.editRouterNetworkForm.get('captive_portal_radius_ip').setValue('');
                    this.editRouterNetworkForm.get('captive_portal_radius_ip').disable();
                    this.editRouterNetworkForm.get('captive_portal_radius_port').setValue('');
                    this.editRouterNetworkForm.get('captive_portal_radius_port').disable();
                    this.editRouterNetworkForm.get('captive_portal_radius_secret').setValue('');
                    this.editRouterNetworkForm.get('captive_portal_radius_secret').disable();
                    break;

                case 'custom':
                    this.editRouterNetworkForm.get('captive_portal_radius_ip').enable();
                    this.editRouterNetworkForm.get('captive_portal_radius_port').enable();
                    this.editRouterNetworkForm.get('captive_portal_radius_secret').enable();
                    break;
            }
        });
        this.editRouterNetworkForm.get('inet_access_allowed').valueChanges.subscribe(value => {
            this.editRouterNetworkForm.get('allow_dns').setValue(true);
            this.editRouterNetworkForm.get('allow_web').setValue(true);
            this.editRouterNetworkForm.get('allow_email').setValue(true);
            this.editRouterNetworkForm.get('allow_push').setValue(true);
        });
        this.editRouterNetworkForm.get('filtering').valueChanges.subscribe(value => {
            if (value) {
                this.editRouterNetworkForm.get('allow_dns').setValue(true);
                this.editRouterNetworkForm.get('allow_web').setValue(true);
            }
        });
        // Make IP checks on every valueChanges event
        merge(
            this.editRouterNetworkForm.get('interface_ip').valueChanges,
            this.editRouterNetworkForm.get('network_subnet').valueChanges,
            this.editRouterNetworkForm.get('gateway_ip').valueChanges,
            this.editRouterNetworkForm.get('dhcp_server_end_address').valueChanges,
            this.editRouterNetworkForm.get('dhcp_server_start_address').valueChanges
        ).subscribe(() => {
            this.ipChecks();
        });
    }

    resetForm(networkType: string) {
        this.editRouterNetworkForm.reset({}, { emitEvent: false });
        this.editRouterNetworkForm.get('network_type').setValue(networkType, { emitEvent: false }); // re-set the network type
    }

    ipChecks() {
        // Remove old IP check hints for page1
        ['interface_ip', 'gateway_ip',].forEach(controlName => {
            const errors = { ...this.editRouterNetworkForm.get(controlName).errors };
            delete errors.notInSameSubnet;
            delete errors.ipIsNetworkAddress;
            delete errors.ipIsBroadcastAddress;
            this.editRouterNetworkForm.get(controlName).setErrors(Object.keys(errors).length ? errors : null);
        });
        // Remove old IP check hints for page2
        ['dhcp_server_start_address', 'dhcp_server_end_address'].forEach(controlName => {
            const err = { ...this.editRouterNetworkForm.get(controlName).errors };
            delete err.dhcpRangeInvalid;
            this.editRouterNetworkForm.get(controlName).setErrors(Object.keys(err).length ? err : null);
        });

        // If subnet is invalid we can't do any IP checks
        if (this.editRouterNetworkForm.get('network_subnet').invalid) {
            return;
        }

        // Get network and broadcast address for Router ip
        let networkIpRouter;
        let broadcastIpRouter;
        if (this.editRouterNetworkForm.get('interface_ip').valid) {
            networkIpRouter = this.getNetworkIp(this.editRouterNetworkForm.get('interface_ip').value, this.editRouterNetworkForm.get('network_subnet').value);
            broadcastIpRouter = this.getBroadcastIp(networkIpRouter, this.editRouterNetworkForm.get('network_subnet').value);
        }

        // Get network and broadcast address for Gateway ip
        let networkIpGateway;
        let broadcastIpGateway;
        if (this.editRouterNetworkForm.get('gateway_ip').valid) {
            networkIpGateway = this.getNetworkIp(this.editRouterNetworkForm.get('gateway_ip').value, this.editRouterNetworkForm.get('network_subnet').value);
            broadcastIpGateway = this.getBroadcastIp(networkIpGateway, this.editRouterNetworkForm.get('network_subnet').value);
        }

        // Error: Not in same subnet
        if (networkIpRouter !== undefined && networkIpGateway !== undefined && networkIpRouter !== networkIpGateway) {
            // Router ip
            let errors = { ...this.editRouterNetworkForm.get('interface_ip').errors, notInSameSubnet: true };
            this.editRouterNetworkForm.get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);

            // Gateway ip
            errors = { ...this.editRouterNetworkForm.get('gateway_ip').errors, notInSameSubnet: true };
            this.editRouterNetworkForm.get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: Router IP is Network IP
        if (this.editRouterNetworkForm.get('interface_ip').value === networkIpRouter) {
            const errors = { ...this.editRouterNetworkForm.get('interface_ip').errors, ipIsNetworkAddress: true };
            this.editRouterNetworkForm.get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);
        }
        // Error: Router IP is Broadcast IP
        if (this.editRouterNetworkForm.get('interface_ip').value === broadcastIpRouter) {
            const errors = { ...this.editRouterNetworkForm.get('interface_ip').errors, ipIsBroadcastAddress: true };
            this.editRouterNetworkForm.get('interface_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: Gateway IP is Network IP
        if (this.editRouterNetworkForm.get('gateway_ip').value === networkIpGateway) {
            const errors = { ...this.editRouterNetworkForm.get('gateway_ip').errors, ipIsNetworkAddress: true };
            this.editRouterNetworkForm.get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: Gateway IP is Broadcast IP
        if (this.editRouterNetworkForm.get('gateway_ip').value === broadcastIpGateway) {
            const errors = { ...this.editRouterNetworkForm.get('gateway_ip').errors, ipIsBroadcastAddress: true };
            this.editRouterNetworkForm.get('gateway_ip').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: DHCP-Start IP is Network IP
        if (this.editRouterNetworkForm.get('dhcp_server_start_address').value === networkIpRouter) {
            const errors = { ...this.editRouterNetworkForm.get('dhcp_server_start_address').errors, ipIsNetworkAddress: true };
            this.editRouterNetworkForm.get('dhcp_server_start_address').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: DHCP-Start IP is Broadcast IP
        if (this.editRouterNetworkForm.get('dhcp_server_start_address').value === broadcastIpRouter) {
            const errors = { ...this.editRouterNetworkForm.get('dhcp_server_start_address').errors, ipIsBroadcastAddress: true };
            this.editRouterNetworkForm.get('dhcp_server_start_address').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: DHCP-End IP is Network IP
        if (this.editRouterNetworkForm.get('dhcp_server_end_address').value === networkIpRouter) {
            const errors = { ...this.editRouterNetworkForm.get('dhcp_server_end_address').errors, ipIsNetworkAddress: true };
            this.editRouterNetworkForm.get('dhcp_server_end_address').setErrors(Object.keys(errors).length ? errors : null);
        }

        // Error: DHCP-End IP is Broadcast IP
        if (this.editRouterNetworkForm.get('dhcp_server_end_address').value === broadcastIpRouter) {
            const errors = { ...this.editRouterNetworkForm.get('dhcp_server_end_address').errors, ipIsBroadcastAddress: true };
            this.editRouterNetworkForm.get('dhcp_server_end_address').setErrors(Object.keys(errors).length ? errors : null);
        }

        // DHCP range check on page 2
        if (this.editRouterNetworkForm.get('dhcp_server_start_address').valid && this.editRouterNetworkForm.get('dhcp_server_end_address').valid) {
            if (!this.isInRange(this.editRouterNetworkForm.get('interface_ip').value, this.editRouterNetworkForm.get('network_subnet').value, this.editRouterNetworkForm.get('dhcp_server_start_address').value) || !this.isInRange(this.editRouterNetworkForm.get('interface_ip').value, this.editRouterNetworkForm.get('network_subnet').value, this.editRouterNetworkForm.get('dhcp_server_end_address').value)) {
                // Set range error for "dhcp_server_start_address"
                let errors = { ...this.editRouterNetworkForm.get('dhcp_server_start_address').errors, dhcpRangeInvalid: true };
                this.editRouterNetworkForm.get('dhcp_server_start_address').setErrors(Object.keys(errors).length ? errors : null);

                // Set range error for "dhcp_server_end_address"
                errors = { ...this.editRouterNetworkForm.get('dhcp_server_end_address').errors, dhcpRangeInvalid: true };
                this.editRouterNetworkForm.get('dhcp_server_end_address').setErrors(Object.keys(errors).length ? errors : null);
            }
        }
    }

    isInRange(inet_ip: string, subnetmask: string, ip: string) {
        const network_ip_as_number = parseInt(this.getIpAsBinary(this.getNetworkIp(inet_ip, subnetmask)), 2);
        const broadcast_ip_as_number = parseInt(this.getIpAsBinary(this.getBroadcastIp(inet_ip, subnetmask)), 2);
        const check_ip_as_number = parseInt(this.getIpAsBinary(ip), 2);
        return check_ip_as_number > network_ip_as_number && check_ip_as_number < broadcast_ip_as_number;
    }

    getNetworkIp(hostIp: string, subnetmask: string) {
        const ipNumbers = hostIp.split('.').map(v => Number(v));
        const subnetmaskNumbers = subnetmask.split('.').map(v => Number(v));
        const networkIpNumbers = ipNumbers.map((v, i) => v & subnetmaskNumbers[i]);
        return networkIpNumbers.join('.');
    }

    getBroadcastIp(networkIp: string, subnetmask: string) {
        // Find host part index
        const split_index_network_part = this.getIpAsBinary(subnetmask).indexOf('0');
        // Get network part as binary
        let bNetworkPart = this.getIpAsBinary(networkIp).substring(0, split_index_network_part);
        // Fill right part with ones to get broadcast ip numbers
        while (bNetworkPart.length < 32) {
            bNetworkPart = bNetworkPart + '1';
        }
        // return broadcast ip address
        return parseInt(bNetworkPart.substring(0, 8), 2)
            + '.' + parseInt(bNetworkPart.substring(8, 16), 2)
            + '.' + parseInt(bNetworkPart.substring(16, 24), 2)
            + '.' + parseInt(bNetworkPart.substring(24, 32), 2);
    }

    getIpAsBinary(ipAddress: string) {
        return ipAddress.split('.').map(v => Number(v).toString(2)).map(v => {
            while (v.length < 8) {
                v = '0' + v;
            }
            return v;
        }).join('');
    }

    async ngOnInit() {
        this.background_working = true;
        try {
            await this.getRouterNetwork();
            // See if custom_config property is set to show information on the RouterNetworks
            const cfpsRouterSettings = await this.cfpService.getCustomerFunctionProperties('ROUTER_SETTINGS').toPromise();
            const cfp = cfpsRouterSettings.find(cfp => cfp.function === 'ROUTER_SETTINGS' && cfp.property === 'custom_config');
            this.showCustomConfigInfo = cfp && cfp.value === 'true';
        } catch (err) {
            this.snackBar.open('Die endooConnect Einstellungen konnten nicht geladen werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    async getRouterNetwork(): Promise<void> {
        const routerNetwork = await this.routerNetworkService.getRouterNetworkByLogicalNetwork(this.logicalNetwork.id_number.toString()).toPromise();
        if (!routerNetwork) {
            this.routerNetworkID = undefined;
            this.routerNetworkCaptivePortalID = undefined;
            return;
        }
        this.routerNetworkTypeOnLoad = routerNetwork.network_type;
        this.routerNetworkID = routerNetwork.id_number;
        if (routerNetwork.captive_portal_id) {
            this.routerNetworkCaptivePortalID = routerNetwork.captive_portal_id;
        }
        const captivePortal = await this.routerNetworkService.getCaptivePortalById(routerNetwork.captive_portal_id).toPromise();
        this.fillForm(routerNetwork, captivePortal);
    }

    fillForm(routerNetwork: RouterNetwork, captivePortal: CaptivePortal): void {
        this.editRouterNetworkForm.patchValue({
            network_type: routerNetwork.network_type,
            network_ip_type: routerNetwork.network_ip_type,
            interface_ip: routerNetwork.interface_ip,
            network_subnet: routerNetwork.network_subnet,
            gateway: routerNetwork.gateway,
            gateway_ip: routerNetwork.gateway_ip,
            preferred_uplink_network: routerNetwork.preferred_uplink_network, // TODO backend property
            dhcp_server_enabled: routerNetwork.dhcp_server_enabled,
            dhcp_server_start_address: routerNetwork.dhcp_server_start_address,
            dhcp_server_end_address: routerNetwork.dhcp_server_end_address,
            captive_portal_enabled: captivePortal?.enabled,
            captive_portal_max_client_up_speed: captivePortal?.max_client_up_speed,
            captive_portal_max_client_down_speed: captivePortal?.max_client_down_speed,
            captive_portal_endoo_auth: captivePortal?.endoo_auth,
            captive_portal_radius_ip: captivePortal?.radius_ip,
            captive_portal_radius_port: captivePortal?.radius_port,
            captive_portal_radius_secret: captivePortal?.radius_secret,
            filtering: routerNetwork.filtering,
            media_access: routerNetwork.media_access,
            inet_access_allowed: routerNetwork.inet_access_allowed,
            allow_dns: routerNetwork.allow_dns,
            allow_web: routerNetwork.allow_web,
            allow_email: routerNetwork.allow_email,
            allow_push: routerNetwork.allow_push
        });
    }

    async saveRouterNetwork(): Promise<void> {
        if (this.editRouterNetworkForm.invalid) {
            return;
        }

        try {
            this.background_working = true;
            const routerNetwork = this.makeRouterNetworkObject();
            const captivePortal = this.makeCaptivePortalObject();
            if (this.editRouterNetworkForm.get('gateway').value && this.editRouterNetworkForm.get('network_type').value !== 'management') {
                if (this.routerNetworkCaptivePortalID) {
                    await this.routerNetworkService.editCaptivePortal(captivePortal).toPromise();
                } else {
                    captivePortal.id_number = await this.routerNetworkService.getMaxCaptivePortalId() + 1;
                    routerNetwork.captive_portal_id = captivePortal.id_number;
                    await this.routerNetworkService.createCaptivePortal(captivePortal).toPromise();
                    this.routerNetworkCaptivePortalID = captivePortal.id_number;
                }
            } else if (this.routerNetworkCaptivePortalID) {
                await this.routerNetworkService.deleteCaptivePortal(captivePortal).toPromise();
                routerNetwork.captive_portal_id = undefined;
            }
            if (this.editRouterNetworkForm.get('network_type').value !== this.routerNetworkTypeOnLoad) {
                await this.routerNetworkService.resetRouterNetwork(routerNetwork).toPromise();
            }
            await this.routerNetworkService.editRouterNetwork(routerNetwork).toPromise();
            this.snackBar.open('Die endooConnect Einstellungen wurden gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Die endooConnect Einstellungen konnten nicht gespeichert werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    /**
     * Function which will be called when the user uses the "Add RouterNetwork Button".
     */
    async addRouterNetworkClick(): Promise<void> {
        this.background_working = true;
        const dialogRef = this.dialog.open(NewRouterNetworkDialogComponent, {
            data: this.logicalNetwork,
        });
        // refresh data after the dialog has been closed
        await dialogRef.afterClosed().toPromise();
        try {
            await this.getRouterNetwork();
        } catch (err) {
            this.snackBar.open('Die endooConnect Einstellungen konnten nicht geladen werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    /**
     * Function which will be called when the user uses the "Remove RouterNetwork Button".
     */
    async removeRouterNetworkClick(): Promise<void> {
        this.background_working = true;
        const dialogRef = this.dialog.open(DeleteRouterNetworkDialogComponent, {
            data: { routerNetwork: this.makeRouterNetworkObject(), captivePortal: this.routerNetworkCaptivePortalID ? this.makeCaptivePortalObject() : null },
        });
        // refresh network information after the dialog has been closed
        await dialogRef.afterClosed().toPromise();
        try {
            await this.getRouterNetwork();
        } catch (err) {
            this.snackBar.open('Die endooConnect Einstellungen konnten nicht geladen werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    makeRouterNetworkObject(): RouterNetwork {
        return new RouterNetwork(
            this.routerNetworkID,
            this.logicalNetwork.id_number,
            this.editRouterNetworkForm.get('network_ip_type').value,
            this.editRouterNetworkForm.get('interface_ip').value,
            this.editRouterNetworkForm.get('network_subnet').value,
            this.editRouterNetworkForm.get('gateway').value,
            this.editRouterNetworkForm.get('gateway_ip').value,
            this.editRouterNetworkForm.get('dhcp_server_enabled').value,
            this.editRouterNetworkForm.get('dhcp_server_start_address').value,
            this.editRouterNetworkForm.get('dhcp_server_end_address').value,
            this.editRouterNetworkForm.get('filtering').value,
            this.routerNetworkCaptivePortalID,
            this.editRouterNetworkForm.get('network_type').value,
            this.editRouterNetworkForm.get('media_access').value,
            this.editRouterNetworkForm.get('preferred_uplink_network').value,
            this.editRouterNetworkForm.get('inet_access_allowed').value,
            this.editRouterNetworkForm.get('allow_dns').value,
            this.editRouterNetworkForm.get('allow_web').value,
            this.editRouterNetworkForm.get('allow_email').value,
            this.editRouterNetworkForm.get('allow_push').value
        );
    }

    makeCaptivePortalObject(): CaptivePortal {
        return new CaptivePortal(
            this.routerNetworkCaptivePortalID,
            this.editRouterNetworkForm.get('captive_portal_enabled').value,
            this.editRouterNetworkForm.get('captive_portal_max_client_up_speed').value,
            this.editRouterNetworkForm.get('captive_portal_max_client_down_speed').value,
            this.editRouterNetworkForm.get('captive_portal_endoo_auth').value,
            this.editRouterNetworkForm.get('captive_portal_radius_ip').value,
            this.editRouterNetworkForm.get('captive_portal_radius_port').value,
            this.editRouterNetworkForm.get('captive_portal_radius_secret').value
        );
    }
}
