import { Injectable } from '@angular/core';
import { DBCustomerFunctionProperty, DBProperty } from '../../data/endooSpotDB_objects';
import { of, forkJoin, Observable, throwError, from } from 'rxjs';
import { CustomerFunctionPropertyService } from '../../endooSpotApplication/Services/CustomerFunctionPropertyService';
import { RouterNetwork } from 'app/data/RouterNetwork';
import { map, mergeAll, mergeMap } from 'rxjs/operators';
import { CaptivePortal } from 'app/data/CaptivePortal';

@Injectable()
export class RouterSettingsService {
    private maxNetworkID: number = undefined;
    private maxCaptivePortalID: number = undefined;
    private numberOfNetworks: number = undefined;
    private numberOfCaptivePortals: number = 0;
    private routerNetworkFunctionIDs: string[] = ['RouterNetwork1', 'RouterNetwork2', 'RouterNetwork3', 'RouterNetwork4', 'RouterNetwork5', 'RouterNetwork6', 'RouterNetwork7', 'RouterNetwork8', 'RouterNetwork9', 'RouterNetwork10', 'RouterNetwork11', 'RouterNetwork12', 'RouterNetwork13', 'RouterNetwork14', 'RouterNetwork15', 'RouterNetwork16'];
    private captivePortalFunctionIDs: string[] = ['CaptivePortal1', 'CaptivePortal2', 'CaptivePortal3', 'CaptivePortal4', 'CaptivePortal5', 'CaptivePortal6', 'CaptivePortal7', 'CaptivePortal8', 'CaptivePortal9', 'CaptivePortal10', 'CaptivePortal11', 'CaptivePortal12', 'CaptivePortal13', 'CaptivePortal14', 'CaptivePortal15', 'CaptivePortal16'];

    constructor(private cfpService: CustomerFunctionPropertyService) { }

    async getNumberOfNetworks(): Promise<number> {
        await this.getRouterNetworks().toPromise();
        return this.numberOfNetworks;
    }

    async getNumberOfCaptivePortals(): Promise<number> {
        await this.getCaptivePortals().toPromise();
        return this.numberOfCaptivePortals;
    }

    /**
     * return highest seen router Network Id
     */
    async getMaxRouterNetworkId(): Promise<number> {
        await this.getNumberOfNetworks();
        return this.maxNetworkID;
    }

    /**
     * return highest seen captive portal Id
     */
    async getMaxCaptivePortalId(): Promise<number> {
        await this.getNumberOfCaptivePortals();
        return this.maxCaptivePortalID;
    }

    /**
     * @param logicalNetworkId
     */
    getRouterNetworkByLogicalNetwork(logicalNetworkId: string): Observable<RouterNetwork> {
        // get DBFunctions of Router Networks which correspond with the logical network
        return this.cfpService.getFunctionsByPropertyAndValue(DBProperty.router_network_lnetworkid, logicalNetworkId).pipe(
            mergeMap((result: DBCustomerFunctionProperty[]) => {
                if (result.length > 0) {
                    return this.getRouterNetwork(result[0].function);
                } else {
                    return of(null);
                }
            })
        );
    }

    /**
     * Function to return array of RouterNetworks
     */
    getRouterNetworks(): Observable<RouterNetwork[]> {
        return forkJoin(this.routerNetworkFunctionIDs.map(functionId => {
            return this.getRouterNetwork(functionId);
        })).pipe(map(networks => {
            // Reset number of networks
            this.numberOfNetworks = 0;
            // Set max network id for creating new ones
            this.maxNetworkID = 0;
            networks.forEach(v => {
                if (v && v.id_number > this.maxNetworkID) {
                    this.maxNetworkID = v.id_number;
                    this.numberOfNetworks++;
                }
            });
            return networks;
        }));
    }

    /**
     * Function to return a RouterNetwork by ID
     * @param functionName
     */
    getRouterNetwork(functionName: string): Observable<RouterNetwork> {
        return this.cfpService.getCustomerFunctionProperties(functionName).pipe(map(
            (result: DBCustomerFunctionProperty[]) => {
                const networkFunctionProperties: DBCustomerFunctionProperty[] = result;
                let routerNetwork: RouterNetwork = undefined;
                if (networkFunctionProperties.length > 0) {
                    // TCP ports
                    let tcp_ports2 = [];
                    let tcp_ports = this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_allowed_tcp_ports);
                    if (tcp_ports !== undefined) {
                        tcp_ports = tcp_ports.replace('[', '').replace(']', '');
                        tcp_ports2 = tcp_ports.length === 0 ? [] : tcp_ports.split(',').map((s) => {
                            return Number.parseInt(s);
                        });
                    }
                    // UDP ports
                    let udp_ports2 = [];
                    let udp_ports = this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_allowed_udp_ports);
                    if (udp_ports !== undefined) {
                        udp_ports = udp_ports.replace('[', '').replace(']', '');
                        udp_ports2 = udp_ports.length === 0 ? [] : udp_ports.split(',').map((s) => {
                            return Number.parseInt(s);
                        });
                    }

                    routerNetwork = new RouterNetwork(
                        Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_id_property)), // router network_id
                        Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_lnetworkid)), // logical network id
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_ip_type), // ip type of router in this network (like dhcp client or static)
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_ip),  // interface ip adress of router if static
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_subnet), // interface netmask of router if static
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_gateway) == 'true', // is router gateway
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_gateway_ip), // gateway ip if router is not gateway
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_dhcp_enabled) == 'true', // is dhcp server enabled on this interface
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_dhcp_start), // start ip address of dhcp server
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_dhcp_end), // end ip address of dhcp server
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_filtering) == 'true', // is filtering enabled on this network
                        Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_captive_portal_id)), // captive portal id of this network
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_type), // network type (media, paed etc.)
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_media_access) == 'true', // has network access to media networks
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_preferred_uplink_network) == 'true',
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_network_port_filter) == 'false',
                        udp_ports2.includes(53) && udp_ports2.includes(853),
                        tcp_ports2.includes(80) && tcp_ports2.includes(443),
                        tcp_ports2.includes(25) && tcp_ports2.includes(587) && tcp_ports2.includes(2525) && tcp_ports2.includes(465) && tcp_ports2.includes(143) && tcp_ports2.includes(993) && tcp_ports2.includes(110) && tcp_ports2.includes(995),
                        tcp_ports2.includes(2197) && udp_ports2.includes(5228) && udp_ports2.includes(5229) && udp_ports2.includes(5230) && tcp_ports2.includes(5223) && tcp_ports2.includes(5228) && tcp_ports2.includes(5229) && tcp_ports2.includes(5230)
                    );
                }
                return routerNetwork;
            }
        ));
    }

    /**
     * Function to create a new router network in the system.
     * @param new_network Object which contains information about the new network that should be added
     */
    createRouterNetwork(new_network: RouterNetwork): Observable<void> {
        return from(this.getNumberOfNetworks()).pipe(map(numberOfNetworks => {
            if (numberOfNetworks >= 16) {
                return throwError('Error S-RN-1');
            }
        })).pipe(() => this.saveRouterNetwork(new_network, this.routerNetworkFunctionIDs[this.numberOfNetworks]))
            .pipe(observable => {
                this.numberOfNetworks++;
                return observable;
            });
    }

    /**
     * Function to edit an existing router network in the system.
     * @param new_network Object which contains information about the new network that should be changed
     */
    editRouterNetwork(new_network: RouterNetwork): Observable<void> {
        // find Function name in database where routerNetwork is saved
        return this.cfpService.getFunctionsByPropertyAndValue(DBProperty.router_network_id_property, new_network.id_number.toString()).pipe(
            mergeMap((result: DBCustomerFunctionProperty[]) => {
                if (result.length > 0) {
                    return this.saveRouterNetwork(new_network, result[0].function);
                } else {
                    return throwError('Error S-RN-2');
                }
            })
        );
    }

    /**
     * Function to create/change a routerNetwork network in the system.
     * @param new_network Object which contains information about the new network that should be saved
     * @param functionId
     */
    saveRouterNetwork(new_network: RouterNetwork, functionId: string): Observable<void> {
        const allowed_tcp_ports = [];
        const allowed_udp_ports = [];
        if (new_network.allow_dns) {
            allowed_udp_ports.push(53, 853);
        }
        if (new_network.allow_web) {
            allowed_tcp_ports.push(80, 443);
        }
        if (new_network.allow_email) {
            allowed_tcp_ports.push(25, 2525, 587, 465, 143, 993, 110, 995);
        }
        if (new_network.allow_push) {
            allowed_tcp_ports.push(2197, 5223, 5228, 5229, 5230);
            allowed_udp_ports.push(5228, 5229, 5230);
        }
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_id_property, this.boolOrNull(new_network.id_number));
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_lnetworkid, this.boolOrNull(new_network.lnetworkid));
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_ip_type, this.boolOrNull(new_network.network_ip_type));
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_ip, this.boolOrNull(new_network.interface_ip));
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_subnet, this.boolOrNull(new_network.network_subnet));
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_gateway, this.boolOrNull(new_network.gateway));
        setRequests[6] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_gateway_ip, this.boolOrNull(new_network.gateway_ip));
        setRequests[7] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_preferred_uplink_network, this.boolOrNull(new_network.preferred_uplink_network));
        setRequests[8] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_dhcp_enabled, this.boolOrNull(new_network.dhcp_server_enabled));
        setRequests[9] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_dhcp_start, this.boolOrNull(new_network.dhcp_server_start_address));
        setRequests[10] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_dhcp_end, this.boolOrNull(new_network.dhcp_server_end_address));
        setRequests[11] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_filtering, this.boolOrNull(new_network.filtering));
        setRequests[12] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_captive_portal_id, this.boolOrNull(new_network.captive_portal_id));
        setRequests[13] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_type, this.boolOrNull(new_network.network_type));
        setRequests[14] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_media_access, this.boolOrNull(new_network.media_access));
        setRequests[15] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_port_filter, this.boolOrNull(!new_network.inet_access_allowed));
        setRequests[16] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_allowed_tcp_ports, String(allowed_tcp_ports));
        setRequests[17] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_network_allowed_udp_ports, String(allowed_udp_ports));
        return forkJoin(setRequests).pipe(mergeAll());
    }

    boolOrNull(value: any) {
        if (value === null || value === undefined || value === NaN) {
            return '';
        }
        return String(value);
    }

    /**
     * Function to delete a logical network from the the system. Function will do it by finding the function id of the router Network function and then will
     * @param network Object which contains information about the router network that should be deleted
     */
    deleteRouterNetwork(network: RouterNetwork): Observable<void> {
        // find Function name in database where routerNetwork is saved
        return this.getRouterNetworks().pipe(mergeMap( // go through all logical network and after deleted network has been found all following networks will be stored one network lower
            (result: RouterNetwork[]) => {
                let deleted: boolean = false;
                const setRequests: Observable<void>[] = [];
                let i = 0;
                for (let t = 0; t < result.length; t++) {
                    const response: RouterNetwork = result[t];
                    if (response === undefined) {
                        continue;
                    }
                    if (deleted) { // if index is higher then the network that has been deleted, store all networks to position with index -1
                        const allowed_tcp_ports = [];
                        const allowed_udp_ports = [];
                        if (response.allow_dns) {
                            allowed_udp_ports.push(53, 853);
                        }
                        if (response.allow_web) {
                            allowed_tcp_ports.push(80, 443);
                        }
                        if (response.allow_email) {
                            allowed_tcp_ports.push(25, 2525, 587, 465, 143, 993, 110, 995);
                        }
                        if (response.allow_push) {
                            allowed_tcp_ports.push(2197, 5223, 5228, 5229, 5230);
                            allowed_udp_ports.push(5228, 5229, 5230);
                        }
                        setRequests[i] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_id_property, this.boolOrNull(response.id_number));
                        setRequests[i + 1] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_lnetworkid, this.boolOrNull(response.lnetworkid));
                        setRequests[i + 2] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_ip_type, this.boolOrNull(response.network_ip_type));
                        setRequests[i + 3] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_ip, this.boolOrNull(response.interface_ip));
                        setRequests[i + 4] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_subnet, this.boolOrNull(response.network_subnet));
                        setRequests[i + 5] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_gateway, this.boolOrNull(response.gateway));
                        setRequests[i + 6] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_gateway_ip, this.boolOrNull(response.gateway_ip));
                        setRequests[i + 7] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_preferred_uplink_network, this.boolOrNull(response.preferred_uplink_network));
                        setRequests[i + 8] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_dhcp_enabled, this.boolOrNull(response.dhcp_server_enabled));
                        setRequests[i + 9] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_dhcp_start, this.boolOrNull(response.dhcp_server_start_address));
                        setRequests[i + 10] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_dhcp_end, this.boolOrNull(response.dhcp_server_end_address));
                        setRequests[i + 11] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_filtering, this.boolOrNull(response.filtering));
                        setRequests[i + 12] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_captive_portal_id, this.boolOrNull(response.captive_portal_id));
                        setRequests[i + 13] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_type, this.boolOrNull(response.network_type));
                        setRequests[i + 14] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_media_access, this.boolOrNull(response.media_access));
                        setRequests[i + 15] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_port_filter, this.boolOrNull(!response.inet_access_allowed));
                        setRequests[i + 16] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_allowed_tcp_ports, String(allowed_tcp_ports));
                        setRequests[i + 17] = this.cfpService.setCustomerFunctionProperty(this.routerNetworkFunctionIDs[t - 1], DBProperty.router_network_allowed_udp_ports, String(allowed_udp_ports));
                        i = i + 18;
                    }
                    if (response.id_number === network.id_number) {
                        deleted = true; // set deleted to true so that after that all networks will be stored one time lower
                    }
                }
                result = result.filter(v => v !== undefined);
                // delete the network with highest index.
                setRequests[i] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_id_property);
                setRequests[i + 1] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_lnetworkid);
                setRequests[i + 2] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_ip_type);
                setRequests[i + 3] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_ip);
                setRequests[i + 4] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_subnet);
                setRequests[i + 5] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_gateway);
                setRequests[i + 6] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_gateway_ip);
                setRequests[i + 7] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_preferred_uplink_network);
                setRequests[i + 8] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_dhcp_enabled);
                setRequests[i + 9] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_dhcp_start);
                setRequests[i + 10] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_dhcp_end);
                setRequests[i + 11] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_filtering);
                setRequests[i + 12] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_captive_portal_id);
                setRequests[i + 13] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_type);
                setRequests[i + 14] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_media_access);
                setRequests[i + 15] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_port_filter);
                setRequests[i + 16] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_allowed_tcp_ports);
                setRequests[i + 17] = this.cfpService.removeCustomerFunctionProperty(this.routerNetworkFunctionIDs[result.length - 1], DBProperty.router_network_allowed_udp_ports);
                return forkJoin(setRequests).pipe(mergeAll()).pipe(observable => {
                    this.numberOfNetworks--;
                    return observable;
                }) as Observable<void>;
            }));
    }

    /**
     * Function to delete properties after the network type on an existing router network has been changed
     * @param network Object which contains information about the router network that should be resetted
     */
    resetRouterNetwork(network: RouterNetwork): Observable<void> {
        return this.cfpService.getFunctionsByPropertyAndValue(DBProperty.router_network_id_property, String(network.id_number)).pipe(
            mergeMap((result: DBCustomerFunctionProperty[]) => {
                const setRequests: Observable<void>[] = [];
                setRequests[0] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_ip, '');
                setRequests[1] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_subnet, '');
                setRequests[2] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_gateway, '');
                setRequests[3] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_gateway_ip, '');
                setRequests[4] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_preferred_uplink_network, '');
                setRequests[5] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_dhcp_enabled, '');
                setRequests[6] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_dhcp_start, '');
                setRequests[7] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_dhcp_end, '');
                setRequests[8] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_filtering, '');
                setRequests[9] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_media_access, '');
                setRequests[10] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_port_filter, '');
                setRequests[11] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_allowed_tcp_ports, '');
                setRequests[12] = this.cfpService.setCustomerFunctionProperty(result[0].function, DBProperty.router_network_allowed_udp_ports, '');
                return forkJoin(setRequests).pipe(mergeAll()) as Observable<void>;
            })
        );
    }

    /**
     * @param captive_portal_id
     */
    getCaptivePortalById(captive_portal_id: number): Observable<CaptivePortal> {
        if (captive_portal_id === NaN) {
            return of(null);
        }
        // get DBFunctions of Captive Portal which correspond with the router network
        return this.cfpService.getFunctionsByPropertyAndValue(DBProperty.router_cp_id_property, String(captive_portal_id)).pipe(
            mergeMap((result: DBCustomerFunctionProperty[]) => {
                if (result.length > 0) {
                    return this.getCaptivePortal(result[0].function);
                } else {
                    return of(null);
                }
            }));
    }

    /**
     * Function to return array of CaptivePortals
     */
    getCaptivePortals(): Observable<CaptivePortal[]> {
        return forkJoin(this.captivePortalFunctionIDs.map(functionId => {
            return this.getCaptivePortal(functionId);
        })).pipe(map(captivePortals => {
            // Reset number of captive portals
            this.numberOfCaptivePortals = 0;
            // Set max network id for creating new ones
            this.maxCaptivePortalID = 0;
            captivePortals.forEach(v => {
                if (v && v.id_number > this.maxCaptivePortalID) {
                    this.maxCaptivePortalID = v.id_number;
                    this.numberOfCaptivePortals++;
                }
            });
            return captivePortals;
        }));
    }

    /**
     * Function to return a CaptivePortal by ID
     * @param functionName
     */
    getCaptivePortal(functionName: string): Observable<CaptivePortal> {
        return this.cfpService.getCustomerFunctionProperties(functionName).pipe(map(
            (result: DBCustomerFunctionProperty[]) => {
                const networkFunctionProperties: DBCustomerFunctionProperty[] = result;
                let captivePortal: CaptivePortal = undefined;
                if (networkFunctionProperties.length > 0) {
                    captivePortal = new CaptivePortal(
                        Number.parseInt(this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_id_property)),
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_enabled) == 'true',
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_max_client_up_speed),
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_max_client_down_speed),
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_endoo_auth),
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_radius_ip),
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_radius_port),
                        this.cfpService.getCustomerFunctionPropertyValue(networkFunctionProperties, functionName, DBProperty.router_cp_radius_secret)
                    );
                }
                return captivePortal;
            }
        ));
    }

    /**
     * @param captivePortal
     */
    createCaptivePortal(captivePortal: CaptivePortal): Observable<void> {
        return from(this.getNumberOfCaptivePortals()).pipe(map(numberOfCaptivePortals => {
            if (numberOfCaptivePortals >= 16) {
                return throwError('Error S-RN-2');
            }
        })).pipe(() => this.saveCaptivePortal(captivePortal, this.captivePortalFunctionIDs[this.numberOfCaptivePortals]))
            .pipe(observable => {
                this.numberOfCaptivePortals++;
                return observable;
            });
    }

    /**
     * Function to edit an existing captive portal in the system.
     * @param captivePortal Object which contains information about the captive portal that should be edited
     */
    editCaptivePortal(captivePortal: CaptivePortal): Observable<void> {
        // find Function name in database where captive portal is saved
        return this.cfpService.getFunctionsByPropertyAndValue(DBProperty.router_cp_id_property, captivePortal.id_number.toString()).pipe(
            mergeMap((result: DBCustomerFunctionProperty[]) => {
                if (result.length > 0) {
                    return this.saveCaptivePortal(captivePortal, result[0].function);
                } else {
                    return throwError('Error S-RN-3');
                }
            })
        );
    }

    /**
     * Function to create/change a captive portal in the system.
     * @param captivePortal
     */
    saveCaptivePortal(captivePortal: CaptivePortal, functionId: string): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_id_property, this.boolOrNull(captivePortal.id_number));
        setRequests[1] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_enabled, this.boolOrNull(captivePortal.enabled));
        setRequests[2] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_max_client_up_speed, this.boolOrNull(captivePortal.max_client_up_speed));
        setRequests[3] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_max_client_down_speed, this.boolOrNull(captivePortal.max_client_down_speed));
        setRequests[4] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_endoo_auth, this.boolOrNull(captivePortal.endoo_auth));
        setRequests[5] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_radius_ip, this.boolOrNull(captivePortal.radius_ip));
        setRequests[6] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_radius_port, this.boolOrNull(captivePortal.radius_port));
        setRequests[7] = this.cfpService.setCustomerFunctionProperty(functionId, DBProperty.router_cp_radius_secret, this.boolOrNull(captivePortal.radius_secret));
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * @param captivePortal
     */
    deleteCaptivePortal(captivePortal: CaptivePortal): Observable<void> {
        // find Function name in database where routerNetwork is saved
        return this.getCaptivePortals().pipe(mergeMap( // go through all captive portals and after deleted cp has been found all following cps will be stored one cp function number lower
            (result: CaptivePortal[]) => {
                let deleted: boolean = false;
                const setRequests: Observable<void>[] = [];
                let i = 0;
                for (let t = 0; t < result.length; t++) {
                    const response: CaptivePortal = result[t];
                    if (response === undefined) {
                        continue;
                    }
                    if (deleted) { // if index is higher then the network that has been deleted, store all networks to position with index -1
                        setRequests[i] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_id_property, this.boolOrNull(response.id_number));
                        setRequests[i + 1] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_enabled, this.boolOrNull(response.enabled));
                        setRequests[i + 2] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_max_client_up_speed, this.boolOrNull(response.max_client_up_speed));
                        setRequests[i + 3] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_max_client_down_speed, this.boolOrNull(response.max_client_down_speed));
                        setRequests[i + 4] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_endoo_auth, this.boolOrNull(response.endoo_auth));
                        setRequests[i + 5] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_radius_ip, this.boolOrNull(response.radius_ip));
                        setRequests[i + 6] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_radius_port, this.boolOrNull(response.radius_port));
                        setRequests[i + 7] = this.cfpService.setCustomerFunctionProperty(this.captivePortalFunctionIDs[t - 1], DBProperty.router_cp_radius_secret, this.boolOrNull(response.radius_secret));
                        i = i + 8;
                    }
                    if (response.id_number === captivePortal.id_number) {
                        deleted = true; // set deleted to true so that after that all networks will be stored one time lower
                    }
                }
                result = result.filter(v => v !== undefined);
                // delete the network with highest index.
                setRequests[i] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_id_property);
                setRequests[i + 1] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_enabled);
                setRequests[i + 2] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_max_client_up_speed);
                setRequests[i + 3] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_max_client_down_speed);
                setRequests[i + 4] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_endoo_auth);
                setRequests[i + 5] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_radius_ip);
                setRequests[i + 6] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_radius_port);
                setRequests[i + 7] = this.cfpService.removeCustomerFunctionProperty(this.captivePortalFunctionIDs[result.length - 1], DBProperty.router_cp_radius_secret);
                return forkJoin(setRequests).pipe(mergeAll()).pipe(observable => {
                    this.numberOfCaptivePortals--;
                    return observable;
                }) as Observable<void>;
            }));
    }
}
