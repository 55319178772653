import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DBAdmin, DBCustomer } from 'app/data/endooSpotDB_objects';

@Injectable()
export class RegisterService {
    selfRegistrateURL: string = '/api/selfRegistrate/';

    /**
     * Constructor of the service object. All params will be injected by angular.
     * @param http http client object injected by angular.
     * @param authService service object handling everything regarding authorization.
     */
    constructor(private http: HttpClient) { }

    createCustomer(customer: DBCustomer, licenseKey: string): Observable<{ customerIdAvailable: boolean, licenseKeyCorrect: boolean }> {
        return this.http.put<{ customerIdAvailable: boolean, licenseKeyCorrect: boolean }>(this.selfRegistrateURL + 'customers', { customer: { customerId: customer.customerId, name: customer.name, email: customer.email, street: customer.street, zip: customer.zip, city: customer.city, country: customer.country }, licenseKey: licenseKey });
    }

    /**
     * Function to add a new admin to the system per customer register dialog.
     * @param new_admin Object which contains information about the new admin that should be added
     */
    registerAdmin(new_admin: DBAdmin, password: string, customerId: string, email: string = ''): Observable<{ usernameSet: boolean, passwordSet: boolean, emailSet: boolean }> {
        return this.http.put<{ usernameSet: boolean, passwordSet: boolean, emailSet: boolean }>(this.selfRegistrateURL + 'admins/' + new_admin.username, { admin: new_admin, password: password, email: email, customer_id: customerId });
    }
}
