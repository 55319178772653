import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthenticationService } from 'app/Login/authentication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterGlobalService } from '../APService/routerGlobal.service';

@Component({
    templateUrl: './routerGlobal.component.html',
})
export class RouterGlobalComponent implements OnInit {
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        public authenticationService: AuthenticationService,
        private routerGlobalService: RouterGlobalService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            default_gateway: [false]
        });
        this.routerGlobalService.getGlobalRouterSettings().subscribe(routerSettings => {
            if (routerSettings === null) {
                return;
            }
            this.form.setValue({
                default_gateway: routerSettings.default_gateway
            });
        });
    }

    async saveSettings(): Promise<void> {
        if (this.form.invalid) {
            return;
        }
        try {
            await this.routerGlobalService.saveGlobalRouterSettings(this.form.get('default_gateway').value).toPromise();
            this.snackBar.open('Die Einstellungen wurden gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Die Einstellungen konnten nicht gespeichert werden', 'OK');
        }
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
