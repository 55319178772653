<form [formGroup]="form" (ngSubmit)="saveWifiSettings()">
    <mat-list *ngIf="wifiSettings">
        <h3 mat-subheader>Verschlüsselung (WPA2) des endooSpot-Netzwerks</h3>
        <mat-list-item style="margin-bottom: 35px;">
            <mat-slide-toggle formControlName="encryption" [(ngModel)]="wifiSettings.encryption">
                <mat-icon mat-list-icon>lock</mat-icon>
                WPA2-Verschlüsselung
            </mat-slide-toggle>
        </mat-list-item>
        <mat-list-item class="mat-list-item-input" style="margin-bottom: 15px;">
            <div class="wifikeykey_div" *ngIf="wifiSettings.encryption">
                <mat-form-field appearance="outline">
                    <mat-label>WPA2-Schlüssel</mat-label>
                    <input matInput [type]="showKey ? 'text' : 'password'" id="wifikey" class="wifikey_on"
                        [(ngModel)]="wifiSettings.encryption_key" formControlName="encryption_key"
                        (keypress)="validateWifiKeyInput($event)" required />
                    <mat-icon matSuffix onMouseOver="this.style.cursor='pointer'" (click)="showKey = !showKey">
                        {{showKey ? 'visibility' : 'visibility_off'}}
                    </mat-icon>
                    <mat-hint>20-63 Zeichen</mat-hint>
                </mat-form-field>
            </div>
        </mat-list-item>
        <mat-list-item class="mat-list-item-input" style="margin-bottom: 15px;">
            <div *ngIf="wifiSettings.encryption" style="display: flex; flex-wrap: wrap; gap: 15px 35px;">
                <button mat-raised-button id="WifiKeyNewBt" (click)="newWifiKey()" type="button">Neuen
                    Netzwerkschl&uuml;ssel generieren</button>
                <button mat-raised-button id="WifiKeySaveBt" (click)="newWifiKeyCancel()"
                    type="button">Zur&uuml;cksetzen</button>
            </div>
        </mat-list-item>
        <mat-divider></mat-divider>
        <h3 mat-subheader>Erlauben von Kommunikation zwischen Ger&auml;ten untereinander (wird z.B. für AirPlay
            ben&ouml;tigt)</h3>
        <mat-list-item style="margin-bottom: 15px;">
            <mat-slide-toggle formControlName="client2client" [(ngModel)]="wifiSettings.client2client">Client2Client
                Kommunikation
            </mat-slide-toggle>
        </mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
            <div mat-line>
                <button mat-raised-button color="primary" class="button-margin-top" [disabled]="background_working"
                    type="submit">Speichern</button>
            </div>
        </mat-list-item>
    </mat-list>
</form>