<h1 mat-dialog-title>Benutzer importieren</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper linear=true #importstepper="matHorizontalStepper">
        <mat-step [stepControl]="form1" label="Benutzer aus CSV-Datei importieren">
            <form [formGroup]="form1">
                <div class="fullwidth_form">
                    <div class="mt-20 mb-20">
                        <label>CSV-Datei *</label>
                        <div class="mt-10">
                            <button type="button" mat-raised-button id="import-button" #csvfileBt>
                                {{fileInput.files.item(0) ? fileInput.files.item(0).name : 'Datei auswählen'}}
                            </button>
                            <input hidden formControlName="file" #fileInput type="file">
                            <mat-error *ngIf="showFileUploadError" style="padding-left: 10.5px;">
                                <small>Bitte wählen Sie eine Datei aus</small>
                            </mat-error>
                        </div>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>Anzahl Spalten in der CSV-Datei</mat-label>
                        <input matInput formControlName="columns" type="number" required />
                        <mat-error *ngIf="hasError('columns', 'required')">Anzahl Spalten erforderlich
                        </mat-error>
                        <mat-error *ngIf="hasError('columns', 'min') || hasError('columns', 'max')">Anzahl Spalten muss
                            zwischen 3 und 9 liegen
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Benutzergruppe</mat-label>
                        <mat-select formControlName="userGroup" required>
                            <mat-option value="pupil">Schüler</mat-option>
                            <mat-option value="teacher">Lehrer</mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('userGroup', 'required')">Benutzergruppe erforderlich
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field *ngIf="form1.get('userGroup').value === 'pupil'" appearance="outline">
                        <mat-label>Abschlussjahr</mat-label>
                        <input matInput formControlName="leavingYear" type="number" required />
                        <mat-error *ngIf="hasError('leavingYear', 'required')">Abschlussjahr erforderlich
                        </mat-error>
                    </mat-form-field>
                    <section class="toggle-section">
                        <mat-slide-toggle formControlName="wlan">WLAN</mat-slide-toggle>
                    </section>
                </div>
                <mat-divider></mat-divider>
                <button mat-button title="Datei laden" (click)="loadUserFileBt()">Datei
                    laden</button>
                <button mat-button title="Schließen" (click)="dialogRef.close()">Schließen</button>
            </form>
        </mat-step>
        <mat-step label="Daten überprüfen">
            <div class="zimlet_div import_table_div">
                <table style="width: 100%;">
                    <thead>
                        <tr class="headline zimlet_tr">
                            <th *ngFor="let column of column_options; let i = index" class="zimlet_th">
                                <mat-select [(ngModel)]="columns[i]">
                                    <mat-option *ngFor="let option of column_options" [value]="option">
                                        {{germanTranslation(option)}}
                                    </mat-option>
                                </mat-select>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row_array of value_table; let row_index = index">
                            <td *ngFor="let column of row_array; let column_index=index ">{{column}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button mat-button [disabled]="!fileLoaded" title="Importieren"
                (click)="importUsersBtClick()">Importieren</button>
            <button mat-button title="Reset" (click)="resetStepper(importstepper)">Reset</button>
        </mat-step>
        <mat-step label="Benutzervertrag">
            <div id="vertraege_div">
                <iframe id="contract_import_pdf" [src]="contract_link" width=100% height=100%> </iframe>
                <a target="_blank" [href]="contract_link">
                    <h4>Datei im neuen Fenster öffnen</h4>
                </a>
                <a target="_blank" download="passwords.csv" [href]="password_link">
                    <h4>Passwörter als CSV-Datei herunterladen</h4>
                </a>
                <button mat-raised-button (click)="closeDialogBtClick()">Schließen</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>