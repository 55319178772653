import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, ObservableInput, throwError } from 'rxjs';
import { AuthenticationService } from 'app/Login/authentication.service';
import { map, catchError } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { RaygunErrorHandler } from 'app/app.raygun.setup';
import * as rg4js from 'raygun4js';

type Options = {
    headers?: HttpHeaders;
    observe: 'response';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
};

@Injectable()
export class EndooHttpService {
    private headers: HttpHeaders;

    constructor(
        private http: HttpClient,
        private authService: AuthenticationService,
        private loadingService: LoadingService,
        //private globalErrorHandler: RaygunErrorHandler // Siehe "T-0374 RayGun aus endooControl und endooTeacher entfernen (vorrübergehend)"
    ) {
        this.headers = new HttpHeaders();
    }

    private mergeHeaders(furtherHeaders?: HttpHeaders): HttpHeaders {
        if (furtherHeaders === undefined) {
            furtherHeaders = new HttpHeaders();
        }
        this.headers.keys().forEach(key => {
            furtherHeaders = furtherHeaders.append(key, this.headers.get(key));
        });
        const customerSpecificHeaders = this.authService.getCustomerSpecificHeaders();
        customerSpecificHeaders.keys().forEach(header => {
            furtherHeaders = furtherHeaders.append(header, customerSpecificHeaders.get(header));
        });
        return furtherHeaders;
    }

    delete<T>(url: string, options: Options, showLoadingDialog: boolean = true, customErrorHandling: boolean = false): Observable<T> {
        const request = this.http.delete<T>(url, { ...options, headers: this.mergeHeaders(options.headers) })
            .pipe(map((response): T => {
                this.loadingService.removeLoading(request);
                return response.body;
            }), catchError((err: HttpErrorResponse, caught: Observable<T>): ObservableInput<T> => {
                this.loadingService.removeLoading(request);
                return this.handleError(err);
            }));
        if (showLoadingDialog) {
            this.loadingService.addLoading(request);
        }
        return request;
    }

    get<T>(url: string, options?: Options, showLoadingDialog: boolean = true, customErrorHandling: boolean = false): Observable<T> {
        const request = this.http.get<T>(url, { ...options, headers: this.mergeHeaders(options.headers) })
            .pipe(map((response): T => {
                this.loadingService.removeLoading(request);
                return response.body;
            }), catchError((err: HttpErrorResponse, caught: Observable<T>): ObservableInput<T> => {
                this.loadingService.removeLoading(request);
                return this.handleError(err);
            }));
        if (showLoadingDialog) {
            this.loadingService.addLoading(request);
        }
        return request;
    }

    post<T>(url: string, body: any | null, options: Options, showLoadingDialog: boolean = true, customErrorHandling: boolean = false): Observable<T> {
        const request = this.http.post<T>(url, body, { ...options, headers: this.mergeHeaders(options.headers) })
            .pipe(map((response): T => {
                this.loadingService.removeLoading(request);
                return response.body;
            }), catchError((err: HttpErrorResponse, caught: Observable<T>): ObservableInput<T> => {
                this.loadingService.removeLoading(request);
                return this.handleError(err);
            }));
        if (showLoadingDialog) {
            this.loadingService.addLoading(request);
        }
        return request;
    }

    put<T>(url: string, body: any | null, options: Options, showLoadingDialog: boolean = true, customErrorHandling: boolean = false): Observable<T> {
        const request = this.http.put<T>(url, body, { ...options, headers: this.mergeHeaders(options.headers) })
            .pipe(map((response): T => {
                this.loadingService.removeLoading(request);
                return response.body;
            }), catchError((err: HttpErrorResponse, caught: Observable<T>): ObservableInput<T> => {
                this.loadingService.removeLoading(request);
                return this.handleError(err);
            }));
        if (showLoadingDialog) {
            this.loadingService.addLoading(request);
        }
        return request;
    }

    private handleError<T>(err: HttpErrorResponse): Observable<any> {
        let message = '';

        if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
            message = err.error.message;
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${err.status}, ` +
                `body was: `, err.error);
            message = err.message;
            if (err.status === 401) { // unauthorized
                message = 'Sitzung abgelaufen. Erneuter Login erforderlich.';
                this.authService.sessionInvalid.emit(true);
                this.authService.reAuthenticate();
                // Set rayygun userdata to anonymous user
                rg4js('setUser', {
                    identifier: '',
                    isAnonymous: true,
                });
            } else {
                //this.globalErrorHandler.handleError(err); // Siehe "T-0374 RayGun aus endooControl und endooTeacher entfernen (vorrübergehend)"
            }
            if (err.status === 403) { // forbidden
                message = 'Keine Berechtigung';
            }
            if (err.status === 404) { // not found
                message = 'Objekt nicht gefunden';
            }
            if (err.status >= 500) { // server error
                message = 'Server error';
            }
        }

        return throwError(message);
    }
}
