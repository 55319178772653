<mat-toolbar>
	<span id="userDevicesListRouterTitle" class="user-select-none">(Internet-)Freischaltung von
		<br *ngIf="appService.isAppScreen()">Endger&auml;ten</span>
	<span class="example-spacer"></span>
</mat-toolbar>

<div class="content-container" id="UserDeviceList_div">
	<mat-card>
		<div class="table-div">
			<mat-form-field>
				<mat-label>In Tabelle suchen</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
			</mat-form-field>
			<mat-table (matSortChange)="sortDataChanged($event)" matSortActive="hostname" [dataSource]="userDevices"
				class="table" id="UserDevicesTable" matSort>
				<ng-container matColumnDef="Lease.ClientName">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Hostname </mat-header-cell>
					<mat-cell class="userDevice-cell-hostname" *matCellDef="let userDevice">
						{{userDevice.Lease.ClientName}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="Lease.IP">
					<mat-header-cell *matHeaderCellDef mat-sort-header> IP-Adresse </mat-header-cell>
					<mat-cell class="userDevice-cell-ip" *matCellDef="let userDevice"> {{userDevice.Lease.IP}}
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="Lease.Mac">
					<mat-header-cell *matHeaderCellDef mat-sort-header> MAC-Adresse</mat-header-cell>
					<mat-cell *matCellDef="let userDevice"> {{userDevice.Lease.Mac}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="Lease.start">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Letzte Aktualisierung</mat-header-cell>
					<mat-cell *matCellDef="let userDevice"> {{userDevice.Lease.start | datetime}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="filtered">
					<mat-header-cell *matHeaderCellDef> Automatischer Login </mat-header-cell>
					<mat-cell *matCellDef="let userDevice">
						<mat-checkbox (change)="switchFilter(userDevice)" [ngModel]="!userDevice.LoginMask"
							class="zimlet_cb"></mat-checkbox>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let userDevice; columns: displayedColumns;"></mat-row>
			</mat-table>
			<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[10,50,100,200]" [showFirstLastButtons]="true">
			</mat-paginator>
		</div>
	</mat-card>
</div>
