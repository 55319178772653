<h1 mat-dialog-title> Personen zu Gruppe hinzuf&uuml;gen</h1>
<div mat-dialog-content>
	<mat-form-field appearance="outline">
		<mat-label>Gruppenname</mat-label>
		<input matInput #autocompleteInput type="text" [(ngModel)]="groupnameSearchstring"
			(change)="changeGroupSearchstring()" [matAutocomplete]="auto">
		<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
			<mat-option *ngFor="let entry of found_groups" [value]="entry.value">
				{{ entry.value }}
			</mat-option>
		</mat-autocomplete>
	</mat-form-field>
	<button mat-raised-button (click)="searchGroupBtClick()" style="margin-left: 10px">Suchen</button>
	<br><br>
	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
	</mat-form-field>
	<mat-table [dataSource]="selected_users" class="table" id="UserList_table" matSort>
		<ng-container matColumnDef="username">
			<mat-header-cell *matHeaderCellDef> Benutzername </mat-header-cell>
			<mat-cell *matCellDef="let person"> {{person.username}} </mat-cell>
		</ng-container>
		<ng-container matColumnDef="displayname">
			<mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
			<mat-cell *matCellDef="let person"> {{person.givenname}} {{person.surname}} </mat-cell>
		</ng-container>
		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row *matRowDef="let person; columns: displayedColumns;"></mat-row>
	</mat-table>
	<mat-paginator #paginator [pageSize]="20" [pageSizeOptions]="[20,50,100]" [showFirstLastButtons]="true">
	</mat-paginator>
</div>
<mat-dialog-actions>
	<button mat-button title="Abbrechen" (click)="cancelAddPersonsToGroupBtClick()">Abbrechen</button>
	<button mat-button color="primary" title="Gruppe Speichern" (click)="saveAddPersonsToGroupBtClick()"
		[disabled]="!isGroupSelected">Hinzuf&uuml;gen</button>
</mat-dialog-actions>
