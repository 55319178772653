<h1 mat-dialog-title>WPA2-PSK Sicherheitsrichtlinie</h1>
<div mat-dialog-content>
	Das Bundesamt für Sicherheit in der Informationstechnik empfielt für WPA2-PSK eine Mindestlänge von 20 Zeichen.
	<br><br>
	Möchten Sie trotzdem speichern?
</div>
<mat-dialog-actions>
	<button mat-raised-button (click)="cancel()">Abbrechen</button>
	<button mat-raised-button (click)="confirm()">Speichern</button>
</mat-dialog-actions>
