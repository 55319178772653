import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { DeleteLogicalNetworkDialogComponent } from '../LogicalNetworkManagementComponent/LogicalNetworkManagementDialogs.component';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { AuthenticationService } from '../../Login/authentication.service';
import { FormGroup, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { NewLogicalNetworkDialogComponent } from '../NewLogicalNetwork/newLogicalNetwork.dialog.component';

@Component({
    selector: 'LogicalNetworkBasicSettings',
    templateUrl: './logicalNetworkBasicSettings.component.html',
})
export class LogicalNetworkBasicSettingsComponent {
    background_working: boolean = false;
    @Input() index: number;
    network: LogicalNetwork;
    get Network() {
        return this.network;
    }
    @Input() set Network(network: LogicalNetwork) {
        this.form.get('id_number').setValue(network.id_number);
        this.form.get('name').setValue(network.name);
        this.form.get('activated').setValue(network.activated);
        this.form.get('vlan_id').setValue(network.vlan_id);
        this.form.get('untagged').setValue(network.untagged);
        this.form.get('color').setValue(network.color);
        this.network = network;
    };
    @Input() notAvailableColors: string[];
    @Output() changed: EventEmitter<void> = new EventEmitter();
    form: FormGroup;

    constructor(
        public authenticationService: AuthenticationService,
        public lnetworkService: LogicalNetworkService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
        this.form = new FormGroup({
            id_number: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            activated: new FormControl(''),
            vlan_id: new FormControl('', [Validators.required, Validators.pattern(/\d+$/), Validators.min(2), Validators.max(4093)]),
            untagged: new FormControl(),
            color: new FormControl('')
        });
    }

    makeNetworkObject() {
        return new LogicalNetwork(
            this.form.get('id_number').value,
            this.form.get('name').value,
            this.form.get('activated').value,
            this.form.get('color').value,
            this.form.get('untagged').value,
            this.form.get('vlan_id').value
        );
    }

    getFormValidationErrors() {
        const errors = [];
        Object.keys(this.form.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors[key] = { keyError: controlErrors[keyError] };
                });
            }
        });
        return errors;
    }

    /**
     * Method to save the current network data.
     */
    private async updateNetwork(): Promise<void> {
        const network = this.makeNetworkObject();

        if (network.untagged) {
            let untaggedNetwork: LogicalNetwork;
            try {
                untaggedNetwork = await this.lnetworkService.getUntaggedNetwork();
            } catch (err) {
                this.snackBar.open('Schulnetzwerk konnte nicht gespeichert werden: ' + err, 'OK');
                return;
            }
            if (untaggedNetwork !== undefined && untaggedNetwork.id_number !== network.id_number) {
                this.snackBar.open('Es kann nur ein untagged/Management Netzwerk festgelegt werden', 'OK');
                return;
            }
        } else {
            // Vlan ids from 2 till 4093 allowed
            if (!this.network.vlan_id || this.network.vlan_id === NaN || this.network.vlan_id < 2 || this.network.vlan_id > 4093) {
                this.snackBar.open('Die VLAN-ID muss zwischen 2 und 4093 liegen', 'OK');
                return;
            }
            // There must not be a vlan-id twice
            let isVlanIdFree: boolean;
            try {
                isVlanIdFree = await this.lnetworkService.isVlanIdFree(network);
            } catch (err) {
                this.snackBar.open('Schulnetzwerk konnte nicht gespeichert werden: ' + err, 'OK');
                return;
            }
            if (!isVlanIdFree) {
                this.snackBar.open('Die VLAN-ID "' + network.vlan_id + '" ist bereits vergeben', 'OK');
                return;
            }
        }
        try {
            // If customer changes untagged to tagged, let him create one first
            const untaggedNetwork = await this.lnetworkService.getUntaggedNetwork();
            if (untaggedNetwork.id_number === network.id_number && !network.untagged) {
                const dialogRef = this.dialog.open(NewLogicalNetworkDialogComponent, {
                    data: {
                        action: 'ChangeNetwork'
                    },
                });
                dialogRef.afterClosed().subscribe(async created => {
                    if (created) {
                        await this.lnetworkService.saveLogicalNetwork(this.index, network).toPromise();
                        this.snackBar.open('Schulnetzwerk wurde erfolgreich gespeichert', 'OK');
                        this.changed.emit();
                    } else {
                        this.snackBar.open('Schulnetzwerk konnte nicht geändert werden, da kein neues untagged/Management Netzwerk angelegt wurde', 'OK');
                    }
                });
            } else {
                await this.lnetworkService.saveLogicalNetwork(this.index, network).toPromise();
                this.snackBar.open('Schulnetzwerk wurde erfolgreich gespeichert', 'OK');
                this.changed.emit();
            }
        } catch (err) {
            this.snackBar.open('Schulnetzwerk konnte nicht gespeichert werden: ' + err, 'OK');
        }
    }

    /**
     * Invoked as the user clicks on the Save Network Button.
     */
    async saveNetworkBtClick(): Promise<void> {
        if (!this.form.valid) {
            return;
        }
        this.background_working = true;
        await this.updateNetwork();
        this.background_working = false;
    }

    /**
     * Invoked as the user clicks on the Delete Network Button.
    */
    deleteNetworkBtClick(): void {
        const dialogRef = this.dialog.open(DeleteLogicalNetworkDialogComponent, {
            data: {
                network: this.makeNetworkObject(),
                excludeNetworkFromSelection: this.index
            }
        });
        dialogRef.afterClosed().subscribe(deleted => {
            if (deleted) {
                this.changed.emit();
            }
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
