import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DemoAccessService } from '../demo-access.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './license-key-generation.dialog.component.html',
    styleUrls: ['./license-key-generation.dialog.component.css'],
})
export class LicenseKeyGenerationDialogComponent implements OnInit {
    licenseKey: string;
    description: string;
    copied: boolean;
    @ViewChild('licenseKeyInput') licenseKeyInput: ElementRef;

    constructor(
        private dialogRef: MatDialogRef<LicenseKeyGenerationDialogComponent>,
        private demoAccessService: DemoAccessService
    ) { }

    ngOnInit() {
        this.licenseKey = '';
    }

    async generateKey(): Promise<void> {
        this.licenseKey = 'wird generiert';
        this.licenseKey = await this.demoAccessService.generateLicenseKey(this.description).toPromise();
    }

    async copyKeyToClipboard(): Promise<void> {
        await navigator.clipboard.writeText(this.licenseKey);
        this.copied = true;
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
