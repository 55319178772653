<mat-toolbar>
    <span class="user-select-none">Dashboard</span>
</mat-toolbar>
<div id="div-dashboard">
    <div class="gap" [hidden]="appService.isAppScreen()">
        <mat-card>
            <div class="no-space border">
                <endoo-devices-graph [DevicesCount]="devicesCount"></endoo-devices-graph>
            </div>
        </mat-card>
    </div>
    <hr>
    <div class="gap">
        <h4 class="user-select-none">Endgeräte</h4>
    </div>
    <div *ngIf="notSupportedModels.length > 0" class="gap">
        <div>
            Die folgenden Modelle unterstützen das Dashboard noch nicht und werden bei der Zählung von Endgeräten
            nicht berücksichtigt
        </div>
        <ul>
            <li *ngFor="let inventoryNumber of notSupportedModels">
                <b style="text-transform: uppercase;">{{inventoryNumber}}</b>
            </li>
        </ul>
    </div>
    <div class="gap">
        <mat-card>
            <div class="graph-width no-space border">
                <endoo-plotly-graph [DevicesGraph]="graphClientCount2_4Ghz">
                </endoo-plotly-graph>
            </div>
            <div class="graph-width no-space border">
                <endoo-plotly-graph [DevicesGraph]="graphClientCount5Ghz">
                </endoo-plotly-graph>
            </div>
        </mat-card>
    </div>
    <div class="gap" [hidden]="appService.isAppScreen()">
        <mat-card>
            <div class="graph-width no-space border">
                <endoo-plotly-graph [DevicesGraph]="graphClientCount2_4GhzTimeline">
                </endoo-plotly-graph>
            </div>
            <div class="graph-width no-space border">
                <endoo-plotly-graph [DevicesGraph]="graphClientCount5GhzTimeline">
                </endoo-plotly-graph>
            </div>
        </mat-card>
    </div>
</div>
