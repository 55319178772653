import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegisterService } from './customerService/register.service';
import { DBAdmin, DBCustomer } from 'app/data/endooSpotDB_objects';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/Login/authentication.service';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Countries } from '../data/locales';

@Component({
    templateUrl: './registerCustomer.component.html',
    styleUrls: ['./registerCustomer.component.css'],
})
export class registerCustomerComponent implements AfterViewInit {
    createCustomerForm: FormGroup;
    createAdminForm: FormGroup;
    @ViewChild(MatStepper) stepper: MatStepper;
    readonly STEP_CUSTOMER = 'Schule eintragen';
    readonly STEP_ADMIN = 'Administrator eintragen';
    readonly DEFAULT_ADMIN_USERNAME = 'admin';
    @ViewChild('password') passwordField: ElementRef;
    countries: string[] = Object.values(Countries);

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private authService: AuthenticationService,
        private registerService: RegisterService,
        private snackBar: MatSnackBar
    ) {
        this.createCustomerForm = this.fb.group({
            name: ['', [Validators.required, Validators.pattern(/[a-zA-ZÄäÖöÜüß0-9\-\.]/)]],
            id: ['', [Validators.required, Validators.pattern(/^[a-zA-Z0-9_\-]*$/)]],
            email: ['', [Validators.required, Validators.email]],
            street: ['', [Validators.required]],
            zip: ['', [Validators.required]],
            city: ['', [Validators.required]],
            country: ['', [Validators.required]],
            license_key: ['', [Validators.required]]
        });
        this.createCustomerForm.get('country').valueChanges.subscribe(value => {
            switch (value) {
                case Countries.GERMANY:
                    this.createCustomerForm.get('zip').setValidators([Validators.required, Validators.pattern(/^[0-9]{5}$/)]);
                    break;

                case Countries.AUSTRIA:
                case Countries.LIECHTENSTEIN:
                case Countries.SWITZERLAND:
                    this.createCustomerForm.get('zip').setValidators([Validators.required, Validators.pattern(/^[0-9]{4}$/)]);
                    break;
            }
        });
        switch (navigator.language) {
            case 'de':
            case 'de-de':
                this.createCustomerForm.get('country').setValue(Countries.GERMANY);
                break;

            case 'de-at':
                this.createCustomerForm.get('country').setValue(Countries.AUSTRIA);
                break;

            case 'de-li':
                this.createCustomerForm.get('country').setValue(Countries.LIECHTENSTEIN);
                break;

            case 'de-ch':
                this.createCustomerForm.get('country').setValue(Countries.SWITZERLAND);
                break;

            default:
                this.createCustomerForm.get('country').setValue(Countries.GERMANY);
        }

        this.createAdminForm = this.fb.group({
            username: [this.DEFAULT_ADMIN_USERNAME, [Validators.required, Validators.pattern(/^[a-zA-Z0-9]*$/)]],
            password: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]]
        });

        // Übergangslösung gemäß B-0065: Buchstaben bei einer Eingabe in Kleinbuchstaben umwandlen
        this.createCustomerForm.get('id').valueChanges.subscribe(value => {
            this.createCustomerForm.get('id').setValue((value as string).toLowerCase(), { emitEvent: false });
        });
    }

    ngAfterViewInit() {
        if (this.stepper.selected.label === this.STEP_ADMIN && this.createAdminForm.get('username').value === this.DEFAULT_ADMIN_USERNAME) {
            this.passwordField.nativeElement.focus();
        }
    }

    async create(): Promise<void> {
        if (this.createCustomerForm.invalid || this.createAdminForm.invalid) {
            return;
        }

        const customer = new DBCustomer();
        customer.name = this.createCustomerForm.get('name').value;
        customer.customerId = this.createCustomerForm.get('id').value;
        customer.email = this.createCustomerForm.get('email').value;
        customer.street = this.createCustomerForm.get('street').value;
        customer.zip = this.createCustomerForm.get('zip').value;
        customer.city = this.createCustomerForm.get('city').value;
        customer.country = this.createCustomerForm.get('country').value;
        const licenseKey = this.createCustomerForm.get('license_key').value;

        const admin = new DBAdmin();
        admin.username = this.createAdminForm.get('username').value + '@' + customer.customerId;
        const password = this.createAdminForm.get('password').value;
        const email = this.createAdminForm.get('email').value;

        try {
            const createCustomerResponse = await this.registerService.createCustomer(customer, licenseKey).toPromise();
            if (!createCustomerResponse.customerIdAvailable) {
                throw 'Schulkürzel ist bereits vergeben';
            }
            if (!createCustomerResponse.licenseKeyCorrect) {
                throw 'Lizenzschlüssel ungültig';
            }

            const registerAdminResponse = await this.registerService.registerAdmin(admin, password, customer.customerId, email).toPromise();
            if (!registerAdminResponse.usernameSet || !registerAdminResponse.passwordSet || !registerAdminResponse.emailSet) {
                throw 'Fehler beim Erstellen des Admins';
            }

            const succeeded = await this.authService.login(admin.username, password, true).toPromise();
            if (succeeded) {
                await this.authService.setCustomerId(customer.customerId, true);
                this.router.navigate(['/app/initialSetup']);
            }
        } catch (err) {
            this.snackBar.open(err, 'OK');
        }
    }
}
