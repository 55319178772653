<mat-card id="registerCard">
    <mat-card-header>
        <h2>Erstmalige Einrichtung</h2>
    </mat-card-header>
    <mat-card-content>
        <mat-horizontal-stepper linear #stepper="matHorizontalStepper">
            <!-- Schritt 1: Schulserver -->
            <mat-step [stepControl]="schoolServerForm" label="Schulserver">
                <form [formGroup]="schoolServerForm">
                    <mat-list>
                        <mat-list-item style="padding-bottom: 25px;">
                            <mat-slide-toggle formControlName="hasSchoolServer">Schulserver vorhanden?
                            </mat-slide-toggle>
                        </mat-list-item>
                        <mat-list-item *ngIf="schoolServerForm.get('hasSchoolServer').value"
                            style="padding-bottom: 50px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Schulserver-Typ</mat-label>
                                <mat-select formControlName="schoolServerType">
                                    <mat-option>-</mat-option>
                                    <mat-option value="iserv">
                                        IServ
                                    </mat-option>
                                    <mat-option value="ucs">
                                        UCS at school
                                    </mat-option>
                                    <mat-option value="other">
                                        Sonstiges
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="schoolServerForm.get('schoolServerType').hasError('required')">
                                    Sie müssen einen Schulserver-Typ auswählen
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                    </mat-list>
                    <mat-divider></mat-divider>
                    <button mat-button matStepperNext>Weiter</button>
                </form>
            </mat-step>
            <!-- Schritt 2: WLAN-Netzwerk einrichten (BYOD und WLAN) -->
            <mat-step [stepControl]="ByodAndWifiNetworkForm" label="BYOD-Netzwerk">
                <form [formGroup]="ByodAndWifiNetworkForm">
                    <!-- <BYOD-Netzwerk -->
                    <mat-list>
                        <mat-list-item style="padding-bottom: 15px;">
                            <mat-slide-toggle formControlName="createLogicalNetwork"
                                (change)="resetForm($event, ByodAndWifiNetworkForm)">BYOD-Netzwerk anlegen?
                            </mat-slide-toggle>
                        </mat-list-item>
                        <ng-container *ngIf="ByodAndWifiNetworkForm.get('createLogicalNetwork').value">
                            <h3 mat-subheader>
                                VLAN
                            </h3>
                            <mat-list-item style="padding-bottom: 25px;">
                                <mat-slide-toggle formControlName="tagged">Tagged-VLAN</mat-slide-toggle>
                            </mat-list-item>
                            <mat-list-item *ngIf="ByodAndWifiNetworkForm.get('tagged').value"
                                style="padding-bottom: 35px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>VLAN-ID</mat-label>
                                    <input type="number" min="2" max="4093" formControlName="vlan_id" matInput />
                                    <mat-error *ngIf="ByodAndWifiNetworkForm.get('vlan_id').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                    <mat-error
                                        *ngIf="ByodAndWifiNetworkForm.get('vlan_id').hasError('min') || ByodAndWifiNetworkForm.get('vlan_id').hasError('max')">
                                        Die VLAN-ID muss zwischen 2 und 4093 liegen
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                        </ng-container>
                        <mat-divider></mat-divider>
                        <!-- <WLAN-Netzwerk -->
                        <mat-list-item style="padding-top: 15px; padding-bottom: 15px;">
                            <mat-slide-toggle formControlName="createWifiNetwork">WLAN-Netzwerk einrichten?
                            </mat-slide-toggle>
                        </mat-list-item>
                        <ng-container *ngIf="ByodAndWifiNetworkForm.get('createWifiNetwork').value">
                            <mat-list-item style="padding-top: 10px; padding-bottom: 15px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>SSID</mat-label>
                                    <input formControlName="ssid" matInput />
                                    <mat-error *ngIf="ByodAndWifiNetworkForm.get('ssid').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                    <mat-error *ngIf="ByodAndWifiNetworkForm.get('ssid').hasError('maxlength')">
                                        SSID darf höhstens 32 Zeichen enthalten
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <h3 mat-subheader>
                                Verschlüsselung
                            </h3>
                            <mat-list-item style="padding-bottom: 25px;">
                                <mat-slide-toggle formControlName="encryption">Verschlüsselung</mat-slide-toggle>
                            </mat-list-item>
                            <mat-list-item *ngIf="ByodAndWifiNetworkForm.get('encryption').value"
                                style="padding-bottom: 15px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Verschlüsselungsmodus</mat-label>
                                    <mat-select formControlName="encryption_mode">
                                        <mat-option>-</mat-option>
                                        <mat-option value="psk">
                                            WPA2 Pre-Shared-Key
                                        </mat-option>
                                        <mat-option value="enterprise">
                                            Enterprise
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="ByodAndWifiNetworkForm.get('encryption_mode').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <mat-list-item
                                *ngIf="ByodAndWifiNetworkForm.get('encryption').value && ByodAndWifiNetworkForm.get('encryption_mode').value === 'psk'"
                                style="padding-top: 35px; padding-bottom: 15px;">
                                <mat-form-field appearance="outline" style="padding-bottom: 15px;">
                                    <mat-label>WLAN-Schlüssel</mat-label>
                                    <input matInput formControlName="psk_key" />
                                    <mat-error *ngIf="ByodAndWifiNetworkForm.get('psk_key').hasError('required')">
                                        Sie müssen den WLAN-Schlüssel eingeben
                                    </mat-error>
                                    <mat-error
                                        *ngIf="ByodAndWifiNetworkForm.get('psk_key').hasError('minlength') || ByodAndWifiNetworkForm.get('psk_key').hasError('maxlength')">
                                        WLAN-Schlüssel <strong>8-63 Zeichen</strong>
                                    </mat-error>
                                    <mat-error *ngIf="ByodAndWifiNetworkForm.get('psk_key').hasError('pattern')">
                                        WLAN-Schlüssel enthält nicht erlaubte Zeichen
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <ng-container
                                *ngIf="ByodAndWifiNetworkForm.get('encryption').value && ByodAndWifiNetworkForm.get('encryption_mode').value === 'enterprise'">
                                <mat-list-item style="padding-top: 35px; padding-bottom: 15px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Radius-Server IP-Adresse</mat-label>
                                        <input matInput formControlName="enterprise_radius_ip" />
                                        <mat-error
                                            *ngIf="ByodAndWifiNetworkForm.get('enterprise_radius_ip').hasError('invalid_ip')">
                                            Ungültige IP-Adresse
                                        </mat-error>
                                    </mat-form-field>
                                </mat-list-item>
                                <mat-list-item style="padding-top: 35px; padding-bottom: 15px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Radius-Server Port</mat-label>
                                        <input matInput formControlName="enterprise_radius_port" />
                                        <mat-error
                                            *ngIf="ByodAndWifiNetworkForm.get('enterprise_radius_port').hasError('required')">
                                            Dieses Feld darf nicht leer sein
                                        </mat-error>
                                        <mat-error
                                            *ngIf="ByodAndWifiNetworkForm.get('enterprise_radius_port').hasError('min') || ByodAndWifiNetworkForm.get('enterprise_radius_port').hasError('max')">
                                            Der Port darf mindestens 0 und maximal 65535 sein
                                        </mat-error>
                                    </mat-form-field>
                                </mat-list-item>
                                <mat-list-item style="padding-top: 35px; padding-bottom: 15px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Radius-Server Shared Secret</mat-label>
                                        <input matInput formControlName="enterprise_radius_secret" />
                                        <mat-error
                                            *ngIf="ByodAndWifiNetworkForm.get('enterprise_radius_secret').hasError('required')">
                                            Ungültige Secret
                                        </mat-error>
                                    </mat-form-field>
                                </mat-list-item>
                            </ng-container>
                            <h3 mat-subheader>
                                Zeitsteuerung
                            </h3>
                            <endoo-times-control [times]="timesControlDataByod" [alwaysFullWidth]="true"
                                (onChange)="timeSettingsChanged($event, byodWifiNetwork)">
                            </endoo-times-control>
                        </ng-container>
                    </mat-list>
                    <mat-divider></mat-divider>
                    <button mat-button matStepperPrevious>Zurück</button>
                    <button mat-button matStepperNext>Weiter</button>
                </form>
            </mat-step>
            <!-- Schritt 3: pädagogisches Netzwerk und WLAN einrichten -->
            <mat-step [stepControl]="PaedAndWifiNetworkForm" label="Pädagogisches Netzwerk">
                <form [formGroup]="PaedAndWifiNetworkForm">
                    <!-- pädagogisches Netzwerk -->
                    <mat-list>
                        <mat-list-item style="padding-bottom: 15px;">
                            <mat-slide-toggle formControlName="createLogicalNetwork"
                                (change)="resetForm($event, ByodAndWifiNetworkForm)">P&auml;dagogisches Netzwerk
                                anlegen?
                            </mat-slide-toggle>
                        </mat-list-item>
                        <ng-container *ngIf="PaedAndWifiNetworkForm.get('createLogicalNetwork').value">
                            <h3 mat-subheader>
                                VLAN
                            </h3>
                            <mat-list-item style="padding-bottom: 25px;">
                                <mat-slide-toggle formControlName="tagged">Tagged-VLAN</mat-slide-toggle>
                            </mat-list-item>
                            <mat-list-item *ngIf="PaedAndWifiNetworkForm.get('tagged').value"
                                style="padding-bottom: 35px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>VLAN-ID</mat-label>
                                    <input type="number" formControlName="vlan_id" matInput />
                                    <mat-error *ngIf="PaedAndWifiNetworkForm.get('vlan_id').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                    <mat-error
                                        *ngIf="PaedAndWifiNetworkForm.get('vlan_id').hasError('min') || PaedAndWifiNetworkForm.get('vlan_id').hasError('max')">
                                        Die VLAN-ID muss zwischen 2 und 4093 liegen
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                        </ng-container>
                        <mat-divider></mat-divider>
                        <!-- <WLAN-Netzwerk -->
                        <mat-list-item style="padding-top: 15px; padding-bottom: 15px;">
                            <mat-slide-toggle formControlName="createWifiNetwork">
                                WLAN-Netzwerk einrichten?
                            </mat-slide-toggle>
                        </mat-list-item>
                        <ng-container *ngIf="PaedAndWifiNetworkForm.get('createWifiNetwork').value">
                            <mat-list-item style="padding-top: 10px; padding-bottom: 15px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>SSID</mat-label>
                                    <input formControlName="ssid" matInput />
                                    <mat-error *ngIf="PaedAndWifiNetworkForm.get('ssid').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                    <mat-error *ngIf="PaedAndWifiNetworkForm.get('ssid').hasError('maxlength')">
                                        SSID darf höhstens 32 Zeichen enthalten
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-checkbox formControlName="client2client" matInput>
                                    Client2Client
                                </mat-checkbox>
                            </mat-list-item>
                            <h3 mat-subheader>
                                Verschlüsselung
                            </h3>
                            <mat-list-item style="padding-bottom: 25px;">
                                <mat-slide-toggle formControlName="encryption">Verschlüsselung</mat-slide-toggle>
                            </mat-list-item>
                            <mat-list-item *ngIf="PaedAndWifiNetworkForm.get('encryption').value"
                                style="padding-bottom: 15px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>Verschlüsselungsmodus</mat-label>
                                    <mat-select formControlName="encryption_mode">
                                        <mat-option>-</mat-option>
                                        <mat-option value="psk">
                                            WPA2 Pre-Shared-Key
                                        </mat-option>
                                        <mat-option value="enterprise">
                                            Enterprise
                                        </mat-option>
                                    </mat-select>
                                    <mat-error
                                        *ngIf="PaedAndWifiNetworkForm.get('encryption_mode').hasError('required')">
                                        Dieses Feld darf nicht leer sein
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <mat-list-item *ngIf="PaedAndWifiNetworkForm.get('encryption').value &&
                                    PaedAndWifiNetworkForm.get('encryption_mode').value === 'psk'"
                                style="padding-top: 35px; padding-bottom: 15px;">
                                <mat-form-field appearance="outline">
                                    <mat-label>WLAN-Schlüssel</mat-label>
                                    <input matInput formControlName="psk_key" />
                                    <mat-error *ngIf="PaedAndWifiNetworkForm.get('psk_key').hasError('required')">
                                        Sie müssen den WLAN-Schlüssel eingeben
                                    </mat-error>
                                    <mat-error
                                        *ngIf="PaedAndWifiNetworkForm.get('psk_key').hasError('minlength') || PaedAndWifiNetworkForm.get('psk_key').hasError('maxlength')">
                                        WLAN-Schlüssel <strong>8-63 Zeichen</strong>
                                    </mat-error>
                                    <mat-error *ngIf="PaedAndWifiNetworkForm.get('psk_key').hasError('pattern')">
                                        WLAN-Schlüssel enthält nicht erlaubte Zeichen
                                    </mat-error>
                                </mat-form-field>
                            </mat-list-item>
                            <ng-container
                                *ngIf="PaedAndWifiNetworkForm.get('encryption').value && PaedAndWifiNetworkForm.get('encryption_mode').value === 'enterprise'">
                                <mat-list-item style="padding-top: 35px; padding-bottom: 15px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Radius-Server IP-Adresse</mat-label>
                                        <input matInput formControlName="enterprise_radius_ip" />
                                        <mat-error
                                            *ngIf="PaedAndWifiNetworkForm.get('enterprise_radius_ip').hasError('invalid_ip')">
                                            Ungültige IP-Adresse
                                        </mat-error>
                                    </mat-form-field>
                                </mat-list-item>
                                <mat-list-item style="padding-top: 35px; padding-bottom: 15px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Radius-Server Port</mat-label>
                                        <input matInput formControlName="enterprise_radius_port" />
                                        <mat-error
                                            *ngIf="PaedAndWifiNetworkForm.get('enterprise_radius_port').hasError('pattern')">
                                            Ungültiger Port
                                        </mat-error>
                                        <mat-error
                                            *ngIf="PaedAndWifiNetworkForm.get('enterprise_radius_port').hasError('required')">
                                            Dieses Feld darf nicht leer sein
                                        </mat-error>
                                    </mat-form-field>
                                </mat-list-item>
                                <mat-list-item style="padding-top: 35px; padding-bottom: 15px;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Radius-Server Shared Secret</mat-label>
                                        <input matInput formControlName="enterprise_radius_secret" />
                                        <mat-error
                                            *ngIf="PaedAndWifiNetworkForm.get('enterprise_radius_secret').hasError('required')">
                                            Ungültige Secret
                                        </mat-error>
                                    </mat-form-field>
                                </mat-list-item>
                            </ng-container>
                            <h3 mat-subheader>
                                Zeitsteuerung
                            </h3>
                            <endoo-times-control [times]="timesControlDataPaed" [alwaysFullWidth]="true"
                                (onChange)="timeSettingsChanged($event, paedWifiNetwork)">
                            </endoo-times-control>
                        </ng-container>
                    </mat-list>
                    <mat-divider></mat-divider>
                    <button mat-button matStepperPrevious [disabled]="buttons_disabled">Zurück</button>
                    <button mat-button matStepperNext [disabled]="buttons_disabled"
                        (click)="createNetworks()">Abschließen</button>
                </form>
            </mat-step>
        </mat-horizontal-stepper>
    </mat-card-content>
</mat-card>