import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'datetime',
    pure: false,
})
export class DatetimePipe implements PipeTransform {

    transform(value: string): string {
        return moment(value).format('DD.MM.YYYY HH:mm');
    }
}
