import { Component, ViewChild, Inject, ApplicationRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Group } from '../../data/Group';
import { User } from '../../data/User';
import { GroupService } from '../GroupService/group.service';
import { UserService } from '../../UserManagement/UserService/user.service';
import { deleteGroupDialogComponent } from './GroupListDialogs.component';
import { AuthenticationService } from '../../Login/authentication.service';
import { ConfirmDialogComponent } from 'app/Components/confirm-dialog-component/confirm-dialog.component';

export type GroupsUserSearchResult = {
    label: string,
    value: string
};

@Component({
    templateUrl: './GroupDetail.dialog.component.html',
    styleUrls: ['./GroupDetail.dialog.component.scss'],
})
export class groupDetailDialogComponent {
    displayedColumns: string[] = ['username', 'givenname', 'surname', 'wlan', 'Add_Delete', 'Delete_User'];
    found_users: GroupsUserSearchResult[];
    group: Group = new Group();
    groupname: string = '';
    addUserSearchstring: string;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('autocompleteInput', { read: MatAutocompleteTrigger }) autoCompleteInput: MatAutocompleteTrigger;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    members: MatTableDataSource<User>;
    form: FormGroup;

    constructor(
        public authService: AuthenticationService,
        private dialog: MatDialog,
        private groupService: GroupService,
        private ref: ApplicationRef,
        private userService: UserService,
        private dialogRef: MatDialogRef<groupDetailDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private snackBar: MatSnackBar,
        private fb: FormBuilder
    ) {
        this.groupname = data.groupname;
        this.members = new MatTableDataSource();
        this.getGroup();

        this.form = this.fb.group({
            name: ['', [Validators.required]],
            description: ['', [Validators.required]]
        });
    }

    async getGroup(): Promise<void> {
        try {
            this.group = await this.groupService.getGroup(this.groupname).toPromise();
            this.members.data = this.group.members;
            this.members.sort = this.sort;
            this.members.paginator = this.paginator;
            this.preparePaginator();
        } catch (err) {
            this.snackBar.open('Gruppen konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('groupDetail.pageSize')) {
            this.members.paginator.pageSize = Number(localStorage.getItem('groupDetail.pageSize'));
            this.members.paginator = this.paginator; // Update the paginator
        }
        this.members.paginator._changePageSize = pageSize => {
            localStorage.setItem('groupDetail.pageSize', String(pageSize));
            this.members.paginator.pageSize = pageSize;
            this.members.paginator = this.paginator; // Update the paginator
        };
    }

    async changeUserSeachstring(): Promise<void> {
        try {
            const data = await this.userService.search(this.addUserSearchstring).toPromise();
            this.found_users = data;
        } catch (err) {
            this.snackBar.open('Benutzersuche fehlgeschlagen: ' + err, 'OK');
        }
    }

    /**
     * Function which will be called when User clicks on the cancel button of the new Group dialog. via angular.
     */
    closeGroupDetailBtClick(): void {
        this.dialogRef.close();
    }

    /**
     * Function which will be called when User clicks on the save button of the new Group dialog. via angular.
     */
    async saveChangeGroupBtClick(): Promise<void> {
        if (!this.form.valid) {
            return;
        }
        try {
            await this.groupService.changeDescription(this.groupname, this.group.description).toPromise();
            this.GroupChanged();
        } catch (err) {
            this.snackBar.open('Die Beschreibung konnte nicht geändert werden: ' + err, 'OK');
        }
        try {
            await this.groupService.changeGroupname(this.groupname, this.group.groupname).toPromise();
            this.GroupChanged();
        } catch (err) {
            this.snackBar.open('Der Gruppenname konnte nicht geändert werden: ' + err, 'OK');
        }
        this.dialogRef.close();
    }

    /**
     * Function which will be called when User clicks on the save button of the new Group dialog. via angular.
     */
    async deleteChangeGroupBtClick(): Promise<void> {
        const deleteGroupdialogRef = this.dialog.open(deleteGroupDialogComponent, {
            width: '250px',
            data: { groupname: this.groupname }
        });
        deleteGroupdialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close();
            }
        });
    }

    /**
     * Function which will be called when the ajax request for changing a Group was successful
     */
    GroupChanged(): void {
        this.snackBar.open('Die Gruppe wurde erfolgreich geändert', 'OK');
        this.dialogRef.close();
    }

    /**
     * Function which will be called when an ajax request which changed a user has been executed successfully. 
     */
    UserChanged(): void {
        this.getGroup();
    }

    async allowGroupMembersWLAN(): Promise<void> {
        try {
            this.group.members.forEach(async (member) => {
                await this.userService.changeWLAN(member.username, true).toPromise();
                this.UserChanged();
            });
        } catch (err) {
            this.snackBar.open('WLAN konnte nicht freigeschaltet werden: ' + err, 'OK');
        }
    }

    /**
     * function which will disable wlan for all members of the group
     */
    async disallowGroupMembersWLAN(): Promise<void> {
        try {
            this.group.members.forEach(async (member) => {
                await this.userService.changeWLAN(member.username, false).toPromise();
                this.UserChanged();
            });
        } catch (err) {
            this.snackBar.open('WLAN konnte nicht abgeschaltet werden: ' + err, 'OK');
        }
    };

    searchUserBtClick(): void {
        setTimeout(() => {
            this.autoCompleteInput.openPanel();
            this.ref.tick();
        }, 100);
    }

    async addUser(): Promise<void> {
        if (!this.addUserSearchstring) {
            return;
        }

        try {
            const result_value = await this.groupService.addPersontoGroup(this.groupname, this.addUserSearchstring).toPromise();
            this.addUserSearchstring = '';
            if (result_value) {
                this.getGroup();
            } else {
                this.snackBar.open('Der Benutzer konnte nicht hinzugefügt werden: Benutzer nicht vorhanden', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Der Benutzer konnte nicht hinzugefügt werden: ' + err, 'OK');
        }
    }

    /**
     * function for removing a user from a distinct group (called by angular when button is clicked)
     */
    async removeUser(username): Promise<void> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Benutzer aus Gruppe entfernen',
                message: 'Soll der Benutzer aus der Gruppe entfernt werden?',
                yesButtonText: 'Ja',
           }
        });
        const result = await dialogRef.afterClosed().toPromise();
        if (!result) {
            return;
        }
        try {
            const result_value = await this.groupService.removePersonfromGroup(this.groupname, username).toPromise();
            if (result_value) {
                this.getGroup();
            } else {
                this.snackBar.open('Benutzer konnte nicht entfernt werden. (Gruppe benötigt mind. 1 Mitglied)', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Benutzer konnte nicht entfernt werden: ' + err, 'OK');
        }
    }

    /**
     * function for delteting a user from the system entirely
     */
     async deleteUser(username :string): Promise<void> {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Benutzer endgültig löschen',
                message: 'Soll der Benutzer komplett aus der Benutzerverwaltung gelöscht werden?',
                yesButtonText: 'Ja',
            }
        });
        const result = await dialogRef.afterClosed().toPromise();
        if (!result) {
            return;
        }
        try {
            let result_value = await this.groupService.removePersonfromGroup(this.groupname, username).toPromise();
            if (!result_value) {
                throw new Error('Fehler beim entfernen');
            }
            result_value = await this.userService.delete(username).toPromise();
            if (!result_value) {
                throw new Error('Fehler beim löschen');
            }
            this.getGroup();
        } catch (err) {
            this.snackBar.open('Benutzer konnte nicht gelöscht werden: ' + err, 'OK');
        }
    }

    /**
     * function to enable wlan for a person which is member in a group
     */
    async wlanChanged(member): Promise<void> {
        try {
            await this.userService.changeWLAN(member.username, member.wlan).toPromise();
        } catch (err) {
            this.snackBar.open('WLAN konnte nicht geändert werden: ' + err, 'OK');
        }
        this.UserChanged();
    };

    convertMemberstoCSV(): string {
        let result, columnDelimiter, lineDelimiter;
        columnDelimiter = ',';
        lineDelimiter = '\n';
        result = '';
        this.members.data.forEach(function (item) {
            result += item.username + columnDelimiter + item.surname + columnDelimiter + item.givenname;
            result += lineDelimiter;
        });

        return result;
    }

    downloadCSV(event: MouseEvent): void {
        event.preventDefault();
        let filename, data, link: HTMLElement;

        let csv = this.convertMemberstoCSV();
        if (csv === '') {
            return;
        }

        filename = 'export_members.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            csv = 'data:text/csv;charset=utf-8,' + csv;
        }
        data = encodeURI(csv);

        link = document.createElement('a');
        document.body.appendChild(link);
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.members.filter = filterValue.trim().toLowerCase();
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
