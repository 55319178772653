import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './multipleApSaveConfirm.dialog.component.html',
})
export class MultipleApSaveConfirmDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<MultipleApSaveConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public inventoryNumbers: string[]
    ) { }

    confirm(): void {
        this.dialogRef.close(true);
    }

    cancel(): void {
        this.dialogRef.close(false);
    }
}
