import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { WifiTime } from 'app/Components/times-control/wifi-time';
import { TimesControlData } from 'app/Components/times-control/times-control-data';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'endoo-timespan',
    templateUrl: 'timespan.component.html',
    styleUrls: ['timespan.component.scss'],
})
export class TimespanComponent {
    @Input('weekdayId') weekdayId: number;
    @Input('timespan') set timespan(timespan: TimesControlData) {
        const onValue = timespan.times.find(v => v.weekdayId === this.weekdayId).onValue;
        this.minValue = this.times.findIndex(v => v === onValue);
        const offValue = timespan.times.find(v => v.weekdayId === this.weekdayId).offValue;
        this.maxValue = this.times.findIndex(v => v === offValue);
    };
    @Output() changed: EventEmitter<WifiTime> = new EventEmitter();
    times: string[] = [];
    minValue: number = 0;
    maxValue: number = 47;
    options: Options = {
        floor: 0,
        ceil: 47, // 48-1 different options for the time value,
        keyboardSupport: false,
        translate: (value: number, label: LabelType): string => {
            switch (label) {
                case LabelType.Low:
                case LabelType.High:
                    return this.times[value].substring(0, 2) + ':' + this.times[value].substring(2, this.times[value].length);

                case LabelType.Floor:
                    return '00:00 Uhr';

                case LabelType.Ceil:
                    return '23:30 Uhr';

                default:
                    return '';
            }
        },
    };

    constructor() {
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j <= 3; j += 3) {
                let hour = i.toString();
                let minute = j + '0';
                if (i < 10) {
                    hour = '0' + i;
                }
                this.times.push(hour + minute);
            }
        }
    }

    onUserChangeEnd(changeContext: ChangeContext): void {
        this.changed.emit({ weekdayId: this.weekdayId, onValue: this.times[changeContext.value], offValue: this.times[changeContext.highValue] });
    }
}
