<form [formGroup]="form" (ngSubmit)="saveNetworkBtClick()">
    <mat-list style="padding-top: 50px;">
        <mat-list-item class="mat-list-item-input">
            <mat-form-field style="margin-bottom: 50px;" appearance="outline">
                <mat-label>Name</mat-label>
                <input formControlName="name"
                    [disabled]="!authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" matInput
                    [(ngModel)]="Network.name" required />
                <mat-error *ngIf="hasError('name', 'required')">Netzwerkname erforderlich
                </mat-error>
            </mat-form-field>
        </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list>
        <h3 *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-subheader>
            VLAN-Zuordnung
        </h3>
        <mat-list-item *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
            class="mat-list-item-input">
            <section class="toggle-section">
                <mat-slide-toggle formControlName="untagged" [(ngModel)]="Network.untagged">
                    Untagged-/Verwaltungsnetzwerk
                </mat-slide-toggle>
            </section>
        </mat-list-item>
        <mat-list-item *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" class="mat-list-item-input"
            style="padding-top: 15px; padding-bottom: 25px;">
            <mat-form-field appearance="outline">
                <mat-label>VLAN-ID</mat-label>
                <input formControlName="vlan_id" matInput type="number" min="2" max="4093"
                    [(ngModel)]="Network.vlan_id" required />
                <mat-error
                    *ngIf="hasError('vlan_id', 'required') || hasError('vlan_id', 'min') || hasError('vlan_id', 'max')">
                    VLAN-ID muss zwischen 2 und 4093 liegen
                </mat-error>
            </mat-form-field>
        </mat-list-item>
    </mat-list>
    <mat-divider></mat-divider>
    <mat-list style="padding-bottom: 20px;">
        <h3 *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-subheader>
            Farb-Zuordnung *
        </h3>
        <mat-list-item *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"
            class="mat-list-item-input">
            <color-picker [colors]="lnetworkService.Colors" [deactivatedColors]="notAvailableColors"
                formControlName="color"></color-picker>
        </mat-list-item>
    </mat-list>
    <mat-divider *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')"></mat-divider>
    <mat-list>
        <mat-list-item>
            <div mat-line>
                <button
                    [disabled]="!authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || background_working"
                    mat-raised-button color="primary" class="button-margin-bottom button-margin-top button-margin-right"
                    title="Speichern" type="submit">Speichern</button>
                <button [disabled]="!authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')" mat-raised-button
                    class="button-margin-bottom button-margin-top" (click)="deleteNetworkBtClick()"
                    type="button">Netzwerk
                    l&ouml;schen</button>
            </div>
        </mat-list-item>
    </mat-list>
</form>
