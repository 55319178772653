<h1 mat-dialog-title>Untagged Netzwerk festlegen</h1>
<div mat-dialog-content>
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let network of networks; let i = index" (click)="onChooseNetwork(i)"
            class="network-entry">
            <div class="div-flex">
                <div class="div-entry-container network-name-circle-symbol" [style.backgroundColor]="network.color">
                </div>
                <div>{{network.name}}</div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</div>
<div mat-dialog-actions>
    <button class="button-margin-top" mat-raised-button (click)="closeDialog(false)">
        Schließen
    </button>
</div>
