<h1 mat-dialog-title>Benutzer anlegen</h1>
<div mat-dialog-content style="max-width: 723px;">
	<mat-horizontal-stepper linear=true #newuserstepper="matHorizontalStepper">
		<mat-step [stepControl]="newUserForm" style="padding-bottom:20px;" label="Benutzer erstellen">
			<form [formGroup]="newUserForm">
				<div class="fullwidth_form" style="margin-top: 20px;">
					<mat-form-field appearance="outline">
						<mat-label>Benutzergruppe</mat-label>
						<mat-select formControlName="usergroup" [(ngModel)]="user_new.group" class="fullwidth" required>
							<mat-option value="pupil">Sch&uuml;ler</mat-option>
							<mat-option value="teacher">Lehrer</mat-option>
							<mat-option value="external">Externe Person</mat-option>
						</mat-select>
						<mat-error *ngIf="hasError('usergroup', 'required')">Benutzergruppe erforderlich
						</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Vorname</mat-label>
						<input formControlName="givenname" matInput class="fullwidth" type="text"
							[(ngModel)]="user_new.givenname" required />
						<mat-error *ngIf="hasError('givenname', 'required')">Vorname erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('givenname', 'pattern')">Vorname darf nur aus Zahlen, Buchstaben und
							Leerzeichen bestehen
						</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Nachname</mat-label>
						<input formControlName="surname" matInput type="text" [(ngModel)]="user_new.surname" required />
						<mat-error *ngIf="hasError('surname', 'required')">Nachname erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('surname', 'pattern')">Nachname darf nur aus Zahlen, Buchstaben und
							Leerzeichen bestehen
						</mat-error>
					</mat-form-field>
					<mat-form-field *ngIf="user_new.group === 'teacher'" appearance="outline">
						<mat-label>Kürzel</mat-label>
						<input formControlName="shorten" matInput type="text" [(ngModel)]="user_new.shorten" required />
						<mat-error *ngIf="hasError('shorten', 'required')">Kürzel erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('shorten', 'pattern')">Kürzel darf nur aus Zahlen, Buchstaben und
							Leerzeichen bestehen
						</mat-error>
					</mat-form-field>
					<mat-form-field *ngIf="user_new.group === 'external'" appearance="outline">
						<mat-label>Benutzername</mat-label>
						<input formControlName="username" matInput type="text" [(ngModel)]="user_new.username"
							required />
						<mat-error *ngIf="hasError('username', 'required')">Benutzername erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('username', 'pattern')">Benutzername darf nur aus Zahlen, Buchstaben
							und
							Leerzeichen bestehen
						</mat-error>
					</mat-form-field>
					<mat-form-field *ngIf="user_new.group === 'pupil'" appearance="outline">
						<mat-label>Abschlussjahr (JJJJ)</mat-label>
						<input formControlName="leavingyear" matInput type="text" [(ngModel)]="user_new.leavingyear"
							required />
						<mat-error *ngIf="hasError('leavingyear', 'required')">Abschlussjahr erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('leavingyear', 'minlength') || hasError('leavingyear', 'maxlength')">
							4 stelliges Abschlussjahr erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('leavingyear', 'pattern')">Abschlussjahr darf nur aus Zahlen bestehen
						</mat-error>
					</mat-form-field>
					<mat-form-field *ngIf="user_new.group === 'pupil'" appearance="outline">
						<mat-label>Geburtsjahr (JJJJ)</mat-label>
						<input formControlName="birthyear" matInput type="text" [(ngModel)]="user_new.birthyear"
							required />
						<mat-error *ngIf="hasError('birthyear', 'required')">Geburtsjahr erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('birthyear', 'minlength') || hasError('birthyear', 'maxlength')">4
							stelliges Geburtsjahr erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('birthyear', 'pattern')">Geburtsjahr darf nur aus Zahlen bestehen
						</mat-error>
					</mat-form-field>
				</div>
				<div>
					<section class="toggle-section">
						<mat-slide-toggle [ngModelOptions]="{standalone: true}" [(ngModel)]="user_new.wlan">WLAN
						</mat-slide-toggle>
					</section>
				</div>
				<div class="fullwidth_form">
					<mat-form-field appearance="outline">
						<mat-label>Benutzer-Passwort</mat-label>
						<input formControlName="password" matInput type="text" [(ngModel)]="user_new.password"
							required />
						<mat-error *ngIf="hasError('password', 'required')">Benutzer-Passwort erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('password', 'minlength')">Benutzer-Passwort muss mind. 10 Zeichen
							lang sein
						</mat-error>
					</mat-form-field>
				</div>
				<mat-divider></mat-divider>
				<div>
					<button mat-button matStepperNext>Weiter</button>
					<button mat-button (click)="dialogRef.close()">Schließen</button>
				</div>
			</form>
		</mat-step>
		<mat-step label="Eingaben &uuml;berpr&uuml;fen">
			<div class="fullwidth_form">
				<mat-form-field appearance="outline">
					<mat-label>Benutzergruppe</mat-label>
					<mat-select [(ngModel)]="user_new.group" class="fullwidth" disabled appearance="outline">
						<mat-option value="pupil">Sch&uuml;ler</mat-option>
						<mat-option value="teacher">Lehrer</mat-option>
						<mat-option value="external">Externe Person</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Vorname</mat-label>
					<input matInput class="fullwidth" type="text" [(ngModel)]="user_new.givenname" disabled />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Nachname</mat-label>
					<input matInput type="text" [(ngModel)]="user_new.surname" disabled />
				</mat-form-field>
				<mat-form-field *ngIf="user_new.group === 'teacher'" appearance="outline">
					<mat-label>Kürzel</mat-label>
					<input matInput type="text" [(ngModel)]="user_new.shorten" disabled />
				</mat-form-field>
				<mat-form-field *ngIf="user_new.group === 'external'" appearance="outline">
					<mat-label>Benutzername</mat-label>
					<input matInput type="text" [(ngModel)]="user_new.username" disabled />
				</mat-form-field>
				<mat-form-field *ngIf="user_new.group === 'pupil'" appearance="outline">
					<mat-label>Abschlussjahr (JJJJ)</mat-label>
					<input matInput type="text" [(ngModel)]="user_new.leavingyear" disabled />
				</mat-form-field>
				<mat-form-field *ngIf="user_new.group === 'pupil'" appearance="outline">
					<mat-label>Geburtsjahr (JJJJ)</mat-label>
					<input matInput type="text" [(ngModel)]="user_new.birthyear" disabled />
				</mat-form-field>
			</div>
			<div>
				<section class="toggle-section">
					<mat-slide-toggle [(ngModel)]="user_new.wlan" disabled>WLAN
					</mat-slide-toggle>
				</section>
			</div>
			<div class="fullwidth_form">
				<mat-form-field appearance="outline">
					<mat-label>Benutzer-Passwort</mat-label>
					<input matInput type="text" [(ngModel)]="user_new.password" disabled />
				</mat-form-field>
			</div>
			<mat-divider></mat-divider>
			<div>
				<button mat-button matStepperPrevious>Zur&uuml;ck</button>
				<button mat-button (click)="createNewUserBtClick(newuserstepper)">Benutzer anlegen</button>
			</div>
		</mat-step>
		<mat-step label="Benutzervertrag">
			<div id="vertraege_div">
				<iframe id="contract_pdf" [src]="contract_link" width="100%" height="300"></iframe>
				<a target="_blank" [href]="contract_link" id="link">
					<h4>Datei in neuem Fenster öffnen</h4>
				</a>
				<mat-divider></mat-divider>
				<button mat-button (click)="closeDialogBtClick()">Schlie&szlig;en</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>
