import { Injectable } from '@angular/core';
import { SwitchInformationDB } from 'app/data/SwitchInformation';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { Observable } from 'rxjs';

@Injectable()
export class SwitchService {
    private readonly switchURL = '/api/switch/';

    constructor(
        private http: EndooHttpService
    ) { }

    /**
     * @param serialNumber The serial number of a switch.
     */
    getSwitchInformation(serialNumber: string): Observable<SwitchInformationDB> {
        return this.http.get<SwitchInformationDB>(this.switchURL + serialNumber, { observe: 'response', responseType: 'json' });
    }
}
