import { Component, OnInit } from '@angular/core';
import { AuthenticationService, CustomerNameAndId } from '../Login/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ChooseCustomerComponent } from 'app/Login/chooseCustomer.component';
import { LicenseKeyGenerationDialogComponent } from 'app/DemoAccess/LicenseKeyGenerationDialog/license-key-generation.dialog.component';
import { environment } from 'environments/environment';
import { AppService } from 'app/Services/app.service';
import { Router } from '@angular/router';

@Component({
    templateUrl: './endooSpotApp.component.html',
    styleUrls: ['./endooSpotApp.component.css'],
})
export class endooSpotAppComponent implements OnInit {
    customers: CustomerNameAndId[] = [];
    selectedCustomer: string;
    enableInternalApp: boolean = true; // if user is not logged in the value will be set to false by can Activate Authguard because if it loads the login page the user will be logged out.
    appTitle: string = environment.appTitle;

    constructor(
        public authenticationService: AuthenticationService,
        private dialog: MatDialog,
        public appService: AppService,
        private router: Router
    ) { }

    ngOnInit() {
        this.customers = this.authenticationService.getAdminCustomersNameAndId();
        this.authenticationService.userIsLoggedIn.subscribe(login => {
            this.enableInternalApp = login.successful;
        });
        this.authenticationService.sessionCustomerEmitter.subscribe(customer => {
            this.selectedCustomer = customer.customerId;
            if (!customer.newlyRegistered) {
                location.reload();
            }
        });
    }

    logout(event: MouseEvent): void {
        this.authenticationService.logout();
        event.preventDefault();
    }

    changeCustomer(event: MouseEvent): void {
        event.preventDefault();
        const dialog = this.dialog.open(ChooseCustomerComponent,{width:"90vh", maxWidth:"700px",});
        dialog.componentInstance.CustomerSelected.subscribe(customer => {
            dialog.close();
            location.reload();
        });
    }

    openGenerateLicenseKeyDialog(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(LicenseKeyGenerationDialogComponent);
    }

    gotoInitialSetupPage(): void {
        this.router.navigate(['/app/initialSetup']);
    }

    openChangePasswordApp(event: MouseEvent): void {
        event.preventDefault();
        open('https://passwort.endoo.eu');
    }
}
