import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBAdmin, DBRole } from '../../data/endooSpotDB_objects';
import { AdminService } from '../AdminService/admin.service';
import { AuthenticationService } from '../../Login/authentication.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { deleteAdminDialogComponent } from './DeleteAdminDialogComponent';

@Component({
    templateUrl: './AdminDetail.dialog.component.html',
})
export class AdminDetailDialogComponent {
    form: FormGroup;
    private changedPassword: boolean;
    public admin: DBAdmin;
    public new_password: string;
    public customer_roles: DBRole[] = [];
    public authorizedRoles: DBRole[] = [];

    /**
     * Constructor for the admin detail dialog component. All params except data will be injected by angular.
     * @param dialog dialog reference which will be injected by angular to open different dialogs.
     * @param adminService Service bean for admin changes. Will be injected by angular.
     * @param authService Service bean which holds everything regarding authorization of the user who is logged in. Will be injected by angular.
     * @param dialogRef A reference object of the self dialog
     * @param snackBar Snack bar object to handle snack bar functions. Will be injected by angular
     * @param data Data object which will be inserted by the function which will open the dialog. It needs an admin object inside the data object.
     */
    constructor(
        private dialog: MatDialog,
        private adminService: AdminService,
        public authService: AuthenticationService,
        private dialogRef: MatDialogRef<AdminDetailDialogComponent>,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder) {
        this.admin = data.admin;
        if (this.admin.description == undefined) {
            this.admin.description = '';
        }
        if (this.admin.email == undefined) {
            this.admin.email = '';
        }
        this.getFunctions();
        this.form = this.fb.group({
            username: [this.admin.username],
            description: [this.admin.description],
            email: [this.admin.email, [Validators.required, Validators.email]]
        });
        this.form.get('username').disable();
        if (!this.isOwnAdminOfCustomer()) {
            this.form.get('description').disable();
            this.form.get('email').disable();
        }
    }

    /**
     * Returns if a customer has a specified role
     * @param role_id String representation of the role
     */
    hasCustomerRole(role_id: string): boolean {
        for (let i = 0; i < this.customer_roles.length; i++) {
            const role = this.customer_roles[i];
            if (role.id === role_id) {
                return true;
            }
        }
        return false;
    }

    /**
     * Function to receive all roles an administrator holds and all functions the customer is authorized for.
     */
    async getFunctions(): Promise<void> {
        try {
            this.customer_roles = await this.adminService.getCustomerRoles().toPromise();
            this.authorizedRoles = await this.adminService.getRoles(this.admin.username).toPromise();
            if (this.authorizedRoles === null) { // If Backend has no roles to return
                this.authorizedRoles = [];
            }
        } catch (err) {
            this.snackBar.open('Rollen konnten nicht abgerufen werden: ' + err, 'OK');
        }
    }

    closeBtClick(): void {
        this.dialogRef.close();
    }

    /**
     * Function for the save button when an user has been changed.
     * Will check what has been changed and trigger the serverside functions.
     */
    async saveAdminBtClick(): Promise<void> {
        if (this.form.invalid) {
            return;
        }
        if (!this.isOwnAdminOfCustomer()) {
            return;
        }
        if (this.form.get('email').value.length < 6 || !this.form.get('email').value.includes('@') || !this.form.get('email').value.includes('.')) {
            this.snackBar.open('Prüfen Sie das Feld E-Mail Adresse und probieren Sie es erneut', 'OK');
            return;
        }

        // the following part will change all parts at the server which the user has change in the app
        try {
            this.admin.description = this.form.get('description').value;
            this.admin.email = this.form.get('email').value;
            if (this.changedPassword) {
                await this.adminService.changeAdmin(this.admin, this.new_password).toPromise();
            } else {
                await this.adminService.changeAdmin(this.admin).toPromise();
            }
            this.snackBar.open('Die Änderungen wurden gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Administrator konnte nicht geändert werden: ' + err, 'OK');
        }
    }

    /**
     * Function for delete button. Will open a modal so the user has to check if the user object should be deleted.
     */
    deleteAdminBtClick(): void {
        if (!this.isOwnAdminOfCustomer()) {
            return;
        }

        const dialogRef = this.dialog.open(deleteAdminDialogComponent, {
            width: '250px',
            data: { username: this.admin.username }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.dialogRef.close();
            }
        });
    }

    /**
     * Function for delete button. Will open a modal so the user has to check if the user object should be deleted.
     */
    async removeAdminBtClick(): Promise<void> {
        if (this.isOwnAdminOfCustomer()) {
            return;
        }
        if (confirm("Möchten Sie den Administrator wirklich aus dieser Schule entfernen?")) {
            await this.adminService.removeAdminFromSchool(this.admin.username,this.authService.getCustomerId()).toPromise();
            this.dialogRef.close();
        }
    }

    /**
     * Function for the new Password button. Function will generate a new password and display it.
     * Will only be stored if the user clicks save.
     */
    async newPasswordBtClick(): Promise<void> {
        if (!this.isOwnAdminOfCustomer()) {
            return;
        }

        let new_Password: string = '';
        const possible: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789&!-';
        for (let i = 0; i < 10; i++) {
            new_Password += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        this.new_password = new_Password;

        const accepted = confirm('Das neue Passwort lautet: ' + new_Password);
        if (accepted) {
            try {
                await this.adminService.changeAdmin(this.admin, this.new_password).toPromise();
                this.snackBar.open('Das Passwort wurde geändert', 'OK');
            } catch (err) {
                this.snackBar.open('Das Passwort konnte nicht geändert werden: ' + err, 'OK');
            }
        }
    }

    /**
     * Function which will change a distinct permission based on user's click event in html
     * @param event event when slied toogle will be clicked
     * @param role_id
     */
    async changeRole(event, role_id: string): Promise<void> {
        if (event.checked) { // received permission
            try {
                await this.adminService.authorizeAdmin(this.admin.username, role_id).toPromise();
            } catch (err) {
                this.snackBar.open('Administrator Rolle konnte nicht geändert werden: ' + err, 'OK');
            }
        } else {
            for (let i = 0; i < this.authorizedRoles.length; i++) {
                try {
                    const element: DBRole = this.authorizedRoles[i];
                    if (element.id === role_id) {
                        await this.adminService.deauthorizeAdmin(this.admin.username, role_id).toPromise();
                    }
                } catch (err) {
                    this.snackBar.open('Dem Administrator konnte die Rolle ' + this.authorizedRoles[i].name + ' nicht entzogen werden: ' + err, 'OK');
                }
            }
        }
        this.getFunctions();
    }

    /**
     * Function to change a permission of an administrator. Will be triggered by the frontend when user clicks the radio button. There are three levels of permissions.
     * @param role_prefix Permission area the level should be changed for.
     * @param event event object of the triggered event
     */
    async changePermission(role_prefix: string, event): Promise<void> {
        switch (event.value) {
            case '0': // if the user should not have any permissions all roles should be deactivated.
                try {
                    await this.adminService.deauthorizeAdmin(this.admin.username, role_prefix + '-MANAGER').toPromise();
                } catch (err) { }
                try {
                    await this.adminService.deauthorizeAdmin(this.admin.username, role_prefix + '-ADMINISTRATOR').toPromise();
                } catch (err) {
                    this.snackBar.open('Die Administrator-Rolle konnte nicht zugewiesen werden: ' + err, 'OK');
                }
                break;
            case '1': // on first level the manager role for this area will be activated
                try {
                    await this.adminService.deauthorizeAdmin(this.admin.username, role_prefix + '-ADMINISTRATOR').toPromise();
                } catch (err) { }
                try {
                    await this.adminService.authorizeAdmin(this.admin.username, role_prefix + '-MANAGER').toPromise();
                } catch (err) {
                    this.snackBar.open('Die Manager-Rolle konnte nicht zugewiesen werden: ' + err, 'OK');
                }
                break;
            case '2': // when second level will be chosen the admin will be given the administrator role for this area.
                try {
                    await this.adminService.deauthorizeAdmin(this.admin.username, role_prefix + '-MANAGER').toPromise();
                } catch (err) { }
                try {
                    await this.adminService.authorizeAdmin(this.admin.username, role_prefix + '-ADMINISTRATOR').toPromise();
                } catch (err) {
                    this.snackBar.open('Die Administrator-Rolle konnte nicht zugewiesen werden: ' + err, 'OK');
                }
                break;
        }
    }

    /**
     * Check if the admin has the role with given role_id
     * @param role_id role_id the permissions should be checked for.
     */
    hasAdminRole(role_id: string): boolean {
        for (let i = 0; i < this.authorizedRoles.length; i++) {
            if (this.authorizedRoles[i].id === role_id) {
                return true;
            }
        }
        return false
    }

    /**
     * if a specific role area has different permission levels function will return permission level
     * @param area area where level should be returned for.
     */
    permission(area: string): string {
        for (let i = 0; i < this.authorizedRoles.length; i++) {
            const splitted = this.authorizedRoles[i].id.split('-');
            if (splitted[0] === area) {
                switch (splitted[1]) {
                    case 'MANAGER':
                        return '1';
                    case 'ADMINISTRATOR':
                        return '2';
                }
            }
        }
        return '0';
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }

    /**
     * Ist ein Quick Fix. Sollte zugunsten von E-0047 Support-Zugang dann angepasst werden.
     */
    isOwnAdminOfCustomer(): boolean {
        return this.admin.username.endsWith('@' + this.authService.getCustomerId());
    }
}
