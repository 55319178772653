import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../Login/authentication.service';
import { User } from '../../data/User'
import { usersResponse, finishResponse, addUserResponse } from './userService.responses'
import { ImportUser } from '../../data/ImportUser'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Group } from '../../data/Group';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { GroupsUserSearchResult } from 'app/GroupManagement/GroupList/GroupDetailDialog.component';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UserService {
    private readonly userURL = '/api/user/'; // URL for operations with single user.
    private readonly usersURL = '/api/users/'; // URL for operations with several persons

    constructor(private http: EndooHttpService, private authService: AuthenticationService, private sanitizer: DomSanitizer) { }

    /**
     * Function which returns all users from Server
     */
    getAll(): Observable<User[]> {
        return this.http.get<usersResponse>(this.usersURL + this.authService.getCustomerId() + '/list/all', { observe: 'response', responseType: 'json' })
            .pipe(map((response: usersResponse) => {
                // get users from response
                return response.persons;
            }));
    }

    /**
     * Function which returns all users from Server
     */
    searchList(searchstring: string): Observable<User[]> {
        return this.http.get<usersResponse>(this.usersURL + this.authService.getCustomerId() + '/list/' + searchstring, { observe: 'response', responseType: 'json' })
            .pipe(map((response: usersResponse) => {
                // get users from response
                return response.persons;
            }));
    }

    /**
     * Function which returns all users from Server
     */
    search(searchstring: string): Observable<GroupsUserSearchResult[]> {
        return this.http.get<GroupsUserSearchResult[]>(this.usersURL + this.authService.getCustomerId() + '/' + searchstring, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which returns all users from Server
     * @param username username of user which should be fetched.
     */
    getUser(username: string): Observable<User> {
        return this.http.get<User>(this.userURL + this.authService.getCustomerId() + '/' + username, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which returns all groups a user belongs to from Server
     * @param username username of user the groups should be returned.
     */
    getUserGroups(username: string): Observable<Group[]> {
        return this.http.get<Group[]>(this.userURL + this.authService.getCustomerId() + '/' + username + '/groups', { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to change GivenName of distinct user
     * @param username Username of user where givenname should be changed
     * @param newValue New Value of the Givenname
     */
    changeGivenName(username: string, newValue: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username + '/givenname', { givenname: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to change Surname of User.
     * @param username Username of the user where the surname should be changed.
     * @param newValue New surname for the user.
     */
    changeSurname(username: string, newValue: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username + '/surname', { surname: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to change Leavingyear of User.
     * @param username Username of the user where the leavingyear should be changed.
     * @param newValue New leavingyear value.
     */
    changeLeavingyear(username: string, newValue: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username + '/leavingyear', { leavingyear: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to change password of User.
     * @param username Username of the user where the password should be changed.
     * @param newValue New password value.
     */
    changePassword(username: string, newValue: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username + '/password', { password: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to change login of User.
     * @param username Username of the user where the login should be changed.
     * @param newValue New login boolean value.
     */
    changeLogin(username: string, newValue: boolean): Observable<boolean> {
        return this.http.post<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username + '/login', { login: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to change wlan of User.
     * @param username Username of the user where the wlan should be changed.
     * @param newValue New wlan boolean value.
     */
    changeWLAN(username: string, newValue: boolean): Observable<boolean> {
        const json = JSON.stringify({ wlan: newValue });
        return this.http.post<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username + '/wlan', json, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to delete an User from the system.
     * @param username Username of the user which should be deleted.
     */
    delete(username: string): Observable<boolean> {
        return this.http.delete<finishResponse>(this.userURL + this.authService.getCustomerId() + '/' + username, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to add a new pupil to the system.
     * @param pupil Object which contains information about the new pupil that should be added
     */
    createPupil(pupil: User): Observable<Object> {
        const json = JSON.stringify(pupil);
        return this.http.put<addUserResponse>(this.userURL + this.authService.getCustomerId() + '/' + 'pupil', json, { observe: 'response', responseType: 'json' })
            .pipe(map(async (response: addUserResponse) => {
                const byteArray = new Uint8Array(response.blob);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                let internal_path: SafeResourceUrl;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE does not provide possibility to show internal blob URLs so show a save dialog
                    window.navigator.msSaveOrOpenBlob(blob, 'test.pdf');
                } else {
                    const url = URL.createObjectURL(blob);
                    internal_path = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    // return Userobjects which the server has send
                }
                return { finished: response.finished, filename: internal_path };
            }));
    }

    /**
     * Function to add a new teacher to the system.
     * @param teacher Object which contains information about the new teacher that should be added
     */
    createTeacher(teacher: User): Observable<Object> {
        const json = JSON.stringify(teacher)
        return this.http.put<addUserResponse>(this.userURL + this.authService.getCustomerId() + '/' + 'teacher', json, { observe: 'response', responseType: 'json' })
            .pipe(map(async (response: addUserResponse) => {
                // get filename from response
                const byteArray = new Uint8Array(response.blob);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                let internal_path: SafeResourceUrl;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE does not provide possiblity to show internal blob URLs so show a save dialog
                    window.navigator.msSaveOrOpenBlob(blob, 'test.pdf');
                } else {
                    const url = URL.createObjectURL(blob);
                    internal_path = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    // return Userobjects which the server has send
                }
                return { finished: response.finished, filename: internal_path };
            }));
    }

    /**
     * Function to add a new external person to the system.
     * @param external_person Object which contains information about the new external person that should be added
     */
    createExternalPerson(external_person: User): Observable<Object> {
        const json = JSON.stringify(external_person)
        return this.http.put<addUserResponse>(this.userURL + this.authService.getCustomerId() + '/' + 'external', json, { observe: 'response', responseType: 'json' })
            .pipe(map(async (response: addUserResponse) => {
                // get filename from response
                const byteArray = new Uint8Array(response.blob);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                let internal_path: SafeResourceUrl;
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE does not provide possiblity to show internal blob URLs so show a save dialog
                    window.navigator.msSaveOrOpenBlob(blob, 'test.pdf');
                } else {
                    const url = URL.createObjectURL(blob);
                    internal_path = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    // return Userobjects which the server has send
                }
                return { finished: response.finished, filename: internal_path };
            }));
    }

    /**
    * Function to import new pupils into the system.
    * @param pupils Array which contains information about the new pupils that should be added
    */
    importPupils(pupils: ImportUser[], import_leavingyear: string, wlan:boolean): Observable<Object> {
        const params = new HttpParams().set('wlan', wlan.toString());
        const json = JSON.stringify(pupils);
        return this.http.put<addUserResponse>(this.usersURL + this.authService.getCustomerId() + '/' + 'pupils', { pupils: json, leavingyear: import_leavingyear.toString(), wlan:wlan }, { params: params, observe: 'response', responseType: 'json' })
            .pipe(map(async (response: addUserResponse) => {
                // get filename from response
                const filename: string = response.filename;
                if (filename === '') { // if no pdf was created because all users already existed show now PDF
                    return { finished: response.finished, filename: '' };
                }
                const byteArray = new Uint8Array(response.blob);
                // create javascript blob object
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                const password_blob = new Blob([response.password_csv], { type: 'application/csv' });
                let internal_path: SafeResourceUrl;
                let password_path: SafeResourceUrl;

                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE does not provide possiblity to show internal blob URLs so show a save dialog
                    window.navigator.msSaveOrOpenBlob(blob, 'test.pdf');
                } else {
                    const url = URL.createObjectURL(blob);
                    const password_url = URL.createObjectURL(password_blob);
                    // bypass security filter of angular
                    internal_path = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    password_path = this.sanitizer.bypassSecurityTrustResourceUrl(password_url);
                }
                return { finished: response.finished, filename: internal_path, password_link: password_path };
            }));
    }

    /**
    * Function to import teachers into the system.
    * @param external_person Object which contains information about the new external person that should be added
    */
    importTeachers(teachers: ImportUser[], wlan:boolean): Observable<Object> {
        const json = JSON.stringify(teachers);
        const params = new HttpParams().set('wlan', wlan.toString());
        return this.http.put<addUserResponse>(this.usersURL + this.authService.getCustomerId() + '/' + 'teachers', { teachers: json, wlan:wlan }, { params: params, observe: 'response', responseType: 'json' })
            .pipe(map(async (response: addUserResponse) => {
                const filename: string = response.filename;
                if (filename === '') { // if no pdf was created because all users already existed show now PDF
                    return { finished: response.finished, filename: '' };
                }
                const byteArray = new Uint8Array(response.blob);
                const blob = new Blob([byteArray], { type: 'application/pdf' });
                let internal_path: SafeResourceUrl;
                let password_path: SafeResourceUrl;
                const password_blob = new Blob([response.password_csv], { type: 'application/csv' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE does not provide possiblity to show internal blob URLs so show a save dialog
                    window.navigator.msSaveOrOpenBlob(blob, 'test.pdf');
                } else {
                    // create blob urls for pdfs and csv
                    const url = URL.createObjectURL(blob);
                    const password_url = URL.createObjectURL(password_blob);
                    internal_path = this.sanitizer.bypassSecurityTrustResourceUrl(url);
                    password_path = this.sanitizer.bypassSecurityTrustResourceUrl(password_url);
                    // return Userobjects which the server has send
                }
                return { finished: response.finished, filename: internal_path, password_link: password_path };
            }));
    }
}
