import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidationErrors } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { LogicalNetwork } from '../../data/LogicalNetwork';
import { ChooseNewUntaggedNetworkComponent } from '../ChooseNewUntaggedNetwork/chooseNewUntaggedNetwork.component';

@Component({
    selector: 'newLogicalNetworkComponent',
    templateUrl: './newLogicalNetwork.component.html',
    styleUrls: ['./newLogicalNetwork.component.css'],
})
export class NewLogicalNetworkDialogComponent implements OnInit {
    step1_completed: boolean = false;
    usedColors: string[] = [];
    form: FormGroup;
    contract_link: string = '';
    network_new: LogicalNetwork;
    action: 'NewNetwork' | 'ChangeNetwork' | 'DeleteNetwork' | 'VisitSite' = 'NewNetwork';
    forceUntagged: boolean = false;
    excludeNetworkFromSelection: number;

    constructor(
        public logicalNetworkService: LogicalNetworkService,
        private dialogRef: MatDialogRef<NewLogicalNetworkDialogComponent>,
        private snackBar: MatSnackBar,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog
    ) {
        if (data !== null) {
            if (data.action) {
                this.action = data.action;
                this.excludeNetworkFromSelection = data.excludeNetworkFromSelection;
            }
        }
        if (this.action !== 'NewNetwork') {
            this.forceUntagged = true;
        }
    }

    ngOnInit() {
        // creating form validator for the new User form.
        this.form = this.fb.group({
            activated: [true],
            name: ['', [Validators.required, Validators.pattern(/[a-zA-Z ]/)]],
            color: ['', Validators.required],
            untagged: [false],
            vlan_id: ['', [Validators.required, Validators.pattern(/\d+$/), Validators.min(2), Validators.max(4093)]]
        });

        // Let "activated" and "untagged" always true, if this is forced by variable
        this.form.get('activated').valueChanges.subscribe(v => {
            if (this.forceUntagged) {
                this.form.get('activated').setValue(true, { emitEvent: false });
            }
        });
        this.form.get('untagged').valueChanges.subscribe(v => {
            if (this.forceUntagged) {
                this.form.get('untagged').setValue(true, { emitEvent: false });
            }
        });
        if (this.forceUntagged) {
            this.form.get('activated').setValue(true);
            this.form.get('untagged').setValue(true);
        }

        this.getAvailableNetworkColors();
    }

    async getAvailableNetworkColors(): Promise<void> {
        const networks = await this.logicalNetworkService.getLogicalNetworks().toPromise();
        this.usedColors = this.logicalNetworkService.getUsedNetworkColors(networks);
    }

    resetStepper(stepper): void {
        stepper.reset();
    }

    getFormValidationErrors(): any[] {
        const errors = [];
        Object.keys(this.form.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    errors[key] = { keyError: controlErrors[keyError] };
                });
            }
        });
        return errors;
    }

    async createNewLogicalNetworkBtClick(): Promise<void> {
        if (this.getFormValidationErrors().length > 0) {
            return;
        }

        const network = new LogicalNetwork(
            this.logicalNetworkService.getMaxNetworkID() + 1,
            this.form.get('name').value,
            this.form.get('activated').value,
            this.form.get('color').value,
            this.form.get('untagged').value,
            this.form.get('vlan_id').value
        );

        if (this.action === 'NewNetwork' && network.untagged) {
            this.snackBar.open('Es kann nur ein untagged/Management Netzwerk angelegt werden', 'OK');
            return;
        }
        // There must not be a vlan-id twice
        let isVlanIdFree: boolean;
        try {
            isVlanIdFree = await this.logicalNetworkService.isVlanIdFree(network);
        } catch (err) {
            this.snackBar.open('Das Schulnetzwerk konnte nicht erstellt werden: ' + err, 'OK');
            return;
        }
        if (!isVlanIdFree) {
            this.snackBar.open('Die VLAN-ID "' + network.vlan_id + '" ist bereits vergeben', 'OK');
            return;
        }
        const logicalNetworkNameExists = await this.logicalNetworkService.networkNameExists(network.name);
        if (logicalNetworkNameExists) {
            this.snackBar.open('Dieser Netzwerkname ist bereits vergeben', 'OK');
            return;
        }
        let resultNetworkId;
        try {
            await this.logicalNetworkService.createLogicalNetwork(network).toPromise();
            resultNetworkId = network.id_number;
            this.snackBar.open('Das Schulnetzwerk wurde erstellt', 'OK');
        } catch (err) {
            resultNetworkId = -1;
            this.snackBar.open('Das Schulnetzwerk konnte nicht erstellt werden: ' + err, 'OK');
        }
        this.dialogRef.close(resultNetworkId);
    }

    chooseUntaggedNetwork(): void {
        const dialogRef = this.dialog.open(ChooseNewUntaggedNetworkComponent, {
            data: {
                excludeNetworkFromSelection: this.excludeNetworkFromSelection
            }
        });
        dialogRef.afterClosed().subscribe(selected => {
            if (selected) {
                this.dialogRef.close(true);
            }
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }
}
