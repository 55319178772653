import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { forkJoin, Observable } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';
import { finishResponse, deviceCreationResponse } from './apService.responses';
import { DBDeviceFunctionProperty, DBDevice, DBProperty, DBModel } from '../../data/endooSpotDB_objects';
import { WifiCard2 } from '../../data/WifiCard2';
import { WifiCard1 } from 'app/data/WifiCard1';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { ApStationStats } from 'app/data/ApStationStats';
import { SwitchPortConfiguration } from 'app/data/SwitchPortConfiguration';
import { ModelService } from './model.service';
import { SwitchSettings } from 'app/data/SwitchSettings';
import { ApPortConfiguration } from 'app/data/ApPortConfiguration';
import { RouterPortConfiguration } from 'app/data/RouterPortConfiguration';

@Injectable()
export class ApDeviceService {
    private readonly deviceURL = '/api/devices/';
    private readonly stationStatsURL = '/api/station-stats/';

    constructor(
        private http: EndooHttpService,
        private authService: AuthenticationService,
        private modelService: ModelService
    ) { }

    /**
     * Function which returns all devices from Server
     */
    getAll(): Observable<DBDevice[]> {
        return this.http.get<DBDevice[]>(this.deviceURL + 'customers/' + this.authService.getCustomerId(), { observe: 'response', responseType: 'json' }, false);
    }

    /**
     * Function which returns all devices from Server
     */
    setDeviceFunctionValue(inventoryNumber: string, function_id: string, property_id: string, value: string): Observable<void> {
        return this.http.post<void>(this.deviceURL + inventoryNumber + '/functions/' + function_id + '/properties/' + property_id, { value: value }, { observe: 'response', responseType: 'json' });
    }

    removeDeviceFunctionProperty(inventoryNumber: string, functionId: string, propertyId: string): Observable<void> {
        return this.http.delete<void>(this.deviceURL + inventoryNumber + '/functions/' + functionId + '/properties/' + propertyId, { observe: 'response', responseType: 'json' });
    }

    /**
    * Function which changes the location of a device
    */
    changeDevice(device: DBDevice): Observable<void> {
        return this.http.post<void>(this.deviceURL + device.inventoryNumber, { device: device }, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which changes the location of a device
     */
    changeComment(device: DBDevice): Observable<finishResponse> {
        return this.http.post<finishResponse>(this.deviceURL + device.inventoryNumber + '/changeComment', { comment: device.comment }, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which creates a device of model-type of model_id and with mac as mac-address.
     * @param model_id the new device's model
     * @param mac mac-address of the new model
     */
    createDevice(model_id: string, mac: string, serialNumber: string, comment: string): Observable<deviceCreationResponse> {
        return this.http.put<deviceCreationResponse>(this.deviceURL, { model_id: model_id, mac: mac, serial_number: serialNumber, comment: comment, customerId: this.authService.getCustomerId() }, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to find the value of devicefunctionProperty in array identified by function_id and property_id
     * @param dfps Array with the device function Properties
     * @param function_id function_id for the function the value should be returned
     * @param property_id property_id for the function property the value shuld be returned
     */
    getDeviceFunctionPropertyValue(dfps: DBDeviceFunctionProperty[], function_id: string, property_id: string, default_value?: string): string {
        for (let i = 0; i < dfps.length; i++) {
            const dfp: DBDeviceFunctionProperty = dfps[i];
            if (dfp.function === function_id && dfp.property === property_id) {
                return dfp.value;
            }
        }
        return default_value;
    }

    addDevice(device: DBDevice): Observable<{ deviceAvailable: boolean, deviceCorrect: boolean }> {
        return this.http.post<{ deviceAvailable: boolean, deviceCorrect: boolean }>(this.deviceURL + this.authService.getCustomerId() + '/addDevice', { inventorynumber: device.inventoryNumber, macadress: device.mac, serialnumber: device.serial_number }, { observe: 'response', responseType: 'json' });
    }

    removeDevice(device: DBDevice, isDefect: boolean, email: string, defectMatter?: string): Observable<{ deviceCorrect: boolean, finished: boolean }> {
        return this.http.post<{ deviceCorrect: boolean, finished: boolean }>(this.deviceURL + 'removeDevice', { inventorynumber: device.inventoryNumber, macadress: device.mac, deviceDefect: isDefect, unusedDevice: !isDefect, email: email, defectMatter: defectMatter, username: this.authService.getUsername(), customerId: this.authService.getCustomerId() }, { observe: 'response', responseType: 'json' });
    }

    /**
     * Returns WifiCard Object for distinct wificard of a devcie
     * @param device device object which has device function properties of wificard
     * @param wificard_name name of the wificard
     */
    getWifiCard1Settings(device: DBDevice): WifiCard1 {
        const enabled: boolean = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard1', DBProperty.wifi_card_enabled, 'true') === 'true';
        const channel: string = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard1', DBProperty.wifi_card_channel, 'auto');
        // 5GHz network default channel width is HT80 while in 2.4 it is HT20
        const channel_width: string = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard1', DBProperty.wifi_card_channel_width, 'HT20');
        const tx_power: string = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard1', DBProperty.wifi_card_txpower, '20');

        return new WifiCard1(enabled, channel, channel_width, tx_power);
    }

    /**
     * Returns WifiCard Object for distinct wificard of a devcie
     * @param device device object which has device function properties of wificard
     * @param wificard_name name of the wificard
     */
    getWifiCard2Settings(device: DBDevice): WifiCard2 {
        const enabled: boolean = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard2', DBProperty.wifi_card_enabled, 'true') === 'true';
        const channel: string = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard2', DBProperty.wifi_card_channel, 'auto');
        // 5GHz network default channel width is HT80 while in 2.4 it is HT20
        const channel_width: string = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard2', DBProperty.wifi_card_channel_width, 'HT80');
        const tx_power: string = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard2', DBProperty.wifi_card_txpower, '20');
        return new WifiCard2(enabled, channel, channel_width, tx_power);
    }

    /**
     * Function to save wificard settings of a device
     * @param wificard_name name of function of the wificard which should be changed
     * @param wifiCard Object which contains information about the wificard
     * @param device_id device the wificard should be changed
     */
    saveWifiCard1(wifiCard: WifiCard1, device_id: string): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.setDeviceFunctionValue(device_id, 'WifiCard1', DBProperty.wifi_card_channel, wifiCard.channel); // channel
        setRequests[1] = this.setDeviceFunctionValue(device_id, 'WifiCard1', DBProperty.wifi_card_channel_width, wifiCard.channel_width); // channel_width
        setRequests[2] = this.setDeviceFunctionValue(device_id, 'WifiCard1', DBProperty.wifi_card_enabled, String(wifiCard.enabled)); // enabled
        setRequests[3] = this.setDeviceFunctionValue(device_id, 'WifiCard1', DBProperty.wifi_card_txpower, wifiCard.tx_power); // tx_power
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Function to save wificard settings of a device
     * @param wificard_name name of function of the wificard which should be changed
     * @param wifiCard Object which contains information about the wificard
     * @param device_id device the wificard should be changed
     */
    saveWifiCard2(wifiCard: WifiCard2, device_id: string): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.setDeviceFunctionValue(device_id, 'WifiCard2', DBProperty.wifi_card_channel, wifiCard.channel); // channel
        setRequests[1] = this.setDeviceFunctionValue(device_id, 'WifiCard2', DBProperty.wifi_card_channel_width, wifiCard.channel_width); // channel_width
        setRequests[2] = this.setDeviceFunctionValue(device_id, 'WifiCard2', DBProperty.wifi_card_enabled, String(wifiCard.enabled)); // enabled
        setRequests[3] = this.setDeviceFunctionValue(device_id, 'WifiCard2', DBProperty.wifi_card_txpower, wifiCard.tx_power); // tx_power
        return forkJoin(setRequests).pipe(mergeAll());
    }

    private getPortNumbers(device: DBDevice): number[] {
        let functionPrefix;
        let pattern;
        switch (this.modelService.getModelForDevice(device).mode) {
            case 'ACCESSPOINT':
                functionPrefix = 'ACCESS_POINT_PORT';
                pattern = /ACCESS_POINT_PORT\d+$/;
                break;

            case 'SWITCH':
                functionPrefix = 'SWITCH_PORT';
                pattern = /SWITCH_PORT\d+$/;
                break;

            case 'ROUTER':
                functionPrefix = 'ROUTER_PORT';
                pattern = /ROUTER_PORT\d+$/;
                break;

            default:
                functionPrefix = null;
                pattern = null;
        }
        if (functionPrefix === null) {
            return [];
        }

        const portFunctions = device.deviceFunctionProperties.filter(value => {
            return value.function.match(pattern);
        });
        const portNumbers = [];
        portFunctions.forEach(portFunction => {
            const portNumber = Number.parseInt(portFunction.function.substring(functionPrefix.length));
            if (!portNumbers.includes(portNumber)) {
                portNumbers.push(portNumber);
            }
        });
        return portNumbers.sort();
    }

    /**
     * get Port configuration for a switch (only filters function property object)
     * @param device device with function properties for port configuration
     */
    getSwitchPortConfigurations(device: DBDevice): SwitchPortConfiguration[] {
        const functionPrefix = 'SWITCH_PORT';
        const portNumbers = this.getPortNumbers(device);
        const portConfigurations = [];

        for (const portNumber of portNumbers) {
            const pc = new SwitchPortConfiguration();
            pc.port = portNumber;
            pc.label = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.switch_port_label, '');
            pc.comments = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.switch_port_comments, '');
            pc.untaggedLogicalNetworkId = Number.parseInt(this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.switch_port_untagged_network_id, ''));
            let ids = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.switch_port_tagged_network_ids, '[]');
            ids = ids.replace('[', '').replace(']', '');
            pc.taggedLogicalNetworkIds = ids.length === 0 ? [] : ids.split(',').map((s) => {
                return Number.parseInt(s);
            });
            pc.poe_enabled = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port + '_POE', DBProperty.switch_port_poe_enabled, 'true') === 'true';
            pc.mgmt_port = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.switch_port_mgmt_port, 'true') === 'true';
            portConfigurations.push(pc);
        }

        return portConfigurations;
    }

    /**
     * Saves the port configuration for device with inventory number
     * @param inventoryNumber inventory number for portconfiguration to save
     * @param pc new port configuration
     */
    saveSwitchPortConfiguration(model: DBModel, inventoryNumber: string, pc: SwitchPortConfiguration): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_PORT' + pc.port, DBProperty.switch_port_label, pc.label);
        setRequests[1] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_PORT' + pc.port, DBProperty.switch_port_comments, pc.comments);
        setRequests[2] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_PORT' + pc.port, DBProperty.switch_port_untagged_network_id, pc.untaggedLogicalNetworkId ? pc.untaggedLogicalNetworkId.toString() : '');
        setRequests[3] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_PORT' + pc.port, DBProperty.switch_port_tagged_network_ids, JSON.stringify(pc.taggedLogicalNetworkIds));
        setRequests[4] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_PORT' + pc.port, DBProperty.switch_port_mgmt_port, String(pc.mgmt_port));
        if (this.modelService.hasModelFunction(model, 'SWITCH_PORT' + pc.port + '_POE')) {
            setRequests[5] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_PORT' + pc.port + '_POE', DBProperty.switch_port_poe_enabled, String(pc.poe_enabled));
        }
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Gets the switch settings for a certain switch device
     * @param device device with function properties
     */
    getSwitchSettings(device: DBDevice): SwitchSettings | null {
        const switchFunctions = device.deviceFunctionProperties.filter(value => {
            return value.function === 'SWITCH_SETTINGS';
        });
        if (switchFunctions.length === 0) {
            return null;
        }
        return new SwitchSettings(
            this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'SWITCH_SETTINGS', DBProperty.switch_global_settings, 'true') === 'true',
            this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'SWITCH_SETTINGS', DBProperty.switch_settings_spanning_tree_protocol_enabled, 'true') === 'true',
            this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'SWITCH_SETTINGS', DBProperty.switch_settings_spanning_tree_protocol, ''),
            this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'SWITCH_SETTINGS', DBProperty.switch_settings_bridge_priority, ''),
            this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'SWITCH_SETTINGS', DBProperty.switch_settings_lldp, '') === 'true'
        );
    }

    saveSwitchSettings(inventoryNumber: string, switchSettings: SwitchSettings): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_SETTINGS', DBProperty.switch_global_settings, String(switchSettings.global_settings));
        setRequests[1] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_SETTINGS', DBProperty.switch_settings_spanning_tree_protocol_enabled, String(switchSettings.spanning_tree_protocol_enabled));
        setRequests[2] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_SETTINGS', DBProperty.switch_settings_spanning_tree_protocol, switchSettings.spanning_tree_protocol);
        setRequests[3] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_SETTINGS', DBProperty.switch_settings_bridge_priority, switchSettings.bridge_priority);
        setRequests[4] = this.setDeviceFunctionValue(inventoryNumber, 'SWITCH_SETTINGS', DBProperty.switch_settings_lldp, String(switchSettings.lldp));
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /*
     * get Port configuration for an AP (only filters function property object)
     * @param device device with function properties for port configuration
     */
    getApPortConfigurations(device: DBDevice): ApPortConfiguration[] {
        const functionPrefix = 'ACCESS_POINT_PORT';
        const portNumbers = this.getPortNumbers(device);
        const portConfigurations = [];

        for (const portNumber of portNumbers) {
            const pc = new ApPortConfiguration();
            pc.port = portNumber;
            pc.label = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.access_point_port_label, '');
            pc.untaggedLogicalNetworkId = Number.parseInt(this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.access_point_port_untagged_network_id, ''));
            let ids = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.access_point_port_tagged_network_ids, '[]');
            ids = ids.replace('[', '').replace(']', '');
            pc.taggedLogicalNetworkIds = ids.length === 0 ? [] : ids.split(',').map((s) => {
                return Number.parseInt(s);
            });
            pc.default_config = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.access_point_port_default_config, 'true') === 'true';
            portConfigurations.push(pc);
        }

        return portConfigurations;
    }

    saveApPortConfiguration(inventoryNumber: string, pc: ApPortConfiguration): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.setDeviceFunctionValue(inventoryNumber, 'ACCESS_POINT_PORT' + pc.port, DBProperty.access_point_port_label, pc.label);
        setRequests[1] = this.setDeviceFunctionValue(inventoryNumber, 'ACCESS_POINT_PORT' + pc.port, DBProperty.access_point_port_untagged_network_id, pc.untaggedLogicalNetworkId ? pc.untaggedLogicalNetworkId.toString() : '');
        setRequests[2] = this.setDeviceFunctionValue(inventoryNumber, 'ACCESS_POINT_PORT' + pc.port, DBProperty.access_point_port_tagged_network_ids, JSON.stringify(pc.taggedLogicalNetworkIds));
        setRequests[3] = this.setDeviceFunctionValue(inventoryNumber, 'ACCESS_POINT_PORT' + pc.port, DBProperty.access_point_port_default_config, String(pc.default_config));
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /*
     * get Port configuration for a router (only filters function property object)
     * @param device device with function properties for port configuration
     */
    getRouterPortConfigurations(device: DBDevice): RouterPortConfiguration[] {
        const functionPrefix = 'ROUTER_PORT';
        const portNumbers = this.getPortNumbers(device);
        const portConfigurations = [];

        for (const portNumber of portNumbers) {
            const pc = new RouterPortConfiguration();
            pc.port = portNumber;
            pc.label = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.router_port_label, '');
            pc.comments = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.router_port_comments, '');
            pc.untaggedLogicalNetworkId = Number.parseInt(this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.router_port_untagged_network_id, ''));
            let ids = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.router_port_tagged_network_ids, '[]');
            ids = ids.replace('[', '').replace(']', '');
            pc.taggedLogicalNetworkIds = ids.length === 0 ? [] : ids.split(',').map((s) => {
                return Number.parseInt(s);
            });
            pc.mode = this.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, functionPrefix + pc.port, DBProperty.router_port_mode, 'default') === 'default';
            portConfigurations.push(pc);
        }

        return portConfigurations;
    }

    saveRouterPortConfiguration(inventoryNumber: string, pc: RouterPortConfiguration): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.setDeviceFunctionValue(inventoryNumber, 'ROUTER_PORT' + pc.port, DBProperty.router_port_label, pc.label);
        setRequests[1] = this.setDeviceFunctionValue(inventoryNumber, 'ROUTER_PORT' + pc.port, DBProperty.router_port_comments, pc.comments);
        setRequests[2] = this.setDeviceFunctionValue(inventoryNumber, 'ROUTER_PORT' + pc.port, DBProperty.router_port_untagged_network_id, pc.untaggedLogicalNetworkId ? pc.untaggedLogicalNetworkId.toString() : '');
        setRequests[3] = this.setDeviceFunctionValue(inventoryNumber, 'ROUTER_PORT' + pc.port, DBProperty.router_port_tagged_network_ids, JSON.stringify(pc.taggedLogicalNetworkIds));
        setRequests[4] = this.setDeviceFunctionValue(inventoryNumber, 'ROUTER_PORT' + pc.port, DBProperty.router_port_mode, pc.mode ? 'default' : 'custom');
        return forkJoin(setRequests).pipe(mergeAll());
    }

    /**
     * Function to retrieve the AP Station stat list by inventory number.
     */
    getAllStations(inventoryNumber: string): Observable<ApStationStats[]> {
        return this.http.get<ApStationStats[]>(this.stationStatsURL + 'getData/' + inventoryNumber, { observe: 'response', responseType: 'json' })
            .pipe(map(stats => {
                stats = stats.map(stat => {
                    stat.rssi = Math.abs(stat.rssi) * -1;
                    return stat;
                });
                return stats;
            }));
    }

    setDeviceStatus(inventoryNumber: string, status: string): Observable<boolean> {
        return this.http.post<{ statusChanged: boolean }>(this.deviceURL + inventoryNumber + '/status', { status: status }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: { statusChanged: boolean }) => {
                return response.statusChanged;
            }));
    }

    isDeviceSwitch(device: DBDevice): boolean {
        return this.modelService.isModelSwitch(this.modelService.getModelForDevice(device));
    }

    isDeviceRouter(device: DBDevice): boolean {
        return this.modelService.isModelRouter(this.modelService.getModelForDevice(device));
    }

    isDeviceAP(device: DBDevice): boolean {
        return this.modelService.isModelAP(this.modelService.getModelForDevice(device));
    }

    hasDeviceError(device: DBDevice): boolean {
        return device.status === 'ERROR' || device.status === 'NOT_CONFIGURED';
    }

    isVpnLastContactTimesUnder5Minutes(time1: number, time2: number): boolean {
        if (!time1 && !time2) {
            return false;
        }
        if (time1 && !time2) {
            return time1 < Date.now() - 5 * 60000;
        }
        if (!time2 && time1) {
            return time2 < Date.now() - 5 * 60000;
        }
        if (time1 && time2) {
            return time1 < Date.now() - 5 * 60000 && time2 < Date.now() - 5 * 60000;
        }
    }

    isDeviceOnline(device: DBDevice) {
        return Date.now() - device.lastContactTime < 5 * 60000; // lastContactTime vor weniger als 5 Minuten?
    }

    isDeviceOnlineStatusUnknown(device: DBDevice) {
        return this.isDeviceOnline(device) === false && (Date.now() - device.lastContactTime < 10 * 60000); // lastContactTime zwischen 5 und 10 Minuten her?
    }

    getDeviceStatusSymbol(device: DBDevice, vpn_last_contact_time1?: number, vpn_last_contact_time2?: number) {
        if (this.isDeviceOnline(device) && device.status === 'UPTODATE' && !(this.hasDeviceError(device) || (this.isDeviceRouter(device) && this.isVpnLastContactTimesUnder5Minutes(vpn_last_contact_time1, vpn_last_contact_time2)))) {
            return 'ok';
        }
        if (!this.isDeviceOnline(device) && !this.isDeviceOnlineStatusUnknown(device)) {
            return 'offline';
        }
        if (this.isDeviceOnline(device) && !this.hasDeviceError(device) && device.status !== 'UPTODATE' && !(this.hasDeviceError(device) || (this.isDeviceRouter(device) && this.isVpnLastContactTimesUnder5Minutes(vpn_last_contact_time1, vpn_last_contact_time2)))) {
            return 'loading';
        }
        if (this.isDeviceOnlineStatusUnknown(device)) {
            return 'online_unknown';
        }
        if (this.isDeviceOnline(device) && (this.hasDeviceError(device) || (this.isDeviceRouter(device) && this.isVpnLastContactTimesUnder5Minutes(vpn_last_contact_time1, vpn_last_contact_time2)))) {
            return 'error';
        }
    }
}
