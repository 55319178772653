import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Group } from '../../data/Group';
import { GroupService } from '../GroupService/group.service';

@Component({
    templateUrl: './deleteGroup.dialog.component.html',
})
export class deleteGroupDialogComponent {
    groupname: string;
    groups: Group[];

    constructor(private groupService: GroupService, private snackBar: MatSnackBar, private dialogRef: MatDialogRef<deleteGroupDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any) {

        if (data.groupname) {
            this.groupname = data.groupname;
        } else {
            this.groups = data.groups;
        }
    }

    async deleteBtClick_DeleteGroupDialog(): Promise<void> {
        let result = true;
        if (this.groupname) {
            try {
                result = await this.groupService.delete(this.groupname).toPromise();
                if (result) {
                    this.snackBar.open('Die Gruppe wurde gelöscht', 'OK');
                } else {
                    this.snackBar.open('Die Gruppe konnte nicht gelöscht werden', 'OK');
                }
            } catch (err) {
                this.snackBar.open('Die Gruppe konnte nicht gelöscht werden: ' + err, 'OK');
                result = false;
            } finally {
                this.dialogRef.close(result);
            }
        } else {
            for (let i = 0; i < this.groups.length; i++) {
                const group = this.groups[i];
                try {
                    result = result && (await this.groupService.delete(group.groupname).toPromise());
                } catch (err) {
                    result = false;
                    this.snackBar.open('Die Gruppe ' + group.groupname + ' konnte nicht gelöscht werden: ' + err, 'OK');
                }
            }
            if (result) {
                this.snackBar.open('Die Gruppen wurden gelöscht ', 'OK');
            }
            this.dialogRef.close(result);
        }
    }

    async cancelBtClick_DeleteGroupDialog(): Promise<void> {
        this.dialogRef.close(false);
    }
}
