import { PlotlyGraph } from './plotly-graph';

export class PlotlyGraphGauge extends PlotlyGraph {

    /**
     * Hiermit wird ein GaugeGraph erzeugt.
     * @param params Verschiedene Parameter um den Graphen anzupassen.
     */
    constructor(params: { title: string, range?: { min?: number, max: number } }) {
        super();

        this.layout = {
            autosize: true,
        };
        this.plotData = [
            {
                title: { text: params.title },
                type: 'indicator',
                mode: 'gauge+number',
                gauge: {
                    axis: {
                        range: params.range ? [params.range.min, params.range.max] : [null, null]
                    }
                },
            }
        ];
    }
}
