import { Injectable } from '@angular/core';
import { AuthenticationService } from 'app/Login/authentication.service';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DemoAccessService {
    private readonly license_key = '/api/licensekey';

    constructor(private http: EndooHttpService, private authService: AuthenticationService) { }

    generateLicenseKey(description: string): Observable<string> {
        return this.http.put<{ license_key: string }>(this.license_key + '/' + this.authService.getCustomerId(), { licenseKeyDescription: description }, { observe: 'response', responseType: 'json' }, false)
            .pipe(map((response: { license_key: string }) => {
                return response.license_key;
            }));
    }
}
