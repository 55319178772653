import { Component, AfterViewInit, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, interval, Subscription } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { ApDeviceService } from '../APService/apDevice.service';
import { DBDevice, DBProperty } from '../../data/endooSpotDB_objects';
import { NewApDialogComponent } from '../NewAp/newAp.dialog.component';
import { AuthenticationService } from '../../Login/authentication.service';
import { APDetailDialogComponent } from '../APDetail/APDetailDialog.component';
import { AddApDialogComponent } from '../AddAP/AddAp.dialog.component';
import { AppService } from 'app/Services/app.service';
import { ModelService } from '../APService/model.service';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';

type DevicesTableEntry = { imageLink$: Observable<string>, device: DBDevice, vpn_last_contact_time1: number, vpn_last_contact_time2: number };

@Component({
    templateUrl: './APList.component.html',
    styleUrls: ['./APList.component.scss'],
})
export class apListComponent implements OnInit, AfterViewInit, OnDestroy {
    selectedDevices: string[] = [];
    selection = new SelectionModel<DevicesTableEntry>(true, []);
    displayedColumns: string[] = ['selection', 'device.image', 'device.status', 'device.inventoryNumber', 'device.lastContactIp', 'device.mac', 'device.location', 'device.wifi'];
    devicesTable: MatTableDataSource<DevicesTableEntry>;
    originalSortedData: DevicesTableEntry[];
    filterValue: string = '';
    sortedData: DevicesTableEntry[] = [];
    background_working: boolean = true;
    now: number = Date.now();
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    reloadInterval: Subscription;
    loggedIn: boolean = true;

    constructor(
        public authenticationService: AuthenticationService,
        public deviceService: ApDeviceService,
        private modelService: ModelService,
        public appService: AppService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit() {
        this.devicesTable = new MatTableDataSource();
        this.devicesTable.sortingDataAccessor = (item, property) => {
            // See displayedColumns property
            if (property.startsWith('device.')) {
                property = property.split('.')[1];
                return item.device[property];
            } else {
                return item[property];
            }
        };
        this.sort.sort({ id: 'device.inventoryNumber', start: 'asc', disableClear: true });
        this.devicesTable.filterPredicate = (data: DevicesTableEntry, filterValue: string) => {
            return data.device.inventoryNumber.trim().toLowerCase().indexOf(filterValue) !== -1
                || data.device.lastContactIp && data.device.lastContactIp.trim().toLowerCase().indexOf(filterValue) !== -1
                || data.device.mac && data.device.mac.trim().toLowerCase().indexOf(filterValue) !== -1
                || data.device.location && data.device.location.trim().toLowerCase().indexOf(filterValue) !== -1;
        };
        this.devicesTable.paginator = this.paginator;
        this.authenticationService.sessionInvalid.subscribe(() => {
            this.loggedIn = false;
        });
        this.authenticationService.userIsLoggedIn.subscribe(login => {
            this.loggedIn = login.successful;
        });
    }

    ngAfterViewInit() {
        this.reloadInterval = this.reloadDevicesEveryMinute().subscribe();
        this.loadDevices();
    }

    ngOnDestroy() {
        if (this.reloadInterval) {
            this.reloadInterval.unsubscribe();
        }
    }

    isAllSelected(): boolean {
        const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();
        const processedData = this.devicesTable._orderData(this.devicesTable.filteredData);
        const selected = processedData.slice(page.startIndex, page.endIndex).filter(row => this.selection.isSelected(row));
        let countSameModelDevices = 0; // Count of all devices at current page which have the same model like the selected one
        if (selected.length > 0) {
            countSameModelDevices = processedData.slice(page.startIndex, page.endIndex).filter(row => row.device.model === selected[0].device.model).length;
        }
        return selected.length > 0 && selected.length === countSameModelDevices;
    }

    isAtLeaseOneSelected(): boolean {
        const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();
        const processedData = this.devicesTable._orderData(this.devicesTable.filteredData);
        const numSelected = processedData.slice(page.startIndex, page.endIndex).filter(row => this.selection.isSelected(row)).length;
        return numSelected > 0;
    }

    private findStartEndIndices(): { startIndex: number, endIndex: number } {
        const pageIndex = this.devicesTable.paginator.pageIndex;
        const pageSize = this.devicesTable.paginator.pageSize;
        const total = this.devicesTable.paginator.length;
        const startIndex = pageIndex * pageSize;
        const endIndex = (startIndex + pageSize) > total ? total : startIndex + pageSize;
        return { startIndex: startIndex, endIndex: endIndex };
    }

    masterToggle(): void {
        const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();
        const processedData = this.devicesTable._orderData(this.devicesTable.filteredData);
        const selected = processedData.slice(page.startIndex, page.endIndex).find(row => this.selection.isSelected(row));

        if (this.isAllSelected()) {
            processedData.slice(page.startIndex, page.endIndex).forEach(row => {
                this.selection.deselect(row);
                this.selectedDevices = this.selectedDevices.filter(inventoryNumber => inventoryNumber !== row.device.inventoryNumber);
            });
        } else {
            processedData.slice(page.startIndex, page.endIndex).filter(row => row.device.model === selected.device.model).forEach(row => {
                if (!this.selection.isSelected(row)) {
                    this.selection.select(row);
                    this.selectedDevices.push(row.device.inventoryNumber);
                }
            });
        }
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('apList.pageSize')) {
            this.devicesTable.paginator.pageSize = Number(localStorage.getItem('apList.pageSize'));
            this.devicesTable.paginator = this.paginator; // Update the paginator
        }
        this.devicesTable.paginator._changePageSize = pageSize => {
            localStorage.setItem('apList.pageSize', String(pageSize));
            this.devicesTable.paginator.pageSize = pageSize;
            this.devicesTable.paginator = this.paginator; // Update the paginator
            this.loadDevices();
        };
    }

    /**
     * Function which will be called when table sort has been changed by the user. Because then pagination should be set to 1
     * @param event
     */
    sortDataChanged(sort: Sort): void {
        if (this.devicesTable.paginator !== undefined) {
            this.devicesTable.paginator.pageIndex = 0;
        }
        if (!sort.active || sort.direction === '') {
            this.sortedData = this.originalSortedData;
            this.doFitlerTable(this.filterValue);
        } else {
            const data = this.devicesTable.filteredData.slice();
            this.sortedData = data.sort((a, b) => {
                const isAsc = sort.direction === 'asc';
                switch (sort.active) {
                    case 'device.status':
                        const statusSymbolA = this.deviceService.getDeviceStatusSymbol(a.device, a.vpn_last_contact_time1, a.vpn_last_contact_time2);
                        const statusSymbolB = this.deviceService.getDeviceStatusSymbol(b.device, b.vpn_last_contact_time1, b.vpn_last_contact_time2);
                        return this.compare(statusSymbolA, statusSymbolB, isAsc);
                    case 'device.inventoryNumber':
                        return this.compare(a.device.inventoryNumber, b.device.inventoryNumber, isAsc);
                    case 'device.lastContactIp':
                        return this.compare(a.device.lastContactIp, b.device.lastContactIp, isAsc);
                    case 'device.mac':
                        return this.compare(a.device.mac, b.device.mac, isAsc);
                    case 'device.location':
                        return this.compare(a.device.location, b.device.location, isAsc);
                    default:
                        return 0;
                }
            });
        }
        this.devicesTable = new MatTableDataSource<DevicesTableEntry>(this.sortedData);
        this.devicesTable.sortingDataAccessor = (item, property) => {
            // See displayedColumns property
            if (property.startsWith('device.')) {
                property = property.split('.')[1];
                return item.device[property];
            } else {
                return item[property];
            }
        };
        this.devicesTable.filterPredicate = (data: DevicesTableEntry, filterValue: string) => {
            return data.device.inventoryNumber.trim().toLowerCase().indexOf(filterValue) !== -1
                || data.device.lastContactIp && data.device.lastContactIp.trim().toLowerCase().indexOf(filterValue) !== -1
                || data.device.mac && data.device.mac.trim().toLowerCase().indexOf(filterValue) !== -1
                || data.device.location && data.device.location.trim().toLowerCase().indexOf(filterValue) !== -1;
        };
        this.devicesTable.paginator = this.paginator;
    }

    compare(a: number | string | undefined, b: number | string | undefined, isAsc: boolean) {
        if (a === undefined) {
            a = 0;
        }
        if (b === undefined) {
            b = 0;
        }
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    /**
     * Returns an observable object which gives back every 30 seconds the actual version of device objects on the server side.
     */
    private reloadDevicesEveryMinute(): Observable<DBDevice[]> {
        return interval(30000).pipe(mergeMap(() => {
            return this.loadDevices();
        }));
    }

    private async loadDevices(): Promise<DBDevice[]> {
        this.background_working = true;
        let devices = [];
        try {
            devices = await this.deviceService.getAll().toPromise();
            const data: DevicesTableEntry[] = [];
            devices.forEach(device => {
                data.push({
                    device: device,
                    imageLink$: this.getModelImageLink(device.model),
                    vpn_last_contact_time1: moment(this.deviceService.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_last_contact_time1, '')).valueOf(),
                    vpn_last_contact_time2: moment(this.deviceService.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'VpnClientCert', DBProperty.vpn_last_contact_time2, '')).valueOf()
                });
            });
            this.devicesTable.data = data;
            this.originalSortedData = data;
            // sort data initially
            this.devicesTable.sort = this.sort;
            this.devicesTable.paginator = this.paginator;
            this.preparePaginator();
        } catch (err) {
            devices = [];
            this.snackBar.open('Geräte konnten nicht geladen werden: ' + err, 'OK');
        }

        this.selection.clear();
        this.devicesTable.data.forEach(devicesTableEntry => {
            if (this.selectedDevices.includes(devicesTableEntry.device.inventoryNumber)) {
                this.selection.select(devicesTableEntry);
            }
        });

        this.background_working = false;
        return devices;
    }

    async deviceChanged(result: boolean): Promise<void> {
        if (!result) {
            await this.loadDevices(); // if change was not successful load device list again
        }
        this.background_working = false;
    }

    /**
     * Function for validating the input of the user for changing a property of a user object (only allowed chars are a-zA-Z0-9 )
     * @param event
     */
    validateAPLocationInput(event: any): void {
        const pattern = /[ a-zA-Z0-9_!.\-,]{1,40}/;
        const inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }

    /**
     * Function which will be called when the user has changed the wifi status of a specific device
     * @param event
     * @param device
     */
    async wifiChanged(event, device: DBDevice): Promise<void> {
        this.background_working = true;
        let isEnabled;
        if (event.checked) { // if new value is true then set wifi status to on otherwise off
            isEnabled = 'true';
        } else {
            isEnabled = 'false';
        }
        this.setDeviceFunctionPropertyValue(device, 'WifiCard1', DBProperty.wifi_card_enabled, isEnabled);
        this.setDeviceFunctionPropertyValue(device, 'WifiCard2', DBProperty.wifi_card_enabled, isEnabled);
        this.background_working = false;
        await new Promise(r => setTimeout(r, 1000));
        this.loadDevices();
    }

    /**
     * Function which will change devicefunctionproperty in data model and on server side
     * @param device Object of the device the function should be changed for
     * @param function_id id of the function which should be changed
     * @param property_id id of the property which should be changed
     * @param value new value of the function_property which should be set.
     */
    async setDeviceFunctionPropertyValue(device: DBDevice, function_id: string, property_id: string, value: string): Promise<void> {
        try {
            await this.deviceService.setDeviceFunctionValue(device.inventoryNumber, function_id, property_id, value).toPromise();
        } catch (err) {
            this.snackBar.open('Geräteadaten konnten nicht gespeichert werden: ' + err, 'OK');
        }
    }

    /**
     * Function to open a new Network Dialog
     */
    addApBtClick(): void {
        const dialogRef = this.dialog.open(AddApDialogComponent);
        dialogRef.afterClosed().subscribe(result => {
            this.loadDevices();
        });
    }

    /**
     * Function to open a new Network Dialog
     */
    newApBtClick(): void {
        const dialogRef = this.dialog.open(NewApDialogComponent);
        dialogRef.afterClosed().subscribe(result => {
            this.loadDevices();
        });
    }

    /**
     * Function which is used when a device has been selected in the table
     * @param device
     */
    async selectDevice(device: DBDevice): Promise<void> {
        await this.selectDevices([device]);
    }

    async selectDevices(devices: DBDevice[]): Promise<void> {
        const dialogRef = this.dialog.open(APDetailDialogComponent, {
            data: { devices: devices },
            disableClose: true
        });
        const componentInstance = dialogRef.componentInstance; // Save component instance so that when dialogref is closed but the response is not finished its value doesn't become null
        componentInstance.deviceChanged.subscribe(async (oldDevices: DBDevice[]) => { // New Devices array after change in PortConfigurationComponent in multiple edit mode
            const devices = await this.loadDevices();
            const newDevices = [];
            for (const oldDevice of oldDevices) {
                for (const device of devices) {
                    if (device.inventoryNumber === oldDevice.inventoryNumber) {
                        newDevices.push(device);
                        break;
                    }
                }
            }
            componentInstance.devices = newDevices;
        });
        dialogRef.afterClosed().subscribe(result => {
            this.loadDevices();
        });
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.filterValue = filterValue;
        this.doFitlerTable(this.filterValue);
    }

    doFitlerTable(filterValue: string): void {
        this.devicesTable.filter = filterValue.trim().toLowerCase();
        if (filterValue.trim() === '') {
            this.devicesTable.data = this.originalSortedData;
            this.sortDataChanged(this.sort);
        }
    }

    getModelImageLink(modelId: string): Observable<string> {
        return this.modelService.getModel(modelId).pipe(map(model => {
            return model.image_link;
        }));
    }

    selectForMultipleEdit(): void {
        const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();
        const processedData = this.devicesTable._orderData(this.devicesTable.filteredData);
        const selected = processedData.slice(page.startIndex, page.endIndex).filter(row => this.selection.isSelected(row));
        this.selectDevices(selected.map(selection => selection.device));
    }

    getMultipleEditButtonState(): boolean {
        const page: { startIndex: number, endIndex: number } = this.findStartEndIndices();
        const processedData = this.devicesTable._orderData(this.devicesTable.filteredData);
        const selected = processedData.slice(page.startIndex, page.endIndex).filter(row => this.selection.isSelected(row));
        return selected.length > 0 && selected.every(row => row.device.model === selected[0].device.model);
    }

    toggleSelection(devicesTableEntry: DevicesTableEntry): void {
        this.selection.toggle(devicesTableEntry);
        if (this.selection.isSelected(devicesTableEntry)) {
            this.selectedDevices.push(devicesTableEntry.device.inventoryNumber);
        } else {
            this.selectedDevices = this.selectedDevices.filter(inventoryNumber => devicesTableEntry.device.inventoryNumber !== inventoryNumber);
        }
    }

    determineWifiStatus(device: DBDevice): number {
        const wifiCard1 = this.deviceService.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard1', DBProperty.wifi_card_enabled, 'true') === 'true';
        const wifiCard2 = this.deviceService.getDeviceFunctionPropertyValue(device.deviceFunctionProperties, 'WifiCard2', DBProperty.wifi_card_enabled, 'true') === 'true';
        return !wifiCard1 && !wifiCard2 ? -1 : wifiCard1 && wifiCard2 ? 0 : wifiCard1 ? 1 : 2;
    }

    isDeviceModelAP(device: DBDevice) {
        return this.modelService.isModelAP(this.modelService.getModelForDevice(device));
    }
}
