import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiNetwork } from '../../data/WifiNetwork';
import { WifiNetworkService } from '../NetworkService/WifiNetwork.service';
import { LogicalNetwork } from 'app/data/LogicalNetwork';
import { LogicalNetworkService } from '../NetworkService/LogicalNetwork.service';
import { AuthenticationService } from 'app/Login/authentication.service';
import { NewLogicalNetworkDialogComponent } from '../NewLogicalNetwork/newLogicalNetwork.dialog.component';
import { ipAddress } from 'app/endooSpotApplication/Validators/ipAddress';
import { Observable } from 'rxjs';
import { PskWarningDialogComponent } from '../PskWarningDialog/pskWarning.dialog.component';
import { TimesControlData } from 'app/Components/times-control/times-control-data';

@Component({
    selector: 'newWifiNetworkComponent',
    templateUrl: './newWifiNetwork.component.html',
})
export class NewWifiNetworkDialogComponent implements OnInit {
    form: FormGroup;
    contract_link: string = '';
    network_new: WifiNetwork;
    timesControlData: TimesControlData;
    logicalNetworks: LogicalNetwork[] = [];
    showKey: boolean = false;
    @Output() changed: EventEmitter<void> = new EventEmitter<void>();

    constructor(private wifiNetworkService: WifiNetworkService, private logicalNetworkService: LogicalNetworkService, public authenticationService: AuthenticationService, private _formBuilder: FormBuilder, private dialogRef: MatDialogRef<NewWifiNetworkDialogComponent>, private snackBar: MatSnackBar, private dialog: MatDialog) {
        // creating form validator for the new User form.
        this.form = this._formBuilder.group({
            ssid: ['', [Validators.required, Validators.maxLength(32)]],
            client2client: [true],
            logicalNetworkId: [undefined, [Validators.required]],
            encryption: [false],
            encryption_mode: ['', [Validators.required]],
            encryption_key: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(63), Validators.pattern(/^[\u0020-\u007e]+$/)]],
            encryption_radius_ip: ['', [Validators.required, ipAddress()]],
            encryption_radius_port: ['', [Validators.required, Validators.min(1), Validators.max(65535)]],
            encryption_radius_secret: ['', [Validators.required]]
        });
        this.form.get('encryption').valueChanges.subscribe(value => {
            if (value) {
                this.form.get('encryption_mode').enable();
                this.form.get('encryption_key').enable();
                this.form.get('encryption_radius_ip').enable();
                this.form.get('encryption_radius_port').enable();
                this.form.get('encryption_radius_secret').enable();
            } else {
                this.form.get('encryption_mode').setValue('');
                this.form.get('encryption_mode').disable();
                this.form.get('encryption_key').setValue('');
                this.form.get('encryption_key').disable();
                this.form.get('encryption_radius_ip').setValue('');
                this.form.get('encryption_radius_ip').disable();
                this.form.get('encryption_radius_port').setValue('');
                this.form.get('encryption_radius_port').disable();
                this.form.get('encryption_radius_secret').setValue('');
                this.form.get('encryption_radius_secret').disable();
            }
        });
        this.form.get('encryption_mode').valueChanges.subscribe(value => {
            if (value === 'psk') {
                this.form.get('encryption_key').enable();
                this.form.get('encryption_radius_ip').setValue('');
                this.form.get('encryption_radius_ip').disable();
                this.form.get('encryption_radius_port').setValue('');
                this.form.get('encryption_radius_port').disable();
                this.form.get('encryption_radius_secret').setValue('');
                this.form.get('encryption_radius_secret').disable();
            }
            if (value === 'enterprise') {
                this.form.get('encryption_key').setValue('');
                this.form.get('encryption_key').disable();
                this.form.get('encryption_radius_ip').enable();
                this.form.get('encryption_radius_port').enable();
                this.form.get('encryption_radius_secret').enable();
            }
        });
        this.form.get('encryption_mode').disable();
        this.form.get('encryption_key').disable();
        this.form.get('encryption_radius_ip').disable();
        this.form.get('encryption_radius_port').disable();
        this.form.get('encryption_radius_secret').disable();

        this.network_new = new WifiNetwork(this.wifiNetworkService.getMaxNetworkID() + 1, 0, true, '');
    }

    ngOnInit() {
        this.timesControlData = new TimesControlData(false, []);
        this.timesControlData.active = this.network_new.wifitime_active;
        this.timesControlData.times.push({ weekdayId: 0, onValue: this.network_new.wifitime_mon_on, offValue: this.network_new.wifitime_mon_off });
        this.timesControlData.times.push({ weekdayId: 1, onValue: this.network_new.wifitime_tue_on, offValue: this.network_new.wifitime_tue_off });
        this.timesControlData.times.push({ weekdayId: 2, onValue: this.network_new.wifitime_wed_on, offValue: this.network_new.wifitime_wed_off });
        this.timesControlData.times.push({ weekdayId: 3, onValue: this.network_new.wifitime_thu_on, offValue: this.network_new.wifitime_thu_off });
        this.timesControlData.times.push({ weekdayId: 4, onValue: this.network_new.wifitime_fri_on, offValue: this.network_new.wifitime_fri_off });
        this.timesControlData.times.push({ weekdayId: 5, onValue: this.network_new.wifitime_sat_on, offValue: this.network_new.wifitime_sat_off });
        this.timesControlData.times.push({ weekdayId: 6, onValue: this.network_new.wifitime_sun_on, offValue: this.network_new.wifitime_sun_off });
        this.fetchLogicalNetworks();
    }

    openNewLogicalNetworkDialog(): void {
        const dialogRef = this.dialog.open(NewLogicalNetworkDialogComponent);

        dialogRef.afterClosed().subscribe(async result => {
            await this.fetchLogicalNetworks();
            this.form.get('logicalNetworkId').setValue(this.logicalNetworks.find(network => network.id_number === result).id_number);
        });
    }

    async fetchLogicalNetworks(): Promise<void> {
        try {
            this.logicalNetworks = await this.logicalNetworkService.getLogicalNetworks().toPromise();
        } catch (err) {
            this.snackBar.open('Die logischen Netzwerke konnten nicht geladen werden: ' + err, 'OK');
            this.dialogRef.close(false);
        }
    }

    async createNewWifiNetworkBtClick(): Promise<void> {
        // Used to ensure user wants to enter an PSK with length lower than 20
        let confirmSaveObservable = new Observable(subscriber => {
            subscriber.next();
        });
        if (this.form.get('encryption_mode').value === 'psk' && this.form.get('encryption_key').value.length < 20) {
            confirmSaveObservable = new Observable(subscriber => {
                const dialogRef = this.dialog.open(PskWarningDialogComponent);
                dialogRef.afterClosed().subscribe(confirmed => {
                    if (confirmed) {
                        subscriber.next();
                    }
                });
            });
        }

        confirmSaveObservable.subscribe(async () => {
            this.network_new.logical_network_id = this.form.get('logicalNetworkId').value;

            this.network_new.wifitime_active = this.timesControlData.active;
            this.network_new.wifitime_mon_on = this.timesControlData.times[0].onValue;
            this.network_new.wifitime_tue_on = this.timesControlData.times[1].onValue;
            this.network_new.wifitime_wed_on = this.timesControlData.times[2].onValue;
            this.network_new.wifitime_thu_on = this.timesControlData.times[3].onValue;
            this.network_new.wifitime_fri_on = this.timesControlData.times[4].onValue;
            this.network_new.wifitime_sat_on = this.timesControlData.times[5].onValue;
            this.network_new.wifitime_sun_on = this.timesControlData.times[6].onValue;
            this.network_new.wifitime_mon_off = this.timesControlData.times[0].offValue;
            this.network_new.wifitime_tue_off = this.timesControlData.times[1].offValue;
            this.network_new.wifitime_wed_off = this.timesControlData.times[2].offValue;
            this.network_new.wifitime_thu_off = this.timesControlData.times[3].offValue;
            this.network_new.wifitime_fri_off = this.timesControlData.times[4].offValue;
            this.network_new.wifitime_sat_off = this.timesControlData.times[5].offValue;
            this.network_new.wifitime_sun_off = this.timesControlData.times[6].offValue;

            const wifiNetworkNameExists = await this.wifiNetworkService.networkNameExists(this.network_new.ssid);
            if (wifiNetworkNameExists) {
                this.snackBar.open('Diese SSID ist bereits vergeben', 'OK');
                return;
            }
            try {
                await this.wifiNetworkService.createWifiNetwork(this.network_new).toPromise();
                this.snackBar.open('Das WLAN-Netzwerk wurde erfolgreich erstellt', 'OK');
                this.dialogRef.close(true);
            } catch (err) {
                this.snackBar.open('Das WLAN-Netzwerk konnte nicht erstellt werden: ' + err, 'OK');
                this.dialogRef.close(false);
            }
        });
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }

    timeSettingsChanged(e: TimesControlData): void {
        this.timesControlData = e;
    }
}
