import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login.component';
import { ChooseCustomerComponent } from './chooseCustomer.component';
import { CanActivateAuthGuard } from './canActivateAuthguard';
import { endooSpotAuthComponent } from './endooSpotAuth.component';

const endooSpotAuthRoutes: Routes = [
    {
        path: 'login',
        component: endooSpotAuthComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
            },
            {
                path: 'chooseCustomer',
                component: ChooseCustomerComponent,
                canActivate: [CanActivateAuthGuard],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(endooSpotAuthRoutes)],
    exports: [RouterModule],
})
export class endooSpotManagementAuthRoutingModule { }
