/**
 * Rest konformes Objekt for communication with backend
 */
export class DBDevice {
   inventoryNumber: string;
   lastContactTime: number; // millis since 01.01.1970
   lastContactIp: string;
   mac: string;
   location: string;
   comment: string;
   status: string;
   model: string;
   serial_number: string;
   deviceFunctionProperties: DBDeviceFunctionProperty[];
}

export class DBModel {
   modelId: string;
   fullName: string;
   mode: 'ACCESSPOINT' | 'SWITCH' | 'ROUTER';
   functions: DBFunction[];
   technical_description: string;
   serial_number_available: boolean;
   image_link: string;
   html_label: string;
   mac_available: boolean;
}

export class DBCustomer {
   customerId: string;
   name: string;
   domain: string;
   logo: string;
   email: string;
   updatedAt: Date;
   status: 'ACTIVE' | 'DISABLED' | 'TESTPACKAGE' | 'INTERNAL';
   street: string;
   zip: string;
   city: string;
   country: string;
}

export class DBAdmin {
   admin_id: string;
   username: string;
   description: string;
   email: string;
}

export class DBFunction {
   functionId: string;
   name: string;
   description: string;
}

export class DBRole {
   id: string;
   name: string;
   description: string;
}

export class DBAuthorizedFunction {
   af_id: number;
   function: DBFunction;
   customer: DBCustomer;
}

export class DBProperty {

   // property names in the db

   /**
    * LOGICAL NETWORKS
    */
   public static logical_network_name_property = 'logical_network_name';
   public static logical_network_activated_property = 'logical_network_enabled';
   public static logical_network_untagged_property = 'logical_network_untagged';
   public static logical_network_vlan_id_property = 'logical_network_vlan_tag';
   public static logical_network_color_property = 'logical_network_color';
   public static logical_network_id_property = 'logical_network_id';

   /**
    * WIFI CARD IN AP_DETAIL
    */
   public static wifi_card_enabled = 'wifi_card_enabled';
   public static wifi_card_type = 'wifi_card_type';
   public static wifi_card_channel = 'wifi_card_channel';
   public static wifi_card_channel_width = 'wifi_card_channel_width';
   public static wifi_card_txpower = 'wifi_card_txpower'

   /**
    * WIFI NETWORKS
    */
   public static wifi_network_id_property = 'wifi_network_id';
   public static wifi_network_enabled = 'wifi_network_enabled';
   public static wifi_network_logical_networkID = 'wifi_network_networkid';
   public static wifi_network_client2client = 'wifi_network_client2client';
   public static wifi_network_ssid = 'wifi_network_ssid';
   public static wifi_network_hidessid = 'wifi_network_hidessid';
   public static wifi_network_encryption = 'wifi_network_encryption';
   public static wifi_network_encryption_mode = 'wifi_network_encryption_mode';
   public static wifi_network_encryption_key = 'wifi_network_encryption_psk';
   public static wifi_network_radius_ip = 'wifi_network_encryption_radius_ip';
   public static wifi_network_radius_secret = 'wifi_network_encryption_radius_secret';
   public static wifi_network_radius_port = 'wifi_network_encryption_radius_port';
   public static wifi_network_wifitime_active = 'wifi_network_timecontrol_enabled';
   public static wifi_network_wifi_time_mon_on = 'wifi_network_timecontrol_mon_on';
   public static wifi_network_wifi_time_tue_on = 'wifi_network_timecontrol_tue_on';
   public static wifi_network_wifi_time_wed_on = 'wifi_network_timecontrol_wed_on';
   public static wifi_network_wifi_time_thu_on = 'wifi_network_timecontrol_thu_on';
   public static wifi_network_wifi_time_fri_on = 'wifi_network_timecontrol_fri_on';
   public static wifi_network_wifi_time_sat_on = 'wifi_network_timecontrol_sat_on';
   public static wifi_network_wifi_time_sun_on = 'wifi_network_timecontrol_sun_on';
   public static wifi_network_wifi_time_mon_off = 'wifi_network_timecontrol_mon_off';
   public static wifi_network_wifi_time_tue_off = 'wifi_network_timecontrol_tue_off';
   public static wifi_network_wifi_time_wed_off = 'wifi_network_timecontrol_wed_off';
   public static wifi_network_wifi_time_thu_off = 'wifi_network_timecontrol_thu_off';
   public static wifi_network_wifi_time_fri_off = 'wifi_network_timecontrol_fri_off';
   public static wifi_network_wifi_time_sat_off = 'wifi_network_timecontrol_sat_off';
   public static wifi_network_wifi_time_sun_off = 'wifi_network_timecontrol_sun_off';

   /**
    * ROUTER NETWORK
    */
   public static router_network_id_property = 'router_network_id';
   public static router_network_lnetworkid = 'router_network_lnetworkid';
   public static router_network_type = 'router_network_type';
   public static router_network_ip_type = 'router_network_ip_type';
   public static router_network_ip = 'router_network_ip';
   public static router_network_gateway = 'router_network_gateway';
   public static router_network_gateway_ip = 'router_network_gateway_ip';
   public static router_network_subnet = 'router_network_subnet';
   public static router_network_dhcp_enabled = 'router_network_dhcp_enabled';
   public static router_network_dhcp_start = 'router_network_dhcp_start';
   public static router_network_dhcp_end = 'router_network_dhcp_end';
   public static router_network_filtering = 'router_network_filtering';
   public static router_network_captive_portal_id = 'router_network_captive_portal_id';
   public static router_network_media_access = 'router_network_media_access';
   public static router_network_preferred_uplink_network = 'router_network_preferred_uplink_network'; // TODO add property name
   public static router_network_port_filter = 'router_network_port_filter';
   public static router_network_allowed_tcp_ports = 'router_network_allowed_tcp_ports';
   public static router_network_allowed_udp_ports = 'router_network_allowed_udp_ports';

   /**
    * CAPTIVE PORTAL
    */
   public static router_cp_id_property = 'captive_portal_id';
   public static router_cp_enabled = 'captive_portal_enabled';
   public static router_cp_max_client_up_speed = 'captive_portal_max_client_up_speed';
   public static router_cp_max_client_down_speed = 'captive_portal_max_client_down_speed';
   public static router_cp_endoo_auth = 'captive_portal_endoo_auth'; // TODO endoo, custom, guest
   public static router_cp_radius_ip = 'captive_portal_radius_ip';
   public static router_cp_radius_port = 'captive_portal_radius_port';
   public static router_cp_radius_secret = 'captive_portal_radius_secret';

   /**
    * PORT CONFIGURATION FOR SWITCHES
    */
   public static switch_port_label = 'switch_port_label';
   public static switch_port_comments = 'switch_port_comments';
   public static switch_port_untagged_network_id = 'switch_port_untagged_network_id';
   public static switch_port_tagged_network_ids = 'switch_port_tagged_network_ids';
   public static switch_port_poe_enabled = 'switch_port_poe_enabled';
   public static switch_port_mgmt_port = 'switch_port_mgmt_port';

   /**
    * PORT CONFIGURATION FOR APs
    */
   public static access_point_port_label = 'access_point_port_label';
   public static access_point_port_untagged_network_id = 'access_point_port_untagged_network_id';
   public static access_point_port_tagged_network_ids = 'access_point_port_tagged_network_ids';
   public static access_point_port_default_config = 'access_point_port_default_config';

   /**
    * PORT CONFIGURATION FOR ROUTERS
    */
   public static router_port_label = 'router_port_label';
   public static router_port_comments = 'router_port_comments';
   public static router_port_untagged_network_id = 'router_port_untagged_network_id';
   public static router_port_tagged_network_ids = 'router_port_tagged_network_ids';
   public static router_port_mode = 'router_port_mode';

   /**
    * SWITCH SETTINGS GLOBAL AND FOR A DEVICE
    */
   public static switch_global_settings = 'switch_global_settings';
   public static switch_settings_spanning_tree_protocol_enabled = 'switch_settings_spanning_tree_protocol_enabled';
   public static switch_settings_spanning_tree_protocol = 'switch_settings_spanning_tree_protocol';
   public static switch_settings_bridge_priority = 'switch_settings_bridge_priority';
   public static switch_settings_lldp = 'switch_settings_lldp';

   /**
    * ROUTER SETTINGS GLOBAL
    */
    public static fwan_settings_default_gateway = 'fwan_settings_default_gateway';

    /**
    * ROUTER VPN
    */
     public static vpn_client_cert_id = 'vpn_client_cert_id';
     public static vpn_client_cert_valid_time = 'vpn_client_cert_valid_time';
     public static vpn_last_contact_ip1 = 'vpn_last_contact_ip1';
     public static vpn_last_contact_ip2 = 'vpn_last_contact_ip2';
     public static vpn_last_contact_time1 = 'vpn_last_contact_time1';
     public static vpn_last_contact_time2 = 'vpn_last_contact_time2';
     public static vpn_wan_ip = 'vpn_wan_ip';

   propertyId: string;
   name: string;
   description: string;
}

export class DBDeviceFunctionProperty {
   function: string;
   property: string;
   value: string;
}

export class DBCustomerFunctionProperty {
   value: string;
   function: string;
   property: string;
}
