import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBDevice, DBModel } from '../../data/endooSpotDB_objects';
import { ModelService } from '../APService/model.service';
import { ApDeviceService } from '../APService/apDevice.service';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { AppService } from 'app/Services/app.service';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'newApComponent',
    templateUrl: './newAp.component.html',
    styleUrls: ['./newAp.component.css'],
})
export class NewApDialogComponent implements OnInit {
    form: FormGroup;
    modelImage: string;
    new_device: DBDevice = new DBDevice();
    models: DBModel[] = [];
    rootUsername: string;
    qrData: string;
    selectedModels: DBModel[];

    constructor(
        private modelService: ModelService,
        private apService: ApDeviceService,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<NewApDialogComponent>,
        private snackBar: MatSnackBar,
        private appService: AppService
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            model_id: ['', [Validators.required]],
            mac: ['', [Validators.required, Validators.maxLength(17), Validators.pattern(/^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/)]],
            serial_number: ['', [Validators.required]],
        });
        this.form.get('mac').valueChanges.subscribe((value: string) => {
            if (value === undefined) {
                return;
            }
            let newValue = '';
            value = value.replace(/\:/g, '');
            for (let i = 0; i < value.length; i += 2) {
                newValue += value.substring(i, i + 2) + ':';
            }
            if (newValue.charAt(newValue.length - 1) === ':') {
                newValue = newValue.substring(0, newValue.length - 1);
            }
            newValue = newValue.substring(0, 17);
            this.form.get('mac').setValue(newValue, { emitEvent: false });
        });
        this.form.get('mac').disable();
        this.form.get('serial_number').disable();
        this.getModels();
        this.initFilterForAutocomplete();
    }

    get mac(): any {
        return this.form.get('mac').value;
    }

    get serialNumber(): any {
        return this.form.get('serial_number').value;
    }

    get modelId(): any {
        return this.form.get('model_id').value;
    }

    get comment(): any {
        return this.new_device.comment;
    }

    async getModels(): Promise<void> {
        try {
            this.models = await this.modelService.getAll().toPromise();
            this.models.sort((a, b) => a.modelId.localeCompare(b.modelId));
            this.selectedModels = this.models;
        } catch (err) {
            this.snackBar.open('Die Geräte konnten nicht geladen werden: ' + err, 'OK');
        }
    }

    async newDevice(stepper: MatHorizontalStepper): Promise<void> {
        try {
            const response = await this.apService.createDevice(this.modelId, this.mac, this.serialNumber, this.comment).toPromise();
            this.new_device = response.device;
            stepper.next();
        } catch (err) {
            this.snackBar.open('Das Gerät konnte nicht erstellt werden: ' + err, 'OK');
        }
    }

    public CloseDialog(dialogResult?: any): void {
        this.dialogRef.close(dialogResult);
    }

    printModelLabel(): void {
        const currentModelId = this.new_device.model;
        const currentModel = this.models.find(model => model.modelId.toLowerCase() === currentModelId.toLowerCase());
        const labelWindow = open();
        labelWindow.document.write(currentModel.html_label);
        if (labelWindow.document.getElementById('title') !== null) {
            labelWindow.document.getElementById('title').innerHTML = currentModel.fullName;
        }
        labelWindow.document.getElementById('model-id').innerHTML = this.new_device.inventoryNumber.toUpperCase();
        if (currentModel.serial_number_available) {
            labelWindow.document.getElementById('serial-no').innerHTML = 'S/N: ' + this.new_device.serial_number;
        }
        if (currentModel.mac_available) {
            labelWindow.document.getElementById('mac').innerHTML = 'MAC: ' + this.new_device.mac;
        }
        // Generate QR-code
        this.qrData = 'v1;' + this.new_device.inventoryNumber + ';' + (!this.new_device.mac ? 'na' : this.new_device.mac) + ';' + (!this.new_device.serial_number ? 'na' : this.new_device.serial_number) + ';' + currentModelId;
        // Print off window
        setTimeout(() => {
            labelWindow.document.getElementById('qr-code').innerHTML = document.getElementsByClassName('qrcode')[0].innerHTML;
        }, 200);
        setTimeout(() => {
            labelWindow.print();
            if (!this.appService.isEdgeBrowser()) {
                labelWindow.close();
            }
        }, 700);
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.form.get(formControl).hasError(error);
    }

    /**
     *
     * @param value the input value from the form
     * @param arrayToFilter which array should be fitlert
     * @returns an array which contains the filtered objects
     */
    private _filter(value: string): DBModel[] {
        if (value !== undefined && typeof value === 'string') {
            const filterValue = value.toLowerCase();
            return this.models.filter(model => model.modelId.toLowerCase().includes(filterValue));
        }
    }

    private async initFilterForAutocomplete() {
        this.form.get('model_id').valueChanges.pipe(
            startWith(''),
            map(value => {
                if (typeof value === 'string') {
                    this.selectedModels = this._filter(value);
                }
            })
        ).subscribe();
    }

    // Display the modelId for the mat-autocomplete
    displayNameOfTheModel(model?: string): string {
        return model ? model : '';
    }

    onSelectionChange(modelId: string) {
        const selectedModel = this.models.find(model => model.modelId.toLowerCase() === modelId.toLowerCase());
        this.modelImage = selectedModel.image_link;
        if (selectedModel.serial_number_available) {
            this.form.get('serial_number').enable();
        } else {
            this.form.get('serial_number').setValue(undefined);
            this.form.get('serial_number').disable();
        }
        if (selectedModel.mac_available) {
            this.form.get('mac').enable();
        } else {
            this.form.get('mac').setValue(undefined);
            this.form.get('mac').disable();
        }
    }

    /**
     * Prevent continue form without selecting model from models array
     */
    onManualInput() {
        this.form.get('model_id').setErrors({ required: true });
    }
}
