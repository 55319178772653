<div id="container-div">
    <p>Hier haben Sie die Möglichkeit für dieses Gerät den Zustand der WLAN-Netzwerke zu ändern.</p>
    <mat-table #table matSortActive="ssid" [dataSource]="networkProperties" matSort>
        <ng-container matColumnDef="ssid">
            <mat-header-cell *matHeaderCellDef>SSID</mat-header-cell>
            <mat-cell *matCellDef="let np">
                {{np.ssid}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="customerLevel">
            <mat-header-cell *matHeaderCellDef>Global aktiviert</mat-header-cell>
            <mat-cell *matCellDef="let np">
                <mat-checkbox [checked]="np.customerWifiNetworkEnabled" disabled></mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="enabledDeviceLevel">
            <mat-header-cell *matHeaderCellDef>Nutze gerätespezifische Einstellungen</mat-header-cell>
            <mat-cell *matCellDef="let np">
                <mat-checkbox (change)="updateDeviceWifiNetworkEnabled($event, np); saved(false)"
                    [checked]="np.enableDeviceSetting">
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="deviceLevel">
            <mat-header-cell *matHeaderCellDef>Auf Gerät aktiviert</mat-header-cell>
            <mat-cell *matCellDef="let np">
                <mat-checkbox (change)="updateDeviceWifiNetworkEnabledPropertyValue($event, np); saved(false)"
                    [hidden]="!np.enableDeviceSetting" [checked]="np.deviceWifiNetworkEnabled">
                </mat-checkbox>
            </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let np; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
<div>
    <button mat-raised-button color="primary" [disabled]="!saveButtonEnabled" (click)="saveChanges()">Speichern</button>
</div>
