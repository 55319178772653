import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { groupsResponse, finishResponse } from './groupService.responses';
import { Group } from '../../data/Group';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { GroupSearchResult } from 'app/data/GroupSearchResult';

@Injectable()
export class GroupService {
    private readonly groupURL = '/api/group/';
    private readonly groupsURL = '/api/groups/';

    constructor(private http: EndooHttpService, private authService: AuthenticationService) { }

    /**
     * Function which returns all groups from Server
     */
    getAll(): Observable<Group[]> {
        return this.http.get<groupsResponse>(this.groupsURL + this.authService.getCustomerId() + '/all', { observe: 'response', responseType: 'json' })
            .pipe(map(groupsResponse => {
                return groupsResponse.groups;
            }));
    }

    /**
     * Function which returns groups which match pattern. Will be returned in jquery ui autocomplete return return_value;ready style.
     * @param searchstring string which contains value that should be searched for
     */
    search(searchstring: string): Observable<GroupSearchResult[]> {
        return this.http.get<GroupSearchResult[]>(this.groupsURL + this.authService.getCustomerId() + '/' + searchstring, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which return a distinct group from Server
     * @param groupname Name of the group which should be returned
     */
    getGroup(groupname: string): Observable<Group> {
        return this.http.get<Group>(this.groupURL + this.authService.getCustomerId() + '/' + groupname, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which list groups which match the searchstring
     * @param searchstring Pattern which returned groups should match
     */
    listGroup(searchstring: string): Observable<Group[]> {
        return this.http.get<Group[]>(this.groupsURL + this.authService.getCustomerId() + '/list/' + searchstring, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to change Groupname of Group.
     * @param groupname Groupname of the group where the groupname should be changed.
     * @param newValue New groupname for the group.
     */
    changeGroupname(groupname: string, newValue: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.groupURL + this.authService.getCustomerId() + '/' + groupname + '/groupname', { groupname: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map(finishResponse => {
                return finishResponse.finished;
            }));
    }

    /**
     * Function to change Groupname of Group.
     * @param groupname Groupname of the group where the groupname should be changed.
     * @param newValue New groupname for the group.
     */
    changeDescription(groupname: string, newValue: string): Observable<boolean> {
        return this.http.post<finishResponse>(this.groupURL + this.authService.getCustomerId() + '/' + groupname + '/description', { description: newValue }, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    addPersontoGroup(groupname: string, username: string): Observable<boolean> {
        return this.http.put<finishResponse>(this.groupURL + this.authService.getCustomerId() + '/' + groupname + '/members/' + username, {}, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    removePersonfromGroup(groupname: string, username: string): Observable<boolean> {
        return this.http.delete<finishResponse>(this.groupURL + this.authService.getCustomerId() + '/' + groupname + '/members/' + username, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to delete a group from the system.
     * @param groupname groupname of the group which should be deleted.
     */
    delete(groupname: string): Observable<boolean> {
        return this.http.delete<finishResponse>(this.groupURL + this.authService.getCustomerId() + '/' + groupname, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }

    /**
     * Function to create a group in the system.
     * @param group Group object of the group which should be created.
     */
    create(group: Group): Observable<boolean> {
        const json = JSON.stringify(group);
        return this.http.put<finishResponse>(this.groupURL + this.authService.getCustomerId() + '/' + group.groupname, json, { observe: 'response', responseType: 'json' })
            .pipe(map((response: finishResponse) => {
                return response.finished;
            }));
    }
}
