<h1 mat-dialog-title>Änderungen speichern?</h1>
<div mat-dialog-content>
	Folgende Änderungen wurden nicht gespeichert:
	<ul>
		<li *ngFor="let name of data.changesNames">
			{{name}}
		</li>
	</ul>
</div>
<div style="padding: 15px; background-color: white; position: sticky; bottom: 0; z-index: 1000;">
	<hr>
	<button mat-raised-button (click)="cancel()">Abbrechen</button>
	<button mat-raised-button (click)="dispose()">Änderungen verwerfen</button>
	<button mat-raised-button (click)="save()">Änderungen übernehmen</button>
</div>
