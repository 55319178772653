import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { DashboardService } from './dashboard.service';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { endooSpotCanvasGraphModule } from './Canvas/endooSpotCanvasGraph.module';
import { endooSpotPlotlyGraphModule } from './Plotly/endooSpotPlotlyGraph.module';
import { DevicePlotlyGraphService } from './device-plotly-graph.service';

@NgModule({
    imports: [
        CommonModule,
        MatTabsModule,
        MatCardModule,
        MatToolbarModule,
        endooSpotPlotlyGraphModule,
        endooSpotCanvasGraphModule,
    ],
    declarations: [
        DashboardComponent,
    ],
    providers: [
        DashboardService,
        DevicePlotlyGraphService,
    ],
})
export class endooSpotDashboardModule { }
