<mat-toolbar>
    <span class="user-select-none">Administratorverwaltung</span>
    <span class="example-spacer"></span>

    <ng-container *ngIf="appService.isAppScreen()">
        <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <a mat-menu-item (click)="addAdminBtClick()">Administrator hinzufügen</a>
            <a mat-menu-item (click)="newAdminBtClick()">Neuer Administrator</a>
        </mat-menu>
    </ng-container>
    <ng-container *ngIf="!appService.isAppScreen()">
        <button mat-raised-button style="margin-left: 10px" (click)="addAdminBtClick()">Administrator zur Schule hinzufügen</button>
        <button mat-raised-button style="margin-left: 10px" (click)="newAdminBtClick()">Neuen Administrator erstellen</button>
    </ng-container>
</mat-toolbar>

<div class="content-container" id="UserList_div">
    <mat-card>
        <div class="table-div">
            <mat-form-field>
                <mat-label>In Tabelle suchen</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
            </mat-form-field>
            <mat-table [dataSource]="admins" class="table" id="UserList_table" matSort>
                <ng-container matColumnDef="selection">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let admin">
                        <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(admin) : null" [checked]="selection.isSelected(admin)" [disabled]="!isOwnAdminOfCustomer(admin)">
                        </mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Benutzername </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectAdmin(admin)" *matCellDef="let admin">
                        {{admin.username}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Beschreibung </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectAdmin(admin)" *matCellDef="let admin">
                        {{admin.description}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> E-Mail Adresse </mat-header-cell>
                    <mat-cell class="clickable_cell" (click)="selectAdmin(admin)" *matCellDef="let admin">
                        {{admin.email}} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let person; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[20, 40, 100]" [showFirstLastButtons]="true">
            </mat-paginator>
        </div>
        <mat-card-actions>
            <button mat-raised-button color="warn" [disabled]="selection.selected.length<=0" title="Loeschen"
                (click)="deleteSelectedAdminsBtClick()" id="Delete_selected_Persons_Bt">Gew&auml;hlte Administratoren
                l&ouml;schen</button>
        </mat-card-actions>
    </mat-card>
</div>