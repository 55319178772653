import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../Login/authentication.service';
import { RESTResponseObject } from './adminService.responses'
import { Observable } from 'rxjs';
import { DBAdmin, DBRole } from '../../data/endooSpotDB_objects';
import { EndooHttpService } from 'app/Services/endooHttp.service';
import { finishResponse } from 'app/Login/authService.responses';

@Injectable()
export class AdminService {
    private readonly adminURL = '/api/admins/';
    private readonly customersURL = '/api/customers/';

    /**
     * Constructor of the service object. All params will be injected by angular.
     * @param http http client object injected by angular.
     * @param authService service object handling everything regarding authorization.
     */
    constructor(private http: EndooHttpService, private authService: AuthenticationService) { }

    /**
     * Function which returns all users from Server
     */
    getAll(): Observable<DBAdmin[]> {
        return this.http.get<DBAdmin[]>(this.adminURL + 'customers/' + this.authService.getCustomerId(), { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which returns all roles a customer is allowed to use
     */
    getCustomerRoles(): Observable<DBRole[]> {
        return this.http.get<DBRole[]>(this.customersURL + this.authService.getCustomerId() + '/roles', { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to return a specific admin from serverside. Will return a observable which will return the admin object when the rest-request has been finished.
     * @param username username of the admin which should be returned.
     */
    getAdmin(username: string): Observable<DBAdmin> {
        return this.http.get<DBAdmin>(this.adminURL + username, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function which returns the roles of an admin identified by username. Will return a observable which will return the admin object when the rest-request has been finished.
     * @param username username of the admin you want to receive permissions for.
     */
    getRoles(username: string): Observable<DBRole[]> {
        return this.http.get<DBRole[]>(this.adminURL + username + '/customers/' + this.authService.getCustomerId() + '/roles', { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to change an admin
     * @param username Username of admin which should be changed
     * @param new_admin object with information about the admin
     * @param password  new password (optional)
     */
    changeAdmin(new_admin: DBAdmin, password?: string): Observable<void> {
        if (password) {
            return this.http.post(this.adminURL + this.authService.getCustomerId() + '/' + new_admin.username, { admin: new_admin, password: password }, { observe: 'response', responseType: 'json' });
        } else {
            return this.http.post(this.adminURL + this.authService.getCustomerId() + '/' + new_admin.username, { admin: new_admin }, { observe: 'response', responseType: 'json' });
        }
    }

    /**
     * Function to delete a an admin from the system.
     * @param username Username of the admin which should be deleted.
     */
    delete(username: string): Observable<boolean> {
        return this.http.delete<boolean>(this.adminURL + this.authService.getCustomerId() + '/' + username, { observe: 'response', responseType: 'json' });
    }

    /**
     * Function to add a new admin to the system.
     * @param new_admin Object which contains information about the new admin that should be added
     */
    createAdmin(new_admin: DBAdmin, password: string): Observable<finishResponse> {
        return this.http.put<finishResponse>(this.adminURL + new_admin.username, { admin: new_admin, password: password }, { observe: 'response', responseType: 'json' });
    }

    /**
     * Authorize admin to execute function with function_id
     * @param username Username of admin which should be authorized
     * @param role_id id of role the admin should be authorized to
     */
    authorizeAdmin(username, role_id: string): Observable<RESTResponseObject> {
        return this.http.post<RESTResponseObject>(this.adminURL + username + '/roles/' + role_id + '/' + this.authService.getCustomerId(), null, { observe: 'response', responseType: 'json' });
    }

    /**
    * Authorize admin to be schooladmin of school with customer_id
    * @param username Username of admin which should be authorized
    * @param customer_id id of school the admin should be authorized to
    */
    makeAdminSchoolAdmin(username, customer_id: string, roles: string[]): Observable<RESTResponseObject> {
        return this.http.post<RESTResponseObject>(this.adminURL + username + '/customers/' + customer_id, {  roles: roles }, { observe: 'response', responseType: 'json' });
    }

    /**
    * Remove a non Tenant Admin from school with customer_id. This is the opposite of makeAdminSchoolAdmin
    * @param username Username of admin which should be authorized
    * @param customer_id id of school the admin should be authorized to
    */
    removeAdminFromSchool(username, customer_id: string): Observable<RESTResponseObject> {
        return this.http.delete<RESTResponseObject>(this.adminURL + username + '/customers/' + customer_id, { observe: 'response', responseType: 'json' });
    }

    /**
    * Authorize admin to execute function with function_id
    * @param username Username of admin which should be authorized
    * @param role_id id of role the admin should be authorized to
    */
    deauthorizeAdmin(username, role_id: string): Observable<RESTResponseObject> {
        return this.http.delete<RESTResponseObject>(this.adminURL + username + '/roles/' + role_id + '/' + this.authService.getCustomerId(), { observe: 'response', responseType: 'json' });
    }

    isEndooAdmin(): boolean {
        return this.authService.getUsername() === 'admin@endoo';
    }
}
