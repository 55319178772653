import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum NOT_SAVED_RESULT {
    SAVE = 1,
    DISPOSE = 2,
    CANCEL = 3
}

@Component({
    templateUrl: './not-saved-dialog.component.html',
})
export class NotSavedDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<NotSavedDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    async save(): Promise<void> {
        this.dialogRef.close(NOT_SAVED_RESULT.SAVE);
    }

    async dispose(): Promise<void> {
        this.dialogRef.close(NOT_SAVED_RESULT.DISPOSE);
    }

    async cancel(): Promise<void> {
        this.dialogRef.close(NOT_SAVED_RESULT.CANCEL);
    }
}
