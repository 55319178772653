import { PlotlyGraph } from './plotly-graph';

export class PlotlyGraphScatter extends PlotlyGraph {

    /**
     * Hiermit wird ein ScatterGraph erzeugt.
     * @param params Verschiedene Parameter um den Graphen anzupassen.
     */
    constructor(params: { title: string, traceNames: string[] }) {
        super();

        this.layout = {
            title: params.title,
            autosize: true,
            showlegend: true,
            xaxis: {
                title: '',
                showgrid: false,
                zeroline: false,
            },
            yaxis: {
                title: '',
                tickformat: 'd',
                range: [0, 100],
                //autorange: true,
            },
        };
        this.plotData = params.traceNames.map(v => {
            return {
                name: v,
                type: 'scatter'
            };
        });
    }
}
