<h1 mat-dialog-title>Gruppe ändern</h1>
<div mat-dialog-content>
	<form [formGroup]="form" (ngSubmit)="saveChangeGroupBtClick()">
		<div class="fullwidth_form">
			<mat-form-field appearance="outline">
				<mat-label>Gruppenname</mat-label>
				<input matInput type="text" formControlName="name" [(ngModel)]="group.groupname"
					[disabled]="group.not_editable" required />
				<mat-error *ngIf="hasError('name', 'required')">Gruppenname erforderlich</mat-error>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Beschreibung</mat-label>
				<input matInput type="text" formControlName="description" [(ngModel)]="group.description"
					[disabled]="group.not_editable" required />
				<mat-error *ngIf="hasError('description', 'required')">Beschreibung erforderlich</mat-error>
			</mat-form-field>
		</div>
		<div>
			<button mat-raised-button type="submit" color="primary" title="Speichern"
				[disabled]="group.not_editable">Speichern</button>
		</div>
	</form>
	<mat-accordion>
		<mat-expansion-panel style="margin-top:10px; margin-bottom:10px;">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<mat-icon class="mat-icon-left"></mat-icon>
					<span>Mitglieder</span>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-form-field>
				<mat-label>Filter</mat-label>
				<input matInput (keyup)="applyFilter($event)" placeholder="Tabelle filtern">
			</mat-form-field>
			<mat-table [dataSource]="members" class="table" id="UserList_table" matSort>
				<ng-container matColumnDef="username">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Benutzername </mat-header-cell>
					<mat-cell *matCellDef="let member"> {{member.username}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="givenname">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Vorname </mat-header-cell>
					<mat-cell *matCellDef="let member"> {{member.givenname}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="surname">
					<mat-header-cell *matHeaderCellDef mat-sort-header> Nachname </mat-header-cell>
					<mat-cell *matCellDef="let member"> {{member.surname}} </mat-cell>
				</ng-container>
				<ng-container matColumnDef="wlan">
					<mat-header-cell *matHeaderCellDef mat-sort-header> WLAN </mat-header-cell>
					<mat-cell *matCellDef="let member">
						<mat-checkbox (change)="wlanChanged(member)" [(ngModel)]="member.wlan"></mat-checkbox>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="Add_Delete">
					<mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
					<mat-cell *matCellDef="let member">
						<button mat-raised-button title="Entfernen" *ngIf="!group.not_editable" (click)="removeUser(member.username)">
							Entfernen</button>
					</mat-cell>
				</ng-container>
				<ng-container matColumnDef="Delete_User">
					<mat-header-cell *matHeaderCellDef mat-sort-header> </mat-header-cell>
					<mat-cell *matCellDef="let member">
						<button mat-raised-button color="warn" title="Löschen" *ngIf="!group.not_editable"
							(click)="deleteUser(member.username)">Löschen</button>
					</mat-cell>
				</ng-container>
				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
				<mat-row *matRowDef="let member; columns: displayedColumns;"></mat-row>
			</mat-table>
			<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" [showFirstLastButtons]="true">
			</mat-paginator>
			<mat-form-field *ngIf="!group.not_editable" appearance="outline" style="padding-top: 30px;">
				<mat-label>Benutzer</mat-label>
				<input matInput #autocompleteInput type="text" [(ngModel)]="addUserSearchstring"
					(change)="changeUserSeachstring()" id="adduser_filter_tx" name="Add User" [matAutocomplete]="auto">
				<mat-autocomplete #auto="matAutocomplete">
					<mat-option *ngFor="let entry of found_users" [value]="entry.value">
						{{ entry.label }}
					</mat-option>
				</mat-autocomplete>
			</mat-form-field>
			<button mat-raised-button *ngIf="!group.not_editable" (click)="searchUserBtClick()"
				class="mr-5">Suchen</button>
			<button mat-raised-button (click)="addUser()" color="primary"
				*ngIf="!group.not_editable">Hinzufügen</button>
			<a href="#" (click)="downloadCSV($event)">
				<h3 id="link">Mitglieder als CSV-Datei herunterladen</h3>
			</a>
			<div class="button-row">
				<button mat-raised-button color="primary" (click)="allowGroupMembersWLAN()" class="mr-5">Mitgliedern
					WLAN-Zugang
					erlauben</button>
				<button mat-raised-button color="warn" (click)="disallowGroupMembersWLAN()">Mitgliedern WLAN-Zugang
					entziehen</button>
			</div>

		</mat-expansion-panel>
	</mat-accordion>
</div>
<div mat-dialog-actions>
	<button mat-raised-button title="Abbrechen" (click)="closeGroupDetailBtClick()">Schlie&szlig;en</button>
	<button mat-raised-button *ngIf="authService.hasPermission('BYOD-ADMINISTRATOR')" color="warn"
		title="Gruppe loeschen" [disabled]="group.not_editable" (click)="deleteChangeGroupBtClick()">Gruppe
		l&ouml;schen</button>
</div>
