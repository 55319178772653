import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiSettingsService } from '../../APService/WifiSettings.service'
import { WifiNetwork } from '../../../data/WifiNetwork';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'WifiGlobalBasicSettings',
    templateUrl: './apGlobalBasicSettings.component.html',
})
export class apGlobalBasicSettingsComponent implements OnInit {
    showKey: boolean = false;
    background_working: boolean = false;
    @Input('wifiSettings') wifiSettings: WifiNetwork;
    oldWifiKey: string;
    form: FormGroup;

    constructor(private wifiSettingsService: WifiSettingsService, private snackBar: MatSnackBar, private fb: FormBuilder) {
        this.form = this.fb.group({
            encryption: [],
            encryption_key: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(63)]],
            client2client: [],
        });
    }

    ngOnInit() {
        this.oldWifiKey = this.wifiSettings.encryption_key;
    }

    newWifiKeyCancel(): void {
        this.wifiSettings.encryption_key = this.oldWifiKey;
    }

    newWifiKey(): void {
        const newkey = this.randomKey(20);
        this.wifiSettings.encryption_key = newkey;
    }

    async saveWifiSettings(): Promise<void> {
        if (this.form.invalid) {
            return;
        }
        this.background_working = true;
        try {
            await this.wifiSettingsService.saveEndooNetwork(this.wifiSettings).toPromise();
            this.snackBar.open('Das Netzwerk wurde erfolgreich gespeichert', 'OK');
        } catch (err) {
            this.snackBar.open('Das Netzwerk konnte nicht gespeichert werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    private randomKey(length: number): string {
        let text = '';
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_!?-_';

        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return text;
    }

    /**
     * Function for validating the input of the user for changing a property of a user object (only ascii chars allowed )
     */
    validateWifiKeyInput(event: any): void {
        const pattern = /^[\x00-\x7F]*$/; // ASCII-Code 0-127
        const inputChar = String.fromCharCode(event.charCode);

        if (!pattern.test(inputChar)) {
            // invalid character, prevent input
            event.preventDefault();
        }
    }
}
