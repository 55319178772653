import { Component, ViewChild, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ShortTermLoginService } from '../ShortTermService/ShortTermLoginService';
import { ShortTermLoginDetailDialogComponent } from './ShortTermLoginDetailDialog.component';
import * as moment from 'moment';
import { Login } from 'app/data/Login';
import { AppService } from 'app/Services/app.service';

const initialSelection = [];
const allowMultiSelect = true;

@Component({
    templateUrl: './ShortTermLoginList.component.html',
})
export class ShortTermLoginListComponent implements OnInit {
    displayedColumns = ['username', 'owner', 'expiration', 'wlan'];
    // implement table selection
    selection = new SelectionModel<Login>(allowMultiSelect, initialSelection);
    logins: MatTableDataSource<Login>; // use mattabledatasource for easy sorting
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private shortTermLoginService: ShortTermLoginService,
        private dialog: MatDialog,
        public appService: AppService
    ) { }

    ngOnInit() {
        this.logins = new MatTableDataSource();
        this.logins.sort = this.sort;
        this.logins.paginator = this.paginator;
        this.selection = new SelectionModel<Login>(allowMultiSelect, initialSelection);
        this.getAllLogins();
    }

    async changeWLAN(login: Login): Promise<void> {
        await this.shortTermLoginService.changeWLAN(login.username, login.wlan).toPromise();
        this.getAllLogins();
    }

    async createLogin(): Promise<void> {
        const login: Login = await this.shortTermLoginService.create(true).toPromise();
        this.logins.data.push(login);
        let dialogRef = this.dialog.open(ShortTermLoginDetailDialogComponent, {
            data: { login: login }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getAllLogins();
        });
    }

    /**
     * Function to receive all groups from server and store it in the MatTableDataSource element
    */
    async getAllLogins(): Promise<void> {
        this.logins.data = await this.shortTermLoginService.getAll().toPromise();
        this.logins.sort = this.sort;
        this.logins.paginator = this.paginator;
        this.logins.data.map(value => {
            // Convert standard format to german format for display
            value.expires_date = moment(value.expires_date, 'DD-MM-YYYY').format('DD.MM.YYYY');
            value.expiration_iso = moment(value.expires_date + "T"+ value.expires_time, "DD.MM.YYYYTHH:mm").toISOString(true);;
            return value;
        });
        this.preparePaginator();
    }

    async selectLogin(login: Login): Promise<void> {
        let dialogRef = this.dialog.open(ShortTermLoginDetailDialogComponent, {
            data: { login: login }
        });
        dialogRef.afterClosed().subscribe(result => {
            this.getAllLogins();
        });
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('endoocontrol.shortTermLoginList.pageSize')) {
            this.logins.paginator.pageSize = Number(localStorage.getItem('endoocontrol.shortTermLoginList.pageSize'));
            this.logins.paginator = this.paginator; // Update the paginator
        }
        this.logins.paginator._changePageSize = pageSize => {
            localStorage.setItem('endoocontrol.shortTermLoginList.pageSize', String(pageSize));
            this.logins.paginator.pageSize = pageSize;
            this.logins.paginator = this.paginator; // Update the paginator
        };
    }
}
