import { Pipe, PipeTransform } from '@angular/core';
import { DBDevice } from 'app/data/endooSpotDB_objects';

@Pipe({
    name: 'devicesList',
    pure: false,
})
export class DevicesListPipe implements PipeTransform {

    transform(value: DBDevice[]): string {
        return value.map(device => device.inventoryNumber.toUpperCase()).join(', ');
    }
}
