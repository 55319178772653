export class CaptivePortal {

    constructor(
        public id_number: number,
        public enabled: boolean,
        public max_client_up_speed: string,
        public max_client_down_speed: string,
        public endoo_auth: 'endoo' | 'custom' | 'guest' | string,
        public radius_ip: string,
        public radius_port: string,
        public radius_secret: string
    ) { }
}
