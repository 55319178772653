import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: 'message-dialog.component.html',
})
export class MessageDialogComponent {
    title: string = 'Test';
    message: string = 'Dies ist ein Test!';

    constructor(
        private dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.title = data.title;
        this.message = data.message;
    }

    okButtonClicked(): void {
        this.dialogRef.close();
    }
}
