import { Component, HostListener } from '@angular/core';
import { onSideNavChange, animateText } from '../../animations/navigation-menu';
import { NavigationMenuService } from 'app/Services/navigation-menu.service';
import { AuthenticationService } from 'app/Login/authentication.service';
import { environment } from 'environments/environment';
import { AppService } from 'app/Services/app.service';

@Component({
    selector: 'navigation-menu',
    templateUrl: './navigation-menu.component.html',
    styleUrls: ['./navigation-menu.component.css'],
    animations: [onSideNavChange, animateText],
})
export class NavigationMenuComponent {
    public sideNavState: boolean = false;
    public linkText: boolean = false;
    public appTitle: string = environment.appTitle;

    constructor(
        private _sidenavService: NavigationMenuService,
        public authenticationService: AuthenticationService,
        public appService: AppService
    ) { }

    toggleSidenav(): void {
        setTimeout(() => {
            this.linkText = this.sideNavState;
        }, 200);
        this._sidenavService.sideNavState$.next(this.sideNavState);
    }

    @HostListener('click') click(event: Event): void {
        this.sideNavState = !this.sideNavState;
        this.toggleSidenav();
    }

    @HostListener('mouseenter') mouseover(event: Event): void {
        if (this.appService.isTouchScreen()) {
            return;
        }
        this.sideNavState = !this.sideNavState;
        this.toggleSidenav();
    }

    @HostListener('mouseleave') mouseleave(event: Event): void {
        this.sideNavState = false;
        this.toggleSidenav();
    }
}
