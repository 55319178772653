<div class="container">
    <div class="header"></div>
    <div *ngIf="loadingCustomers" class="loginCard">
        <h2>Lade Schulliste...</h2>
    </div>
    <div *ngIf="!loadingCustomers && customers.length === 0" class="loginCard">
        <h2>Diesem Benutzer wurden bisher keine Schulen zugewiesen.</h2>
        <button mat-raised-button (click)="authenticationService.logout()">
            Logout
        </button>
    </div>
    <mat-card *ngIf="!loadingCustomers && customers.length > 1" class="loginCard">
        <form name="form" #f="ngForm" novalidate class="school-select-form">
            <h2 class="loginCardTitle">Wählen Sie eine Schule aus</h2>
            <mat-form-field appearance="outline">
                <mat-label>Schule</mat-label>
                <input type="text" matInput [matAutocomplete]="auto" [formControl]="schoolSelection" autofocus>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="customerSelected($event)">
                    <mat-option *ngFor="let customer of filteredCustomers | async" [value]="customer.id">
                        {{customer.name}} ({{customer.id}})
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <button mat-raised-button color="primary"
                [disabled]="buttonLoading || selectedCustomer === undefined || selectedCustomer === ''"
                (click)="selectCustomer()">
                Auswählen
          </button>
        </form>
    </mat-card>
    <div class="footer"></div>
</div>