import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DBDevice } from '../../../data/endooSpotDB_objects';
import { WifiCard1 } from '../../../data/WifiCard1';
import { ApDeviceService } from '../../APService/apDevice.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from 'app/Login/authentication.service';
import { ApDetailService } from '../apDetail.service';
import { IAPDetailComponent } from '../ApDetailComponent.interface';

@Component({
    selector: 'wifiCard1-component',
    templateUrl: './wifiCard1.component.html',
})
export class WifiCard1Component implements OnInit, IAPDetailComponent {
    @Input() devices: DBDevice[] = [];
    wifiCard: WifiCard1;
    background_working: boolean; // variable which is true, when app is waiting for information from server-side
    @Output() onChange = new EventEmitter();

    constructor(
        public authenticationService: AuthenticationService,
        public apService: ApDeviceService,
        private snackBar: MatSnackBar,
        private apDetailService: ApDetailService
    ) {
        this.wifiCard = new WifiCard1(true, 'auto', 'HT20', '20');
    }

    ngOnInit() {
        this.getWifiCardSettings();
    }

    async getWifiCardSettings(): Promise<void> {
        if (this.devices.length === 1) {
            this.wifiCard = this.apService.getWifiCard1Settings(this.devices[0]);
        }
    }

    async saveWifiCardSettings(): Promise<void> {
        this.background_working = true;

        try {
            if (await this.apDetailService.confirmChange(this.devices)) {
                this.devices.forEach(async device => {
                    await this.apService.saveWifiCard1(this.wifiCard, device.inventoryNumber).toPromise();
                });
                this.onChange.emit({ saved: true });
                this.snackBar.open('Die Einstellungen wurden erfolgreich gespeichert', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Die Einstellungen konnten nicht gespeichert werden: ' + err, 'OK');
        }

        this.background_working = false;
    }

    saved(saved: boolean): void {
        this.onChange.emit({ saved });
    }

    async save(): Promise<void> {
        await this.saveWifiCardSettings();
    }
}
