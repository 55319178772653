import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { endooSpotManagementAppModule } from './endooSpotApplication/endooSpotManagementApp.module';
import { CanActivateAuthGuard } from './Login/canActivateAuthguard';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { endooSpotManagementRegisterCustomerModule } from './RegisterCustomer/endooSpotManagementRegisterCustomer.module';
import { ModelService } from './APManagement/APService/model.service';
import { AuthenticationService } from './Login/authentication.service';
import { RaygunErrorHandler } from './app.raygun.setup';
import * as rg4js from 'raygun4js';

export function initializeApp(authService: AuthenticationService, modelService: ModelService) {
    rg4js('setUser', {
        identifier: authService.getUsername(),
        isAnonymous: !authService.isLoggedIn(),
    });
    return (): Promise<any> => {
        if (authService.isLoggedIn()) {
            return modelService.init();
        } else {
            return Promise.resolve();
        }
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatMenuModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatCardModule,
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        endooSpotManagementAppModule,
        endooSpotManagementRegisterCustomerModule,
        AppRoutingModule,
        RouterModule,
    ],
    providers: [
        CanActivateAuthGuard,
        //RaygunErrorHandler, // Siehe "T-0374 RayGun aus endooControl und endooTeacher entfernen (vorrübergehend)"
        { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AuthenticationService, ModelService], multi: true },
        //{ provide: ErrorHandler, useClass: RaygunErrorHandler }, // Siehe "T-0374 RayGun aus endooControl und endooTeacher entfernen (vorrübergehend)"
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
