import { Injectable, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { tokenResponse } from './authService.responses';
import { DBCustomer, DBRole } from '../data/endooSpotDB_objects';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from './login.component';
import { ChooseCustomerComponent } from './chooseCustomer.component';

export type CustomerNameAndId = { name: string, id: string };

@Injectable()
export class AuthenticationService {
    private readonly authUrl = '/auth/';
    private readonly meUrl = this.authUrl + 'me/';
    private headers: HttpHeaders;
    sessionInvalid: EventEmitter<boolean> = new EventEmitter();
    sessionCustomerEmitter: EventEmitter<{ customerId: string, newlyRegistered: boolean }> = new EventEmitter();
    userIsLoggedIn: EventEmitter<{ successful: boolean, newlyRegistered: boolean }> = new EventEmitter();
    loginDialogIsOpen: boolean = false; // To show only one instance of the login dialog and not more

    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private dialog: MatDialog) {
        this.headers = new HttpHeaders({ // create new header when a new token is emitted.
            'Content-Type': 'application/json',
        });
    }

    public reAuthenticate(): void {
        if (this.loginDialogIsOpen) {
            return;
        }

        this.eraseLoginData();
        this.loginDialogIsOpen = true;
        const logindialog = this.dialog.open(LoginComponent, { disableClose: true });
        logindialog.componentInstance.showRegisterLink = false;
        this.userIsLoggedIn.subscribe(loggedIn => {
            if (loggedIn) {
                logindialog.close();
            }
        });
        logindialog.afterClosed().subscribe(() => {
            const chooseCustomerDialog = this.dialog.open(ChooseCustomerComponent, { width:"90vh", maxWidth:"700px", disableClose: true });
            chooseCustomerDialog.afterClosed().subscribe(() => {
                this.loginDialogIsOpen = false;
                location.reload(); // Reload page
            });
            this.sessionCustomerEmitter.subscribe(selectedCustomer => {
                chooseCustomerDialog.close();
            });
        });
    }

    private getLoginHeaders(): HttpHeaders {
        return this.headers.append('Authorization', 'Bearer ' + this.getLoginToken());
    }

    getCustomerSpecificHeaders(): HttpHeaders {
        return this.headers.append('Authorization', 'Bearer ' + this.getCustomerSpecificToken());
    }

    login(username: string, password: string, newlyRegistered: boolean = false): Observable<boolean> {
        return this.http
            .post<tokenResponse>(
                this.authUrl +
                'login',
                JSON.stringify({ username: username, password: password }),
                { headers: this.headers }
            )
            .pipe(map((response: tokenResponse) => {
                // login successful if there's a jwt token in the response
                const token = response.token;
                // store username and jwt token in local storage to keep user logged in between page refreshes
                this.setLoginToken(username, token);
                localStorage.setItem('lastUsernameLoggedIn', username);
                // let all objects know that login was successful
                this.userIsLoggedIn.emit({ successful: true, newlyRegistered: newlyRegistered });
                return true;
            }), catchError(error => {
                this.userIsLoggedIn.emit({ successful: false, newlyRegistered: newlyRegistered });
                return of(false);
            }));
    }

    /**
     * Receives the customers where the actual admin has administrative privileges
     */
    getAdminCustomers(): Observable<CustomerNameAndId[]> {
        return this.http
            .get<DBCustomer[]>(
                this.meUrl + 'customers',
                { headers: this.getLoginHeaders() }
            )
            .pipe(map((response: DBCustomer[]) => {
                // store admin ids in local storage
                const adminCustomerNamesAndIds: CustomerNameAndId[] = response.map(
                    customer => {
                        return { name: customer.name, id: customer.customerId };
                    }
                );
                localStorage.setItem(
                    'adminCustomersNameAndId',
                    JSON.stringify(adminCustomerNamesAndIds)
                ); // store customerID as long as session-token is valid
                if (adminCustomerNamesAndIds.length === 1) {
                    // if user is admin only of one customer choose this customer
                    this.setCustomerId(response[0].customerId);
                }
                return adminCustomerNamesAndIds;
            }, catchError(error =>
                throwError(error.error || 'Server error')
            )));
    }

    /**
     * Receives the permissions of the logged in user for the chosen customer in the actual session
     */
    private getCustomerToken(customerId: string): Observable<void> {
        return this.http
            .get<tokenResponse>(
                this.meUrl +
                'customerToken/'
                + customerId,
                { headers: this.getLoginHeaders() }
            )
            .pipe(map((response: tokenResponse) => {
                this.setCustomerSpecificToken(response.token);
            }), catchError(error =>
                throwError(error.error || 'Server error')
            ));
    }

    /**
     * Receives the permissions of the logged in user for the chosen customer in the actual session
     */
    private getPermissions(): Observable<void> {
        return this.http
            .get<DBRole[]>(
                this.meUrl +
                'customers/' +
                this.getCustomerId() +
                '/roles',
                { headers: this.getLoginHeaders() } // TODO bei Bedarf auf CustomerSpecificHeaders umstellen
            )
            .pipe(map((response: DBRole[]) => {
                // login successful if there's a jwt token in the response
                let permissions = [];
                if (response) {
                    permissions = response.map(role => role.id);
                }
                localStorage.setItem(
                    'permissions',
                    JSON.stringify({ permissions: permissions })
                );
            }), catchError(error =>
                throwError(error.error || 'Server error')
            ));
    }

    /**
     * Function to return token of the actual session
     */
    private getLoginToken(): string {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser !== null ? currentUser.token : '';
    }

    /**
     * Function to set username/token combination.
     */
    private setLoginToken(username: string, token: string): void {
        localStorage.setItem(
            'currentUser',
            JSON.stringify({
                username: username,
                token: token,
            })
        );
    }

    /**
     * Function to return token of the actual session
     */
    private getCustomerSpecificToken(): string {
        const currentCustomer = JSON.parse(localStorage.getItem('currentCustomer'));
        return currentCustomer !== null ? currentCustomer.token : '';
    }

    /**
     * Function to set customer token.
     */
    private setCustomerSpecificToken(token: string): void {
        localStorage.setItem(
            'currentCustomer',
            JSON.stringify({
                token: token,
            })
        );
    }

    /**
     * Function to return the actual session's username
     */
    getUsername(): string {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        return currentUser ? currentUser.username : '';
    }

    /**
     * Function to return the actual session's customerId
     */
    getCustomerId(): string {
        const storageObject = JSON.parse(localStorage.getItem('customerID'));
        return storageObject ? storageObject.customerId : '';
    }

    /**
     * Function to return the previously logged in customer.
     */
    getLastUsernameLoggedIn(): string {
        // Name changed of storage item. To not break the username feature save its value to the new named storage item then remove the old one.
        if (localStorage.getItem('lastCustomerLoggedIn')) {
            localStorage.setItem('lastUsernameLoggedIn', localStorage.getItem('lastCustomerLoggedIn'));
            localStorage.removeItem('lastCustomerLoggedIn');
        }
        const username = localStorage.getItem('lastUsernameLoggedIn');
        return username ? username : '';
    }

    /**
     * Sets the customer to work in the actual session
     * @param customerID
     */
    async setCustomerId(customerId: string, newlyRegistered: boolean = false): Promise<void> {
        localStorage.setItem(
            'customerID',
            JSON.stringify({ customerId: customerId })
        );
        await this.getCustomerToken(customerId).toPromise();
        await this.getPermissions().toPromise();
        this.sessionCustomerEmitter.emit({ customerId, newlyRegistered });
    }

    /**
     * Function to return the customerIds assigned to the current admin
     */
    getAdminCustomersNameAndId(): CustomerNameAndId[] {
        const adminCustomers = JSON.parse(
            localStorage.getItem('adminCustomersNameAndId')
        );
        return adminCustomers ? adminCustomers : [];
    }

    /**
     * Function which returns a boolean value that shows if user is logged in
     */
    isLoggedIn(): boolean {
        const jwtHelper = new JwtHelperService();
        return !jwtHelper.isTokenExpired(this.getLoginToken());
    }

    /**
     * Function which returns information if current user has permission for function of function_id
     * @param function_id ID of the function which permission should be there
     */
    hasPermission(role_id: string): boolean {
        const storageObject = JSON.parse(localStorage.getItem('permissions'));
        if (storageObject === null) {
            return false;
        } else {
            const permissionsObject: string[] = storageObject.permissions;
            return permissionsObject.indexOf(role_id) !== -1;
        }
    }

    isEndooAdmin(): boolean {
        return this.hasPermission('ENDOO-ADMINISTRATOR');
    }

    eraseLoginData() {
        // clear token remove user from local storage and from all connected classes to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentCustomer');
        localStorage.removeItem('customerID');
        localStorage.removeItem('adminCustomersNameAndId');
        localStorage.removeItem('permissions');
    }

    /**
     * Logout the user of current session and clear all session dependant information
     */
    logout(): void {
        this.eraseLoginData();
        this.router.navigate(['login']);
    }
}
