import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EndooHttpService } from './endooHttp.service';
import { AppService } from './app.service';
import { LoadingService } from './loading.service';

@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        HttpClientModule,
    ],
    providers: [
        EndooHttpService,
        AppService,
        LoadingService,
    ],
})
export class endooSpotServicesModule { }
