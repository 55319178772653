import { Injectable } from '@angular/core';
import { CustomerFunctionPropertyService } from '../../endooSpotApplication/Services/CustomerFunctionPropertyService';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeAll } from 'rxjs/operators';
import { DBProperty } from '../../data/endooSpotDB_objects';

@Injectable()
export class RouterGlobalService {

    constructor(private cfpService: CustomerFunctionPropertyService) { }

    getGlobalRouterSettings(): Observable<{ default_gateway: boolean } | null> {
        return this.cfpService.getCustomerFunctionProperties('FWAN_SETTINGS').pipe(map(properties => {
            if (properties.length > 0) {
                const default_gateway = properties.find(cfp => cfp.property === DBProperty.fwan_settings_default_gateway).value === 'true';
                return { default_gateway };
            }
            return null;
        }));
    }

    saveGlobalRouterSettings(default_gateway: boolean): Observable<void> {
        const setRequests: Observable<void>[] = [];
        setRequests[0] = this.cfpService.setCustomerFunctionProperty('FWAN_SETTINGS', DBProperty.fwan_settings_default_gateway, default_gateway.toString());
        return forkJoin(setRequests).pipe(mergeAll());
    }
}
