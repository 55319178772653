<h1 mat-dialog-title>endooConnect Einstellungen hinzufügen</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper linear=true #stepper>
        <!-- MatStep 1: Allgemeine Netzwerkeinstellungen -->
        <mat-step [stepControl]="form.get('page1')">
            <ng-template matStepLabel>Allgemeine Netzwerkeinstellungen</ng-template>
            <ng-container *ngTemplateOutlet="page1"></ng-container>
            <mat-divider></mat-divider>
            <button mat-button (click)="onStepperNext()">Weiter</button>
        </mat-step>
        <!-- MatStep 2: Weitergehende Netzwerkeinstellungen -->
        <mat-step [stepControl]="form.get('page2')">
            <ng-template matStepLabel>Weitergehende Netzwerkeinstellungen</ng-template>
            <ng-container *ngTemplateOutlet="page2"></ng-container>
            <mat-divider></mat-divider>
            <button class="button-margin-right" mat-button matStepperPrevious>Zurück</button>
            <button mat-button (click)="onStepperNext()">Weiter</button>
        </mat-step>
        <!-- MatStep 3: Firewall-Einstellungen -->
        <mat-step [stepControl]="form.get('page3')">
            <ng-template matStepLabel>Firewall-Einstellungen</ng-template>
            <ng-container *ngTemplateOutlet="page3"></ng-container>
            <mat-divider></mat-divider>
            <button class="button-margin-right" mat-button matStepperPrevious>Zurück</button>
            <button mat-button (click)="onStepperNext()">Weiter</button>
        </mat-step>
        <!-- MatStep 4: Eingaben überprüfen -->
        <mat-step>
            <ng-template matStepLabel>Eingaben überprüfen</ng-template>
            <ng-container *ngTemplateOutlet="page4"></ng-container>
            <mat-divider></mat-divider>
            <button class="button-margin-right" mat-button matStepperPrevious>Zurück</button>
            <button mat-button (click)="createNewRouterNetworkBtClick()">
                Netzwerk anlegen
            </button>
        </mat-step>
    </mat-horizontal-stepper>
</div>

<!-- Template: Allgemeine Netzwerkeinstellungen -->
<ng-template #page1>
    <form [formGroup]="form.get('page1')">
        <mat-list>
            <!-- Select: Netzwerktyp -->
            <mat-list-item>
                <mat-form-field appearance="outline">
                    <mat-label>Netzwerktyp</mat-label>
                    <mat-select formControlName="network_type">
                        <mat-option value="wan">Uplink-Netzwerk</mat-option>
                        <mat-option value="management">Management-Netzwerk</mat-option>
                        <mat-option value="byod">BYOD-Netzwerk</mat-option>
                        <mat-option value="media">Medien-Netzwerk</mat-option>
                        <mat-option value="paed">Pädagogisches-Netzwerk</mat-option>
                        <mat-option value="custom">Benutzerdefiniertes Netzwerk</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('page1').get('network_type').hasError('required')">
                        Dieses Feld darf nicht leer sein
                    </mat-error>
                </mat-form-field>
            </mat-list-item>
            <!-- Select: IP-Konfiguration -->
            <mat-list-item *ngIf="form.get('page1').get('network_type').value">
                <mat-form-field appearance="outline">
                    <mat-label>IP-Konfiguration</mat-label>
                    <mat-select formControlName="network_ip_type">
                        <mat-option value="dhcp">DHCP-Client</mat-option>
                        <mat-option value="static">Statisch</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('page1').get('network_ip_type').hasError('required')">
                        Dieses Feld darf nicht leer sein
                    </mat-error>
                </mat-form-field>
            </mat-list-item>
            <!-- Textfeld: Router IP-Adresse -->
            <mat-list-item *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <mat-form-field appearance="outline">
                    <mat-label>Router IP-Adresse</mat-label>
                    <input matInput formControlName="interface_ip" />
                    <mat-error *ngIf="form.get('page1').get('interface_ip').hasError('required')">
                        Dieses Feld darf nicht leer sein
                    </mat-error>
                    <mat-error
                        *ngIf="form.get('page1').get('interface_ip').hasError('invalid_ip') && !form.get('page1').get('interface_ip').hasError('required')">
                        Ungültige IP-Adresse
                    </mat-error>
                    <mat-error
                        *ngIf="form.get('page1').get('interface_ip').hasError('routerIpAndGatewayIpAreSame') && !form.get('page1').get('interface_ip').hasError('invalid_ip') && !form.get('page1').get('interface_ip').hasError('required')">
                        Router-IP Adresse und Gateway IP-Adresse müssen unterschiedlich sein
                    </mat-error>
                    <mat-error
                        *ngIf="!form.get('page1').get('interface_ip').hasError('invalid_ip') && !form.get('page1').get('interface_ip').hasError('required')">
                        <mat-error *ngIf="form.get('page1').get('interface_ip').hasError('notInSameSubnet')">
                            Router IP-Adresse und Gateway IP-Adresse sind nicht im selben Subnetz
                        </mat-error>
                        <mat-error *ngIf="form.get('page1').get('interface_ip').hasError('ipIsNetworkAddress')">
                            Router IP-Adresse entspricht der Netzwerkadresse
                        </mat-error>
                        <mat-error *ngIf="form.get('page1').get('interface_ip').hasError('ipIsBroadcastAddress')">
                            Router IP-Adresse entspricht der Broadcastadresse
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </mat-list-item>
            <!-- Textfeld: Router IP-Subnetz -->
            <mat-list-item *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <mat-form-field appearance="outline">
                    <mat-label>Router IP-Subnetz</mat-label>
                    <input matInput formControlName="network_subnet" />
                    <mat-hint>Zum Beispiel: 255.255.255.0</mat-hint>
                    <mat-error
                        *ngIf="form.get('page1').get('network_subnet').hasError('invalid_subnet') && !form.get('page1').get('network_subnet').hasError('required')">
                        Ungültige Subnetzmaske
                    </mat-error>
                </mat-form-field>
            </mat-list-item>
            <ng-container *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <!-- Toggle: endooConnect ist das Gateway im Netzwerk -->
                <mat-list-item *ngIf="form.get('page1').get('network_type').value !== 'wan'">
                    <mat-slide-toggle formControlName="gateway">
                        endooConnect ist das Gateway im Netzwerk
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Textfeld: Gateway IP-Adresse -->
                <mat-list-item *ngIf="!form.get('page1').get('gateway').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Gateway IP-Adresse</mat-label>
                        <input matInput formControlName="gateway_ip" />
                        <mat-error *ngIf="form.get('page1').get('gateway_ip').hasError('required')">
                            Dieses Feld darf nicht leer sein
                        </mat-error>
                        <mat-error
                            *ngIf="form.get('page1').get('gateway_ip').hasError('invalid_ip') && !form.get('page1').get('gateway_ip').hasError('required')">
                            Ungültige IP-Adresse
                        </mat-error>
                        <mat-error
                            *ngIf="form.get('page1').get('gateway_ip').hasError('routerIpAndGatewayIpAreSame') && !form.get('page1').get('gateway_ip').hasError('invalid_ip') && !form.get('page1').get('gateway_ip').hasError('required')">
                            Gateway IP-Adresse und Router-IP Adresse müssen unterschiedlich sein
                        </mat-error>
                        <mat-error
                            *ngIf="!form.get('page1').get('gateway_ip').hasError('invalid_ip') && !form.get('page1').get('gateway_ip').hasError('required')">
                            <mat-error *ngIf="form.get('page1').get('gateway_ip').hasError('notInSameSubnet')">
                                Gateway IP-Adresse und Router IP-Adresse sind nicht im selben Subnetz
                            </mat-error>
                            <mat-error *ngIf="form.get('page1').get('gateway_ip').hasError('ipIsNetworkAddress')">
                                Gateway IP-Adresse entspricht der Netzwerkadresse
                            </mat-error>
                            <mat-error *ngIf="form.get('page1').get('gateway_ip').hasError('ipIsBroadcastAddress')">
                                Gateway IP-Adresse entspricht der Broadcastadresse
                            </mat-error>
                        </mat-error>
                    </mat-form-field>
                </mat-list-item>
            </ng-container>
            <!-- Toggle: Bevorzugtes WAN-Netzwerk / Uplink Netzwerk -->
            <mat-list-item *ngIf="form.get('page1').get('network_type').value === 'wan'">
                <mat-slide-toggle formControlName="preferred_uplink_network">
                    Bevorzugtes WAN-Netzwerk / Uplink Netzwerk
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list>
    </form>
</ng-template>

<!-- Template: Weitergehende Netzwerkeinstellungen -->
<ng-template #page2>
    <form [formGroup]="form.get('page2')">
        <!-- Keine weiteren Einstellungen möglich -->
        <mat-list
            *ngIf="(form.get('page1').get('network_ip_type').value === 'dhcp' && !form.get('page1').get('gateway').value) || form.get('page1').get('network_type').value === 'wan'">
            <mat-list-item>
                Keine weiteren Einstellungen möglich
            </mat-list-item>
        </mat-list>
        <mat-list
            *ngIf="(form.get('page1').get('network_ip_type').value === 'static' || form.get('page1').get('gateway').value) && form.get('page1').get('network_type').value !== 'wan'">
            <ng-container *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <!-- Toggle: DHCP-Server im Netzwerk aktivieren -->
                <mat-list-item>
                    <mat-slide-toggle formControlName="dhcp_server_enabled">
                        DHCP-Server im Netzwerk aktivieren
                    </mat-slide-toggle>
                </mat-list-item>
                <ng-container *ngIf="form.get('page2').get('dhcp_server_enabled').value">
                    <!-- Textfeld: DHCP-Server Start-Adresse -->
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>DHCP-Server Start-Adresse</mat-label>
                            <input matInput formControlName="dhcp_server_start_address" />
                            <mat-error *ngIf="form.get('page2').get('dhcp_server_start_address').hasError('required')">
                                Dieses Feld darf nicht leer sein
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('page2').get('dhcp_server_start_address').hasError('invalid_ip') && !form.get('page2').get('dhcp_server_start_address').hasError('required')">
                                Ungültige IP-Adresse
                            </mat-error>
                            <mat-error
                                *ngIf="!form.get('page2').get('dhcp_server_start_address').hasError('invalid_ip') && !form.get('page2').get('dhcp_server_start_address').hasError('required')">
                                <mat-error
                                    *ngIf="form.get('page2').get('dhcp_server_start_address').hasError('ipIsNetworkAddress')">
                                    DHCP-Server Start-Adresse entspricht der Netzwerkadresse
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('page2').get('dhcp_server_start_address').hasError('ipIsBroadcastAddress')">
                                    DHCP-Server Start-Adresse entspricht der Broadcastadresse
                                </mat-error>
                            </mat-error>
                            <mat-error
                                *ngIf="!form.get('page2').get('dhcp_server_start_address').hasError('invalid_ip') && !form.get('page2').get('dhcp_server_start_address').hasError('required') && !form.get('page2').get('dhcp_server_start_address').hasError('ipIsNetworkAddress') && !form.get('page2').get('dhcp_server_start_address').hasError('ipIsBroadcastAddress')">
                                <mat-error
                                    *ngIf="form.get('page2').get('dhcp_server_start_address').hasError('dhcpRangeInvalid')">
                                    DHCP-Server Start-Adresse befindet sich nicht im Subnetz
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </mat-list-item>
                    <!-- Textfeld: DHCP-Server End-Adresse -->
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>DHCP-Server End-Adresse</mat-label>
                            <input matInput formControlName="dhcp_server_end_address" />
                            <mat-error *ngIf="form.get('page2').get('dhcp_server_start_address').hasError('required')">
                                Dieses Feld darf nicht leer sein
                            </mat-error>
                            <mat-error
                                *ngIf="form.get('page2').get('dhcp_server_end_address').hasError('invalid_ip') && !form.get('page2').get('dhcp_server_end_address').hasError('required')">
                                Ungültige IP-Adresse
                            </mat-error>
                            <mat-error
                                *ngIf="!form.get('page2').get('dhcp_server_end_address').hasError('invalid_ip') && !form.get('page2').get('dhcp_server_end_address').hasError('required')">
                                <mat-error
                                    *ngIf="form.get('page2').get('dhcp_server_end_address').hasError('ipIsNetworkAddress')">
                                    DHCP-Server End-Adresse entspricht der Netzwerkadresse
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('page2').get('dhcp_server_end_address').hasError('ipIsBroadcastAddress')">
                                    DHCP-Server End-Adresse entspricht der Broadcastadresse
                                </mat-error>
                            </mat-error>
                            <mat-error
                                *ngIf="!form.get('page2').get('dhcp_server_end_address').hasError('invalid_ip') && !form.get('page2').get('dhcp_server_end_address').hasError('required') && !form.get('page2').get('dhcp_server_end_address').hasError('ipIsNetworkAddress') && !form.get('page2').get('dhcp_server_end_address').hasError('ipIsBroadcastAddress')">
                                <mat-error
                                    *ngIf="form.get('page2').get('dhcp_server_end_address').hasError('dhcpRangeInvalid')">
                                    DHCP-Server End-Adresse befindet sich nicht im Subnetz
                                </mat-error>
                            </mat-error>
                        </mat-form-field>
                    </mat-list-item>
                </ng-container>
            </ng-container>
            <ng-container
                *ngIf="form.get('page1').get('gateway').value && form.get('page1').get('network_type').value !== 'management'">
                <mat-divider></mat-divider>
                <div mat-subheader>Captive Portal</div>
                <!-- Toggle: Captive-Portal aktiviert -->
                <mat-list-item>
                    <mat-slide-toggle formControlName="captive_portal_enabled">
                        Captive-Portal aktiviert</mat-slide-toggle>
                </mat-list-item>
                <ng-container *ngIf="form.get('page2').get('captive_portal_enabled').value">
                    <!-- Textfeld: Upload-Rate (MBit/s) -->
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>Upload-Rate (MBit/s)</mat-label>
                            <input type="number" min="0" matInput
                                formControlName="captive_portal_max_client_up_speed" />
                            <mat-error
                                *ngIf="form.get('page2').get('captive_portal_max_client_up_speed').hasError('required')">
                                Dieses Feld darf nicht leer sein
                            </mat-error>
                        </mat-form-field>
                    </mat-list-item>
                    <!-- Textfeld: Download-Rate (MBit/s) -->
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>Download-Rate (MBit/s)</mat-label>
                            <input type="number" min="0" matInput
                                formControlName="captive_portal_max_client_down_speed" />
                            <mat-error
                                *ngIf="form.get('page2').get('captive_portal_max_client_down_speed').hasError('required')">
                                Dieses Feld darf nicht leer sein
                            </mat-error>
                        </mat-form-field>
                    </mat-list-item>
                    <div mat-subheader>Login-Verfahren</div>
                    <!-- Button Group: endoo Kennungen verwenden -->
                    <mat-list-item>
                        <mat-button-toggle-group formControlName="captive_portal_endoo_auth">
                            <mat-button-toggle [value]="'endoo-users'">
                                Endoo Kennungen
                            </mat-button-toggle>
                            <mat-button-toggle [value]="'custom'">
                                Eigene Kennungen
                            </mat-button-toggle>
                            <mat-button-toggle [value]="'endoo-guest'">
                                Ohne Kennungen (nur AGB)
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </mat-list-item>
                    <mat-list-item
                        *ngIf="form.get('page2').get('captive_portal_endoo_auth').value === 'endoo-users' || form.get('page2').get('captive_portal_endoo_auth').value === 'custom'">
                        <a href="https://login.endoo.eu" target="_blank" class="link">Beispiel für Anmeldemaske
                            anzeigen</a>
                    </mat-list-item>
                    <mat-list-item *ngIf="form.get('page2').get('captive_portal_endoo_auth').value === 'endoo-guest'">
                        <a href="https://login.endoo.eu/guest" target="_blank" class="link">Beispiel für Anmeldemaske
                            anzeigen</a>
                    </mat-list-item>
                    <ng-container *ngIf="form.get('page2').get('captive_portal_endoo_auth').value === 'custom'">
                        <!-- Textfeld: Radius-IP -->
                        <mat-list-item>
                            <mat-form-field appearance="outline">
                                <mat-label>Radius-IP</mat-label>
                                <input matInput formControlName="captive_portal_radius_ip" />
                                <mat-error
                                    *ngIf="form.get('page2').get('captive_portal_radius_ip').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                                <mat-error
                                    *ngIf="form.get('page2').get('captive_portal_radius_ip').hasError('invalid_ip') && !form.get('page2').get('captive_portal_radius_ip').hasError('required')">
                                    Ungültige IP-Adresse
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <!-- Textfeld: Radius-Port -->
                        <mat-list-item>
                            <mat-form-field appearance="outline">
                                <mat-label>Radius-Port</mat-label>
                                <input matInput type="number" min="1" max="65535"
                                    formControlName="captive_portal_radius_port" />
                                <mat-error
                                    *ngIf="form.get('page2').get('captive_portal_radius_port').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                        <!-- Textfeld: Radius-Secret -->
                        <mat-list-item>
                            <mat-form-field appearance="outline">
                                <mat-label>Radius-Secret</mat-label>
                                <input matInput formControlName="captive_portal_radius_secret" />
                                <mat-error
                                    *ngIf="form.get('page2').get('captive_portal_radius_secret').hasError('required')">
                                    Dieses Feld darf nicht leer sein
                                </mat-error>
                            </mat-form-field>
                        </mat-list-item>
                    </ng-container>
                </ng-container>
            </ng-container>
        </mat-list>
    </form>
</ng-template>

<!-- Template: Firewall-Einstellungen -->
<ng-template #page3>
    <form [formGroup]="form.get('page3')">
        <mat-list>
            <!-- Keine weiteren Einstellungen möglich -->
            <mat-list-item
                *ngIf="!form.get('page1').get('gateway').value && form.get('page1').get('network_type').value !== 'management'">
                Keine weiteren Einstellungen möglich
            </mat-list-item>
            <ng-container *ngIf="form.get('page1').get('gateway').value">
                <!-- Toggle: Jugendschutzfilterung im Netzwerk aktivieren -->
                <mat-list-item>
                    <mat-slide-toggle formControlName="filtering">
                        Jugendschutzfilterung im Netzwerk aktivieren
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Toggle: Zugriff auf Mediennetzwerke erlauben -->
                <mat-list-item
                    *ngIf="form.get('page1').get('network_type').value === 'byod' || form.get('page1').get('network_type').value === 'paed' || form.get('page1').get('network_type').value === 'custom'">
                    <mat-slide-toggle formControlName="media_access"
                        [disabled]="form.get('page1').get('network_type').value === 'management' && form.get('page2').get('dhcp_server_enabled').value === true">
                        Zugriff auf Mediennetzwerke erlauben
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Toggle: Alles beim Zugriff ins Internet erlauben -->
                <mat-list-item>
                    <mat-slide-toggle formControlName="inet_access_allowed"
                        [disabled]="form.get('page1').get('network_type').value === 'management'">
                        Alles beim Zugriff ins Internet erlauben
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Checkboxen: Alles beim Zugriff ins Internet erlauben -->
                <mat-list-item *ngIf="!form.get('page3').get('inet_access_allowed').value">
                    <div style="padding-left: 42px; display: flex; flex-direction: column;">
                        <mat-checkbox formControlName="allow_dns" [disabled]="form.get('page3').get('filtering').value">
                            DNS erlauben
                        </mat-checkbox>
                        <mat-checkbox formControlName="allow_web" [disabled]="form.get('page3').get('filtering').value">
                            Web erlauben
                        </mat-checkbox>
                        <mat-checkbox formControlName="allow_email">
                            Email erlauben
                        </mat-checkbox>
                        <mat-checkbox formControlName="allow_push">
                            Push erlauben
                        </mat-checkbox>
                    </div>
                </mat-list-item>
            </ng-container>
            <!-- Toggle: Zugriff auf alle Schulnetzwerke erlauben -->
            <mat-list-item *ngIf="form.get('page1').get('network_type').value === 'management'">
                <mat-slide-toggle [checked]="true" disabled>
                    Zugriff auf alle Schulnetzwerke erlauben
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list>
    </form>
</ng-template>

<!-- Template: Eingaben überprüfen -->
<ng-template #page4>
    <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>settings</mat-icon>
                <span>Allgemeine Netzwerkeinstellungen</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
            <!-- Select: Netzwerktyp -->
            <mat-list-item>
                <mat-form-field appearance="outline">
                    <mat-label>Netzwerktyp</mat-label>
                    <mat-select [value]="form.get('page1').get('network_type').value" disabled>
                        <mat-option value="wan">Uplink-Netzwerk</mat-option>
                        <mat-option value="management">Management-Netzwerk</mat-option>
                        <mat-option value="byod">BYOD-Netzwerk</mat-option>
                        <mat-option value="media">Medien-Netzwerk</mat-option>
                        <mat-option value="paed">Pädagogisches-Netzwerk</mat-option>
                        <mat-option value="custom">Benutzerdefiniertes Netzwerk</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-list-item>
            <!-- Select: IP-Konfiguration -->
            <mat-list-item>
                <mat-form-field appearance="outline">
                    <mat-label>IP-Konfiguration</mat-label>
                    <mat-select [value]="form.get('page1').get('network_ip_type').value" disabled>
                        <mat-option value="dhcp-client">DHCP-Client</mat-option>
                        <mat-option value="static">Statisch</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-list-item>
            <!-- Textfeld: Router IP-Adresse -->
            <mat-list-item *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <mat-form-field appearance="outline">
                    <mat-label>Router IP-Adresse</mat-label>
                    <input matInput disabled [value]="form.get('page1').get('interface_ip').value" />
                </mat-form-field>
            </mat-list-item>
            <!-- Textfeld: Router IP-Subnetz -->
            <mat-list-item *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <mat-form-field appearance="outline">
                    <mat-label>Router IP-Subnetz</mat-label>
                    <input matInput disabled [value]="form.get('page1').get('network_subnet').value" />
                </mat-form-field>
            </mat-list-item>
            <ng-container *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <!-- Toggle: endooConnect ist das Gateway im Netzwerk -->
                <mat-list-item *ngIf="form.get('page1').get('network_type').value !== 'wan'">
                    <mat-slide-toggle disabled [checked]="form.get('page1').get('gateway').value">
                        endooConnect ist das Gateway im Netzwerk
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Textfeld: Gateway IP-Adresse -->
                <mat-list-item *ngIf="!form.get('page1').get('gateway').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Gateway IP-Adresse</mat-label>
                        <input matInput [value]="form.get('page1').get('gateway_ip').value" disabled />
                    </mat-form-field>
                </mat-list-item>
            </ng-container>
            <!-- Toggle: Bevorzugtes WAN-Netzwerk / Uplink Netzwerk -->
            <mat-list-item *ngIf="form.get('page1').get('network_type').value === 'wan'">
                <mat-slide-toggle [value]="form.get('page1').get('preferred_uplink_network').value" disabled>
                    Bevorzugtes WAN-Netzwerk / Uplink Netzwerk
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>settings</mat-icon>
                <span>Weitergehende Netzwerkeinstellungen</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list
            *ngIf="(form.get('page1').get('network_ip_type').value === 'dhcp' && !form.get('page1').get('gateway').value) || form.get('page1').get('network_type').value === 'wan'">
            <!-- Keine weiteren Einstellungen möglich -->
            <mat-list-item>
                Keine weiteren Einstellungen möglich
            </mat-list-item>
        </mat-list>
        <mat-list
            *ngIf="(form.get('page1').get('network_ip_type').value === 'static' || form.get('page1').get('gateway').value) && form.get('page1').get('network_type').value !== 'wan'">
            <ng-container *ngIf="form.get('page1').get('network_ip_type').value === 'static'">
                <!-- Toggle: DHCP-Server im Netzwerk aktivieren -->
                <mat-list-item>
                    <mat-slide-toggle [checked]="form.get('page2').get('dhcp_server_enabled').value" disabled>
                        DHCP-Server im Netzwerk aktivieren
                    </mat-slide-toggle>
                </mat-list-item>
                <ng-container *ngIf="form.get('page2').get('dhcp_server_enabled').value">
                    <!-- Toggle: DHCP-Server Start Adresse -->
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>DHCP-Server Start-Adresse</mat-label>
                            <input matInput [value]="form.get('page2').get('dhcp_server_start_address').value"
                                disabled />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                        <!-- Toggle: DHCP-Server End-Adresse -->
                        <mat-form-field appearance="outline">
                            <mat-label>DHCP-Server End-Adresse</mat-label>
                            <input matInput [value]="form.get('page2').get('dhcp_server_end_address').value" disabled />
                        </mat-form-field>
                    </mat-list-item>
                </ng-container>
            </ng-container>
            <ng-container
                *ngIf="form.get('page1').get('gateway').value && form.get('page1').get('network_type').value !== 'management'">
                <div mat-subheader>Captive Portal</div>
                <mat-list-item>
                    <mat-slide-toggle [checked]="form.get('page2').get('captive_portal_enabled').value" disabled>
                        Captive-Portal aktiviert</mat-slide-toggle>
                </mat-list-item>
                <mat-list-item *ngIf="form.get('page2').get('captive_portal_enabled').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Upload-Rate (MBit/s)</mat-label>
                        <input type="number" matInput
                            [value]="form.get('page2').get('captive_portal_max_client_up_speed').value" disabled />
                    </mat-form-field>
                </mat-list-item>
                <mat-list-item *ngIf="form.get('page2').get('captive_portal_enabled').value">
                    <mat-form-field appearance="outline">
                        <mat-label>Download-Rate (MBit/s)</mat-label>
                        <input type="number" matInput
                            [value]="form.get('page2').get('captive_portal_max_client_down_speed').value" disabled />
                    </mat-form-field>
                </mat-list-item>
                <!-- Button Group: endoo Kennungen verwenden -->
                <mat-list-item *ngIf="form.get('page2').get('captive_portal_enabled').value">
                    <mat-button-toggle-group [value]="form.get('page2').get('captive_portal_endoo_auth').value"
                        disabled>
                        <mat-button-toggle [value]="'endoo-users'">
                            endoo Kennungen verwenden
                        </mat-button-toggle>
                        <mat-button-toggle [value]="'custom'">
                            Benutzerdefiniert
                        </mat-button-toggle>
                        <mat-button-toggle [value]="'endoo-guest'">
                            Bedingungen akzeptieren
                        </mat-button-toggle>
                    </mat-button-toggle-group>
                </mat-list-item>
                <ng-container
                    *ngIf="form.get('page2').get('captive_portal_enabled').value && form.get('page2').get('captive_portal_endoo_auth').value === 'custom'">
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>Radius-Server</mat-label>
                            <input matInput [value]="form.get('page2').get('captive_portal_radius_ip').value"
                                disabled />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>Radius-Port</mat-label>
                            <input matInput type="number"
                                [value]="form.get('page2').get('captive_portal_radius_port').value" disabled />
                        </mat-form-field>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-form-field appearance="outline">
                            <mat-label>Radius-Secret</mat-label>
                            <input matInput [value]="form.get('page2').get('captive_portal_radius_secret').value"
                                disabled />
                        </mat-form-field>
                    </mat-list-item>
                </ng-container>
            </ng-container>
        </mat-list>
    </mat-expansion-panel>
    <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <mat-icon>settings</mat-icon>
                <span>Firewall-Einstellungen</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-list>
            <!-- Keine weiteren Einstellungen möglich -->
            <mat-list-item
                *ngIf="!form.get('page1').get('gateway').value && form.get('page1').get('network_type').value !== 'management'">
                Keine weiteren Einstellungen möglich
            </mat-list-item>
            <ng-container *ngIf="form.get('page1').get('gateway').value">
                <!-- Toggle: Jugendschutzfilterung im Netzwerk aktivieren -->
                <mat-list-item>
                    <mat-slide-toggle [checked]="form.get('page3').get('filtering').value" disabled>
                        Jugendschutzfilterung im Netzwerk aktivieren
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Toggle: Zugriff auf Mediennetzwerke erlauben -->
                <mat-list-item
                    *ngIf="form.get('page1').get('network_type').value === 'byod' || form.get('page1').get('network_type').value === 'paed' || form.get('page1').get('network_type').value === 'custom'">
                    <mat-slide-toggle [checked]="form.get('page3').get('media_access').value" disabled>
                        Zugriff auf Mediennetzwerke erlauben
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Toggle: Alles beim Zugriff ins Internet erlauben -->
                <mat-list-item>
                    <mat-slide-toggle [checked]="form.get('page3').get('inet_access_allowed').value" disabled>
                        Alles beim Zugriff ins Internet erlauben
                    </mat-slide-toggle>
                </mat-list-item>
                <!-- Checkboxen: Alles beim Zugriff ins Internet erlauben -->
                <mat-list-item *ngIf="!form.get('page3').get('inet_access_allowed').value">
                    <div style="padding-left: 42px; display: flex; flex-direction: column;">
                        <mat-checkbox disabled [checked]="form.get('page3').get('allow_dns').value">
                            DNS erlauben
                        </mat-checkbox>
                        <mat-checkbox disabled [checked]="form.get('page3').get('allow_web').value">
                            Web erlauben
                        </mat-checkbox>
                        <mat-checkbox disabled [checked]="form.get('page3').get('allow_email').value">
                            Email erlauben
                        </mat-checkbox>
                        <mat-checkbox disabled [checked]="form.get('page3').get('allow_push').value">
                            Push erlauben
                        </mat-checkbox>
                    </div>
                </mat-list-item>
            </ng-container>
            <!-- Toggle: Zugriff auf alle Schulnetzwerke erlauben -->
            <mat-list-item *ngIf="form.get('page1').get('network_type').value === 'management'">
                <mat-slide-toggle [checked]="true" disabled>
                    Zugriff auf alle Schulnetzwerke erlauben
                </mat-slide-toggle>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
</ng-template>