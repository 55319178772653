import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBAdmin } from '../../data/endooSpotDB_objects';
import { AdminService } from '../AdminService/admin.service';

@Component({
    templateUrl: './deleteAdmin.dialog.component.html',
})
export class deleteAdminDialogComponent {
    username: string;
    users: DBAdmin[];

    /**
     * Constructor for the angular delete admin dialog component. All params except data will be directly injected from angular
     * @param adminService Service object for communication with admin rest api backend.
     * @param snackBar Snack bar object to handle everything regarding snack bars in the application.
     * @param dialogRef Reference to the dialog the component belongs to
     * @param data Data object which will be inserted by the component which opens the dialog. It requests a username with object name "username" in the data object or an array with admin objects called users
     */
    constructor(
        private adminService: AdminService,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<deleteAdminDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        if (data.username) {
            this.username = data.username;
        } else {
            this.users = data.users;
        }
    }

    /**
     * function which will be executed when button in dialog will be clicked.
     */
    async deleteAdminsClick_dialog() {
        if (this.username) {
            try {
                await this.adminService.delete(this.username).toPromise();
                this.snackBar.open('Der Benutzer wurde gelöscht ', 'OK');
                this.dialogRef.close(true);
            } catch (err) {
                this.snackBar.open('Der Benutzer konnte nicht gelöscht werden: ' + err, 'OK');
                this.dialogRef.close(false);
            }
        } else {
            for (let i = 0; i < this.users.length; i++) {
                try {
                    const user = this.users[i];
                    await this.adminService.delete(user.username).toPromise();
                } catch (err) {
                    this.snackBar.open('Der Benutzer ' + this.users[i].username + ' konnte nicht gelöscht werden: ' + err, 'OK');
                    this.dialogRef.close(false);
                }
            }
            this.snackBar.open('Die Benutzer wurden gelöscht', 'OK');
            this.dialogRef.close(true);
        }
    }

    /**
     * Function which will be called if "cancel" has been clicked so that the dialog should be closed without any changes.
     */
    cancelDeleteAdminsClick_dialog() {
        this.dialogRef.close(false);
    }
}
