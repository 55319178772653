<h1 mat-dialog-title>Gerät entfernen</h1>
<div mat-dialog-content>
	<mat-horizontal-stepper linear=true>
		<mat-step [stepControl]="form" style="padding-bottom:20px;" label="Geräteinformationen">
			<form [formGroup]="form">
				<div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
					<mat-radio-group formControlName="isDefect" [(ngModel)]="isDefect"
						style="display: flex; flex-direction: column;">
						<mat-radio-button [value]="false" style="margin-bottom: 10px;">
							Gerät nicht in Gebrauch
						</mat-radio-button>
						<mat-radio-button [value]="true">
							Gerät ist defekt
						</mat-radio-button>
						<mat-error *ngIf="hasError('isDefect', 'required')">Auswahl erforderlich
						</mat-error>
					</mat-radio-group>
					<mat-form-field appearance="outline" style="margin-top: 20px;">
						<mat-label>E-Mail Adresse</mat-label>
						<input matInput formControlName="email" [(ngModel)]="email" required />
						<mat-error *ngIf="hasError('email', 'required')">E-Mail Adresse erforderlich
						</mat-error>
						<mat-error *ngIf="hasError('email', 'email')">E-Mail Adresse <strong>ungültig</strong>
						</mat-error>
					</mat-form-field>
					<mat-form-field appearance="outline" style="margin-top: 20px;">
						<mat-label>Beschreibung</mat-label>
						<textarea matInput rows="5" formControlName="defectMatter" [(ngModel)]="defectMatter"
							required></textarea>
						<mat-error *ngIf="hasError('defectMatter', 'required')">
							Beschreibung erforderlich
						</mat-error>
					</mat-form-field>
				</div>
			</form>
			<mat-divider></mat-divider>
			<div>
				<button mat-button matStepperNext>Weiter</button>
			</div>
		</mat-step>
		<mat-step label="Eingaben überprüfen">
			<div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
				<mat-radio-group disabled [(ngModel)]="isDefect" style="display: flex; flex-direction: column;">
				</mat-radio-group>
				<mat-form-field appearance="outline" style="margin-top: 20px;">
					<mat-label>E-Mail Adresse</mat-label>
					<input matInput disabled [(ngModel)]="email" />
				</mat-form-field>
				<mat-form-field appearance="outline" style="margin-top: 20px;">
					<mat-label>Beschreibung</mat-label>
					<textarea matInput disabled rows="5" [(ngModel)]="defectMatter"></textarea>
				</mat-form-field>
			</div>
			<mat-divider></mat-divider>
			<div>
				<button class="button-margin-right" mat-button matStepperPrevious>Zurück</button>
				<button mat-button (click)="removeDevice()">Gerät entfernen</button>
			</div>
		</mat-step>
	</mat-horizontal-stepper>
</div>