import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from 'app/Services/app.service';
import { UserDevice } from '../../data/UserDevice';
import { UserDeviceService } from '../UserDeviceService/userDevice.service';

@Component({
    templateUrl: './UserDeviceList.component.html',
    styleUrls: ['./UserDeviceList.component.css'],
})
export class userDeviceListComponent implements OnInit {
    displayedColumns: string[] = ['hostname', 'ip', 'mac', 'owner', 'filtered']; // columns in frontend which should be displayed
    userDevices: MatTableDataSource<UserDevice>;
    background_working: boolean = true;
    // view elemts from frontend
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    /**
     * @param userDeviceService Service which will be injected by angular
     * @param snackBar snackbar object which will be injected by angular
     */
    constructor(
        private userDeviceService: UserDeviceService,
        private snackBar: MatSnackBar,
        public appService: AppService
    ) {
        this.userDevices = new MatTableDataSource();
        this.userDevices.sort = this.sort;
        this.userDevices.paginator = this.paginator;
    }

    ngOnInit() {
        this.getUserDevList();
    }

    /**
     * Function to retrieve list of user Devices via Angular Service.
     */
    async getUserDevList(): Promise<void> {
        this.background_working = true;
        try {
            this.userDevices.data = await this.userDeviceService.getUserDevices().toPromise();
            // reload table sort and pagination
            this.userDevices.sort = this.sort;
            this.userDevices.paginator = this.paginator;
            this.preparePaginator();
            this.background_working = false;
            if (this.userDevices.data.length === 0) {
                this.snackBar.open('Es wurden keine Geräte gefunden', 'OK');
            }
        } catch (err) {
            this.snackBar.open('Die Geräte konnten nicht geladen werden: ' + err, 'OK');
        }
        this.background_working = false;
    }

    /**
     * Sets the size of table entries saved if any
     */
    preparePaginator(): void {
        if (localStorage.getItem('userDeviceList.pageSize')) {
            this.userDevices.paginator.pageSize = Number(localStorage.getItem('userDeviceList.pageSize'));
            this.userDevices.paginator = this.paginator; // Update the paginator
        }
        this.userDevices.paginator._changePageSize = pageSize => {
            localStorage.setItem('userDeviceList.pageSize', String(pageSize));
            this.userDevices.paginator.pageSize = pageSize;
            this.userDevices.paginator = this.paginator; // Update the paginator
        };
    }

    /**
     * Function which will be called when table sort has been changed by the user. Because then pagination should be set to 1
     * @param event
     */
    sortDataChanged(event): void {
        this.userDevices.paginator.pageIndex = 0;
    }

    /**
     * Function which will be called from frontend when the filter of a userdevice should be changed
     * @param userDevice The user-Device the filter should be switched for
     */
    async switchFilter(userDevice: UserDevice): Promise<void> {
        // read information from the checkbox and send then the change command to the server
        const loginmask1 = !userDevice.filtered;
        this.background_working = true;
        try {
            await this.userDeviceService.setFilter(userDevice, loginmask1).toPromise();
        } catch (err) {
            this.snackBar.open('Das Gerät konnte nicht gespeichert werden: ' + err, 'OK');
        }
        this.getUserDevList();
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value;
        this.userDevices.filter = filterValue.trim().toLowerCase();
    }
}
