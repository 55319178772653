export class Text {
    x: number;
    y: number;
    fillStyle: string;
    textAlign: CanvasTextAlign;
    textBaseLine: CanvasTextBaseline;
    font: string;
    value: string;

    /**
     * 
     * @param x Die X-Komponente des Textes.
     * @param y Die Y-Komponente des Textes.
     * @param fillStyle Die Farbe des Textes.
     * @param textAlign Die horizontale Ausrichtung des Textes.
     * @param textBaseLine Die vertikale Ausrichtung des Textes.
     * @param font Die Schriftart des Textes.
     * @param value Der Text selbst.
     */
    constructor(x: number, y: number, fillStyle: string, textAlign: CanvasTextAlign, textBaseLine: CanvasTextBaseline, font: string, value: string) {
        this.x = x;
        this.y = y;
        this.fillStyle = fillStyle;
        this.textAlign = textAlign;
        this.textBaseLine = textBaseLine;
        this.font = font;
        this.value = value;
    }
}
