<h1 mat-dialog-title>Neues Gerät erstellen</h1>
<div mat-dialog-content>
    <mat-horizontal-stepper linear=true #stepper="matHorizontalStepper">
        <mat-step [stepControl]="form" style="padding-bottom:20px;" label="Ger&auml;teinformationen">
            <div style="display: flex;">
                <div style="width: 50%">
                    <form [formGroup]="form">
                        <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                            <mat-form-field appearance="outline">
                                <mat-label>Modell wählen*</mat-label>
                                <input matInput placeholder="Modell suchen:" type="text" [matAutocomplete]="autoModel"
                                    formControlName="model_id" [(ngModel)]="new_device.model"
                                    style="text-transform: uppercase;" (input)="onManualInput()" />
                                <mat-autocomplete #autoModel="matAutocomplete" [displayWith]="displayNameOfTheModel"
                                    (optionSelected)="onSelectionChange($event.option.value)">
                                    <mat-option *ngFor="let model of selectedModels" [value]="model.modelId"
                                        style="text-transform: uppercase;">
                                        {{model.modelId}}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-error *ngIf="hasError('model_id', 'required')">Modellauswahl
                                    erforderlich
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>MAC-Adresse*</mat-label>
                                <input formControlName="mac" [(ngModel)]="new_device.mac" matInput maxlength="17" />
                                <mat-error *ngIf="hasError('mac', 'required')">MAC erforderlich
                                </mat-error>
                                <mat-error *ngIf="hasError('mac', 'maxlength') || hasError('mac', 'pattern')">MAC
                                    <strong>ungültig</strong>
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Seriennummer*</mat-label>
                                <input formControlName="serial_number" [(ngModel)]="new_device.serial_number"
                                    matInput />
                                <mat-error *ngIf="hasError('serial_number', 'required')">Seriennummer
                                    erforderlich
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </form>
                </div>
                <div style="width: 50%; display: flex; justify-content: center; align-items: center; padding: 20px;">
                    <p *ngIf="!modelImage" class="user-select-none">Modell wählen</p>
                    <img *ngIf="modelImage" [src]="modelImage" style="max-width: 120px;" draggable="false" />
                </div>
            </div>
            <div class="fullwidth_form">
                <mat-form-field appearance="outline">
                    <mat-label>Kommentar</mat-label>
                    <textarea matInput cdkTextareaAutosize rows="3" [(ngModel)]="new_device.comment"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div>
                <button mat-button matStepperNext>Weiter</button>
            </div>
        </mat-step>
        <mat-step label="Eingaben überprüfen">
            <div style="display: flex;">
                <div style="width: 50%">
                    <div class="fullwidth_form" style="margin-top: 20px; margin-bottom: 20px;">
                        <mat-form-field appearance="outline">
                            <mat-label>Modell</mat-label>
                            <input disabled matInput [(ngModel)]="new_device.model"
                                style="text-transform: uppercase;" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>MAC-Adresse</mat-label>
                            <input disabled matInput [(ngModel)]="new_device.mac" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Seriennummer</mat-label>
                            <input disabled matInput [(ngModel)]="new_device.serial_number" />
                        </mat-form-field>
                    </div>
                </div>
                <div style="width: 50%; display: flex; justify-content: center; align-items: center; padding: 20px;">
                    <p *ngIf="!modelImage" class="user-select-none">Modell wählen</p>
                    <img *ngIf="modelImage" [src]="modelImage" style="max-width: 120px;" draggable="false" />
                </div>
            </div>
            <div class="fullwidth_form">
                <mat-form-field appearance="outline">
                    <mat-label>Kommentar</mat-label>
                    <textarea disabled matInput cdkTextareaAutosize rows="3"
                        [(ngModel)]="new_device.comment"></textarea>
                </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div>
                <button class="button-margin-top button-margin-right" mat-button matStepperPrevious>Zurück</button>
                <button mat-button (click)="newDevice(stepper)">Gerät
                    erstellen</button>
            </div>
        </mat-step>
        <mat-step label="Erstelltes Gerät">
            <mat-list>
                <mat-list-item>
                    <b>Modell:</b>&nbsp;
                    <span style="text-transform: uppercase;">{{new_device.model}}</span>
                </mat-list-item>
                <mat-list-item>
                    <b>Inventarnummer:</b>&nbsp;
                    <span style="text-transform: uppercase;">{{new_device.inventoryNumber}}</span>
                </mat-list-item>
                <mat-list-item>
                    <b>MAC-Adresse:</b>&nbsp;
                    <span>{{new_device.mac}}</span>
                </mat-list-item>
                <mat-list-item>
                    <b>Seriennummer:</b>&nbsp;
                    <span>{{new_device.serial_number}}</span>
                </mat-list-item>
            </mat-list>
            <mat-divider></mat-divider>
            <div>
                <button class="button-margin-right" mat-button (click)="printModelLabel()">Label
                    drucken</button>
                <button mat-button (click)="CloseDialog(true)">Schließen</button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>
<!-- This QR Code is used for label printing -->
<div [hidden]="true">
    <qrcode [qrdata]="qrData ? qrData : 'error'" [width]="72" [errorCorrectionLevel]="'M'" [elementType]="'svg'">
    </qrcode>
</div>