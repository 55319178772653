import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Group } from '../../data/Group';
import { GroupService } from '../GroupService/group.service'

@Component({
    templateUrl: './NewGroup.component.html',
})
export class newGroupComponent {
    group_new: Group = new Group();
    newGroupForm: FormGroup;

    constructor(
        private groupService: GroupService,
        private _formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<newGroupComponent>
    ) {
        this.newGroupForm = this._formBuilder.group({
            groupname: ['', [Validators.required, Validators.pattern(/[a-zA-Z0-9]/)]],
            description: ['', [Validators.required, Validators.pattern(/[a-zA-Z0-9 ]/)]]
        });
    }

    /**
     * Function which will be called when User clicks on the save button of the new Group dialog. via angular.
     */
    async saveNewGroupBtClick(newGroupStepper): Promise<void> {
        // test if inserted values have the right format
        const regex_groupname: RegExp = new RegExp(/[a-zA-Z0-9]/);
        const regex_description: RegExp = new RegExp(/[a-zA-Z0-9 ]/);
        const checked = regex_groupname.test(this.group_new.groupname) && regex_description.test(this.group_new.description);
        if (checked) {
            try {
                const result_value = await this.groupService.create(this.group_new).toPromise();
                if (!result_value) {
                    throw new Error('Ein unbekannter Fehler ist aufgetreten');
                }
                this.group_new = new Group();
                this.snackBar.open('Die Gruppe wurde angelegt', 'OK');
                newGroupStepper.next();
            } catch (err) {
                this.snackBar.open('Die Gruppe konnte nicht angelegt werden: ' + err, 'OK');
            }
        }
    }

    resetStepper(stepper): void {
        stepper.reset();
    }

    /**
     * Says whether an error is occurred.
     * @param formControl The formControlName
     * @param error The error/validator type
     */
    hasError(formControl: string, error: string): boolean {
        return this.newGroupForm.get(formControl).hasError(error);
    }

    closeDialogBtClick(): void {
        this.dialogRef.close();
    }
}
