import { Component, Output, EventEmitter, Input, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

@Component({
    selector: 'color-picker',
    templateUrl: 'color-picker.component.html',
    styleUrls: ['color-picker.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ColorPickerComponent),
            multi: true,
        },

    ],
})
export class ColorPickerComponent implements ControlValueAccessor, Validator {
    onChanged: any = () => { };
    onTouched: any = () => { };
    onValidationChange: any = () => { };

    initialColor: string;
    disabled: boolean;
    @Input() colors: string[];
    @Input() deactivatedColors: string[];

    /**
     * For use as direct value setter/getter.
     * You should use this component alternatively with ngModel or formControlName for form validation reasons.
     */
    @Input() selectedColor: string;
    @Output() ColorSelected: EventEmitter<string> = new EventEmitter();

    get SelectedColor() {
        return this.selectedColor;
    }

    set SelectedColor(color: string) {
        this.selectedColor = color;
        this.onChanged(this.selectedColor);
        this.onValidationChange();
    }

    writeValue(value: string) {
        if (!this.selectedColor) {
            this.initialColor = value;
        }
        this.selectedColor = value;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    registerOnChange(fn: any): void {
        this.onChanged = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        return !this.selectedColor ? { invalid: true } : null;
    }

    selectColor(color: string): void {
        if (!this.disabled && color !== this.selectedColor && (!this.deactivatedColors.includes(color) || color === this.initialColor)) {
            this.SelectedColor = color;
            this.ColorSelected.emit(color);
        }
    }
}
