<div class="sidenav_container" [@onSideNavChange]="sideNavState ? 'open' : 'close'">
    <mat-card id="sidebar-card">
        <mat-card-header>
            <div mat-card-avatar id="profile_picture"></div>
            <span id="endoo-control" class="user-select-none">{{appTitle}}</span>
        </mat-card-header>
    </mat-card>
    <mat-divider></mat-divider>
    <mat-nav-list *ngIf="authenticationService.hasPermission('HARDWARE-DASHBOARD')">
        <a mat-list-item title="Hier erhalten Sie zusätzliche Netzwerkinformationen."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/dashboard'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">assessment</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Dashboard</span>
        </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list>
        <a mat-list-item title="Sie können hier Benutzer anlegen."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/userList'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">person</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Benutzerverwaltung</span>
        </a>
        <a mat-list-item title="Sie können hier Gruppen anlegen."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/groupList'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">people</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Gruppenverwaltung</span>
        </a>
        <a *ngIf="authenticationService.hasPermission('BYOD-ADMINISTRATOR')" mat-list-item
            title="Hier können Sie WLAN in die Klasse bringen."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/shortTermLoginList'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">lock_clock</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">&nbsp;Kurzfristige Kennungen</span>
        </a>
        <a *ngIf="authenticationService.hasPermission('BYOD2-ADMINISTRATOR') && !authenticationService.hasPermission('BYOD-ADMINISTRATOR')"
            mat-list-item title="Hier werden die Ger&auml;te aufgelistet, welche mit dem endoo-Netz verbunden sind."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/userDeviceList'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">devices</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Verbundene Endger&auml;te</span>
        </a>
        <a *ngIf="authenticationService.hasPermission('BYOD-ADMINISTRATOR')" mat-list-item
            title="Hier werden die Ger&auml;te aufgelistet, welche mit dem endoo-Netz verbunden sind."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/userDeviceListRouter'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">devices</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Verbundene Endger&auml;te</span>
        </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list>
        <a *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-MANAGER')"
            mat-list-item title="Hier verwalten Sie Ihre logischen Netzwerke."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/networkManagement'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">settings_ethernet</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Schulnetzwerke</span>
        </a>
    </mat-nav-list>
    <mat-divider></mat-divider>
    <mat-nav-list>
        <a *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-MANAGER')"
            mat-list-item title="Hier können Sie Ihre Geräte verwalten."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/apList'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">router</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">Ger&auml;teverwaltung</span>
        </a>
        <a *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR') || authenticationService.hasPermission('HARDWARE-MANAGER')"
            mat-list-item title="Hier verwalten Sie Ihre WLAN-Netzwerke."
            [routerLink]="appService.isTouchScreen() && !sideNavState ? [] : '/app/wifiNetworkManagement'"
            [routerLinkActive]="appService.isTouchScreen() && !sideNavState ? 'undefined' : 'active'">
            <mat-icon class="entry-icon">wifi</mat-icon>
            <span class="entry-text" [@animateText]="linkText ? 'show' : 'hide'">WLAN-Netzwerke</span>
        </a>
    </mat-nav-list>

</div>
