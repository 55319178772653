import { Content } from './canvas.component';

export abstract class GraphicElement {
    x: number;
    y: number;
    content: Content;

    /**
     * @param x Die X-Komponente welche in der Mitte des Elements liegen soll.
     * @param y Die Y-Komponente welche in der Mitte des Elements liegen soll.
     */
    constructor(x: number, y: number, content: Content) {
        this.x = x;
        this.y = y;
        this.content = content;
    }
}
