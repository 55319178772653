import { Component } from '@angular/core';
import { WifiSettingsService } from '../APService/WifiSettings.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WifiNetwork } from '../../data/WifiNetwork';
import { AuthenticationService } from 'app/Login/authentication.service';

@Component({
    templateUrl: './apGlobal.component.html',
})
export class apGlobalComponent {
    wifiSettings: WifiNetwork;

    constructor(
        public wifiSettingsService: WifiSettingsService,
        private snackBar: MatSnackBar,
        public authenticationService: AuthenticationService
    ) {
        this.getWifiSettings();
    }

    async getWifiSettings(): Promise<void> {
        try {
            // Get the wifisettings and set the scope variable so that the view will work.
            this.wifiSettings = await this.wifiSettingsService.getendooNetwork().toPromise();
        } catch (err) {
            this.snackBar.open('Das Netzwerk konnte nicht geladen werden: ' + err, 'OK');
        }
    }
}
