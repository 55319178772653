import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { endooSpotAppComponent } from './endooSpotApplication.component';
import { ChangeUserListComponent } from '../UserManagement/UserList/UserList.component';
import { apListComponent } from '../APManagement/APList/apList.component';
import { apGlobalComponent } from '../APManagement/APGlobal/apGlobal.component';
import { userDeviceListComponent } from '../UserDeviceManagement/UserDeviceList/UserDeviceList.component';
import { groupListComponent } from '../GroupManagement/GroupList/GroupList.component';
import { LogicalNetworkManagementComponent } from '../NetworkManagement/LogicalNetworkManagementComponent/LogicalNetworkManagement.component';
import { adminListComponent } from '../AdminManagement/AdminList/AdminList.component';
import { WifiNetworkManagementComponent } from '../NetworkManagement/WifiNetworkManagementComponent/WifiNetworkManagement.component';
import { CustomerFunctionPropertyService } from './Services/CustomerFunctionPropertyService';
import { userDeviceListRouterComponent } from '../UserDeviceManagementRouter/UserDeviceListRouter/UserDeviceListRouter.component';
import { DashboardComponent } from 'app/Dashboard/dashboard.component';
import { CanActivateAuthGuard } from 'app/Login/canActivateAuthguard';
import { GlobalSwitchSettingsComponent } from 'app/APManagement/GlobalSwitchSettings/globalSwitchSettings.component';
import { initialNetworkSetupComponent } from 'app/InitialNetworksSetup/initialNetworkSetup.component';
import { RouterGlobalComponent } from 'app/APManagement/RouterGlobal/routerGlobal.component';
import { StartpageComponent } from 'app/Startpage/startpage.component';
import { ShortTermLoginListComponent } from 'app/ShortTermLogin/ShortTermList/ShortTermLoginList.component';

const endooSpotAppRoutes: Routes = [
    {
        path: 'app',
        component: endooSpotAppComponent,
        canActivate: [CanActivateAuthGuard],
        children: [
            {
                path: '',
                component: StartpageComponent,
                data: {
                    title: 'Willkommen',
                    rolesAll: [],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'userList',
                component: ChangeUserListComponent,
                data: {
                    title: 'Benutzerverwaltung',
                    rolesAll: [],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'shortTermLoginList',
                component: ShortTermLoginListComponent,
                data: {
                    title: 'Kurzfristige Kennungen',
                    rolesAll: ['BYOD-ADMINISTRATOR'],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'apList',
                component: apListComponent,
                data: {
                    title: 'Geräteverwaltung',
                    rolesAll: [],
                    rolesOneOf: ['HARDWARE-ADMINISTRATOR', 'HARDWARE-MANAGER'],
                    roleNoneOf: [],
                },
            },
            {
                path: 'apGlobal',
                component: apGlobalComponent,
                data: {
                    title: 'Geräteverwaltung',
                    rolesAll: ['HARDWARE-ENDOOSPOT'],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'switchGlobal',
                component: GlobalSwitchSettingsComponent,
                data: {
                    title: 'Geräteverwaltung',
                },
            },
            {
                path: 'routerGlobal',
                component: RouterGlobalComponent,
                data: {
                    title: 'Geräteverwaltung',
                },
            },
            {
                path: 'userDeviceList',
                component: userDeviceListComponent,
                data: {
                    title: 'Verbundene Endgeräte',
                    rolesAll: ['BYOD2-ADMINISTRATOR'],
                    rolesOneOf: [],
                    roleNoneOf: ['BYOD-ADMINISTRATOR'],
                },
            },
            {
                path: 'userDeviceListRouter',
                component: userDeviceListRouterComponent,
                data: {
                    title: 'Verbundene Endgeräte',
                    rolesAll: ['BYOD-ADMINISTRATOR'],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'groupList',
                component: groupListComponent,
                data: {
                    title: 'Gruppenverwaltung',
                    rolesAll: [],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'networkManagement',
                component: LogicalNetworkManagementComponent,
                data: {
                    title: 'Schulnetzwerke',
                    rolesAll: [],
                    rolesOneOf: ['HARDWARE-ADMINISTRATOR', 'HARDWARE-MANAGER'],
                    roleNoneOf: [],
                },
            },
            {
                path: 'wifiNetworkManagement',
                component: WifiNetworkManagementComponent,
                data: {
                    title: 'WLAN-Netzwerke',
                    rolesAll: [],
                    rolesOneOf: ['HARDWARE-ADMINISTRATOR', 'HARDWARE-MANAGER'],
                    roleNoneOf: [],
                },
            },
            {
                path: 'adminManagement',
                component: adminListComponent,
                data: {
                    title: 'Administratorverwaltung',
                    rolesAll: ['ADMINISTRATOR-ADMINISTRATOR'],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: {
                    title: 'Dashboard',
                    rolesAll: ['HARDWARE-DASHBOARD'],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
            {
                path: 'initialSetup',
                component: initialNetworkSetupComponent,
                data: {
                    title: 'Einrichtungsdialog',
                    rolesAll: [],
                    rolesOneOf: [],
                    roleNoneOf: [],
                },
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(endooSpotAppRoutes)],
    exports: [RouterModule],
    providers: [CustomerFunctionPropertyService],
})
export class endooSpotManagementAppRoutingModule { }
