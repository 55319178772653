<div style="margin-bottom: 10px;">
    <mat-accordion>
        <mat-expansion-panel *ngIf="authenticationService.hasPermission('HARDWARE-ADMINISTRATOR')">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Einstellungen
                </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="toggle-section">
                <mat-slide-toggle [(ngModel)]="wifiCard.enabled" (change)="saved(false)">WLAN-Modul aktiviert
                </mat-slide-toggle>
            </section>
            <div style="margin-bottom: 20px;">
                <mat-form-field appearance="outline">
                    <mat-label>Kanal</mat-label>
                    <mat-select type="text" [(ngModel)]="wifiCard.channel" (selectionChange)="saved(false)">
                        <mat-option value="auto">Automatisch (empfohlen)</mat-option>
                        <mat-option value="1">1</mat-option>
                        <mat-option value="2">2</mat-option>
                        <mat-option value="3">3</mat-option>
                        <mat-option value="4">4</mat-option>
                        <mat-option value="5">5</mat-option>
                        <mat-option value="6">6</mat-option>
                        <mat-option value="7">7</mat-option>
                        <mat-option value="8">8</mat-option>
                        <mat-option value="9">9</mat-option>
                        <mat-option value="10">10</mat-option>
                        <mat-option value="11">11</mat-option>
                        <mat-option value="12">12</mat-option>
                        <mat-option value="13">13</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-bottom: 20px;">
                <mat-form-field appearance="outline">
                    <mat-label>Kanalbreite</mat-label>
                    <mat-select matInput type="text" [(ngModel)]="wifiCard.channel_width"
                        (selectionChange)="saved(false)">
                        <mat-option value="HT20">20 MHz</mat-option>
                        <mat-option value="HT40">40 MHz</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="margin-bottom: 20px;">
                <mat-form-field appearance="outline">
                    <mat-label>Sendeleistung</mat-label>
                    <mat-select matInput type="text" [(ngModel)]="wifiCard.tx_power" (selectionChange)="saved(false)">
                        <mat-option value="20">20dBm (empfohlen)</mat-option>
                        <mat-option value="18">18dBm</mat-option>
                        <mat-option value="16">16dBm</mat-option>
                        <mat-option value="14">14dBm</mat-option>
                        <mat-option value="12">12dBm</mat-option>
                        <mat-option value="10">10dBm</mat-option>
                        <mat-option value="8">8dBm</mat-option>
                        <mat-option value="6">6dBm</mat-option>
                        <mat-option value="4">4dBm</mat-option>
                        <mat-option value="2">2dBm</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div mat-line>
                <button mat-raised-button color="primary"
                    class="button-margin-bottom button-margin-top button-margin-right"
                    (click)="saveWifiCardSettings()">Speichern</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>